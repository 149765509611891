import * as Sentry from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";
import {
  getBankAccountsById,
  getUsersMFIAccountsById,
} from "../../store/features/Transfer/Transfer";
import {
  createNeeroWallet,
  fetchMMAccountsById,
  getAllUserNeeroWallets,
  getAllUserNeeroWalletsById,
} from "../../store/features/Auth/Auth";
import MobileWithdrawBox from "../TransferPaymentWithdrawModal/MobileWithdrawBox";
import BankWithdrawBox from "../TransferPaymentWithdrawModal/BankWithdrawBox";
import MfiWithdrawBox from "../TransferPaymentWithdrawModal/MfiWithdrawBox";
import DeliveryWithdrawBox from "../TransferPaymentWithdrawModal/DeliveryWithdrawBox";
import { useAppSelector } from "../../store/hooks";
import { isEmpty } from "../../utilities/help";
import BeneficiarySummary from "../BeneficiarySumary";
import CashLayout from "../CashLayout";
import { FormControlLabel, Switch } from "@material-ui/core";
import WithdrawMethodDescription from "../TransferPaymentWithdrawModal/WithdrawMethodDescription";
import TransferInfo from "../TransferPaymentWithdrawModal/TransferInfo";
import CustomButton from "../CustomButton";
import NeeroWalletWithdrawBox from "../TransferPaymentWithdrawModal/NeeroWalletWithdrawBox";
import { toast } from "material-react-toastify";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js/max";

const TransferPaymentWithdrawModal = (props: any) => {
  const {
    transfer,
    userBalance,
    t,
    paymentMethod,
    checked,
    amountInEuros,
    spread_config,
    errorGot,
    fetchingFees,
    withdrawalFees,
    fees,
    widthdrawMethod,
    hideWithdrawModal,
    processingPayment,
    submitTransaction,
    feesCalculated,
    setcustomMobileOperator,
    setinstitutionId,
    setwidthdrawMethod,
    mobileOperatorList,
    selectedMobileOperator,
    setpaymentActiveStep,
    setselectedMobileOperator,
    setSelectedCountryReset,
    phone,
    setPhone,
    customMobileOperator,
    setcustomPhoneNumber,
    setcustomBankName,
    bankSelectionList,
    customBankName,
    selectedBankName,
    accountHolderName,
    setIBAN,
    setcustomIban,
    numberInputInvalidChars,
    customPhoneNumber,
    setselectedBankName,
    iBAN,
    customIban,
    mfiSelectionList,
    setcustomMfiName,
    setcustomMfiOwnerId,
    setselectedMfiName,
    customMfiName,
    mfiAccountUserName,
    setmfiOwnerId,
    mfiOwnerId,
    setmfiAccountUserName,
    customMfiOwnerId,
    selectedMfiName,
    size,
    steps,
    allDirectPaymentMethods,
    checkingForWithdrawProviders,
    setChecked,
    directPaymentAmountExceed,
    setAmountInEuros,
    allWithdrawFeesList,
    fetchingAllWithdrawFeesList,
    setneeroWalletId,
    neeroWalletId,
    neeroWalletNumber,
    setneeroWalletNumber,
    customNeeroWalletNumber,
    setcustomNeeroWalletNumber,
    feesResponse,
  } = props;

  const {
    auth: { user },
  } = useAppSelector((state) => state.persistedReducer);

  // toggle check on toggle for widthdraw mobile
  const handleChange = (event: any) => {
    window.localStorage.setItem(
      "withdrawEnabled",
      event.target.checked.toString()
    );

    setChecked(event.target.checked);
    setPhone(transfer?.beneficiary?.phone_number);

    // set mobile money as default if not repeat
    if (
      transfer?.transactionToRepeat == undefined ||
      transfer?.transactionToRepeat == null
    ) {
      if (event.target.checked) {
        setwidthdrawMethod("Mobile Money");
      } else {
        setwidthdrawMethod("");
      }
    }
  };

  const bonus = useMemo(() => {
    return widthdrawMethod !== "Mobile Money"
      ? 0
      : spread_config.received_amount * 0.005;
  }, [spread_config, widthdrawMethod]);

  // all benefitiary accounts
  const [beneficiaryMfiAccounts, setbeneficiaryMfiAccounts] = useState([]);
  const [beneficiaryMMAccounts, setBeneficiaryMMAccounts] = useState([]);
  const [beneficiaryBankAccounts, setBeneficiaryBankAccounts] = useState([]);
  const [beneficiaryNeeroWallets, setbeneficiaryNeeroWallets] = useState([]);

  const showNeero = useMemo(() => {
    try {
      if (transfer?.beneficiary?.country?.toLowerCase() == "cameroon") {
        const isNeeroWalletPresent = allDirectPaymentMethods.find(
          (method: any) =>
            method.cashout_method.payment_type.name == "wallet" &&
            method.cashout_method.payment_provider.name == "Neero"
        );
        if (isNeeroWalletPresent) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }, [transfer]);

  // show hide delivery
  const [isDeliveryPayoutPresent, setisDeliveryPayoutPresent] = useState(false);
  useEffect(() => {
    if (
      allDirectPaymentMethods !== undefined &&
      allDirectPaymentMethods.length > 0
    ) {
      setisDeliveryPayoutPresent(
        allDirectPaymentMethods.some(
          (method: any) =>
            method?.cashout_method?.payment_type?.name == "delivery"
        )
      );
    }
  }, [allDirectPaymentMethods]);

  const [fetchingBeneficiaryAccounts, setfetchingBeneficiaryAccounts] =
    useState(false);

  // get and set all benefitiary account for selection
  const getAllBenefitiaryAccounts = async (id: string) => {
    try {
      const tasks: any = [
        getUsersMFIAccountsById(id),
        getBankAccountsById(id),
        fetchMMAccountsById(id),
        getAllUserNeeroWalletsById(id),
      ];

      const other = [{ id: 99, name: "Other" }];
      setfetchingBeneficiaryAccounts(true);

      for (const [fnIndex, fn] of tasks.entries()) {
        try {
          const response = await fn;
          if (fnIndex == 2) {
            setfetchingBeneficiaryAccounts(false);
          }
          if (
            response?.data !== undefined &&
            response?.data !== null &&
            response?.data?.length > 0
          ) {
            if (fnIndex == 0) {
              setbeneficiaryMfiAccounts(
                response?.data
                  ?.map((acc: any) => ({ name: acc?.owner_id, id: acc?.id }))
                  .filter((itm: any) => itm?.name !== "Other")
                  .concat(other)
              );
            } else if (fnIndex == 1) {
              setBeneficiaryBankAccounts(
                response?.data
                  ?.map((acc: any) => ({ name: acc?.iban, id: acc?.id }))
                  .filter((itm: any) => itm?.name !== "Other")
                  .concat(other)
              );
            } else if (fnIndex == 2) {
              setBeneficiaryMMAccounts(
                response?.data
                  .map((acc: any) => ({ name: acc?.phone_number, id: acc?.id }))
                  .filter((itm: any) => itm?.name !== "Other")
                  .concat(other)
              );
            } else if (fnIndex == 3) {
              setbeneficiaryNeeroWallets(
                response?.data
                  .map((acc: any) => ({
                    name: acc?.wallet_account_number,
                    id: acc?.account_id,
                  }))
                  .filter((itm: any) => itm?.name !== "Other")
                  .concat(other)
              );
            }
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    } catch (error: any) {
      setfetchingBeneficiaryAccounts(false);
      Sentry.captureException(error);
    }
  };

  // enable recipient withdraw toggle if receiver is not active and verified company
  useEffect(() => {
    try {
      // set recievers name from contact info
      if (
        transfer?.beneficiary?.id !== undefined &&
        transfer?.beneficiary?.id !== null
      ) {
        getAllBenefitiaryAccounts(transfer?.beneficiary?.id);
      }

      if (
        transfer?.beneficiary?.is_individual ||
        transfer?.beneficiary?.company == null ||
        transfer?.beneficiary?.company == undefined ||
        (!transfer?.beneficiary?.is_individual &&
          !transfer?.beneficiary?.is_verified)
      ) {
        setChecked(true);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer?.beneficiary?.id]);

  const directTransferDisabled = useMemo(() => {
    // unverified ind or co - true
    // ind to active verified co - true
    // false only for company to active verified co
    const isBeneficiaryIndividual =
      transfer?.beneficiary?.is_individual ||
      transfer?.beneficiary?.is_individual == 1;
    const isUserIndividual = user?.is_individual || user?.is_individual == 1;

    if (
      (!transfer?.beneficiary?.company?.is_verified ||
        transfer?.beneficiary?.company?.is_verified == null) &&
      !isBeneficiaryIndividual
    ) {
      // unverified company
      return true;
    } else if (
      (!transfer?.beneficiary?.is_verified ||
        transfer?.beneficiary?.is_verified == null) &&
      isBeneficiaryIndividual
    ) {
      // unverified individual
      return true;
    } else if (
      transfer?.beneficiary?.company?.is_verified &&
      transfer?.beneficiary?.company?.is_active &&
      !isBeneficiaryIndividual &&
      isUserIndividual
    ) {
      // active verified company, sender ind
      return true;
    } else if (
      transfer?.beneficiary?.company?.is_verified &&
      transfer?.beneficiary?.company?.is_active &&
      !isBeneficiaryIndividual &&
      !isUserIndividual
    ) {
      // act verf comp, comp sender
      return false;
    } else {
      return true;
    }
  }, [transfer?.beneficiary, user]);

  const isInvalid: any =
    directPaymentAmountExceed ||
    feesCalculated ||
    errorGot !== "" ||
    processingPayment ||
    fetchingBeneficiaryAccounts ||
    (checked === true && widthdrawMethod == "") ||
    (checked === true &&
      widthdrawMethod == "Mobile Money" &&
      selectedMobileOperator == "Other" &&
      customMobileOperator == "") ||
    (checked === true &&
      widthdrawMethod == "Mobile Money" &&
      selectedMobileOperator == "" &&
      customMobileOperator == "") ||
    (checked === true &&
      widthdrawMethod == "Mobile Money" &&
      selectedMobileOperator !== "Other" &&
      phone == "" &&
      customPhoneNumber == "") ||
    (checked === true &&
      widthdrawMethod == "Mobile Money" &&
      phone == "Other" &&
      customPhoneNumber == "") ||
    checkingForWithdrawProviders ||
    (checked === true &&
      widthdrawMethod == "Bank Account" &&
      ((isEmpty(iBAN) && isEmpty(customIban)) ||
        (isEmpty(selectedBankName) && isEmpty(customBankName))));

  // update beneficiary's neero wallet accounts
  const updateBeneficiaryNeeroWalletAccounts = () => {
    const other = [{ id: 99, name: "Other" }];
    getAllUserNeeroWalletsById(transfer?.beneficiary?.id).then(
      (response: any) => {
        setbeneficiaryNeeroWallets(
          response?.data
            .map((acc: any) => ({
              name: acc?.wallet_account_number,
              id: acc?.id,
            }))
            .filter((itm: any) => itm?.name !== "Other")
            .concat(other)
        );
      }
    );
  };

  // handle direct transfer submission for neero
  const handleSubmissionForNeero = () => {
    try {
      if (widthdrawMethod === "Neero") {
        const isNeeroWalletExisting = beneficiaryNeeroWallets.find(
          (wallet: any) =>
            (wallet.name == neeroWalletNumber &&
              neeroWalletNumber !== "Other") ||
            (wallet.name == customNeeroWalletNumber &&
              neeroWalletNumber == "Other")
        );

        if (isNeeroWalletExisting) {
          submitTransaction();
        } else {
          // validate phone number
          const countryCode: any = transfer?.beneficiary?.country_code;
          const receipientNumber = `+${countryCode}${customNeeroWalletNumber}`;
          // non benin
          if (
            ((countryCode !== "229" && typeof countryCode == "string") ||
              (countryCode !== 229 && typeof countryCode == "number")) &&
            !(
              isValidPhoneNumber(receipientNumber) &&
              isPossiblePhoneNumber(receipientNumber) &&
              validatePhoneNumberLength(receipientNumber) == undefined
            )
          ) {
            toast.error(t("validPhone"));
            return;
          }

          createNeeroWallet(transfer?.beneficiary?.id, {
            title: `My Neero Wallet ${customNeeroWalletNumber}`,
            country: transfer?.beneficiary?.country,
            owner_name: transfer?.beneficiary?.full_name,
            country_code: transfer?.beneficiary?.country_code,
            wallet_account_number: customNeeroWalletNumber,
          }).then((response: any) => {
            setneeroWalletId(response?.mm_account?.account_id);
            submitTransaction(response?.mm_account?.account_id);
            updateBeneficiaryNeeroWalletAccounts();
          });
        }
      } else {
        submitTransaction();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="max-w-[800px] w-full mx-auto">
      {size[0] < 900 && (
        <div className="w-full py-3 mb-4 bg-white">
          <h3>{steps[1]}</h3>
        </div>
      )}
      <div
        className="max-w-[800px] px-4 pb-4 pt-1 my-3 shadow-c"
        id="transferPaymentWithdrawContainer"
      >
        <div
          className="flex mt-3 flex-row items-center justify-between border-b-2"
          id="transferBeneDetails"
        >
          <div className="flex-1">
            <BeneficiarySummary
              name={transfer?.beneficiary?.full_name}
              company={
                transfer?.beneficiary?.company?.name ||
                transfer?.beneficiary?.email
              }
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <p>{t("Balance")}</p>
            <CashLayout cash={userBalance} />
          </div>
        </div>

        <div
          className="flex justify-between gap-4 max-sm:flex-col"
          id="withdrawInfoContainer"
        >
          <div
            className={
              hideWithdrawModal
                ? "scrollDiv h-0 m-0 w-full"
                : "scrollDiv m-0 w-full"
            }
          >
            <div className="mt-4">
              {/* direct transfer toggle */}
              {!hideWithdrawModal && (
                <div
                  className="flex flex-col mb-3"
                  style={{
                    border: "1px solid #cccccc",
                  }}
                >
                  <FormControlLabel
                    className="wrapperSwitchAndLableNew"
                    control={
                      <Switch
                        disabled={directTransferDisabled}
                        onChange={(e) => {
                          if (!directTransferDisabled) {
                            handleChange(e);
                          }
                        }}
                        color="primary"
                        checked={checked}
                      />
                    }
                    label={t("Checked_Toogle")}
                  />

                  <WithdrawMethodDescription
                    checked={checked}
                    widthdrawMethod={widthdrawMethod}
                    t={t}
                  />
                </div>
              )}

              {/* withdraw methods */}
              {checked === true && !hideWithdrawModal && (
                <div className="flex flex-col ">
                  <label className="withdrawTitle px-3 max-sm:!text-sm">
                    {t("selectWithdrawMode")}
                  </label>

                  {/* no withdraw method error */}
                  {transfer.getAllMobileOperator?.find(
                    (val: any) => val.providers
                  ) === undefined && (
                    <small className="text-red-500">
                      {t("No_Withdraw_Mode_Found")}
                    </small>
                  )}

                  <div className="bacnkCheckBoxWrapper flex flex-col p-2 my-3 gap-2">
                    {/* mobile */}
                    <MobileWithdrawBox
                      directPaymentAmountExceed={directPaymentAmountExceed}
                      mobileOperatorList={mobileOperatorList}
                      setwidthdrawMethod={setwidthdrawMethod}
                      widthdrawMethod={widthdrawMethod}
                      t={t}
                      transfer={transfer}
                      setselectedMobileOperator={setselectedMobileOperator}
                      setSelectedCountryReset={setSelectedCountryReset}
                      setinstitutionId={setinstitutionId}
                      setcustomMobileOperator={setcustomMobileOperator}
                      selectedMobileOperator={selectedMobileOperator}
                      customMobileOperator={customMobileOperator}
                      phone={phone}
                      setPhone={setPhone}
                      customPhoneNumber={customPhoneNumber}
                      setcustomPhoneNumber={setcustomPhoneNumber}
                      beneficiaryMMAccounts={beneficiaryMMAccounts}
                      numberInputInvalidChars={numberInputInvalidChars}
                    />

                    {showNeero && (
                      <NeeroWalletWithdrawBox
                        directPaymentAmountExceed={directPaymentAmountExceed}
                        processingPayment={processingPayment}
                        fetchingBeneficiaryAccounts={
                          fetchingBeneficiaryAccounts
                        }
                        checkingForWithdrawProviders={
                          checkingForWithdrawProviders
                        }
                        mobileOperatorList={mobileOperatorList}
                        setwidthdrawMethod={setwidthdrawMethod}
                        widthdrawMethod={widthdrawMethod}
                        t={t}
                        transfer={transfer}
                        setselectedMobileOperator={setselectedMobileOperator}
                        setSelectedCountryReset={setSelectedCountryReset}
                        setinstitutionId={setinstitutionId}
                        setcustomMobileOperator={setcustomMobileOperator}
                        selectedMobileOperator={selectedMobileOperator}
                        customMobileOperator={customMobileOperator}
                        phone={phone}
                        setPhone={setPhone}
                        customPhoneNumber={customPhoneNumber}
                        setcustomPhoneNumber={setcustomPhoneNumber}
                        beneficiaryMMAccounts={beneficiaryMMAccounts}
                        numberInputInvalidChars={numberInputInvalidChars}
                        setneeroWalletId={setneeroWalletId}
                        neeroWalletId={neeroWalletId}
                        beneficiaryNeeroWallets={beneficiaryNeeroWallets}
                        neeroWalletNumber={neeroWalletNumber}
                        setneeroWalletNumber={setneeroWalletNumber}
                        customNeeroWalletNumber={customNeeroWalletNumber}
                        setcustomNeeroWalletNumber={setcustomNeeroWalletNumber}
                      />
                    )}

                    {/* bank account */}
                    <BankWithdrawBox
                      directPaymentAmountExceed={directPaymentAmountExceed}
                      t={t}
                      transfer={transfer}
                      bankSelectionList={bankSelectionList}
                      setwidthdrawMethod={setwidthdrawMethod}
                      widthdrawMethod={widthdrawMethod}
                      setcustomBankName={setcustomBankName}
                      setinstitutionId={setinstitutionId}
                      setselectedBankName={setselectedBankName}
                      selectedBankName={selectedBankName}
                      customBankName={customBankName}
                      setIBAN={setIBAN}
                      iBAN={iBAN}
                      customIban={customIban}
                      setcustomIban={setcustomIban}
                      accountHolderName={accountHolderName}
                      beneficiaryBankAccounts={beneficiaryBankAccounts}
                      setSelectedCountryReset={setSelectedCountryReset}
                    />

                    {/* micro transaction */}
                    <MfiWithdrawBox
                      directPaymentAmountExceed={directPaymentAmountExceed}
                      mfiSelectionList={mfiSelectionList}
                      widthdrawMethod={widthdrawMethod}
                      t={t}
                      transfer={transfer}
                      setcustomMfiName={setcustomMfiName}
                      setselectedMfiName={setselectedMfiName}
                      setwidthdrawMethod={setwidthdrawMethod}
                      setinstitutionId={setinstitutionId}
                      selectedMfiName={selectedMfiName}
                      customMfiName={customMfiName}
                      setmfiAccountUserName={setmfiAccountUserName}
                      mfiAccountUserName={mfiAccountUserName}
                      beneficiaryMfiAccounts={beneficiaryMfiAccounts}
                      setcustomMfiOwnerId={setcustomMfiOwnerId}
                      setmfiOwnerId={setmfiOwnerId}
                      mfiOwnerId={mfiOwnerId}
                      customMfiOwnerId={customMfiOwnerId}
                    />

                    {/* delivery */}
                    <DeliveryWithdrawBox
                      transfer={transfer}
                      directPaymentAmountExceed={directPaymentAmountExceed}
                      isDeliveryPayoutPresent={isDeliveryPayoutPresent}
                      setwidthdrawMethod={setwidthdrawMethod}
                      widthdrawMethod={widthdrawMethod}
                      t={t}
                    />
                  </div>
                </div>
              )}

              <div className="flex max-sm:flex-col gap-3">
                <CustomButton
                  onClick={() => {
                    setpaymentActiveStep(0);
                  }}
                  label="back"
                />
                <CustomButton
                  onClick={handleSubmissionForNeero}
                  disabled={
                    directPaymentAmountExceed ||
                    feesCalculated ||
                    errorGot !== "" ||
                    processingPayment ||
                    fetchingBeneficiaryAccounts ||
                    (checked === true && widthdrawMethod == "") ||
                    (checked === true &&
                      widthdrawMethod == "Mobile Money" &&
                      selectedMobileOperator == "Other" &&
                      customMobileOperator == "") ||
                    (checked === true &&
                      widthdrawMethod == "Mobile Money" &&
                      selectedMobileOperator == "" &&
                      customMobileOperator == "") ||
                    (checked === true &&
                      widthdrawMethod == "Mobile Money" &&
                      selectedMobileOperator !== "Other" &&
                      phone == "") ||
                    (checked === true &&
                      widthdrawMethod == "Mobile Money" &&
                      phone == "Other" &&
                      customPhoneNumber == "") ||
                    checkingForWithdrawProviders
                  }
                  id="maketransfersuccessbtn"
                  label={processingPayment ? t("processing") : t("continue")}
                  classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                />
              </div>
            </div>
          </div>

          {/* transfer info */}
          <TransferInfo
            feesResponse={feesResponse}
            amountInEuros={amountInEuros}
            fees={fees}
            fetchingFees={fetchingFees}
            withdrawalFees={withdrawalFees}
            t={t}
            spread_config={spread_config}
            paymentMethod={paymentMethod}
            transfer={transfer}
            widthdrawMethod={widthdrawMethod}
          />
        </div>
      </div>
    </div>
  );
};

export default TransferPaymentWithdrawModal;
