import { useEffect, useMemo } from "react";
import * as Sentry from "@sentry/browser";
import InputField from "../forms/InputField";
import { applyRate, buildLimitError } from "../../utilities/help";
import { Alert } from "@material-ui/lab";
import { toast } from "material-react-toastify";
import CustomButton from "../CustomButton";

const AmountAndReason = ({ props, next, cashInMethod }: any) => {
  const {
    amountInEuros,
    reason,
    amountInCFA,
    t,
    setReason,
    setisCFAFirst,
    userBalance,
    setLimitError,
    paymentMethod,
    transfer,
    checkCashInLimit,
    setAmountInEuros,
    setAmountInCFA,
    fetchingFees,
    spread_config,
    errorGot,
    notReceivingCountry,
    setErrorGot,
    feesResponse,
  } = props;

  const feeObj = useMemo(() => {
    try {
      if (feesResponse) {
        return (
          feesResponse &&
          feesResponse.fee_calculation &&
          feesResponse.fee_calculation.find((feeItem: any) =>
            feeItem.hasOwnProperty("local_currency")
          )
        );
      } else {
        return null;
      }
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }, [feesResponse]);

  // correctly set gnf on repeat
  useEffect(() => {
    try {
      if (amountInCFA == "" && feeObj?.received_amount_local_currency) {
        setAmountInCFA(feeObj?.received_amount_local_currency);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [amountInEuros, feeObj, amountInCFA]);

  useEffect(() => {
    try {
      if (transfer?.loggedInUserCountry?.cash_in_methods) {
        let maxAmount: number = 0;
        transfer?.loggedInUserCountry?.cash_in_methods.forEach(
          (method: any) => {
            const limit: number = parseInt(method?.cash_in_method?.max_amount);
            if (limit > maxAmount) {
              maxAmount = limit;
            }
          }
        );

        if (parseInt(amountInEuros) > maxAmount) {
          toast.error(`${t("max_amount_exceeded")}${maxAmount}`);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer.loggedInUserCountry?.cash_in_methods, amountInEuros]);

  const validate = () => {
    if (reason.length === 0) {
      toast.error(t("reasonError"));
      return;
    }
    if (amountInEuros.length === 0) {
      toast.error(t("the_euro_amount_field_is_required"));
      return;
    }
    if (amountInCFA.length === 0) {
      toast.error(t("enterCfa"));
      return;
    }
    next();
  };

  return (
    <div className="flex w-full mt-4 flex-col max-sm:!px-3">
      <label className="withdrawTitle">{t("enter_reason")}</label>
      <div className="mb-3 ">
        <InputField
          extraClasses="transferPaymentFormInput"
          handleChange={(e: any) => setReason(e.target.value)}
          value={reason}
          label=""
          placeholder={t("reason")}
          type="text"
          name="reason"
        />
      </div>

      {/* payment amount divs */}
      <div className="mb-1">
        <label className="withdrawTitle">{t("enter_amount")}</label>
        <div className="flex ">
          <div
            className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
            style={{
              borderRadius: "10px",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: "#e8e7e7",
              border: "1px solid #e8e8e8",
              borderRight: "none",
              marginBlock: "7px",
            }}
          >
            {t("EUR")}
          </div>
          <input
            name="Sent amount (EUR)"
            className="owner w-full text-sm"
            style={{
              background: "#fafafa",
              border: "none",
            }}
            placeholder={t("Sent_Amount_Eur_Only")}
            onChange={(e: any) => {
              setisCFAFirst(false);
              setLimitError(null);
              setErrorGot("");
              if (
                Number(e.target.value) < 1 ||
                Number(e.target.value) > Number(userBalance)
              ) {
                if (paymentMethod === "balance") {
                }
              }
              if (checkCashInLimit(transfer, "min", e.target.value)) {
                setLimitError(buildLimitError(transfer, "min", t));
              }
              if (checkCashInLimit(transfer, "max", e.target.value)) {
                setLimitError(buildLimitError(transfer, "max", t));
              }
              setAmountInEuros(e.target.value);

              // if guinea use gnf conversion
              if (transfer?.beneficiary?.country?.toLowerCase() == "guinea") {
                feeObj &&
                  setAmountInCFA(
                    applyRate(e, feeObj?.exchange_rate_local_currency, "EUR")
                  );
              } else {
                setAmountInCFA(
                  applyRate(e, spread_config.exchange_rate, "EUR")
                );
              }
            }}
            value={amountInEuros}
            type="number"
            disabled={notReceivingCountry || fetchingFees}
          />
        </div>
      </div>
      {notReceivingCountry && <Alert severity="error">{t(errorGot)}</Alert>}

      <div className="mb-3">
        <div className="flex">
          <div
            className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
            style={{
              borderRadius: "10px",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: "#e8e7e7",
              border: "1px solid #e8e8e8",
              borderRight: "none",
              marginBlock: "7px",
            }}
          >
            {transfer?.beneficiary?.country?.toLowerCase() == "guinea"
              ? "GNF"
              : props.t("CFA")}
          </div>
          <input
            disabled={notReceivingCountry || fetchingFees}
            className="owner w-full text-sm"
            style={{
              background: "#fafafa",
              border: "none",
            }}
            name="Amount received (CFA)"
            placeholder={
              transfer?.beneficiary?.country?.toLowerCase() == "guinea"
                ? props.t("Sent_Amount_GNF_Only")
                : props.t("Sent_Amount_Cfa_Only")
            }
            onChange={(e: any) => {
              if (feeObj?.local_currency !== "GNF") {
                props.setAmountInCFA(e.target.value);
                props.setAmountInEuros(
                  applyRate(e, props.spread_config.exchange_rate, "CFA")
                );
              } else {
                props.setAmountInCFA(e.target.value);
                props.setAmountInEuros(
                  applyRate(e, feeObj?.exchange_rate_local_currency, "CFA")
                );
              }
              props.setisCFAFirst(true);
            }}
            value={
              (feeObj && props.amountInCFA) ||
              feeObj?.received_amount_local_currency ||
              ""
            }
            type="number"
          />
        </div>
      </div>

      <div className="flex gap-3 max-sm:flex-col">
        <CustomButton label={props.t("back")} onClick={props.handleBack} />
        <CustomButton
          label={
            props.processingPayment
              ? props.t("processing")
              : props.t("continue")
          }
          disabled={fetchingFees || notReceivingCountry}
          onClick={validate}
          classNames="w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
        />
      </div>
    </div>
  );
};

export default AmountAndReason;
