import { Form, Formik } from "formik";
import {
  checkUserExistsPhoneLogin,
  loginWithPhone,
  sendOTPForLogin,
  verifyOTPForLogin,
} from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import {
  loginUser,
  refreshCaptchaToken,
} from "../../store/features/Auth/AuthSlice";
import { updateDanaPayCountries } from "../../store/features/Transfer/TransferSlice";
import { getTransferCountries } from "../../store/features/Transfer/Transfer";
import { extractErrorMessage } from "../../helpers";
import {
  buildString,
  handleRedirection,
  isEmpty,
  prependZero,
} from "../../utilities/help";
import { Alert } from "@material-ui/lab";
import { useEffect, useRef, useState } from "react";
import FormErrorText from "../FormErrorText";
import { toast } from "material-react-toastify";
import PhoneNumberPicker from "../SignUp/PhoneNumberPicker";
import CustomInputField from "../SignUp/CustomInputField";
import moment from "moment";
import CustomButton from "../CustomButton";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js/max";

const LoginWithPhone = (props: any) => {
  const {
    t,
    showErrorMessage,
    captchaToken,
    dispatch,
    setLoggedUserCountry,
    history,
    handleHeapAndCustomerioEvents,
    refreshCaptcha,
    errorMessage,
    yup,
    setRegistrationData,
    setisUserRejected,
  } = props;

  const [isOtpModalOpen, setisOtpModalOpen] = useState(false);
  const [otp, setotp] = useState("");
  const [isOtpValid, setisOtpValid] = useState(false);
  const [country_code, setcountry_code] = useState("");
  const [phone_number, setphone_number] = useState("");
  const form: any = useRef(null);

  const validationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required("PhoneNumberError")
      .test(
        "yourTestCondition",
        "invalid_phone_number",
        function (value: any, context: any) {
          const receipentNumber =
            "+" + context.parent.country_code + value?.toString();

          if (
            !isValidPhoneNumber(receipentNumber) ||
            !isPossiblePhoneNumber(receipentNumber)
          ) {
            if (
              validatePhoneNumberLength(receipentNumber) == undefined ||
              validatePhoneNumberLength(receipentNumber) == "INVALID_COUNTRY"
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      )
      .test(
        "yourTestCondition",
        "phone_number_below_min_length",
        function (value: any, context: any) {
          const receipentNumber =
            "+" + context.parent.country_code + value?.toString();
          if (validatePhoneNumberLength(receipentNumber) == "TOO_SHORT") {
            return false;
          } else {
            return true;
          }
        }
      )
      .test(
        "yourTestCondition",
        "phone_number_below_max_length",
        function (value: any, context: any) {
          const receipentNumber =
            "+" + context.parent.country_code + value?.toString();

          if (validatePhoneNumberLength(receipentNumber) == "TOO_LONG") {
            return false;
          } else {
            return true;
          }
        }
      ),
    pincode: isOtpValid
      ? yup
          .string()
          .matches(/^(\d{4}|\d{6})$/, "pincode_invalid")
          .required("pincode_error")
      : yup.string().matches(/^(\d{4}|\d{6})$/, "pincode_invalid"),
    country_code: yup.string().required("CE"),
  });

  const handleOtpSubmit = () => {
    verifyOTPForLogin({
      country_code: parseInt(country_code),
      phone_number: phone_number,
      verification_code: otp,
    })
      .then(() => {
        setisOtpValid(true);
      })
      .catch((err: any) => {
        if (err.data && err.data.message) {
          toast.error(t(err.data.message));
        }
        Sentry.captureException(err);
      })
      .finally(() => {
        setisOtpModalOpen(false);
        setotp("");
      });
  };

  useEffect(() => {
    form.current && form.current.validateForm();
  }, [isOtpValid]);

  const isUserRejected = (result: any) => {
    const verificationResult = result?.user?.verification_result;
    const userRejected = result?.user?.is_rejected;
    const userActive = result?.user?.is_active;
    if (userActive) {
      setisUserRejected(false);
      return true;
    } else if (
      (verificationResult == "RED-FINAL" && !userActive) ||
      (userRejected == true && !userActive)
    ) {
      setisUserRejected(true);
      return undefined;
    } else {
      setisUserRejected(false);
      return true;
    }
  };

  const handleUserTypeSelection = (result: any) => {
    try {
      const userIsResumingAfterAccountSelection = !isEmpty(
        localStorage.getItem("acctype")
      );
      if (!userIsResumingAfterAccountSelection) {
        if (result.user.is_individual) {
          localStorage.setItem("acctype", "Individual");
        } else {
          localStorage.setItem("acctype", "Business");
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // get countries in case its missing
  useEffect(() => {
    try {
      getTransferCountries().then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          dispatch(updateDanaPayCountries(res.data));
        }
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  // otp logic
  // resend logic
  const [alreadySentCode, setalreadySentCode] = useState(false);
  const [disableResend, setdisableResend] = useState(false);
  const [seconds, setseconds] = useState(30);
  const [isTimerRunning, setisTimerRunning] = useState(false);
  const [channel, setchannel] = useState("sms");

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 1) {
        isTimerRunning && setseconds(seconds - 1);
      }

      if (seconds === 1) {
        setdisableResend(false);
        setisTimerRunning(false);
        clearInterval(interval);
        isTimerRunning && setseconds(30);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleOTPAbuse = (successCallback: any) => {
    try {
      if (window.localStorage.getItem("otpresendtime") == undefined) {
        const payload = { times: 1, time: moment() };
        window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
        successCallback();
      } else {
        const oldPayload = JSON.parse(
          window.localStorage.getItem("otpresendtime")!
        );

        const isTimeLessThanTwoMin =
          moment.duration(moment().diff(moment(oldPayload.time))).minutes() < 2;

        if (parseInt(oldPayload.times) >= 5 && isTimeLessThanTwoMin) {
          toast.error(t("otp_limit_error"));
          setTimeout(() => {
            window.localStorage.removeItem("otpresendtime");
          }, 2 * 60 * 1000);
        } else {
          const payload = { ...oldPayload, times: oldPayload.times + 1 };
          window.localStorage.setItem("otpresendtime", JSON.stringify(payload));
          successCallback();
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const startTimer = () => {
    const successCallback = () => {
      if (!disableResend) {
        setisTimerRunning(true);
        setdisableResend(true);
        setseconds(3);
      }
    };
    handleOTPAbuse(successCallback);
  };

  const [hasPincodeSet, sethasPincodeSet] = useState(false);

  return (
    <div className="!mt-7">
      <div className="opacity-75 !mb-7">
        {channel == "sms" ? (
          <>
            {t("you_will_receive_code_sms")}{" "}
            <div
              className="font-bold inline-block text-[#037375] cursor-pointer"
              onClick={() => setchannel("whatsapp")}
            >
              {t("receive_code_via_whatsapp")}
            </div>
          </>
        ) : (
          <>
            {t("you_will_receive_code_whatsapp")}{" "}
            <div
              className="font-bold inline-block text-[#037375] cursor-pointer"
              onClick={() => setchannel("sms")}
            >
              {t("receive_code_via_sms")}
            </div>
          </>
        )}
      </div>
      <Formik
        validateOnChange
        innerRef={form}
        initialValues={{
          phoneNumber: "",
          pincode: "",
          otp: "",
          captcha: "",
          country_code: "",
          countryName: "",
        }}
        onSubmit={(data: any, { setSubmitting }) => {
          try {
            if (!alreadySentCode) {
              return;
            }
            setSubmitting(true);
            showErrorMessage([]);

            // login user data
            data.captcha = captchaToken;
            captchaToken !== null &&
              verifyOTPForLogin({
                country_code: parseInt(data.country_code),
                phone_number: data.phoneNumber,
                verification_code: parseInt(data.otp),
                pincode: parseInt(data.pincode),
              })
                .then((result: any) => {
                  localStorage.removeItem("depositattempts");
                  localStorage.removeItem("maketransferattempts");

                  if (isUserRejected(result) == undefined) {
                    setSubmitting(false);
                    return;
                  }

                  handleHeapAndCustomerioEvents(result, data);
                  setRegistrationData(result.user);
                  handleUserTypeSelection(result);

                  setSubmitting(false);
                  // if the response hasa token [user exists]
                  if (result.access_token) {
                    // store the user data in redux store
                    localStorage.setItem("user:key", result.access_token);
                    setLoggedUserCountry(result.user);
                    dispatch(loginUser(result));

                    handleRedirection(result?.user, history);
                  } else {
                    toast.warning(t(JSON.stringify(result)));
                  }
                })
                .catch((error: any) => {
                  Sentry.captureException(error);
                  toast.error(t("otp_invalid"));
                  setSubmitting(false);
                })
                .finally(() => {
                  setSubmitting(false);
                });
          } catch (error: any) {
            Sentry.captureException(error);
            showErrorMessage((err: any) => [...err, error.message]);
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
          isValid,
          setFieldTouched,
          setSubmitting,
          dirty,
        }) => (
          <Form>
            <div className="mb-3">
              <PhoneNumberPicker
                type="number"
                phoneNumberClasses="ml-2 w-full"
                placeholder={t("Phone_Number")}
                country={values?.countryName}
                value={values?.phoneNumber}
                handleChange={(e: any) => {
                  handleChange("phoneNumber")(e);
                  setFieldTouched("phoneNumber", true, false);
                  setalreadySentCode(false);
                }}
                mainContainerClasses="w-full relative"
                setCountry={(text: any) => {
                  handleChange("country_code")(text.country_code);
                  handleChange("countryName")(text.name);
                  setFieldTouched("country_code", true, false);
                  setalreadySentCode(false);
                }}
              />
              {errors.phoneNumber && (
                <FormErrorText
                  errorMessage={errors.phoneNumber}
                  classNames="text-xs"
                />
              )}
            </div>

            <CustomInputField
              mainContainerClasses="w-full mb-3"
              inputClasses="!rounded-xl h-[50px] w-full border-1 !px-3 border-[rgba(0,0,0,0.2)]"
              name="otp"
              handleChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("enter_otp_received")}
              value={values.otp}
              error={t(`${errors.otp}`)}
              touched={touched.otp}
            />
            <CustomInputField
              errorClasses="my-2 text-red-400 text-xs"
              inputClasses="w-full"
              mainContainerClasses="w-full !rounded-xl h-[50px] mb-2 border-1 !px-3 border-[rgba(0,0,0,0.2)] flex items-center"
              extraClasses="mb-2 customInputStyle"
              type="password"
              name="pincode"
              handleChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("pincode")}
              value={values.pincode}
              error={t(`${errors.pincode}`)}
              touched={touched.pincode}
            />

            <CustomButton
              type="submit"
              classNames="w-full mt-3 min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
              disabled={isSubmitting || !isValid || !dirty || !alreadySentCode}
              label={isOtpValid ? t("log_in") : t("log_in")}
              data-login="login"
            />

            <button
              className={`!mt-3 text-center w-full  font-bold text-[#037375] !outline-none cursor-pointer text-center ${
                !isValid ? "opacity-50" : "opacity-75"
              }`}
              onClick={(e: any) => {
                e.preventDefault();
                if (isValid) {
                  startTimer();
                  setSubmitting(true);
                  checkUserExistsPhoneLogin({
                    country_code: parseInt(values.country_code),
                    phoneNumber: values.phoneNumber,
                  })
                    .then((response: any) => {
                      if (response?.has_pin) {
                        sethasPincodeSet(response?.has_pin);
                        setcountry_code(values.country_code);
                        setphone_number(values.phoneNumber);
                        sendOTPForLogin({
                          country_code: parseInt(values.country_code),
                          phone_number: values.phoneNumber,
                          channel: channel,
                        }).then(() => {
                          setSubmitting(false);
                        });
                      } else {
                        toast.error(t("phone_login_not_existing"));
                        return;
                      }
                    })
                    .catch((error: any) => {
                      toast.error(t("phone_login_not_existing"));
                      Sentry.captureException(error);
                    })
                    .finally(() => setSubmitting(false));
                }

                if (!alreadySentCode && isValid) {
                  setalreadySentCode(true);
                }
              }}
              disabled={isSubmitting}
            >
              {disableResend ? (
                <>
                  {t("resend_withdraw_code_1")}
                  <span className="text-[#035357] font-bold">
                    00:{prependZero(seconds)}{" "}
                  </span>
                  {t("seconds")}
                </>
              ) : alreadySentCode ? (
                t("resend_code")
              ) : (
                t("send_code")
              )}
            </button>
          </Form>
        )}
      </Formik>

      {errorMessage.length > 0 && (
        <div className="mb-4">
          <Alert severity="error">
            {errorMessage.map((errorText: string, index: number) => (
              <p>
                <small key={index}>{t(errorText)}</small>
              </p>
            ))}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default LoginWithPhone;
