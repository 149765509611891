import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import { forwardRef, useEffect, useMemo, useState } from "react";
import SumSub from "../Sumsub";
import * as Sentry from "@sentry/browser";
import { Dialog, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { toast } from "material-react-toastify";
import { currentBalance } from "../../store/features/Transfer/Transfer";
import { editUser } from "../../store/features/Auth/AuthSlice";
import { useDispatch } from "react-redux";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewIndividualBanners = (props: any) => {
  const {
    user,
    containerClasses = "mx-5 max-sm:!mx-1 p-3 rounded-xl max-sm:mt-4 max-sm:!px-2 bg-[#F7E4CD] shadow-c",
  } = props;
  const { t } = useTranslation();
  const isRedRetry = user?.verification_result == "RED-RETRY";
  const dispatch = useDispatch();

  const residencyList = [
    {
      id: 1,
      name: t("residency_option_1"),
      val: "Non EU Person",
    },
    {
      id: 2,
      name: t("residency_option_2"),
      val: "EU Person",
    },
    {
      id: 3,
      name: t("residency_option_3"),
      val: "WAEMU/CEMAC Person",
    },
  ];

  const bannerIcon = useMemo(() => {
    if (user?.on_boarding_status == "pending") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="#F5A05B"
          viewBox="0 0 256 256"
        >
          <path d="M200,112a8,8,0,0,1-8,8H152a8,8,0,0,1,0-16h40A8,8,0,0,1,200,112Zm-8,24H152a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Zm40-80V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Zm-80.26-34a8,8,0,1,1-15.5,4c-2.63-10.26-13.06-18-24.25-18s-21.61,7.74-24.25,18a8,8,0,1,1-15.5-4,39.84,39.84,0,0,1,17.19-23.34,32,32,0,1,1,45.12,0A39.76,39.76,0,0,1,135.75,166ZM96,136a16,16,0,1,0-16-16A16,16,0,0,0,96,136Z"></path>
        </svg>
      );
    } else if (user?.on_boarding_status == "submitted") {
      if (isRedRetry) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="#F5A05B"
            viewBox="0 0 256 256"
          >
            <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
          </svg>
        );
      } else {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="#F5A05B"
            viewBox="0 0 256 256"
          >
            <path d="M128,40a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,40Zm0,176a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,216ZM173.66,90.34a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32l40-40A8,8,0,0,1,173.66,90.34ZM96,16a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,16Z"></path>
          </svg>
        );
      }
    } else if (
      user?.on_boarding_status == "activated" &&
      user?.activation_scoring == "YELLOW"
    ) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="#F5A05B"
          viewBox="0 0 256 256"
        >
          <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path>
        </svg>
      );
    } else if (user?.on_boarding_status == "verified") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="#F5A05B"
          viewBox="0 0 256 256"
        >
          <path d="M128,40a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,40Zm0,176a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,216ZM173.66,90.34a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32l40-40A8,8,0,0,1,173.66,90.34ZM96,16a8,8,0,0,1,8-8h48a8,8,0,0,1,0,16H104A8,8,0,0,1,96,16Z"></path>
        </svg>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="#F5A05B"
          viewBox="0 0 256 256"
        >
          <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
        </svg>
      );
    }
  }, [user]);

  const bannerTitle = useMemo(() => {
    if (user?.on_boarding_status == "pending") {
      return "identification_required";
    } else if (user?.on_boarding_status == "submitted") {
      if (isRedRetry) {
        return "action_required";
      } else {
        return "verification_in_progress";
      }
    } else if (user?.on_boarding_status == "verified") {
      return "verification_verified";
    } else if (
      user?.on_boarding_status == "activated" &&
      user?.activation_scoring == "YELLOW"
    ) {
      return "restricted_account";
    } else {
      return "identification_required";
    }
  }, [user]);

  const bannerDetails = useMemo(() => {
    if (user?.on_boarding_status == "pending") {
      return "identification_required_details";
    } else if (user?.on_boarding_status == "submitted") {
      if (isRedRetry) {
        return "action_required_details";
      } else {
        return "verification_in_progress_details";
      }
    } else if (user?.on_boarding_status == "verified") {
      return "verification_verified_details";
    } else if (
      user?.on_boarding_status == "activated" &&
      user?.activation_scoring == "YELLOW"
    ) {
      return "restricted_account_message";
    } else {
      return "identification_required";
    }
  }, [user]);

  const [residencyLevelName, setresidencyLevelName] = useState("");

  // set correct residency level from response
  useEffect(() => {
    try {
      if (user?.residency !== undefined && user?.residency !== null) {
        const residency = residencyList.find(
          (item: any) =>
            item.val.toLowerCase() == user?.residency.toLowerCase() ||
            item.name.toLowerCase() == user?.residency.toLowerCase()
        );
        if (residency !== undefined) {
          setresidencyLevelName(residency.val);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [user]);

  const [open, setOpen] = useState(false);

  const buttonLabel = useMemo(() => {
    if (user?.on_boarding_status == "pending") {
      return "finalize_verification";
    } else if (user?.on_boarding_status == "submitted") {
      if (isRedRetry) {
        return "update_information";
      } else {
        return "whatsapp_redirection";
      }
    } else if (user?.on_boarding_status == "verified") {
      return "whatsapp_redirection";
    } else {
      return "whatsapp_redirection";
    }
  }, [user]);

  return (
    <div className={containerClasses}>
      <div className="flex items-center gap-1 mb-2">
        {bannerIcon}
        <div className="font-bold">{t(bannerTitle)}</div>
      </div>
      <div className="mb-2">{t(bannerDetails)}</div>
      {!(
        user?.on_boarding_status == "activated" &&
        user?.activation_scoring == "YELLOW"
      ) && (
        <CustomButton
          onClick={() => {
            if (
              buttonLabel == "finalize_verification" ||
              buttonLabel == "update_information"
            ) {
              setOpen(true);
            } else {
              window.open(`https://wa.me/message/CN4NWDBLAQUBL1`, "_blank");
            }
          }}
          label={buttonLabel}
          classNames="w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
        />
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <div className="flex flex-row items-center justify-between px-4 py-2 ">
          <span className="text-white">{t("start_Transfer")}</span>
          <button
            className="flex items-center justify-center px-4  rounded-lg shadow-lg btn"
            onClick={() => setOpen(false)}
            style={{ height: 50, width: 50, borderRadius: 25 }}
          >
            <i className="m-0 text-gray-600 fa fa-close"></i>
          </button>
        </div>
        <>
          <div className="row">
            <div className="mx-auto text-center my-4 col-md-6">
              <SumSub
                isModal={true}
                residencyLevelName={residencyLevelName}
                isKYB={!user?.is_individual}
                phone={user?.full_phone_number}
                finish={() => {
                  // check if user is valid and then load a payment payment
                  try {
                    currentBalance()
                      .then((response: any) => {
                        dispatch(editUser(response));
                        if (response.is_active && response.is_verified) {
                          const external_link = localStorage.getItem(
                            "ext_mm_process_link"
                          );
                          if (external_link) {
                            fetch(external_link)
                              .then((res: any) => res.json())
                              .then((response: any) => {
                                if (response.redirect_uri) {
                                  window.location?.replace(
                                    response.redirect_uri
                                  );
                                } else {
                                  setOpen((prev) => !prev);
                                }
                              })
                              .catch((error: any) => {
                                Sentry.captureException(error);
                                toast.error(t(error?.data?.message));
                              });
                          } else {
                            setOpen((prev) => !prev);
                          }
                        } else {
                          setOpen((prev) => !prev);
                        }
                      })
                      .catch((error: any) => {
                        Sentry.captureException(error);
                        toast.error(t(error?.data?.message));
                      });
                  } catch (error: any) {
                    Sentry.captureException(error);
                  }
                }}
              />
            </div>
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default NewIndividualBanners;
