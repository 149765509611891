import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";

const IntroduceDanapay = (props: any) => {
  const {} = props;
  const { t } = useTranslation();
  return (
    <div className="shadow-c flex flex-col flex-1 max-w-[49%] max-sm:!max-w-[100%] rounded-xl max-sm:!w-full bg-white !pt-3 !pb-3 px-4">
      <div className="w-[50px] h-[50px] bg-[#FF6639] rounded-xl flex-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="#ffffff"
          viewBox="0 0 256 256"
        >
          <path d="M239.18,97.26A16.38,16.38,0,0,0,224.92,86l-59-4.76L143.14,26.15a16.36,16.36,0,0,0-30.27,0L90.11,81.23,31.08,86a16.46,16.46,0,0,0-9.37,28.86l45,38.83L53,211.75a16.38,16.38,0,0,0,24.5,17.82L128,198.49l50.53,31.08A16.4,16.4,0,0,0,203,211.75l-13.76-58.07,45-38.83A16.43,16.43,0,0,0,239.18,97.26Zm-15.34,5.47-48.7,42a8,8,0,0,0-2.56,7.91l14.88,62.8a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0l-54.72-33.65a8,8,0,0,0-8.38,0L69.09,215.94c-.15.09-.19.12-.38,0a.37.37,0,0,1-.17-.48l14.88-62.8a8,8,0,0,0-2.56-7.91l-48.7-42c-.12-.1-.23-.19-.13-.5s.18-.27.33-.29l63.92-5.16A8,8,0,0,0,103,91.86l24.62-59.61c.08-.17.11-.25.35-.25s.27.08.35.25L153,91.86a8,8,0,0,0,6.75,4.92l63.92,5.16c.15,0,.24,0,.33.29S224,102.63,223.84,102.73Z"></path>
        </svg>
      </div>
      <div className="!mt-2 !mb-2 font-bold">{t("introduce_danapay")}</div>
      <div className="text-sm !mb-6">{t("do_you_like_danapay")}</div>

      <div className="flex items-center gap-3 max-sm:flex-col max-sm:items-start">
        {/* <CustomButton
          label="sponsor_loved_one"
          classNames="min-h-[48px] px-3 rounded-xl text-white capitalize text-sm font-bold bg-black"
        /> */}
        <CustomButton
          onClick={() => {
            window.open(
              "https://www.trustpilot.com/evaluate/danapay.io",
              "_blank"
            );
          }}
          label="leave_review"
          classNames="min-h-[48px] px-3 rounded-xl text-white capitalize text-sm font-bold bg-black"
        />
      </div>
    </div>
  );
};

export default IntroduceDanapay;
