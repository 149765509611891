import Skeleton from "react-loading-skeleton";
import useResize from "../../hooks/useResize";

const LoadingSkeleton = (props: any) => {
  const {} = props;
  const dimensions = useResize();

  return (
    <div className="px-3 max-sm:mt-4 max-sm:!px-1">
      <Skeleton className="min-h-[171px]" style={{ borderRadius: "0.75rem" }} />

      <Skeleton
        className="rounded-xl  w-[50%]"
        containerClassName="flex max-sm:flex-col max-sm:gap-2 justify-between min-h-[246px] mt-10"
        height={246}
        style={{ borderRadius: "0.75rem" }}
      />

      <Skeleton
        className="min-h-[171px] !mt-12"
        style={{ borderRadius: "0.75rem" }}
      />
    </div>
  );
};

export default LoadingSkeleton;
