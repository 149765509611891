import { useTranslation } from "react-i18next";
import ContactScroll from "./ContactScroll";
import { useState } from "react";
import FullContactsModal from "./FullContactsModal";
import { useAppSelector } from "../../store/hooks";

const Contacts = (props: any) => {
  const {} = props;
  const { t } = useTranslation();

  const [selectedCategory, setselectedCategory] = useState("individuals");
  const [fullContactsModalOpen, setfullContactsModalOpen] = useState(false);

  const { auth } = useAppSelector((state) => state.persistedReducer);

  return (
    <div className="shadow-c flex flex-col flex-1 max-w-[49%] rounded-xl max-sm:!max-w-[100%] bg-white !pt-3 !pb-3 px-4">
      <div className="flex justify-between items-center">
        <div className="font-bold">{t("Contacts")}</div>
        <div
          className="text-[#037375] text-right cursor-pointer"
          onClick={() => setfullContactsModalOpen(true)}
        >
          {`${t("all")} ${auth?.favorites?.length} contacts`}
        </div>
      </div>
      <div className="max-w-[340px] text-sm mt-2">
        {t("add_contacts_and_initiate")}
      </div>
      <div className="flex items-center gap-2 !mt-5">
        <div
          className="flex-center min-h-[36px] text-sm font-medium rounded-3xl bg-[#037375] !px-3 cursor-pointer"
          onClick={() => setselectedCategory("individuals")}
          style={{
            background:
              selectedCategory == "individuals" ? "#037375" : "#F5F5F5",
            color: selectedCategory == "individuals" ? "white" : "black",
          }}
        >
          {t("individuals")}
        </div>
        <div
          className="flex-center min-h-[36px] text-sm font-medium bg-[#F5F5F5] rounded-3xl !px-3 cursor-pointer"
          onClick={() => setselectedCategory("businesses")}
          style={{
            background:
              selectedCategory == "businesses" ? "#037375" : "#F5F5F5",
            color: selectedCategory == "businesses" ? "white" : "black",
          }}
        >
          {t("businesses")}
        </div>
      </div>

      <ContactScroll selectedCategory={selectedCategory} />
      <FullContactsModal
        open={fullContactsModalOpen}
        onClose={() => setfullContactsModalOpen(false)}
      />
    </div>
  );
};

export default Contacts;
