import { Modal, Tab, Tabs } from "@material-ui/core";
import { Form, Formik } from "formik";
import FormErrorText from "../FormErrorText";
import CustomModal from "../CustomModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "material-react-toastify";
import { extractError } from "../../utility";
import * as yup from "yup";
import * as Sentry from "@sentry/browser";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js/max";
import {
  addContact,
  checkUserNameIfReal,
  getFavorites,
} from "../../store/features/Auth/Auth";
import { saveUserFavorites } from "../../store/features/Auth/AuthSlice";
import CustomInputField from "../SignUp/CustomInputField";
import PhoneNumberPicker from "../SignUp/PhoneNumberPicker";
import CustomButton from "../CustomButton";
import { Close } from "@material-ui/icons";

const AddContactModal = (props: any) => {
  const { open, onClose } = props;

  const dispatch = useAppDispatch();
  const [preComChannel, setPreComChannel] = useState(null);
  const { t } = useTranslation();

  const refreshContacts = () => {
    getFavorites()
      .then((response) => {
        dispatch(saveUserFavorites(response));
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t(error?.data?.message));
      });
  };

  // new contact form handling
  const formRef = useRef<any>();
  const [contactType, setContactType] = useState(0);

  const handleTypeOfUserSelection = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setContactType(newValue);
    if (formRef?.current?.resetForm !== undefined) {
      formRef.current.resetForm();
    }
  };

  const validationSchemaAccordingToUserType = useMemo(
    () =>
      contactType == 0
        ? yup.object().shape({
            company_name: yup.string().required("company_name_error"),
            contact_first_name: yup
              .string()
              .required("contact_first_name_error"),
            contact_last_name: yup.string().required("contact_last_name_error"),
            contact_email:
              preComChannel === "mail"
                ? yup.string().required("ERQ").email("email_not_valid")
                : yup.string().email("email_not_valid").notRequired(),
            phone_number: yup
              .string()
              .required("PhoneNumberError")
              .test(
                "yourTestCondition",
                "invalid_phone_number",
                function (value: any, context: any) {
                  const receipentNumber =
                    "+" + context.parent.country_code + value?.toString();

                  if (context.parent.country_code == 229) {
                    return true;
                  }

                  if (
                    !isValidPhoneNumber(receipentNumber) ||
                    !isPossiblePhoneNumber(receipentNumber)
                  ) {
                    if (
                      validatePhoneNumberLength(receipentNumber) == undefined ||
                      validatePhoneNumberLength(receipentNumber) ==
                        "INVALID_COUNTRY"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }
              )
              .test(
                "yourTestCondition",
                "phone_number_below_min_length",
                function (value: any, context: any) {
                  const receipentNumber =
                    "+" + context.parent.country_code + value?.toString();

                  if (context.parent.country_code == 229) {
                    if (value?.length < 10) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    if (
                      validatePhoneNumberLength(receipentNumber) == "TOO_SHORT"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
              )
              .test(
                "yourTestCondition",
                "phone_number_below_max_length",
                function (value: any, context: any) {
                  const receipentNumber =
                    "+" + context.parent.country_code + value?.toString();

                  if (context.parent.country_code == 229) {
                    if (value?.length > 10) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    if (
                      validatePhoneNumberLength(receipentNumber) == "TOO_LONG"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
              )
              .test(
                "yourTestCondition",
                "benin_phone_error",
                function (value: any, context: any) {
                  if (
                    context.parent.country_code == 229 &&
                    value &&
                    value.length == 10 &&
                    value.slice(0, 2) == "01"
                  ) {
                    return true;
                  } else if (context.parent.country_code != 229) {
                    return true;
                  } else {
                    return false;
                  }
                }
              ),
            country_code: yup.string().required("CE"),
          })
        : yup.object().shape({
            last_name: yup.string().required("LNR"),
            first_name: yup.string().required("FNR"),
            email:
              preComChannel === "mail"
                ? yup.string().required("ERQ").email("email_not_valid")
                : yup.string().email("email_not_valid").notRequired(),
            phone_number: yup
              .string()
              .required("PhoneNumberError")
              .test(
                "yourTestCondition",
                "invalid_phone_number",
                function (value: any, context: any) {
                  const receipentNumber =
                    "+" + context.parent.country_code + value?.toString();

                  if (context.parent.country_code == 229) {
                    return true;
                  }

                  if (
                    !isValidPhoneNumber(receipentNumber) ||
                    !isPossiblePhoneNumber(receipentNumber)
                  ) {
                    if (
                      validatePhoneNumberLength(receipentNumber) == undefined ||
                      validatePhoneNumberLength(receipentNumber) ==
                        "INVALID_COUNTRY"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }
              )
              .test(
                "yourTestCondition",
                "phone_number_below_min_length",
                function (value: any, context: any) {
                  const receipentNumber =
                    "+" + context.parent.country_code + value?.toString();

                  if (context.parent.country_code == 229) {
                    if (value?.length < 10) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    if (
                      validatePhoneNumberLength(receipentNumber) == "TOO_SHORT"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
              )
              .test(
                "yourTestCondition",
                "phone_number_below_max_length",
                function (value: any, context: any) {
                  const receipentNumber =
                    "+" + context.parent.country_code + value?.toString();

                  if (context.parent.country_code == 229) {
                    if (value?.length > 10) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    if (
                      validatePhoneNumberLength(receipentNumber) == "TOO_LONG"
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
              )
              .test(
                "yourTestCondition",
                "benin_phone_error",
                function (value: any, context: any) {
                  if (
                    context.parent.country_code == 229 &&
                    value &&
                    value.length == 10 &&
                    value.slice(0, 2) == "01"
                  ) {
                    return true;
                  } else if (context.parent.country_code != 229) {
                    return true;
                  } else {
                    return false;
                  }
                }
              ),
            country_code: yup.string().required("CE"),
          }),
    [contactType]
  );

  const [inHumanNamePopupOpen, setinHumanNamePopupOpen] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);

  const handleUserCreateSubmission = async (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);

      // check for valid username
      setCreatingUser(true);
      const full_name = data?.first_name + " " + data?.last_name;
      checkUserNameIfReal({ full_name })
        .then((response: any) => {
          if (response.isRealName) {
            setinHumanNamePopupOpen(false);
            createUser(data, setSubmitting);
          } else {
            setinHumanNamePopupOpen(true);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          setinHumanNamePopupOpen(false);
          setSubmitting(false);
          setCreatingUser(false);
          toast.error(t(error?.data?.message));
        });
    } catch (error: any) {
      toast.error(t(error));
      setCreatingUser(false);
      setSubmitting(false);
    }
  };

  const createUser = async (data: any, setSubmitting: any) => {
    try {
      if (contactType == 1) {
        const newContact = {
          ...data,
          country_code: parseInt(data.country_code),
          is_individual: true,
        };

        if (!data.email) {
          delete newContact.email;
        }

        addContact(newContact)
          .then((res: any) => {
            toast.success(t("contact_added"));
            refreshContacts();
            onClose();
            setSubmitting(false);
          })
          .catch((error: any) => {
            setSubmitting(false);
            Sentry.captureException(error);

            toast.error(t(extractError(error)));
          })
          .finally(() => setCreatingUser(false));
      } else {
        const newContact = {
          company_name: data.company_name,
          phone_number: data.phone_number,
          country_code: parseInt(data.country_code),
          first_name: data?.contact_first_name,
          last_name: data?.contact_last_name,
          email: data?.contact_email,
          is_individual: false,
        };

        if (!data.email) {
          delete newContact.email;
        }
        setSubmitting(true);
        addContact(newContact)
          .then((res: any) => {
            toast.success(t("contact_added"));
            refreshContacts();
            onClose();
            setSubmitting(false);
          })
          .catch((error: any) => {
            setSubmitting(false);
            toast.error(t(extractError(error)));
            Sentry.captureException(error);
          })
          .finally(() => setCreatingUser(false));
      }
    } catch (error) {
      toast.error(t(extractError(error)));
      setSubmitting(false);
      setCreatingUser(false);
      Sentry.captureException(error);
    }
  };

  const [countryCode, setcountryCode] = useState("");

  return (
    <Modal open={open} onClose={() => onClose()}>
      <div className="m-auto h-full overflow-y-scroll justify-center flex items-center row contactscomponent">
        <div className="max-w-[600px]">
          <div className="p-4 bg-white flex flex-col rounded-xl addcontactmodal">
            {/* header */}
            <div className="flex flex-row items-center justify-between mb-3">
              <div className="font-bold text-xl">{t("Add_New_Contact")}</div>

              <button
                onClick={() => {
                  onClose();
                  setinHumanNamePopupOpen(false);
                  setCreatingUser(false);
                }}
                style={{ height: 40, width: 40, borderRadius: 20 }}
                className="flex items-center justify-center shadow-c"
              >
                <Close />
              </button>
            </div>
            <hr className="text-[rgba(0,0,0,0.25)]" />

            <Formik
              validateOnMount
              enableReinitialize
              innerRef={formRef}
              initialValues={
                contactType == 1
                  ? {
                      first_name: "",
                      last_name: "",
                      email: "",
                      phone_number: "",
                      country_code: "" || countryCode,
                    }
                  : {
                      company_name: "",
                      contact_first_name: "",
                      contact_last_name: "",
                      contact_email: "",
                      phone_number: "",
                      country_code: "" || countryCode,
                    }
              }
              onSubmit={async (data: any, { setSubmitting }) => {
                handleUserCreateSubmission(data, setSubmitting);
              }}
              validationSchema={validationSchemaAccordingToUserType}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
                dirty,
                isSubmitting,
                setSubmitting,
                setTouched,
                setValues,
              }) => (
                <>
                  <div className="mt-4 mb-3">
                    <Tabs
                      value={contactType}
                      onChange={handleTypeOfUserSelection}
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#037375",
                        },
                      }}
                    >
                      <Tab
                        style={{
                          textTransform: "none",
                          fontSize: 16,
                        }}
                        label={t("Business")}
                      />
                      <Tab
                        style={{
                          textTransform: "none",
                          fontSize: 16,
                        }}
                        label={t("Individual")}
                      />
                    </Tabs>
                  </div>
                  <Form>
                    {contactType == 0 && (
                      <CustomInputField
                        mainContainerClasses="w-full mb-3"
                        name="company_name"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("CompanyName")}
                        value={values.last_name}
                        error={
                          contactType == 0 && touched.company_name
                            ? t(`${errors.company_name}`)
                            : ""
                        }
                        touched={touched.company_name}
                      />
                    )}

                    <CustomInputField
                      mainContainerClasses="w-full mb-3"
                      name={
                        contactType == 0 ? "contact_first_name" : "first_name"
                      }
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={
                        contactType == 0
                          ? t("contact_first_name")
                          : t("FirstName")
                      }
                      value={values.first_name}
                      error={
                        contactType == 0 && touched.contact_first_name
                          ? t(`${errors.contact_first_name}`)
                          : touched.first_name
                          ? t(`${errors.first_name}`)
                          : ""
                      }
                      touched={
                        contactType == 0
                          ? touched?.contact_first_name!
                          : touched.first_name
                      }
                    />

                    <CustomInputField
                      mainContainerClasses="w-full mb-3"
                      name={
                        contactType == 0 ? "contact_last_name" : "last_name"
                      }
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={
                        contactType == 0
                          ? t("contact_last_name")
                          : t("LastName")
                      }
                      value={values.last_name}
                      error={
                        contactType == 0 && touched.contact_last_name
                          ? t(`${errors.contact_last_name}`)
                          : touched.last_name
                          ? t(`${errors.last_name}`)
                          : ""
                      }
                      touched={
                        contactType == 0
                          ? touched.contact_last_name
                          : touched.last_name
                      }
                    />

                    <div className="mb-3">
                      <PhoneNumberPicker
                        type="number"
                        phoneNumberClasses="ml-2 w-full"
                        placeholder={t("enter_phone_number")}
                        country={values?.countryName}
                        value={values?.phone_number}
                        handleChange={(e: any) => {
                          setTouched({ ...touched, phone_number: true });
                          handleChange("phone_number")(e.target.value);
                        }}
                        mainContainerClasses="w-full relative"
                        setCountry={(text: any) => {
                          setcountryCode(text.country_code);
                          setValues({
                            ...values,
                            country_code: text.country_code,
                            country: text.code && text.code.toLowerCase(),
                            countryName: text.name,
                            isPostCodeRequired: (
                              text.requires_post_code == 1
                            ).toString(),
                          });
                        }}
                      />
                      {errors.phone_number && touched.phone_number && (
                        <FormErrorText
                          errorMessage={errors.phone_number}
                          classNames="text-xs"
                        />
                      )}
                    </div>

                    <CustomInputField
                      mainContainerClasses="w-full"
                      name={contactType == 0 ? "contact_email" : "email"}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={
                        contactType == 0
                          ? t("contact_email")
                          : t("Email_Address")
                      }
                      value={values.email}
                      error={
                        contactType == 0
                          ? t(`${errors.contact_email}`)
                          : t(`${errors.email}`)
                      }
                      touched={
                        contactType == 0 ? touched.contact_email : touched.email
                      }
                    />
                    <CustomButton
                      classNames="min-h-[48px] mt-4 flex-1 w-full rounded-xl text-white capitalize text-sm font-bold bg-black"
                      label={
                        isSubmitting ? t("pending_title") : t("Invite_Contact")
                      }
                      type="submit"
                      disabled={
                        !(isValid && dirty) || isSubmitting || creatingUser
                      }
                    />

                    {/* inhuman name popup */}
                    <CustomModal
                      open={inHumanNamePopupOpen}
                      containerClasses={
                        "w-full h-full flex justify-center text-center items-center"
                      }
                      modalClasses={
                        "bg-white m-auto p-4 rounded col-md-6 col-lg-5 col-11"
                      }
                      titleClasses={
                        "font-bold text-xl max-sm:text-lg mt-2 mb-4"
                      }
                      title={t("inhuman_name_error")}
                      contentClasses={"mt-4"}
                      onBack={() => {
                        setinHumanNamePopupOpen(false);
                        setSubmitting(false);
                        setCreatingUser(false);
                      }}
                      onNext={() => {
                        createUser(values, setSubmitting);
                        setinHumanNamePopupOpen(false);
                      }}
                      onBackTitle={"go_back_and_change"}
                      onNextTitle={"proceed"}
                      buttonContainerClasses={"flex gap-3 max-sm:flex-col"}
                      backBtnClasses="w-full rounded-lg btn btn-block rounded-lg-primary border md:text-md max-sm:!text-sm bg-white !text-black"
                    />
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddContactModal;
