import { Form, Formik } from "formik";
import { login } from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import {
  loginUser,
  refreshCaptchaToken,
} from "../../store/features/Auth/AuthSlice";
import { addLoggedInUserCountry } from "../../store/features/Transfer/TransferSlice";
import {
  getTransferCountries,
  paymentsAnonymous,
} from "../../store/features/Transfer/Transfer";
import { extractErrorMessage } from "../../helpers";
import { buildString, handleRedirection, isEmpty } from "../../utilities/help";
import { disableSpacePaste } from "../../utilities/help";
import { useLocation } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { toast } from "material-react-toastify";
import CustomInputField from "../SignUp/CustomInputField";
import FormErrorText from "../FormErrorText";
import CustomButton from "../CustomButton";

const LoginWithEmail = (props: any) => {
  const {
    t,
    showErrorMessage,
    captchaToken,
    dispatch,
    setLoggedUserCountry,
    history,
    handleHeapAndCustomerioEvents,
    setCaptchaToken,
    refreshCaptcha,
    errorMessage,
    yup,
    setRegistrationData,
    setisUserRejected,
  } = props;

  const validationSchema = yup.object().shape({
    password: yup.string().required("password_required"),
    email: yup.string().email("email_not_valid").required("email_required"),
  });

  const isUserRejected = (result: any) => {
    const verificationResult = result?.user?.verification_result;
    const userRejected = result?.user?.is_rejected;
    const userActive = result?.user?.is_active;

    if (userActive) {
      setisUserRejected(false);
      return true;
    } else if (
      (verificationResult == "RED-FINAL" && !userActive) ||
      (userRejected == true && !userActive)
    ) {
      setisUserRejected(true);
      return undefined;
    } else {
      setisUserRejected(false);
      return true;
    }
  };

  const handleUserTypeSelection = (result: any) => {
    try {
      const userIsResumingAfterAccountSelection = !isEmpty(
        localStorage.getItem("acctype")
      );
      if (!userIsResumingAfterAccountSelection) {
        if (result.user.is_individual) {
          localStorage.setItem("acctype", "Individual");
        } else {
          localStorage.setItem("acctype", "Business");
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const search = useLocation().search;
  const queryURL = new URLSearchParams(search);

  const [email, setemail] = useState<any>("");
  useEffect(() => {
    try {
      const link = queryURL.get("email");
      if (!isEmpty(link)) {
        setemail(link);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [search]);

  return (
    <div className="!mt-7">
      <Formik
        enableReinitialize
        validateOnMount
        validateOnChange
        initialValues={{
          email: email || "",
          password: "",
          captcha: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          try {
            setSubmitting(true);
            showErrorMessage([]);
            // login user data
            data.captcha = captchaToken;
            captchaToken !== null &&
              login(data)
                .then((result: any) => {
                  localStorage.removeItem("depositattempts");
                  localStorage.removeItem("maketransferattempts");

                  if (isUserRejected(result) == undefined) {
                    setSubmitting(false);
                    return;
                  }

                  handleHeapAndCustomerioEvents(result, data);
                  setRegistrationData(result.user);

                  handleUserTypeSelection(result);

                  setSubmitting(false);
                  // if the response hasa token [user exists]
                  if (result.access_token) {
                    // store the user data in redux store
                    localStorage.setItem("user:key", result.access_token);
                    setLoggedUserCountry(result.user);
                    dispatch(loginUser(result));

                    //if user email is verified
                    if (result.user?.email_verified_at !== null) {
                      //check if user has temporary password
                      if (result.user?.has_temporary_password) {
                        history.push("/changePassword");
                      } else {
                        const pay_id = localStorage.getItem("payment_id");
                        if (pay_id) {
                          getTransferCountries().then((res: any) => {
                            dispatch(
                              addLoggedInUserCountry(
                                res?.data?.find(
                                  (cc: any) =>
                                    cc.country_code ===
                                    result?.user?.country_code
                                )
                              )
                            );
                            history.push(`/payment/${pay_id}`);
                          });
                        } else {
                          const appref = localStorage.getItem("appref");

                          if (appref!) {
                            getTransferCountries().then((res: any) => {
                              dispatch(
                                addLoggedInUserCountry(
                                  res?.data?.find(
                                    (cc: any) =>
                                      cc.country_code ===
                                      result?.user?.country_code
                                  )
                                )
                              );
                              paymentsAnonymous(appref!)
                                .then((response: any) => {
                                  const id = response.details.id;
                                  history.push(
                                    `/payment/${id}?status=anonymous`
                                  );
                                })
                                .catch((error) => {
                                  Sentry.captureException(error);

                                  if (error.status >= 400) {
                                    localStorage.removeItem("appref");
                                    toast.error(
                                      t(error?.data?.errors?.error[0])
                                    );
                                    localStorage.setItem(
                                      "paymentError",
                                      error?.data?.errors?.error[0]
                                    );
                                    history.push("/dashboard/home");
                                  }
                                });
                            });
                          }
                          // if (result?.user?.is_active === false) {
                          //   history.push('/login');
                          // }
                          else {
                            setCaptchaToken("");
                            dispatch(
                              refreshCaptchaToken(
                                refreshCaptcha === true ? false : true
                              )
                            );
                            handleRedirection(result?.user, history);
                            // history.push("/dashboard/home");
                          }
                        }
                        // }
                      }
                    } else {
                      // if email is not verified go to verify email screen
                      history.push("/verify");
                    }
                  } else {
                    toast.warning(t(JSON.stringify(result)));
                  }
                })
                .catch((error) => {
                  toast.error(error?.data?.error);
                  setSubmitting(false);
                  dispatch(
                    refreshCaptchaToken(refreshCaptcha === true ? false : true)
                  );
                  const err = extractErrorMessage(error);
                  const errorArray = err.map((errorText: string) =>
                    buildString(errorText?.toLowerCase())
                  );
                  showErrorMessage(errorArray);
                  Sentry.captureException(error);
                });
          } catch (error: any) {
            showErrorMessage((err: any) => [...err, error.message]);
            Sentry.captureException(error);
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => (
          <Form>
            <CustomInputField
              mainContainerClasses="w-full mb-3"
              inputClasses="!rounded-xl h-[50px] w-full border-1 !px-3 border-[rgba(0,0,0,0.2)]"
              type="email"
              name="custemailmJob"
              handleChange={(e: any) => {
                if (disableSpacePaste("email", e.target.value)) {
                  handleChange("email")(
                    disableSpacePaste("email", e.target.value)
                  );
                } else {
                  handleChange("email")(e.target.value);
                }
              }}
              onBlur={handleBlur}
              placeholder={t("Email_Address")}
              value={values.email}
              error={t(`${errors.email}`)}
              touched={touched.email}
            />

            <CustomInputField
              inputClasses="w-full"
              mainContainerClasses="w-full !rounded-xl h-[50px] mb-3 border-1 !px-3 border-[rgba(0,0,0,0.2)] flex items-center"
              extraClasses="mb-3 customInputStyle"
              name="password"
              handleChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("password")}
              value={values.password}
              touched={touched.password}
              type="password"
            />

            {errors.password && touched.password && (
              <FormErrorText errorMessage={errors.password} />
            )}

            <div
              className="font-sm font-bold text-[#037375] my-7 cursor-pointer"
              onClick={() => history.push("/forgot")}
            >
              {t("forgotPass")}
            </div>

            <CustomButton
              type="submit"
              classNames="w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
              disabled={
                isSubmitting || !isValid || captchaToken === ""
                  ? true
                  : false && isSubmitting
              }
              onClick={() => {}}
              label="log_in"
              data-login="login"
            />
          </Form>
        )}
      </Formik>
      {errorMessage.length > 0 && (
        <div className="mt-3">
          <Alert severity="error">
            {errorMessage.map((errorText: string, index: number) => (
              <p className="text-xs" key={index}>
                {t(errorText)}
              </p>
            ))}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default LoginWithEmail;
