import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

const PhoneSearchModal = (props: any) => {
  const { all_countries, setSelected, setCountry, setcountryCodeDropdownOpen } =
    props;

  const [searchText, setsearchText] = useState("");
  const [filteredSearchListing, setfilteredSearchListing] = useState([]);
  const [listing, setlisting] = useState([]);

  useEffect(() => {
    try {
      if (searchText !== "") {
        const filteredResults = all_countries.filter((country: any) => {
          return (
            (country &&
              country.name &&
              country.name.toLowerCase().includes(searchText.toLowerCase())) ||
            (country.country_code &&
              country.country_code
                .toLowerCase()
                .includes(searchText.toLowerCase()))
          );
        });
        setlisting(filteredResults);
      } else {
        setlisting(all_countries);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [searchText, all_countries]);

  return (
    <div className="z-10 absolute left-0 w-full top-[58px] border-1 pl-4 pr-4 border-[rgba(0,0,0,0.2)] !rounded-xl h-[321px] bg-[#FAFAFA]">
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="24"
          fill="#000000"
          viewBox="0 0 256 256"
        >
          <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
        </svg>
        <input
          type="text"
          className="min-h-[42px] font-bold opacity-50"
          value={searchText}
          onChange={(e: any) => setsearchText(e.target.value)}
          //   placeholder={placeholder}
        />
      </div>
      <hr />
      <div className="w-full overflow-y-scroll h-[270px] pl-2 pr-2">
        {listing?.map((item: any, index: number) => (
          <div className="" key={index}>
            <div
              className="flex flex-row items-center my-3.5 cursor-pointer"
              onClick={() => {
                setCountry(item);
                setSelected(item);
                setcountryCodeDropdownOpen(false);
              }}
              key={index}
            >
              <img
                src={`../flags/${item.country_code}.png`}
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 15,
                }}
                className="mr-2"
                alt=""
              />
              <div>{item?.name}</div>

              <div className="ml-auto">+{item?.country_code}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhoneSearchModal;
