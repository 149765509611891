import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import { CircularProgress } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PhoneSearchModal from "./PhoneSearchModal";
import { ExpandLess } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const PhoneNumberPicker = (props: any) => {
  const {
    mainContainerClasses = "w-full relative",
    label,
    labelClasses = "mb-1 text-gray-600 text-[12.6px]",
    type = "text",
    inputClasses = "!rounded-xl h-[50px] w-full border-1 pl-5 pr-5 border-[rgba(0,0,0,0.2)]",
    value,
    name,
    handleChange,
    disabled = false,
    placeholder,
    onBlur,
    error = "",
    touched = false,
    errorClasses = "mt-2 text-red-400 text-xs",
    country,
    setCountry = () => {},
    phoneNumberClasses = "ml-2",
    containerClasses = "!rounded-xl h-[50px] w-full border-1 border-[rgba(0,0,0,0.2)] flex items-center pl-3 pr-3 overflow-hidden",
  } = props;
  const { transfer } = useAppSelector((state) => state.persistedReducer);
  const { t } = useTranslation();

  // calculating countries
  const [all_countries, setAllCountries] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);
  const [calculatingCountries, setcalculatingCountries] = useState(true);

  const getDefaultCountries = (countries: any) => {
    try {
      setcalculatingCountries(false);
      if (country) {
        const c: any = countries.reduce((sum: any, current: any) => {
          return [...sum, ...current.countries];
        }, []);

        if (typeof country === "string") {
          const result: any = c.find(
            (cc: any) => cc?.name?.toLowerCase() === country?.toLowerCase()
          );
          setCountry(result ? result : null);
          setSelected(result ? result : null);
        } else {
          const result = c.find(
            (cc: any) =>
              cc?.name?.toLowerCase() === country?.name?.toLowerCase()
          );
          setCountry(result ? result : null);
          setSelected(result ? result : null);
        }
      } else {
        const c: any = countries.reduce((sum: any, current: any) => {
          return [...sum, ...current.countries];
        }, []);
        const result = c[0];
        setCountry(result ? result : null);
        setSelected(result ? result : null);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      setcalculatingCountries(false);
    }
  };

  // create country listing
  const create_country_list = () => {
    try {
      const regions = [
        { content: "Europe", numbers: [3, 4, 5] },
        { content: "Africa", numbers: [2] },
        { content: "Middle East", numbers: [9, 8] },
      ];
      const all = regions.map((country: any) => {
        if (
          transfer?.loggedInUserCountry === undefined ||
          transfer?.loggedInUserCountry?.country_code === null
        ) {
          const dana_countries = transfer?.danaPayCountries
            .filter((val: any) => {
              const first_number = val?.country_code?.split("")[0];
              return country?.numbers?.includes(parseInt(first_number));
            })
            .map((value: any) => {
              return {
                name: value?.name,
                code: value?.code,
                country_code: value?.country_code,
                mobileCode: `+${value?.country_code}`,
                flag: `${value?.country_code}.png`,
                requires_post_code: value?.requires_post_code,
              };
            });
          return {
            ...country,
            countries: dana_countries,
          };
        }

        const dana_countries = transfer?.danaPayCountries
          .filter((val: any) => {
            const first_number = val?.country_code?.split("")[0];
            return country?.numbers?.includes(parseInt(first_number));
          })
          .map((value: any) => {
            return {
              name: value?.name,
              code: value?.code,
              country_code: value?.country_code,
              mobileCode: `+${value?.country_code}`,
              flag: `${value?.country_code}.png`,
              requires_post_code: value?.requires_post_code,
            };
          });
        return {
          ...country,
          countries: dana_countries,
        };
      });
      getDefaultCountries(all);
      setAllCountries(all);
    } catch (error: any) {
      setcalculatingCountries(false);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    try {
      if (
        transfer?.danaPayCountries &&
        transfer?.danaPayCountries?.length > 0
      ) {
        create_country_list();
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [transfer?.loggedInUserCountry?.country_code, transfer?.danaPayCountries]);

  // country code dropdown
  const [countryCodeDropdownOpen, setcountryCodeDropdownOpen] = useState(false);

  return (
    <>
      {label && <div className={labelClasses}>{t(label)}</div>}
      <div className={mainContainerClasses}>
        {calculatingCountries && (
          <div className={containerClasses}>
            <CircularProgress className="mx-auto" size={10} />
          </div>
        )}
        {!calculatingCountries && (
          <div className={containerClasses}>
            {selected?.country_code && (
              <img
                src={`../flags/${selected.country_code}.png`}
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 15,
                }}
                className="mr-2"
                alt=""
              />
            )}
            <div onClick={() => setcountryCodeDropdownOpen((p) => !p)}>
              {countryCodeDropdownOpen ? <ExpandLess /> : <ExpandMore />}
            </div>

            <input
              type={type}
              value={value}
              name={name}
              onChange={handleChange}
              disabled={disabled}
              placeholder={placeholder}
              autoComplete="off"
              onBlur={onBlur}
              className={phoneNumberClasses}
            />

            {/* search modal */}
            {countryCodeDropdownOpen && (
              <PhoneSearchModal
                all_countries={transfer?.danaPayCountries}
                setSelected={setSelected}
                setCountry={setCountry}
                setcountryCodeDropdownOpen={setcountryCodeDropdownOpen}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneNumberPicker;
