import { useMemo } from "react";
import * as Sentry from "@sentry/browser";
import CustomDropdown from "../CustomDropdown";

const NeeroWalletWithdrawBox = (props: any) => {
  const {
    mobileOperatorList,
    setwidthdrawMethod,
    widthdrawMethod,
    t,
    transfer,
    setinstitutionId,
    numberInputInvalidChars,
    directPaymentAmountExceed,
    setneeroWalletId,
    neeroWalletId,
    beneficiaryNeeroWallets,
    neeroWalletNumber,
    setneeroWalletNumber,
    customNeeroWalletNumber,
    setcustomNeeroWalletNumber,
    processingPayment,
    fetchingBeneficiaryAccounts,
    checkingForWithdrawProviders,
  } = props;

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              cashoutMethod.cashout_method.payment_type.name == "wallet"
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + "€";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  const handleWalletIdSelection = (walletNumber: number) => {
    try {
      const walletAccount = beneficiaryNeeroWallets.find(
        (wallet: any) => wallet.name == walletNumber
      );
      setneeroWalletId(walletAccount ? walletAccount.id : "");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const defaultNeeroWallet = useMemo(() => {
    try {
      if (
        beneficiaryNeeroWallets &&
        beneficiaryNeeroWallets.length > 0 &&
        transfer?.transactionToRepeat?.data?.retry_payload?.cashout_account_id
      ) {
        const selectedNeeroWallet = beneficiaryNeeroWallets.find(
          (wallet: any) =>
            wallet.id ==
            transfer?.transactionToRepeat?.data?.retry_payload
              ?.cashout_account_id
        );

        if (selectedNeeroWallet) {
          return selectedNeeroWallet.name;
        } else {
          return "";
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      return "";
    }
  }, [
    beneficiaryNeeroWallets,
    transfer?.transactionToRepeat?.data?.retry_payload?.cashout_account_id,
  ]);

  const isDisabled = useMemo(() => {
    try {
      if (
        fetchingBeneficiaryAccounts ||
        processingPayment ||
        (directPaymentAmountExceed && widthdrawMethod == "Neero")
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [
    directPaymentAmountExceed,
    processingPayment,
    fetchingBeneficiaryAccounts,
    checkingForWithdrawProviders,
    widthdrawMethod,
  ]);

  return (
    <>
      <>
        <div className={`wrapperBankCheckBox m-0 innerBankBox `}>
          <div className={`wrapperBankCheckBoxwrapper`}>
            <input
              type="checkbox"
              className="default:ring-2"
              onChange={(e) => {
                e.target.checked && setwidthdrawMethod("Neero");
              }}
              name="Neero"
              checked={widthdrawMethod == "Neero"}
            />
            <span className="checkmark"></span>
          </div>
          <label
            className={`opacity-[${
              directPaymentAmountExceed && widthdrawMethod == "Neero" ? 0.5 : 1
            }]`}
          >
            {t("Neero")}
          </label>
        </div>

        {directPaymentAmountExceed && widthdrawMethod == "Neero" && (
          <small className="mb-2 innerBankBox text-red-500">
            {t("max_amount_exceeded_cashout_message_1")}
            {maxLimit}
            {t("max_amount_exceeded_cashout_message_2")}
          </small>
        )}
      </>

      {widthdrawMethod == "Neero" && (
        <div className="mb-1 flex flex-col innerBankBox">
          <div className="innerBoxDesc mb-2">
            <span>{t("neero_wallet_description")}</span>
            <b>{t("neero")}</b>
          </div>

          {beneficiaryNeeroWallets.length > 0 && (
            <CustomDropdown
              isDisabled={isDisabled}
              defaultValue={defaultNeeroWallet}
              setinstitutionId={setinstitutionId}
              onchange={(val: any) => {
                if (val !== "Other") {
                  handleWalletIdSelection(val);
                } else {
                  setcustomNeeroWalletNumber("");
                }
                setneeroWalletNumber(val);
              }}
              list={beneficiaryNeeroWallets}
              customClasses="wrapperBankCheckBox innerBankBox m-0 !bg-white"
              placeholder={t("choose_phone_number")}
            />
          )}

          {(neeroWalletNumber == "Other" ||
            beneficiaryNeeroWallets.length < 1) && (
            <div
              className="w-full flex px-0 gap-1  text-sm items-center  border-2 rounded-2"
              style={{
                color: "#080707",
                border: "0px",
                padding: "0px 16px",
                margin: "0px 0px",
              }}
            >
              <div
                className={`p-2 bg-white flex items-center opacity-[${
                  directPaymentAmountExceed && widthdrawMethod == "Neero"
                    ? 0.3
                    : 1
                }]`}
              >
                <p className="text-sm font-bold">
                  {transfer?.beneficiary?.country_code}
                </p>
              </div>
              <div className="w-full mb-1">
                <input
                  disabled={isDisabled}
                  onChange={(text) => {
                    setcustomNeeroWalletNumber(text.target.value);
                  }}
                  value={customNeeroWalletNumber}
                  className="flex-1 w-full p-2 text-sm w-full px-2 py-2 text-sm bg-white border-2 rounded-2"
                  placeholder={t("Phone_Number")}
                  type="number"
                  onKeyDown={(e) => {
                    if (numberInputInvalidChars.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  style={{
                    color: "#080707",
                    border: "0px",
                    padding: "15px 16px",
                    margin: "7px 0px",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NeeroWalletWithdrawBox;
