export const TRANSLATIONS_EN = {
  welcome: "Welcome to DanaPay",
  version: "Version",
  thanks_for_application:
    "Thank you ! We will process your application and you will receive a confirmation email within 24",
  linkExpired: "Your account verification link has expired",
  serviceWarning:
    "Our service is currently disrupted. Please contact the support.",
  frequired: "Please fill the required fields",
  emailExists: "This email already exists. Please login.",
  accountActivation:
    "Your account is being activated. It will take 24 hours maximum. Please log in again as soon as you receive the confirmation email.",
  sendRange: "You can send between X and Y",
  escrowText:
    "Escrowed payment brings more trust on trading. With escrow payment, your funds will be locked into a smart-contract and your recipient will be able to see that they are available. But he will not be able to get them as soon as you haven't unlocked them. Once the funds are locked, you will also not able to get back then as long as your recipient hasn't accepted the refund more details",
  instantText: "Same day reception if the payment is sent before 3pm.",
  payConfirmation: "Payment confirmed. We will now execute your transfer.",
  payCancelled:
    "Payment cancelled or refused. If you did not cancel it, please try with another bank account.",
  adminErrorText: "An error has occurred, please contact the administrator.",
  forgotPass: "Forgot password",
  fptext: "Enter the email you used for registration",
  termsText:
    "  By clicking on Submit you agree to our Privacy Policy applicable to the processing of your personal data.",
  sutitile: "Register to Danapay!",
  suSubtitle: "Register and discover the new international payment experience",
  email: "Email Address",
  password: "Password",
  changePass: "Change your password",
  changePassSub: "choose a new password to secure your account.",
  confirmPass: "Confirm Password",
  newPass: "New Password",
  oldPass: "Old Password",
  register: "Register",
  Change_Password: "Change Password",
  ftHelp: "Help",
  ftTerms: "Terms and Conditions",
  ftPolicy: "Privacy Policy",
  loginText: "Login and start you international payment",
  login: "Login",
  start_Transfer: "Start Transfer",
  beneficiary: "Beneficiary",
  amount: "Amount",
  payment: "Payment",
  confirmation: "Confirmation",
  close: "Close",
  back: "Back",
  next: "Next",
  fees: "Fees",
  TransferFees: "Transfer Fees",
  WithdrwalFees: "Withdrawal Fees",
  exchange_rate: "Exchange rate",
  Payout_mode: "Payout mode",
  transaction_type: "Transaction type",
  instant_payments: "Instant payments",
  escrow_payment: "Escrow Payment",
  escrowed: "Escrowed",
  enterPerson: "Enter your personal information",
  validPhone: "A valid phone number is required.",
  finish: "Finish",
  coInfo: "Enter your company information",
  docsTitle: "ShareHolder/s Documents",
  docsReason:
    "We need these document for compliance reason. They are required ounce and will not be asked again.",
  docsDrag: "Drag 'n' drop some files here, or click to select files",
  docsDropping: "Drop the files here ...",
  skip: "Skip",
  continue: "Continue",
  total: "Total",
  totalSent: "Total sent",
  action: "Actions",
  date: "Date",
  photo: "Photo",
  status: "Status",
  instant: "Instant Payment",
  escrow: "Escrowed Payments",
  home: "Home",
  developer: "Developer",
  eur_amount: "Sent Amount (EUR)",
  cfa_amount: "Amount received (CFA)",
  payOutMode: "Payout Mode",
  transactionType: "Transaction Type",
  wellDone: "Well Done",
  successMessage:
    "Your payment has been taken in account. As soon as we have received funds your will be notified and we will execute the transfer",
  backHome: "Back to Home",
  successFooter: "Made with Love from DanaPay for a better Africa",
  createApp: "Create New Application",
  appId: "App Id",
  appIcon: "App Icon",
  apiKey: "API Key",
  sandBox: "Sand Box",
  production: "Production",
  AppGenInfo: "App General information",
  appName: "Application name",
  application: "Application Description",
  general: "General",
  name: "Name",
  Email: "Email",
  companyNumber: "Company registration number",
  Verifying_your_Email: "Verifying your Email",
  Please_wait: "Please wait",
  Please_Wait: "Please wait",
  email_validated: "Your Email has been verified",
  login_here: "Login here",
  EMail_Verification: "E-Mail Verification",
  sent_code: "We have sent you a confirmation E-mail",
  click_to_continue: "Click on the received link to continue your registration",
  Send_New_Link: "Send New Link",
  Email_Address: "Email Address",
  FirstName: "First Name",
  LastName: "Last Name",
  OtherActivities: "Other Activities",
  CompanyName: "Company Name",
  RegistrationID: "Registration ID",
  AddressInformation: "Address Information",
  Country: "Country",
  City: "City",
  IBAN: "IBAN",
  Register: "Register",
  link_expired: "This verification link has expired",
  askNewLink:
    "Ask for a new link to continue your registration with or start over again.",
  RequestLink: "Request Link",
  acceptTerms:
    "   By clicking on Submit you agree to our Privacy Policy applicable to the processing of your personal data.",
  AddApplication: "Add Application",
  Date_Created: "Date_Created",
  newApp: "Create a new application.",
  SandBox: "SandBox",
  App_General_Information: "App General Information",
  Application_Name: "Application Name",
  Application_Description: "Application Description",
  Market_Place: "Market Place ",
  Market_Place_Desc: "Your sellers will be paid by DanaPay ",
  Merchant: "Merchant (Coming soon)",
  Merchant_Desc: "Your sellers will be paid by DanaPay",
  API_Scope: "API Scope ",
  Configuration: "Configuration",
  Redirect_URL: "Redirect URL",
  Webhook: "Webhook",
  Creating_App: "Creating App",
  AppInfoDesc:
    "The app_secret and app_private_key must be stored safely in your backend environment ie not in a client-side bundle not in a mobile app.",
  AppInfo: "Application information",
  App_Secret: "App Secret",
  Download_Private_Key: "Download Private Key",
  App_Icon: "App Icon",
  App_Name: "App Name",
  Api_Key: "Api Key",
  App_ID: "App_ID",
  Amount_received_CFA: "Amount received (CFA)",
  Amount_received_EUR: "Amount received (EUR)",
  Processing: "Processing",
  fetchingTransfer: "Fetching transaction",
  repeating_transfer: "Repeating Transfer",
  Back_To_website: "Back To website",
  Available_outstanding_balance: "Available outstanding balance",
  Withdraw_All: "Withdraw All",
  expectAmount: "The seized amount must be from 1 to ",
  Add_New_Account: "Add Account",
  Bank_Name: "Bank name",
  Owner: "Owner",
  owner_tag: "Owner",
  sendingOTP: "We shall send an OTP to ",
  Send_and_Continue: "Send and Continue",
  sentOTP: "We sent you an OTP to",
  enterOTP: " Enter it below to confirm cash out",
  EnterOTP: "Enter OTP",
  ConfirmCashout: "Confirm",
  the_phone_number_may_not_be_greater_than_12_characters:
    "The phone number may not be greater than 12 characters",
  onTheWay: " On the way!",
  onTheWayDesc:
    "Your withdrawal has been taken into account and is being processed. You will be notified as soon as we have finalized the transaction.",
  Havegreatday: "Have a great day! ",
  GOBACK: "GO BACK",
  Title: "Title",
  OwnerName: "Owner name",
  Add: "Add",
  CompleteTransfer: "Complete Transfer",
  WellDone: "Well Done",
  WellDoneDesc: "Make a deposit on our Bank of Africa  account",
  enterPhoneNumber: " Enter the phone number of the beneficiary blow",
  beneInfo: "Please enter the beneficiary information.",
  AllTransactions: "All Transactions",
  Source: "Source",
  Actions: "Actions",
  Status: "Status",
  Amount: "Amount",
  Date: "Date",
  Name: "Name",
  Photo: "Photo",
  Type: "Type",
  RefreshingList: "Refreshing List",
  FetchingPayment: "Fetching Payment",
  Release: "Release",
  AskRefund: "Ask Refund",
  Repeat: "Repeat",
  RevokeRelease: "Revoke Release",
  Pay: "Pay",
  Refund: "Refund",
  RevokeRefund: "Revoke Refund",
  Sender: "Sender",
  CompanyRegistrationNo: "Registration number",
  Receiver: "Receiver",
  Details: "Details",
  Payment_method: "Payment method",
  Sent_amount: "Sent amount",
  Fees: "Fees",
  Exchange_rate: "Exchange rate",
  Received_amount: "Amount received",
  Total_Received: "Total received",
  AskRelease: "Ask Release",
  InstantPayment: "Instant Payment",
  Escrowed_Payments: "Escrowed Payments",
  Withdraws: "Pay-Out",
  StartTransfer: " Start Transfer",
  Enter_Withdraw_details: "Pay-Out",
  Information: "Information",
  // InformationDesc:
  //   "Your company is inactive at the moment.Please contact the administrator for more details.",
  InformationDesc: "Need help? We are available via chat or WhatsApp.",
  schedule_meeting: "Schedule meeting",
  Address: "Address",
  NotReceiver: "Please contact the support if this is not your recipient",
  BankDetails: "Bank Details",
  Confirmation: "Confirmation",
  Complete: "Complete",
  TransactionDetails: "Transaction Details",
  sentOtp: "We sent and OTP code on your mobile number",
  sentOtpHeader: "Verify Phone Number",
  // bankDetailsTitle: "Payment taken in account!",
  bankDetailsTitle: "It’s time to make your transfer",
  bankDetailsSubTitle: "Make a deposit on our Bank of Africa  account",
  bankDetailsSubDescription:
    "Now, to finalize the deposit on your DanaPay account, please go to one branch of the Bank Of Africa and make a cash deposit. Or, if you have a web banking, sign in to your online bank account, locate the transfer menu, and enter the information below.",
  bd_reference: "Reference",
  complete_payment: "Complete Payment",
  validate_refund: "Validate refund",
  Revoke_Refund: "Revoke Refund",
  Validate_Release: "Validate_Release",
  processing: " Processing...",
  uploading: " Uploading...",
  file_upload: " File Uploaded.",
  with_0_balance: "It's not possible to withdraw with 0 balance",
  cashout_confirm:
    "Click to select an account you want is to send the money to.",
  delete: "Delete",
  title: "Title",
  Instant: "Instant",
  Escrow: "Escrow",
  PaymentMode: "Payment Mode",
  WithdrawMode: "Withdraw Mode",
  BeneficiaryWith72Hours:
    "Your beneficiary should receive the funds within 72 hours",
  BeneficiaryWith1Hours:
    "Your recipient will receive the funds in their Danapay account within the next 2 minutes of receiving your payment.",
  // "Your beneficiary should receive the funds in their Mobile Money account 5 minutes after your payment.",
  BeneficiaryWith1HoursTwo:
    "He will have to make a withdrawal request from the application or by contacting support.",
  // BeneficiaryWith1Hours: "Your recipient should receive the funds within an hour",
  PleaseSpecifyYourRecipient:
    "Please specify your recipient bank account details. He has to be the unquie owner and the bank has to be into the receiving country.",
  PleaseSpecifyYourRecipientMobile:
    "Please specify your recipient's mobile account details. He must own it.",
  nearestCollectionPoint:
    "Your beneficiary will be contacted to be directed to the nearest collection point",
  PaymentReceivedAsSoon:
    "Your beneficiary will instantly receive the funds within his Danapay account as soon as we have received the payment",
  DependingWithDrawMethod:
    "He will be able to withdraw them within 5 minutes to 72 hours depending on the method chosen",
  You_can_send_between: "You can send between",
  Balance: "Balance",
  ExchangeRate: "Exchange Rate",
  TransactionType: "Transaction Type",
  Deposit: "Deposit",
  cant_use_balance: "You cant use the Balance pay method to payIn",
  cant_deposit_zero: "You can't cash in 0 Euros",
  MakeDeposit: "Make Deposit",
  insufficient: "Your have insufficient balance.",
  enterEuros: "Please enter Amount in Euros",
  selectCashIn: "Please select a cash in method",
  enterCfa: "Please enter Amount in CFA",
  cantSendToSelf: "You can't send money to yourself.",
  Your_current_balance: "Balance",
  selectCashinMethod: "Select cash in method",
  selectPreviousUserList: "Select a previous user list",
  MobileOperator: "Mobile operator",
  selectWithdrawMode: "Select a withdraw mode",
  BackHome: "Back Home",
  Reference: "Reference",
  passwordLinkSent: "A password reset link was sent to your email",
  payout: "Withdraw",
  Please_select_cashin_Method: "Please select the cash in method",
  menuHome: "Home",
  menuDeveloper: "Developer",
  menuHelp: "Help",
  menuPolicy: "Privacy Policy",
  menuTerms: "Terms and Conditions",
  Payment_Mode: "Payment Mode",
  Sent_amount_EUR: "Sent amount in EUR",
  Sent_Amount_Eur_Only: "Sent amount (EUR)",
  Sent_amount_Cfa: "Receive amount in  CFA",
  Sent_amount_EUR_Only: "Amount in EUR",
  Sent_amount_CFA_Only: "Amount in  CFA",
  entries: "entries",
  Sent_Amount_Cfa_Only: "Receive amount (CFA)",
  Sent_Amount_GNF_Only: "Receive amount (GNF)",
  Checked_Toogle:
    "I specify how my beneficiary will withdraw the funds from Danapay upon receipt",
  type_withdraw_amount: "Type the withdraw Amount",
  Enter_withdraw_Details: "Enter the withdraw Details",
  Type_verification_code: "Type the verification code",
  TVCText: "Click on Send code to receive a new code",
  warning: "Warning",
  started: "Started",
  funds_escrowed: "Funds escrowed",
  transfered: "Transferred",
  released: "Released",
  escrowing: "Escrowing",
  transfering: "Transferring",
  releasing: "Releasing",
  paying_in: "Paying in",
  paying_out: "Paying out",
  payment_failed: "Payment failed",
  payment_started: "Payment started",
  revoking_refund_confirmation: "Revoking",
  payment_pending: "Payment pending",
  payed_out: "Paid out",
  completed: "Completed",
  payed_in: "Paid in",
  previous: "Previous",
  reason: "Payment reason",
  Reason: "Reason",
  BankName: "Bank Name",
  BIC: "BIC",
  password_required: "Password is required",
  email_required: "Email address is required.",
  email_not_valid: "Email is not valid.",
  LNR: "Last name is required",
  FNR: "First name is required",
  TRQ: "User type is required",
  ERQ: "Email address is required.",
  P_REG:
    "The password must have at least 8 characters, 1 upper case 1 number and 1 special character (#$@!%&*?_-)",
  PSM: "Passwords must match",
  RNE: "Registration number is required.",
  CNE: "Company name is required",
  AIE: "Address information is required",
  CE: "Country is required",
  CIE: "City is required",
  "": "",
  payment_instruction: "Payment Instruction",
  pi_text: "Select the bank on which you will make the transfer",
  select_files: "Select the files to upload in [pdf, png, jpg,jpeg] max 5MB",
  upload: "Upload ",
  uploaded_successfully: "was uploaded successfully",
  Drop_file_to_upload: "Drop file to upload",
  or: "or",
  Register_of_commerce: "Register of commerce",
  Articles_of_association: "Articles of association",
  Beneficiary_owner: "Beneficiary owner",
  Directors_IDs: "Directors IDs",
  Proof_of_address: "Proof of address",
  menuHistory: "History",
  bulkPayment: "Bulk Transfer",
  Upload_csv: "Upload a beneficiaries csv detail",
  Csv_Format_Error: "Error. Please Check the csv format",
  Upload_Csv_Toast_Error:
    "The uploaled CSV is not well formatted. Please use the template below.",
  Beneficiary_selection:
    "Download up to 1000 beneficiaries from a CSV file by clicking the button below.",
  // Beneficiary_selection: "Select a beneficiary from the list or click on the button below to upload a list. You can upload a maximum of 1000 beneficiaries",
  // Beneficiary_selection: "Select a beneficiary from the list or click on the button below to upload a list. You can upload a maximum of 500 000 beneficiaries",
  Upload_Csv_Toast:
    "Please check that all the phone numbers are correct and have no country code nor spaces. You will be charged even the operation failes because of bad formatted and wrong phone numbers",
  Lose_Csv_Toast:
    "You are about to leave the bulk transfer page. You will lose all your data.",
  Upload_Rows: "rows successfully uploaded",
  Upload_Another_Csv_Toast:
    "You are about to upload a new bulk transfer CSV. You will lose the previous loaded data",
  Individual: "Individual",
  Business: "Business",
  Phone_Number: "Phone number",
  Other: "Other",
  Add_Contact: "Add Contact",
  Invite_Contact: "Invite contact",
  Add_New_Contact: "Add new contact",
  Add_New_Contact_Info:
    "Add a contact and start an instant payment to him in only one simple click.",
  Latest_Transactions: "Latest Transactions",
  Contacts: "Contacts",
  People_selected: "People selected",
  Select_Beneficiaries: "Select Beneficiaries",
  select_cashOut: "Please select payout method",
  select_account: "Please select an account to send the money to.",
  select_account_type: "Please select your account type",
  balance_zero: "It's not possible to withdraw with 0 balance",
  Withdrawal_amount: "Withdraw amount is too high.",
  select_user: "Select user you want to send money to",
  select_country: "Select by country",
  country_not_configured: "Receiving country not configured yet.",
  country_not_selected: "Select a country",
  sumSubTitle: "User  verification ",
  sumSubDescription:
    "We need these document for compliance reason. They are required ounce and will not be asked again.",
  skip_later: "Skip for Later",
  add_user: "Add User",
  amount_out_of_range: "Amount out of range",
  euroAmountRequired: "The euro amount field is required.",
  select_users: "",
  Make_deposit: "Make a deposit on our account in ",
  the_key_code_must_be_between_5_and_6_digits:
    "The key code must be between 5 and 6 digits.",
  verification_failed: "verification failed",
  the_key_code_field_is_required: "The key code field is required",
  Individual_Account: "Individual Account",
  file_to_large:
    "Sorry, your file is too large. we accept file size 5mbs at most",
  pay_by_link: "Pay By Link",
  Bulk_Payments: "Bulk Payments",
  Pay_By_Link: "Pay By Link",
  not_config:
    "has no receiving countries attached to it in DanaPay configuration.",
  accept_payment: "accept payment",
  userNotActive:
    "Your account has not been activated yet, Please contact the Danapay Administrator",
  userNotActiveTitle: "Account Activation",
  termsTextDes: " By clicking on register you agree to our",
  verification: "Verification",
  bank_transfer: "Bank Transfer",
  balance: "Balance",
  Personal_Information: "Personal Information",
  Company_Information: "Company information",
  Verify_User: "Verify User",
  Account_Type: "Account Type",
  Account_Type_Required: "Account Type is required",
  select_bank: "Select a bank",
  validated: "Validated",
  bank_details: "Bank Details",
  bank_payout: "Bank Payout",
  deposit: "Deposit",
  the_payout_has_started: "The payout has started",
  the_payout_has_pending: "The payout has pending",
  the_payout_has_completed: "The payout has completed",
  the_payment_is_pending: "The payment is pending",
  the_payment_is_compled: "The payment is compled",
  the_payment_has_failed: "The payment has failed",
  the_transfer_is_in_progress: "The transfer is in progress",
  the_transfer_is_completed: "The transfer is completed",
  title_required: "Title is a required field",
  bank_required: "Bank is a required field",
  owner_required: "Owner is a required field",
  bic_required: "BIC is a required field",
  sumsubText: "Upload a document that proves your identity",
  loggingOut: "Logging Out",
  loggingOutSure: "Are you sure to do this?",
  yes: "Yes",
  no: "No",
  your_account_has_been_activated: "Your account has been activated.",
  we_confirm_the_reception_of_your_payment:
    "We confirm the reception of your payment.",
  your_transfer_has_been_successfully_executed:
    "Your transfer has been successfully executed",
  your_payout_is_in_progress: "Your payout is in progress",
  we_have_executed_your_withdraw: "We have executed  your withdraw ",
  your_deposit_is_completed: "Your deposit is completed",
  your_account_has_been_inactivated: "Your account has been inactivated",
  your_account_has_been_deactivated: "Your account has been deactivated",
  the_payin_has_started: "The payin has started",
  the_payout_is_in_progress: "The payout is in progress",
  the_payout_is_completed: "The payout is completed",
  deleteAccount: "Are you sure you wish to delete this Account?",
  accountDeleted: "Account has been deleted",
  accountError: "Getting Accounts error",
  resource_item_for_transfercountry_not_found:
    "Something went wrong. Contact the support.",
  deposit_fee_configuration_not_found:
    "Something went wrong. Contact the support.",
  you_will_receive_an_email_validation_link_if_the_email_is_found_and_is_not_validated_already:
    "You will receive an email validation link if the email is found and is not validated already",
  the_country_code_field_is_required: "The country code field is required.",
  the_selected_country_code_is_invalid: "The selected country code is invalid",
  the_phone_number_must_be_at_least_8_characters:
    "The phone number must be at least 8 characters",
  withdraw_fee_configuration_not_found: "Withdraw fee configuration not found",
  this_phone_number_is_already_registered:
    "This phone number is already registered",
  your_username_or_password_is_invalid: "Your username or password is invalid.",
  the_email_has_already_been_taken: "The email has already been taken.",
  try_refresh:
    "Try refreshing your browser, if the problem persists, please contact the Danapay support team.",
  fields_required: "All fields are  required",
  noCompany: "You don't have a company",
  the_euro_amount_field_is_required: "The euro amount field is required.",
  Event: "Event",
  event_placeholder: "Please enter your event name",
  event_error: "Event name is required",
  cancelTitle: "Cancel",
  cancelMessage: "Do you confirm that you want to cancel this operation?",
  cancel: "Cancel",
  ok: "Ok",
  cancelling: "Cancelling",
  text_bene_details: " Beneficiary details for the phone number ",
  beneficiary_details: "Beneficiary details",
  text_tell_us_more: "Please enter beneficiary information.",
  reasonError: "Reason field is required.",
  withdrawalError: "please select any withdrawal mode.",
  BankNameError: "Bank name is required.",
  IbanError: "Iban is required.",
  BicError: "Bic is required.",
  ReceiverFullNameError: "Receiver's full name is required",
  MFIAccountNumberError: "Account number is required",
  CountryDialCodeError: "Country dialcode is required.",
  PhoneNumberError: "Phone number is required.",
  this_phone_number_is_already_taken: "The phone number is already taken.",
  profile: "Profile",
  company: "Company",
  company_name: "Company Name",
  company_name_error: "Company Name is required",
  registration_id: "Registration id",
  contact_first_name: "Contact First Name",
  contact_first_name_error: "Contact First Name is required",
  contact_last_name: "Contact Last Name",
  contact_last_name_error: "Contact Last Name is required",
  contact_email: "Contact Email",
  contact_email_error: "Contact Email is required",
  contact_phone_number: "Contact phone number",
  send_the_code_again: "Resend the OTP code",
  resend_in: "Resend in ",
  seconds: "seconds",
  not_verified: "Not verified",
  verify_my_account: "Verify my account",
  optional: "Optional",
  you_are_not_allowed_to_modify_company_informations_already_verified_by_us_please_contact_the_support:
    "You are not allowed to modify company information already verified by us please contact the support",
  you_are_not_allowed_to_modify_names_already_verified_by_us_please_contact_the_support:
    "You are not allowed to modify names already verified by us please contact the support",
  this_action_requires_administrative_rights:
    "This action requires administrative rights",
  contact_added: "Contact was successfully added.",
  select: "Select ...",
  the_phone_number_field_is_required: "The phone number field is required",
  code_sent: "New code has been sent",
  resending: "Resending",
  no_receiving_country: "Beneficiary's country is not a receiving country",
  too_many_attempts: "Too many attempts",
  is_number: "Please make sure this is your phone number ",
  manage_accounts: "Your Accounts",
  verify: "Verify",
  transfer_fee_configuration_not_found: "Transfer fee configuration not found",
  userNotVerify:
    "Want to make your first transfer or deposit? Start by completing your account verification by clicking here!",
  the_iban_has_already_been_taken: "The iban has already been taken.",
  owner_name: "Owner name",
  edit: "Edit",
  Add_Account: " Add Account",
  Edit_Account: "Edit Account",
  operator: "Operator",
  Owner_Name: "Owner name",
  Account_Title: " Account Title",
  Add_bank_account: "Add bank account",
  operator_required: "Operator is a required field",
  phone_number_required: "Phone number is a required field ",
  Other_operator: "Ohter is a required field ",
  Owner_required: "Owner is required",
  bank_name_required: "Bank name is required ",
  Added_successfully: "Added successfully",
  bank_accounts: "Bank accounts",
  mobile_money_accounts: "Mobile Money accounts",
  mfi_accounts: "MFI Accounts",
  "Invalid IBAN": "IBAN must be valid",
  Are_you_want_to_delete: "Are you sure you want to delete this account?",
  Deleted_successfully: "Deleted successfully",
  confirm: "Confirm",
  update: "Update",
  calculating: "calculating",
  Payout_delays_in_mali: "Payout delays in Mali",
  instant_transfer_text:
    "The funds are transferred instantly after we receive funds. This option will take from 5min to 1hour.",
  direct_debit: "Direct debit",
  total_CFA: "Total CFA",
  pending_title: " Please wait...",
  pending_footer: "More details about the verification process",
  pending_title_description:
    " Your account is being verified.It could take 10mn . As soon as it's done, you will be automatically redirected to the payment page.",
  Your_payment_has_been_title: "Well done! 👍",
  Your_payment_has_been:
    "Your payment has been taken in account. As soon as we have received funds, you will be notified and we will execute the transfer.",
  your_name_differs_from_the_account_holder:
    "Your payment failed because you are not the bank account holder. Please try again",
  your_payment_failed_because_you_are_not_the_bank_account_owner_please_try_again:
    "Your payment failed because you are not the bank account owner. Please try again",
  payment_failed_error: "Your payment has been rejected. Please try again",
  send_by_sms: "Receive code by SMS",
  send_by_mail: "Receive code by Email",
  send_code: "Send Code",
  sending: "sending ...",
  Please_enter_amount:
    // "Veuillez entrer le montant (EUR) pour chaque utilisateur.",
    "Please enter the amount (EUR) for each user.",
  mobile_money: "Mobile Money",
  iban_required: "IBAN is required",
  cashout_was_successfully_archived: "Cashout was cancelled successfully.",
  pay_link: "Request Payment",
  benefactor: "Benefactor",
  enter_benefactor_phone: "Enter benefactor phone number",
  the_specified_sending_country_does_not_make_payments_to_this_receiving_country:
    "The specified sending country does not make payments to this receiving country",
  the_selected_cashin_method_id_is_invalid:
    "The selected cash in method invalid, Please contact Danapay team.",
  Copy: "Copy",
  copy_link: "Copy Link",
  link_copied: "Link has been copied",
  receiver_requested_a_payment: "Receiver requested a payment",
  your_account_is_currently_inactive: "Your account is currently inactive",
  payment_demand: "Payment demand",
  completePayment: "Complete Payment",
  notUser:
    "This link is not meant for you, Please contact Danapay if you received this link.",
  make_a_transfer: "Make a transfer",
  received_amount: "Received amount",
  ignore: "ignore",
  noTransactions:
    "You have no more transactions from now on. Please make your first transaction.",
  fetching_transactions_details: "Fetching transactions details",
  Same_Day: "24hrs",
  Withdraw_mode_and_delays: "Withdraw mode and delays",
  Bank_Accounts: "Bank Accounts",
  Mobile_Money_by_Hub2: "Mobile money",
  Withdraw_mode_and_delays_description:
    "The funds are transferred instantly after we receive them. Your beneficiary can make a withdraw a few minutes later The withdraw delay depends on the chosen mode.",
  make_a_deposit: "Make a Deposit",
  click_here_to: "Click here to ",
  contact_information: "Contact Information",
  personal_information: "Personal Information",
  Back_to_Login: "Back to Login",
  Type_amount: "Type the amount",
  card: "Bank Card",
  trying_to_get_property_type_of_nonobject:
    "Trying to get property type of non-object",
  Add_Mobile_account: "Add MM account",
  Add_MFI_account: "Add MFI Account",
  instant_bank_transfer: "Instant Bank Transfer",
  manual_bank_transfer: "Manual Bank Transfer",
  this_payout_method_is_not_configured_in_this_countrycontact_the_administrator:
    "This payout method is not configured in this country. Contact the administrator.",
  the_phone_number_has_already_been_taken:
    "The phone number has already been taken.",
  my_number: "I confirm that I am the owner of this mobile money account.",
  my_account_required: "Confirm you own the mobile money account.",
  bulk_transfer: "Bulk Transfer",
  recipients: "Recipients",
  UTR: "User type is required",
  phone_required: "Phone number is a required field",
  mobile_money_by_hub2: "Mobile money",
  // mobile_money_by_hub2: "Mobile money by Hub2",
  "mobile money": "Mobile money",
  select_user_type: " Select user type",
  the_payment_is_completed: "The payment is completed",
  the_payment_has_started: "The payment has started",
  job: "Job",
  revenue: "Income range",
  revenue_select: "Select a range",
  job_err: "Job is required.",
  activity_err: "Activity is required.",
  job_select: "Select a job",
  revenue_err: "Income range is required",
  payment_reference_number: "Payment Reference Number",
  user_information: "User Information",
  accounts_information: "Accounts Information",
  dp_bank_accounts: "Danapay bank accounts details",
  manage_accounts_sub:
    "Add, Update and delete the account you will use for your withdraws",
  danapay_accounts: "Danapay Accounts",
  danapay_bank_accounts: "Danapay Bank Accounts",
  danapay_accounts_sub:
    "Find the list of Danapay accounts to which you can make deposits in your country",
  You_requested_for_pay: "You requested for a payment",
  beneficiary: "Beneficiaries",
  update_email: "Update email address",
  update_password: "Update your password",
  update_phone: "Update your phone number",
  update_user_info: "Update your personal information",
  Settings: "Settings",
  phone_verify_text:
    " We sent an OPT to your phone number. Enter the six digit OTP Code below.",
  phone_verify_header: "Verify Phone Number",
  phone_verify_btn_text: "Verify",
  verify_your_email: "Verify Your Email",
  verify_your_email_text:
    "We sent you a verification link to your email address.. Go to your email and click the link. Check you spam folder if you don't see the mail.",
  phone_update_success_message: "Phone number has been verified and updated.",
  email_update_success_message: "Email Address has been verified and updated.",
  Select_Country: "Select Country",
  Select_Mobile_Operator: "Select mobile operator ",
  No_Operator_Found: "No operator Found",
  No_Withdraw_Mode_Found: "No withdrawal method is available for this country",
  No_Withdraw_Methods: "No withdrawal method is available for your country",
  Select_Operator_Error: "Please select any mobile operator.",
  Select_Mfi_Error: "Please select any Mfi provider.",
  Mobile_Operator: "Mobile Operator",
  Enter_revenue_details: "Enter your revenue details",
  Enter_revenue_details_company: "Tell us more about your business",
  payline_cancelled: "Payment Cancelled",
  revenue_information: "Income",
  payline_cancelled_text:
    "We confirm to you that your payment has been canceled.",
  payline_successful: "Well done! 👍",
  payline_successful_text:
    "Your payment has been taken in account. As soon as we have received funds, you will be notified and we will execute the transfer.",
  download: "Download",
  select_user_text:
    "Select the beneficiary into the contact list or enter a phone number",
  personal_information_text:
    "Update your personnel information. Only possible before your account verification.",
  security: "Security",
  your_password_has_been_saved: "Your new password has been saved",
  the_payin_is_in_progress: "The pay in is in progress",
  payment_was_successfully_archived: "Payment was successfully archived",
  error_downloading:
    "We encountered an error while downloading, try again later",
  comp_info: "Company Information",
  comp_info_text: "You can update your company information before activation",
  comp_doc: "Company Documents",
  comp_doc_text:
    "Select the  document type  you want to update and upload the corresponding documents",
  download_done: "Your download is ready.",
  payment_completed: "Payment completed",
  funds_received: "Funds received",
  transfer_in_progress: "Transfer in progress",
  deposit_failed: "Deposit failed",
  deposit_completed: "Deposit completed",
  deposit_in_progress: "Deposit in progress",
  transfer_failed: "Transfer failed",
  transfer_completed: "Transfer completed",
  withdraw_requested: "Withdraw requested",
  withdraw_in_progress: "Withdraw in progress",
  withdraw_failed: "Withdraw failed",
  withdraw_completed: "Withdraw completed",
  the_deposit_is_in_progress: "The deposit is in progress",
  the_deposit_is_completed: "The deposit is completed",
  select_provider: "Select Provider",
  select_provider_text: "Click to select a a mobile money provider",
  payment_was_successfully_archived: "Payment was successfully cancelled",
  mm_title: "Processing",
  mm_text:
    "In order to continue, please login with the temporary password we sent you by email",
  payment_requested: "Payment Requested",
  All: "All",
  Transfer: "Transfer",
  "Bulk Transfer": "Bulk Transfer",
  Deposits: "Deposits",
  Withdraws: "Withdraws",
  Contact_Name: "Contact Name",
  download_receipts: "Download Receipts",
  download_CSV: "Download CSV",
  filter: "Filter",
  from: "From",
  to: "To",
  your_withdraw_is_in_progress: "Your withdraw is in progress",
  your_withdraw_is_complete: "Your withdraw is complete",
  sender_country_is_invalid: "Sender country is invalid",
  only_designated_user_may_proceed_to_payment_please_get_in_touch_with_the_requestor_to_get_the_correct_link:
    "Only designated user may proceed to payment. please get in touch with the requestor to get the correct link.",
  password_reset_successfully: "Password reset successfully.",
  add_documents: "Add Documents",
  Documents: "Documents",
  file_title: "File name",
  browse_file: "browse file",
  drop_file: "Drop files to Attach, or",
  drop_file_header: "Attach file to transfer",
  Uploading: "Uploading",
  deleting_head_text: "Deleting Document",
  deleting_body_text: "Are you sure you want to delete this document?",
  bridgeapi_cancelled: "Payment  cancelled",
  bridgeapi_cancelled_text: "You cancelled your payment, we shall delete it.",
  bridgeapi_failed: "Payment failed",
  bridgeapi_failed_text: "Sorry your payment failed",
  bridgeapi: "Instant SEPA",
  sepa: "Bank Transfer",
  danapay: "Balance",
  truelayer: "Direct debit",
  payline: "Bank Card",
  Hub2: "Mobile money",
  hub_2_mobile_money: "Mobile Money",
  instant_sepa: "Instant SEPA",
  bank_card: "Bank Card",
  your_withdraw_has_failed: "Your withdraw has failed",
  upload_beneficiaries_details: "Upload beneficiaries details",
  click_here_to_upload: "Click here to upload or drag and drop your file here",
  // accepted_formats: "Accepted formats is CSVfiles with a maximum of 500 000 rows",
  accepted_formats: "Accepted formats is CSVfiles with a maximum of 1000 rows",
  download_template: "Download template",
  retry: "retry",
  wait_for_prompt_on_your_phone:
    "Please check your phone for mobile money pin prompt, Enter your pin to complete the payment, then tap back Home",
  payment_verification_pending: "Mobile money verification",
  done: "Done",
  otp_required: "Please enter the 4 digit OTP code",
  processing_text:
    "Please wait while we process your payment, Dont refresh the page.",
  OTP_Verification: "OTP Verification",
  payment_verification_pending_cancel: "Payment cancelled",
  wait_for_prompt_on_your_phone_cancel:
    "Your payment was cancelled, tap back home to try again",
  mm_success_title: "Payment was successful",
  mm_success_description:
    "Your payment was successful, thank you for choosing Danapay.",
  payment_verification_failed: "payment Verification failed",
  failed_message:
    "We were unable to process your payment, please try again later.",
  link_already_used: "The link has already been used",
  link_already_used_desc:
    "If it wasn't you please contact Danapay for assistance",
  sent_credentials: "We have sent your temporary credentials to your email ",
  link_expiry_text: "The payment URL has already expired",
  not_your_payment:
    "If you received this payment link in your email, please contact admin for assistance",
  cashin_method_is_not_yet_supported: "CashIn method is not yet supported.",
  payment_request_has_already_been_executed_please_get_in_touch_with_the_administrator_for_further_details:
    "Payment request has already been executed please get in touch with the administrator for further details",
  // direct_transfer:"Direct Transfer",
  // the_cashout_is_in_progress:"The cashout is in progress.",
  // the_cashout_is_completed:"The cashout is completed.",
  downloading_transaction: "Download Transaction",
  downloading: "Downloading",
  download_csv_note:
    "The download may take some time to process. We will notify you when its ready with an email.",
  download_csv_subtitle:
    " You're about to download a CSV file of the filtered transactions, click on Download CSV to continue.",
  csv_format_text:
    "If you plan to use the CSV file in any third-party software, specify your preferred format for it.",
  Search: "Search",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Drag_Drop_Csv_Error: "Please drag and drop a valid Csv file.",
  direct_transfer: "Direct Transfer",
  the_cashout_is_in_progress: "The cashout is in progress.",
  the_cashout_is_completed: "The cashout is completed.",
  you_could_not_be_verified_as_human: "You could not be verified as human.",
  reset: "Reset",
  Changed_successfully: "Modified successfully",
  withdrawal_Fees: "Withdrawal Fees",
  using_direct_transfer:
    "Your beneficiary should receive the funds in their mobile money account within 5 minutes of receiving your payment.",
  not_using_direct_transfer:
    "Your beneficiary will receive the funds in their Danapay account within 2 minutes of receiving your payment, by making a withdrawal request via the application or by contacting support.",
  suspicious: "In progress",
  Received_transfer: "Received transfer",
  discovery: "How did you discover us?",
  discoverysub: "Please select the right source",
  dismiss: "Dismiss",
  registrationModalTitle: "Please download the mobile application",
  registrationModalMessage:
    "The web application is now fully dedicated to comapanies. As you are an individual, please download the mobile application. It offers better user experience",
  android_version: "Android version",
  ios_version: "iOS version",
  new_contact: "Unrecognized phone number. Please add it as a new contact",
  Campaign_Reward: "Campaign Reward",
  Reffered_by_someone: "Referred by someone",
  Referral: "Referred by someone",
  discovery_other_heard: "Where else did you hear about us?",
  referral_code_error: "Refferal code cannot be empty",
  referral_code: "Referral code",
  other_field_error: "Other field cannot be empty",
  starting_date: "Starting date",
  complete_date: "Complete date",
  select_the_micro_finance: "Select the micro finance",
  select_the_bank: "Select the bank",
  "Mobile Money": "Mobile Money",
  "Bank Account": "Bank Account",
  no_direct_payment:
    "Your beneficiary will instantly receive the funds within his Danapay account as soon as we have received the payment.",
  no_direct_payment2:
    "He will get the funds within 5mn to 72h depending on the withdraw method he chooses",
  mobile_money_warning:
    "Please specify your recipient mobile money account details. He has to be the owner. Be sure that's the right number because we don't make any verification before sending. We will not responsible of any error.",
  bank_account_warning:
    "Please specify your recipient bank account details. He has to be the unique owner and the bank has to be into the receiving country.",
  microfinance_warning:
    "Please specify your recipient microfinance details. He can provide you his unique identifier by contacting his affiliated microfinance institution",
  bank_account_directTransfer:
    "Your beneficiary should receive the funds in their bank account within 72h of receiving your payment.",
  microfinance_directTransfer:
    "Your beneficiary should receive the funds in their microfinance account within 30mn of receiving your payment.",
  mobile_money_directTransfer:
    "Your beneficiary should receive the funds in their mobile money account within 5 minutes of receiving your payment.",
  receivers_fullname: `Receiver's full name`,
  select_iban: "Select IBAN",
  select_mobile_number: "Select mobile number",
  not_specified: "Not specified",
  account_id_required: "Account id is required",
  owner_name_required: "Owner name is required",
  select_mfi_name: "Select MFI name",
  mfi_name_required: "MFI name is required",
  default_notification_update_message:
    "A new version of the application is now available. We invite you to reload the application to ensure its proper functioning and access to all the latest features.",
  default_notification_update_title:
    "A new version of this application is now available",
  verification_email_error: "Oops! Something went wrong.",
  verification_email_error_description: `We're experiencing a little technical problem on our end. No worries, our team is already on it and working to resolve it as quickly as possible. Please try again in a few moments. We appreciate your patience and understand the inconvenience this may cause.`,
  documents_and_selfie: "Your ID verification",
  transfer_countries:
    "Specify the countries to which you plan to make transfers once registered.",
  company_envisioned_countries:
    "Specify envisionned sending and receiving countries",
  transfer_countries_placeholder: "Select receiving countries",
  sending_countries_placeholder: "Select one or more countries",
  transfer_countries_required: "Please select atleast 1 country",
  residency_selection: "Specify your current residential status.",
  residency: "Residency",
  residency_required: "Please select your residency",
  residency_details: "Residence",
  residency_selection_placeholder: "Select your residency",
  residency_option_1: "I live in the European Union but I am not a citizen (Foreigner)",
  residency_option_2: "I reside and am a citizen of the European Union",
  residency_option_3: "Living in and a citizen of the WAEMU or CEMAC",
  "Account id": "Account id",
  bank_transfer_primary_info: `Your transfer will be initiated upon receipt of funds in our account. We recommend making an instant transfer to speed up the processing of your transfer.`,
  bank_transfer_sub_info_1: `Please now make a transfer in the amount of`,
  bank_transfer_sub_info_2: `on our account`,
  mobile_money_bank_transfer_info_1:
    "Funds will be credited to mobile account number",
  mobile_money_bank_transfer_info_2:
    " from your beneficiary within 5 minutes. No action on his part will be necessary.",
  bank_account_bank_transfer_info_1:
    "The funds will be credited to bank account number",
  bank_account_bank_transfer_info_2:
    " from your beneficiary within 24 to 72 hours. No action on his part will be necessary.",
  danapay_bank_transfer_main_info_1: `Your beneficiary will receive the funds in their Danapay account. In order to access these funds, authentication is required using the number`,
  danapay_bank_transfer_main_info_2: `or email address`,
  danapay_bank_transfer_instruction: `Please send him the following instructions:`,
  danapay_bank_transfer_instruction_1: `How to access your Danapay account when you have received funds?`,
  danapay_bank_transfer_instruction_2: `How to withdraw funds to a mobile account?`,
  danapay_bank_transfer_instruction_3: `How to withdraw funds to a bank account?`,
  balance_main_info: "We execute your transfer immediately",
  balance_danapay_info_1: `The funds will be credited to bank account number`,
  balance_danapay_info_2: ` from your beneficiary within 24 to 72 hours. No action on his part will be necessary`,
  balance_mobileMoney_info_1: `Funds will be credited to mobile account number`,
  balance_microfinance_info_1: `The funds will be credited to the microfinance account number`,
  balance_microfinance_info_2: `from your beneficiary within 24 to 72 hours. No action on his part will be necessary.`,
  balance_bankAccount_info_1: `The funds will be credited to bank account number`,
  balance_mobileMoney_info_2: `from your beneficiary within 5 minutes. No action on his part will be necessary.`,
  balance_bankAccount_info_2: `from your beneficiary within 24 to 72 hours. No action on his part will be necessary.`,
  instant_sepa_main_info: `Your transfer will be initiated upon receipt of funds in our account. Depending on your bank, the processing time for instant transfers can vary between 5 minutes and 72 hours.`,
  instant_sepa_mobileMoney_info_1: `The funds will be credited to your beneficiary's mobile account number`,
  instant_sepa_microfinance_info_1: `The funds will be credited to your beneficiary's microfinance account number`,
  instant_sepa_bankAccount_info_1: `The funds will be credited to bank account number`,
  instant_sepa_mobileMoney_info_2: `within 5 minutes. No action on his part will be necessary.`,
  instant_sepa_bankAccount_info_2: `from your beneficiary within 24 to 72 hours. No action on his part will be necessary.`,
  instant_sepa_microfinance_info_2: `within 24 to 72 hours. No action on his part will be necessary.`,
  payline_main_info: `Your transfer will be initiated upon receipt of confirmation from your bank, a process which should take a few minutes.`,
  payline_mobileMoney_info_1: `Funds will be credited to mobile account number`,
  payline_microfinance_info_1: `The funds will be credited to the microfinance account number`,
  payline_mobileMoney_info_2: `from your beneficiary within 5 minutes. No action on his part will be necessary.`,
  payline_bankAccount_info_1: `The funds will be credited to bank account number`,
  payline_bankAccount_info_2: `from your beneficiary within 24 to 72 hours. No action on his part will be necessary.`,
  payline_microfinance_info_2: `from your beneficiary within 24 to 72 hours. No action from his piece will be necessary.`,
  microfinance_bank_transfer_info_1: `The funds will be credited to the microfinance account number`,
  microfinance_bank_transfer_info_2: `from your beneficiary within 24 to 72 hours. No action on his part will be necessary.`,
  privacy_policy: 'Privacy policy',
  terms_and_condition: 'Terms and Conditions',
  activity: 'What is your main activity?',
  revenue_level: 'What is your business revenue?',
  sending_countries_1: 'Countries you are going to ',
  sending_countries_2: 'receive funds',
  company_receiving_countries_1: "Countries you are going to ",
  company_receiving_countries_2: "send funds",
  company_receiving_countries_placeholder: "Select the countries",
  company_verification: 'Company verification',
  sumsub_server_error: 'Server error. Please try again later',
  login_phone: 'Connecting by phone number',
  login_email: 'Connecting by email',
  pincode: 'Pincode',
  pincode_error: 'Pincode is required',
  pincode_invalid: 'Pincode is invalid. Please enter a valid pincode',
  submit: 'Submit',
  terms_condition_1: "By clicking on Register, you agree to our ",
  terms_condition_2:
    "applicable to the processing of your personal data and to our",
  privacy_policy: "Privacy policy",
  terms_and_condition: "Terms and Conditions",
  activity: "Activity",
  revenue_level: "Revenue Level",
  sending_countries: "Sending countries",
  receiving_countries: "Destination of your transfers",
  envisioned_countries: "Financial flows",
  company_verification: "Company verification",
  sumsub_server_error: "Server error. Please try again later",
  login_phone: "Connecting by phone number",
  login_email: "Connecting by email",
  pincode: "Pincode",
  pincode_error: "Pincode is required",
  pincode_invalid: "Pincode is invalid. Please enter a valid pincode",
  submit: "Submit",
  otp_invalid: "Invalid otp. Please enter a valid otp",
  phone_login_not_existing:
    "Please create an account with corresponding phone number",
  sumsub_skip: "Verify later",
  sumsub_verification_applicant_submitted_title: "A little more patience!",
  sumsub_verification_applicant_submitted_text_1: "We have received your documents and",
  sumsub_verification_applicant_submitted_text_bold_1: " they are being verified. ",
  sumsub_verification_applicant_submitted_text_2: "In the meantime, you have",
  sumsub_verification_applicant_submitted_text_bold_2: " limited access to our services. ",
  sumsub_verification_applicant_submitted_text_3: "Need help? We are available via chat, email, or WhatsApp. ",
  sumsub_individual_application_reviewed_title: "Congratulations!",
  sumsub_individual_application_reviewed_1: "Your profile has been verified and validated. It is in the process of being activated. In the meantime, ",
  sumsub_individual_application_reviewed_2: "you have limited access to our services. ",
  sumsub_individual_application_reviewed_3: "Need help? We are available via chat, email, or WhatsApp.",
  sumsub_individual_application_created_title: "Ready to make your first transfer?",
  sumsub_individual_application_created: "First, complete the verification of your account by clicking here!",
  sumsub_individual_application_retry_title: "Oops. Information needs to be completed!",
  sumsub_individual_application_retry_text_1: "Your profile has been verified.",
  sumsub_individual_application_retry_text_2: "We need additional information.",
  sumsub_individual_application_retry_text_3: "Please provide them by clicking here.",
  sumsub_individual_application_retry_cta: "Complete my file",

  complete_verification: "Complete Verification",
  whatsapp_redirection: "Contact Us on WhatsApp",
  sumsub_finish: "Finish",
  required_red: "(required)*",
  sumsub_reject_title: "Your account could not be validated by our Compliance team.",
  sumsub_reject_body: "We are unable to grant you access to the platform.",
  sumsub_return_home: "Homepage",
  delivery: "Delivery",
  delivery_description: "By choosing this withdrawal method, our teams will contact your beneficiary and go to the agreed time and place to give them the funds.",
  delivery_cashout_banner: "By choosing this withdrawal method, our teams will contact you to arrange a time and meeting place to give you the funds.",
  aborted: "Aborted",
  company_revenue_required: "Business revenue is required",
  bank_transfer_instructions:
    "Make a transfer to danapay. Our RIB will be provided to you at the last step. To speed up your transaction, make an instant transfer from your bank. The transfer will be executed as soon as we receive the funds in our bank account.",
  balance_instructions:
    "Use the money available in your balance to pay for your transfer instantly. Your transfer will be processed immediately.",
  bank_card_instructions:
    "You will be redirected to our partner Monext - Payline to make your payment. The transfer will be executed immediately after your payment.",
  instant_sepa_instructions: "",
  balance_instructions_title: "The fastest: ",
  bank_transfer_instructions_title: "The cheapest: ",
  bank_card_instructions_title: "The most practical: ",
  enter_reason: "Reason for payment",
  enter_amount: "Amount to be paid in EUR and CFA",
  "Amount out of range": "Max amount exceeded",
  max_amount_exceeded: "The maximum amount you can send is ",
  max_amount_exceeded_cashin_message: "Maximum amount exceeded for this payment amount. Please choose another payment method",
  bank_transfer_instruction_title: "The cheapest",
  bank_transfer_instruction_description:
    "Make a transfer to danapay. Our RIB will be provided to you at the last stage. To speed up your transaction, make an instant transfer from your bank. The transfer will be executed as soon as we have received the funds in our bank account",
  bank_card_instruction_title: "The most convenient way",
  bank_card_instruction_description:
    "You will be redirected to our partner Monext - Payline to make your payment The transfer will be executed immediately after your payment.",
  max_limit: "Max limit: ",
  remove_favourite_success: "Favorite successfully removed",
  usertype_info_1: "To open a Business account, ",
  usertype_info_2: "you must have a legally established company ",
  usertype_info_3: "(with legal documents such as statutes, etc.).",
  usertype_info_4: "If you do not have a formal business, ",
  usertype_info_5: "please create an Individual account",
  usertype_info_modal_title: "Are you sure about your choice?",
  usertype_info_modal_body_1: "You have chosen to create a Business account. ",
  usertype_info_modal_body_2: "To use our services, ",
  usertype_info_modal_body_3: "you will need to provide legal documents ",
  usertype_info_modal_body_4: "(company statutes, etc.). ",
  usertype_info_modal_body_5: "Do you confirm this choice?",
  usertype_info_modal_newbody_1: "You are about to create a ",
  usertype_info_modal_newbody_2: "business account",
  usertype_info_modal_back: "No, I want to change",
  usertype_info_modal_continue: "Yes, I confirm",
  bonus: "Bonus",
  bonus_description: "We are offering your beneficiary a free bonus of  ",
  invalid_phone_number: 'Invalid phone number. Please enter a valid mobile number in the correct format.',
  remove: "Remove",
  otp_failed_default_error: "OTP validation failed, please try again later",
  otp_limit_error: "Too many attempts. Please try again afte 2 mins",
  umo_description: "The Union Monétaire Ouest Africaine (UMO) residency option is available for users residing in the West African Economic and Monetary Union countries. Selecting this option means that the your financial activities will be regulated according to the UMO standards and regulations.",
  non_umo_description: "The EU residency option is available for users residing within the European Union. Selecting this option means that your financial activities will be regulated according to the standards and regulations of the European Union.",
  umo_description_error: "This option is not available for users registering with a European phone number.",
  non_umo_description_error: "This option is not available for users registering with a african phone number.",
  sumsub_verification_title: "A little more patience!",
  sumsub_verification_banner_text_1: "We have received your documents and",
  sumsub_verification_banner_text_bold_1: " they are being verified. ",
  sumsub_verification_banner_text_2: "In the meantime, you have",
  sumsub_verification_banner_text_bold_2: " limited access to our services. ",
  sumsub_verification_banner_text_3: "Need help? We are available via chat, email, or WhatsApp. ",
  cancelled: "",
  danapay_account: "Danapay Account",
  danapay_account_tooltip: "The beneficiary must withdraw the funds from her account to retrieve the money.",
  account_ending_with: "Account ending with ",
  see_details: "See details",
  professional_account: "Professional Account",
  transaction_reference: "Transaction reference",
  send_funds_via: "You have chosen to send the funds via",
  sender_funds_via: "Sender chosen to send the funds via",
  you_sent: "You sent",
  you_received: "You received",
  sender_sent: "Sender sent",
  danapay_fees: "Danapay fees",
  received: "received",
  on: "on",
  beneficiary_details: "Beneficiary details",
  full_name: "Full name",
  email_address: "Email address",
  not_received_funds_contact_whatsapp_sender: "Your recipient still hasn't received the funds? Please contact us via chat ",
  not_received_funds_contact_whatsapp_receiver: "You still haven't received the funds? Please contact us via chat ",
  on_whatsapp: "on WhatsApp",
  upload_file_placeholder: "Drop the file to attach or click here to browse them",
  document_upload_message: "Also remember to keep the original of your supporting documents. They may still be useful to you for accounting or legal reasons.",
  inhuman_name_error: "The name entered does not appear to be a human name. Please enter a valid name.",
  go_back_and_change: "Go back and change",
  proceed: "Proceed",
  enterpreneur_label: "I am a one-person business",
  enterpreneur_error: "Please select whether your company is an entrepreneurial entity or not",
  eu_description: "By selecting this box, you confirm that you have the nationality of one of the countries of the European Union and that you reside there permanently.",
  non_eu_description: "By selecting this box, you confirm that you are from a country outside the European Union but that you reside there permanently.",
  post_code_placeholder: "Postal code",
  post_code_error: "You are in a country where the postal code is mandatory. Please enter your postal code.",
  post_code_optional: "The postal code is optional for this country.",
  Flyers: "Flyers",
  max_amount_exceeded_cashout_message_1: "Maximum amount (",
  max_amount_exceeded_cashout_message_2: ") exceeded for this withdraw mode. Please lower your amount or choose another withdraw mode",
  after_bank_transfer_message_1: "Please ensure that you send the funds from a ",
  after_bank_transfer_message_2: "bank account registered in your name. ",
  after_bank_transfer_message_3: "Using a bank account that does not belong to you will result in the funds being returned, which will delay the transaction.",
  bank_transfer_success_title: "Thank you for your transaction.",
  bank_transfer_success_subtext: "Now it's time to make a bank transfer to DanaPay. Please select a bank account to complete the transfer.",
  select_channel: "Select your channel",
  otp_verification_description: "We will send an otp to your preferred channel. Please select your channel and proceed",
  validationunique: "This email address is already associated with an account. Please log in or reset your password if you’ve forgotten it.",
  send_otp: "Send OTP",
  sorry: "Sorry",
  no_withdraw_modes: "Configuration not found. Please contact Danapay support team",
  download_the_receipt: "Download the receipt",
  repeat_the_transaction: "Repeat the transaction",
  sent: "Sent",
  transaction_status: "Transaction Status",
  recommend_us_link: "Did you like your experience? Click here to recommend us",
  fee_paid: "Fee paid",
  receiving_mode: "Receiving mode",
  mobile: "Mobile",
  bank: "Bank",
  delivery: "Delivery",
  mfi: "Microfinance",
  number: "Number",
  payment_initiated: "Transfer initiation",
  fund_received_by_danapay: "Receipt of funds by Danapay",
  the_transfer_is_in_progress: "Transmission of funds",
  fund_received_by_the_beneficiary: "Reception of funds by ",
  on_hold: "On hold",
  reception: "Reception",
  download_receipt: "Download receipt",
  fund_received_on_your_danapay_wallet: "Funds received in your Danapay account",
  fund_deposited_on_the_user_danapay_wallet: "Reception of funds by Danapay",
  funds_received_on_your: "Funds received on your",
  account: "account",
  transfer_completed_successfully: "Transfer completed successfully",
  amount_to_withdraw: "Amount to withdraw",
  withdaw_details: "Withdrawal details",
  specify_withdraw_details: "Specify the details of your withdrawal",
  you_have_balance_1: "You have balance of ",
  you_have_balance_2: " on your account",
  click_to_withdraw: "Click here to withdraw all amount",
  specify_withdraw_method: "Specify the withdrawal method",
  costs: "Costs",
  dealine: "Deadline",
  ceiling: "Ceiling",
  withdraw_limit_exceed: "You cannot use this method because the amount exceeds the allowed limit",
  mm_desc: "Make sure your number is correct as we do not do any verification before sending.",
  choose_operator: "Choose the operator",
  choose_new_number: "Choose a new number",
  bank_desc: "You must be the holder of the specified bank account",
  account_holder: "Account holder",
  choose_bank: "Choose the bank",
  choose_iban: "Choose a new IBAN",
  mobile: "Mobile",
  bank: "Bank",
  verification_code: "Verification code",
  verification_choose_method: "Choose the method of receiving the confirmation code, then validate",
  sms: "SMS",
  whatsapp: "Whatsapp",
  click_receive_code: "Click here to receive the code",
  enter_code_received: "Enter the code received",
  submit_code: "Submit code",
  resend_withdraw_code_1: "You will be able to resend the code in ",
  resend_withdraw_code_2: " seconds",
  "Le numéro de téléphone est déjà enregistré à un autre compte": "The phone number is already registered to another account",
  withdraw_confirmation_title: "Confirmation of your withdrawal",
  withdraw_request_taken: "Your withdrawal request has been taken into account",
  receipt_account: "Receipt account",
  total_to_pay: "Total to pay",
  mobile_money_withdraw_success_message: "You should receive the funds within 5 minutes. Your balance will be debited for the amount of the transaction.",
  bank_success_withdraw_message: "You should receive the funds within 72 hours. Your balance will be debited for the amount of the transaction.",
  delivery_success_withdraw_message: "You should receive the funds within 6 hours. Your balance will be debited for the amount of the transaction. You will be contacted by our teams to agree on the terms.",
  "No.": "No.",
  home_page: "Home page",
  verification: "Verification",
  temp_restriction: "Your account is temporary restricted. Only the bank transfer method is available. Once you compete a first operation, you will gain access to other payment methods.",
  is_invalid: " is invalid",
  fee_paid: "Fee paid",
  receiving_mode: "Receiving mode",
  withdraw_number: "Number",
  mfi_owner_id: "MFI owner id",
  mfi_name: "MFI Name",
  "Le numéro de téléphone est déjà enregistré à un autre compte": "The phone number is already registered to another account",
  temp_restriction: "Your account is temporary restricted. Only the bank transfer method is available. Once you compete a first operation, you will gain access to other payment methods.",
  is_invalid: " is invalid",
  funds_in_danapay_account: "Your beneficiary will receive the funds in their Danapay account",
  action_required_main_message: 'Awaiting proof',
  action_required_message: "Your transaction has been suspended. Please send your last three bank statements or your last three pay slips to the email address conformite@danapay.com",
  payment_cancelled: "Your payment has been canceled. Please try again with another payment method",
  payment_failed_receiver_message: "Payment for this transaction failed. Your sender will likely initiate another transaction shortly.",
  payment_failed_sender_message: "Your payment failed. Please try again with another payment method",
  transaction_suspended: "Your transaction has been suspended. Please send your last three account statements or your last three payslips to the email address conformite@danapay.com",
  business_signup_description_1: "To create a business account, ",
  business_signup_description_2: "you must already have a legally established business. ",
  business_signup_description_3: "Legal documents may be requested from you when you register.",
  enter_info: "Enter your information",
  select_gender_placeholder: "Select your gender",
  income_details: "Income Details",
  income_note_1: "We use this information to ",
  income_note_2: "adjust your ceiling ",
  income_note_3: "sending based on your income. The higher your income, the higher your limit. This information remains strictly confidential and is processed ",
  income_note_4: "in accordance with the GDPR.",
  individual_specify_section: "Specify the sector of activity in which you work",
  individual_income: "Income bracket",
  country_destination: "Country of destination of funds",
  countries_to_send: "Specify the countries to which you will ship",
  current_residence: "Current residence",
  specify_residency: "Specify your current residential situation",
  select_otp_channel: "Please select the channel on which you would like to receive your number verification code",
  enter_code: "Enter the code received",
  click_to_receive_code: "Click here to receive the code",
  resend_code_in: "You will be able to resend the code in ",
  gender_required: "Gender is a required field",
  company_activity_title: "Company information",
  company_envisioned_countries_subtitle: "Specify the countries you want to transact with",
  is_required: "is required",
  transfer_success_message_sender: "Transfer executed successfully.",
  transfer_success_message_receiver: "Transfer successfully received.",
  danapay_account_infomessage: "Your beneficiary will receive the funds in their Danapay account.",
  mm_infomessage: "Your beneficiary will receive the funds in their Mobile Money account.",
  bank_infomessage: "Your beneficiary will receive the funds in their bank account.",
  delivery_infomessage: "Your beneficiary will receive the funds via delivery",
  cancel_sender_infomessage: "Your payment has been canceled, please try again with another payment method",
  cancel_receiver_infomessage: "The payment of this operation has failed. Your sender will likely initiate another transaction shortly.",
  aborted_sender_infomessage: "Your payment has failed, please try again with another payment method.",
  aborted_receiver_infomessage: "The payment of this operation has failed. Your sender will likely initiate another transaction shortly.",
  at: "at",
  enter_phone_number: "Enter your phone number",
  add_contacts_and_initiate: "Add contacts and initiate your transactions with just one click",
  individuals: "Individuals",
  businesses: "Businesses",
  select_a_contact: "Select a contact",
  search_for_contact: "Search for a contact",
  transfer: "transfer",
  introduce_danapay: "Introduce Danapay",
  do_you_like_danapay: "Do you like Danapay? Help us make ourselves known to your loved ones and partners",
  sponsor_loved_one: "Sponsor a loved one",
  leave_review: "Leave a review",
  transfer_in_progress: "In progress",
  make_a_deposit_btn: "Make a deposit",
  make_a_transfer_btn: "Make a transfer",
  make_a_withdraw_btn: "Make a withdrawal",
  no_contacts: "No contacts found",
  all: "All",
  sponsorship: "Sponsorship",
  help: "Help",
  identification_required: "Identification required",
  identification_required_details: "In order to use our services, it is necessary for us to verify your identity.",
  finalize_verification: "Finalize my verification",
  verification_in_progress: "Verification in progress",
  verification_in_progress_details: "We have received all of your documents and your account is currently being verified. You will receive an email once your account is activated",
  verification_verified: "Wait a little longer",
  verification_verified_details: "Our partner SumSub has verified your identity, but we carry out some additional checks internally. You will receive an email once your account is activated.",
  action_required: "Action required",
  action_required_details: "Your account is temporarily inaccessible. We need additional information or documents from you",
  update_information: 'Update my information',
  please_log_in: "Please log in",
  choose_connection_method: "Choose a connection method to get started",
  by_email: "By email",
  by_telephone: "By telephone",
  no_account: "I don't have an account. ",
  terms_conditions_privacy_policies: "Terms and Conditions and Privacy Policy",
  you_will_receive_code_sms: "You will receive a code by SMS.",
  you_will_receive_code_whatsapp: "You will receive a code by Whatsapp.",
  receive_code_via_whatsapp: "Receive via Whatsapp instead",
  receive_code_via_sms: "Receive via SMS instead",
  enter_otp_received: "Enter the OTP code received",
  resend_code: "Resend code",
  log_in: "Login",
  invalid_otp: "The OTP code you entered is invalid.",
  alert: "Alert",
  fake_name_warning: "Your first or last name seems wrong. Do you want to continue with this information?",
  phone_already_exists: "This phone number is already in use. Please use a different number.",
  use_another_method: "Use another method",
  withdrawal: "Withdrawal",
  completed_successfully: "completed successfully",
  phone_number_below_min_length: "Phone number is below minimum length",
  phone_number_below_max_length: "Phone number is above maximum length",
  'iban_validation.unique': "This IBAN is already used. Please use another account.",
  'Ce numéro IBAN est déjà utilisé avec une autre compte banquaire. Veuillez en choisir un autre.': "This IBAN number is already used with another bank account. Please choose another one.",
  no_fee_config_withdraw_error: "Unable to retrieve fee configuration. Please contact support",
  restricted_account: "Restricted account",
  restricted_account_message: "Your account has been activated and you can make transfers. But, in order to have access to all payment methods, please make a first transfer by paying by bank transfer.",
  confirmation_message_company_presumsub_1: "You are about to provide your company's legal documents.",
  confirmation_message_company_presumsub_2: "Do you certify the list of effective beneficiaries that you are going to declare as compliant?",
  I_ceritfy: "I certify",
  benin_phone_error: "Invalid phone number format for Benin. Please use +229 01 XX XX XX XX.",
  please_register: "Please register",
  danapay_experience: "Your Danapay experience starts here",
  already_account: "I already have an account.",
  log_in_v3: "Log in",
  confirmation_email_sent: "Confirmation email sent",
  resend_new_link: "Resend new link",
  neero_wallet_description: "Your beneficiary will receive the funds in their ",
  neero: "Neero wallet",
  wallet_account_number: "Wallet account number",
  Add_Neero_account: "Add Neero wallet",
  Neero_accounts: "Neero wallet accounts",
  choose_phone_number: "Choose a phone number",
};

