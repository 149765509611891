import { Dialog } from "@material-ui/core";
import FileUpload from "../FileUpload";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton";

const BulkCsvUploadModal = (props: any) => {
  const {
    openDialog,
    handleClose,
    upload,
    processingPayment,
    uploadingCSV,
    setopenDialog,
    setUploadingCSV,
    setProcessingPayment,
    showTransfer,
  } = props;
  const { t } = useTranslation();
  return (
    <Dialog open={openDialog} onClose={() => handleClose}>
      <div className="p-4 col-md-12">
        <div className="flex items-center justify-center mb-6">
          <p className="font-bold text-center text-2xl">
            {t("upload_beneficiaries_details")}
          </p>
        </div>

        <FileUpload
          onDrop={(acceptedFiles: any) => {
            upload(acceptedFiles);
          }}
          accept=".csv"
          multiple={false}
        >
          <label className="flex justify-center p-8 transition bg-gray-100 border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
            <span className="flex items-center space-x-2">
              <span className="font-medium text-gray-600">
                <p className="font-light text-center mb-2">
                  {t("click_here_to_upload")}
                </p>
                <p className="font-light text-center text-slate-400">
                  {t("accepted_formats")}
                </p>
              </span>
            </span>
          </label>
        </FileUpload>
        <div className="flex items-center justify-center mt-6 mb-6">
          <p className="font-regular text-center text-1xl text-gray-400 mx-2">
            {t("download_template")}
          </p>
          <Link to="/sample.csv" target={"_blank"} download="sample.csv">
            <i
              className="fa fa-download text-7xl"
              aria-hidden="true"
              style={{
                color: "rgb(3, 115, 117)",
                fontSize: "26px",
              }}
            ></i>
          </Link>
        </div>
        {processingPayment === true ? (
          <div>
            <small
              className="font-bold text-center justify-center text-xl"
              style={{ display: "flex" }}
            >
              {uploadingCSV[0]?.name}
            </small>
            <small
              className="font-bold text-center justify-center text-xl"
              style={{ display: "flex" }}
            >
              {t("uploading")}
            </small>
          </div>
        ) : (
          uploadingCSV.length > 0 && (
            <div>
              <small
                className="font-bold text-center justify-center text-xl"
                style={{ display: "flex" }}
              >
                {uploadingCSV[0]?.name}
              </small>
              <small
                className="font-bold text-center justify-center text-xl"
                style={{ display: "flex" }}
              >
                {t("file_upload")}
              </small>
            </div>
          )
        )}
        <div className="flex gap-3 mt-3 max-sm:!flex-col">
          <CustomButton
            label="back"
            onClick={() => {
              setopenDialog(false);
              setUploadingCSV([]);
              setProcessingPayment(false);
            }}
          />
          <CustomButton
            label="confirm"
            onClick={() => {
              setopenDialog(false);
              showTransfer("");
            }}
            classNames="w-full flex-1 min-h-[48px] rounded-xl text-white capitalize text-sm font-bold bg-black"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default BulkCsvUploadModal;
