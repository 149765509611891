import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import LangSwitch from "./LangSwitch";
import { logout } from "../store/features/Auth/Auth";
import { confirmAlert } from "react-confirm-alert";
import * as Sentry from "@sentry/browser";

import {
  refreshCaptchaToken,
  resetAuth,
} from "../store/features/Auth/AuthSlice";
import { resetTransfer } from "../store/features/Transfer/TransferSlice";
import { useHistory } from "react-router-dom";
import { resetDeveloper } from "../store/features/Developer/DeveloperSlice";
import { toast } from "material-react-toastify";
import useResize from "../hooks/useResize";
import CurrencySwitch from "./CurrencySwitch";

const HeaderBarv3 = (props: any) => {
  const {} = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const dimensions = useResize();

  const {
    auth: { user, sideBarCollapsed },
  } = useAppSelector((state) => state.persistedReducer);

  const handleLogout = () => {
    confirmAlert({
      title: t("loggingOut"),
      message: t("loggingOutSure"),
      overlayClassName: "zIndexClass",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            logout()
              .then((response: any) => {
                localStorage.removeItem("user:key");
                localStorage.removeItem("withdrawEnabled");
                localStorage.removeItem("paymentError");
                localStorage.removeItem("depositattempts");
                localStorage.removeItem("maketransferattempts");
                localStorage.removeItem("otpresendtime");
                dispatch(resetAuth());
                dispatch(resetTransfer());
                dispatch(resetDeveloper());
                history.push("/login");
                dispatch(refreshCaptchaToken(false));
              })
              .catch((error: any) => {
                Sentry.captureException(error);
                toast.error(t(error?.data?.message));
              });
          },
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div
      className="fixed flex pb-2 HeaderBarv3 mr-auto flex-row justify-between w-screen bg-white !pt-6"
      style={{ zIndex: 30 }}
    >
      <div
        className={`w-[${
          dimensions[0] < 1070 || sideBarCollapsed ? "95%" : "80%"
        }] ml-auto px-3 max-sm:!px-1 max-sm:!mx-auto max-[1070px]:w-[95%]`}
      >
        <div className="flex flex-row items-center justify-between flex-1 max-sm:flex-wrap">
          <div className="flex flex-row">
            <Avatar
              name={user?.full_name}
              size={dimensions[0] < 400 ? "41" : "51"}
              round
              className="shadow-c"
            />
            <div
              className="flex flex-col justify-center ml-2"
              style={{ width: dimensions[0] < 400 ? "auto" : 250 }}
            >
              <b className="text-xl capitalize max-sm:!text-sm font-bold">
                {user?.full_name}
              </b>
              {!user?.is_individual && (
                <div className="font-medium text-base max-sm:!text-xs">
                  {user?.company?.name}{" "}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-row items-center gap-3 justify-between max-sm:ml-auto">
            <CurrencySwitch customMainContainerClasses="bg-[#FAFAFA] rounded-xl w-[120px] h-[52px] max-sm:!w-min !text-sm font-semibold pl-3" />
            <LangSwitch customMainContainerClasses="bg-[#FAFAFA] rounded-xl w-[120px] h-[52px] max-sm:!w-min !text-sm font-semibold pl-3" />
            <button
              className="flex-center w-[48px] h-[48px] bg-[#696969] rounded-xl"
              onClick={handleLogout}
            >
              <i
                className="m-0 pl-1 w-min fa fa-sign-out"
                style={{ fontSize: 14, color: "white" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBarv3;
