import CashLayout from "../CashLayout";

const WithdrawMethodDescription: any = (props: any) => {
  const { checked, widthdrawMethod, t } = props;

  if (checked && widthdrawMethod !== "") {
    return (
      <div
        className="withdrawTitle pb-[20px] max-sm:!text-sm"
        style={{ paddingInline: "15px" }}
      >
        {widthdrawMethod == "Mobile Money" && t("mobile_money_directTransfer")}
        {/* {widthdrawMethod == "Mobile Money" && (
          <div className="mt-4">
            {`${t("bonus_description")}`}{" "}
            <CashLayout cash={bonus} isCfa={true} />
          </div>
        )} */}
        {widthdrawMethod == "Bank Account" && t("bank_account_directTransfer")}
        {widthdrawMethod == "Microfinance" && t("microfinance_directTransfer")}
        {widthdrawMethod == "delivery" && t("delivery_description")}
      </div>
    );
  } else if (!checked) {
    return (
      <>
        <div
          className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 text-gray-400 mt-2 pb-[20px]"
          style={{ paddingInline: "15px" }}
        >
          {t("no_direct_payment2")}
        </div>
      </>
    );
  } else {
    return (<></>);
  }
};

export default WithdrawMethodDescription;
