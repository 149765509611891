import React from "react";
import BeneficiaryCard from "../BeneficiaryCard/BeneficiaryCard";
import {
  addBeneficiary,
  addTransferCountry,
  setBeneFound,
} from "../../store/features/Transfer/TransferSlice";
import { useDispatch } from "react-redux";
import CustomButton from "../CustomButton";

const BeneficiaryInfo = (props: any) => {
  const { t, transfer, onNext, setPhone, setCountry } = props;
  const dispatch = useDispatch();

  const removeBeneficiary = () => {
    dispatch(addBeneficiary(null));
    dispatch(addTransferCountry(null));
    dispatch(setBeneFound(false));
    setPhone("");
    setCountry({ dialCode: "" });
  };

  return (
    <div className="mx-auto bg-white max-w-[800px] BeneficiaryInfo rounded-lg lg:p-8 md:p-3 sm:p-3">
      <div className="flex flex-col items-center justify-center px-4">
        <h3 className="my-3 text-2xl font-bold text-center">
          {t("beneficiary")}
        </h3>
        <p className="text-center">
          {t("text_bene_details")}:{" "}
          <b>{transfer.beneficiary.full_phone_number}</b>
        </p>
      </div>

      <BeneficiaryCard beneficiary={transfer.beneficiary} />

      <div className="flex justify-between gap-3 max-sm:flex-col">
        <CustomButton onClick={removeBeneficiary} label="cancel" />
        <CustomButton
          onClick={onNext}
          label="continue"
          data-transfer-payment-details="paymentdetails"
          classNames="w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
        />
      </div>
    </div>
  );
};

export default BeneficiaryInfo;
