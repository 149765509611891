import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { useMemo } from "react";
import * as Sentry from "@sentry/browser";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import InfoMessage from "../InfoMessage";
import CustomDropdown from "../CustomDropdown";
import FormErrorText from "../FormErrorText";
import { isEmpty } from "../../utilities/help";
const numberInputInvalidChars = ["-", "+", "e"];

const NeeroWalletWithdrawBox = (props: any) => {
  const {
    setwidthdrawMethod,
    widthdrawMethod,
    directPaymentAmountExceed,
    allDirectPaymentMethods,
    setMethod,
    cashoutMethod,
    fetchingAllFees,
    userNeeroAccounts,
    neeroWalletNumber,
    setneeroWalletNumber,
    customNeeroWalletNumber,
    setcustomNeeroWalletNumber,
    withdrawError,
  } = props;

  const { t } = useTranslation();
  const {
    auth: { user, neero_wallet_accounts },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);

  // working
  const methodID = useMemo(() => {
    try {
      if (cashoutMethod && cashoutMethod.length > 0) {
        const cashoutMethodObj = cashoutMethod.find((method: any) => {
          return method?.cashout_method?.payment_type?.name == "wallet";
        });
        if (cashoutMethodObj) {
          return cashoutMethodObj?.cashout_method?.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }, [cashoutMethod]);

  const withdrawalFees = useMemo(() => {
    try {
      if (allDirectPaymentMethods && allDirectPaymentMethods.length > 0) {
        const feeObj = allDirectPaymentMethods.find((cashoutMethod: any) => {
          return cashoutMethod.cashout_method.payment_type.name == "wallet";
        });
        if (feeObj && feeObj.withdrawFees) {
          return feeObj.withdrawFees;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [allDirectPaymentMethods]);

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              cashoutMethod.cashout_method.payment_type.name == "wallet"
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + "€";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  return (
    <div
      className={`shadow-c rounded-lg cursor-pointer${
        widthdrawMethod == "wallet" ? " border-1 border-[#037375]" : ""
      }`}
      onClick={(e) => {
        setwidthdrawMethod("wallet");
        setMethod(methodID);
      }}
    >
      <div className="!items-stretch flex m-0 flex-shrink-0 min-h-[56px] px-3 !border-none max-sm:flex-wrap max-sm:gap-4 max-sm:p-2">
        <div className="flex-center mr-4">
          <div
            className={`text-[rgba(0,0,0,0.75)] font-bold flex-center pr-4 opacity-[${
              directPaymentAmountExceed && widthdrawMethod == "wallet" ? 0.5 : 1
            }]`}
          >
            {t("Neero")}
          </div>
        </div>

        <div className={"ml-auto my-auto hidden max-sm:block"}>
          {widthdrawMethod == "wallet" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>

        <div className="min-w-[200px] ml-auto max-sm:m-0 flex gap-12 max-sm:gap-3 text-center max-sm:justify-between max-sm:w-full items-center">
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">{t("costs")}</div>
            {fetchingAllFees ? (
              <CircularProgress size={12} />
            ) : (
              <div className="font-bold text-[rgba(0,0,0,0.75)]">
                {withdrawalFees && withdrawalFees.toFixed(2)}€
              </div>
            )}
          </div>
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">
              {t("dealine")}
            </div>
            <div className="font-bold text-[rgba(0,0,0,0.75)]">72h</div>
          </div>
          <div>
            <div className="text-xs  text-[rgba(0,0,0,0.5)]">
              {t("ceiling")}
            </div>
            <div className="font-bold text-[rgba(0,0,0,0.75)]"> {maxLimit}</div>
          </div>
        </div>
        <div className={"ml-8 my-auto max-sm:hidden"}>
          {widthdrawMethod == "wallet" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>
      </div>

      {widthdrawMethod == "wallet" && (
        <div className="flex flex-col p-3 max-sm:!py-3">
          {userNeeroAccounts.length > 0 && (
            <CustomDropdown
              CustomIcon={
                <KeyboardArrowDown
                  style={{ width: "30px", height: "38px", color: "black" }}
                />
              }
              isDisabled={
                directPaymentAmountExceed && widthdrawMethod == "wallet"
              }
              defaultValue={
                neeroWalletNumber || transfer?.withdraw?.data?.neero_wallet
              }
              setinstitutionId={() => {}}
              onchange={(val: any) => {
                setneeroWalletNumber(val);
              }}
              list={userNeeroAccounts}
              placeholderComponent={
                <div className="opacity-50 font-normal">
                  {t("choose_phone_number")}
                </div>
              }
              customClasses="px-3 bg-white rounded-xl mt-3 border border-1-[rgba(0,0,0,0.2)] h-[50px]"
              placeholder={t("choose_phone_number")}
            />
          )}

          {(neeroWalletNumber == "Other" || userNeeroAccounts.length < 1) && (
            <>
              <div
                className="relative flex h-[50px] rounded-xl text-[rgba(0,0,0,0.5)]"
                style={{
                  border: "1px solid rgba(0,0,0,0.20)",
                  marginTop: 16,
                }}
              >
                <div
                  className={`w-[79px] text-[rgba(0,0,0,0.5)] rounded-l-xl justify-center overflow-hidden inset-y-0 flex items-center`}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    backgroundColor: "#F0F0F0",
                  }}
                >
                  +{user?.country_code}
                </div>
                {/* custom number */}
                <input
                  disabled={
                    directPaymentAmountExceed && widthdrawMethod == "wallet"
                  }
                  onChange={(text) => {
                    setcustomNeeroWalletNumber(text.target.value);
                  }}
                  value={customNeeroWalletNumber}
                  className="w-full pl-2 rounded-r-xl"
                  placeholder={t("choose_new_number")}
                  type="number"
                  onKeyDown={(e) => {
                    if (numberInputInvalidChars.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              {!isEmpty(withdrawError) && (
                <FormErrorText errorMessage={withdrawError} />
              )}
            </>
          )}
        </div>
      )}
      {directPaymentAmountExceed && widthdrawMethod == "wallet" && (
        <InfoMessage
          customTextContainerClasses="text-sm text-[#963B3B]"
          message={`withdraw_limit_exceed`}
          innerContainerClasses="flex items-center min-h-[46px] max-sm:p-2"
          mainContainerClasses="mx-3 flex items-center mb-3 rounded bg-[#F7C3C3]"
          iconFill="#963B3B"
        />
      )}
    </div>
  );
};

export default NeeroWalletWithdrawBox;
