import { ots } from "./data";

const OperationIcon = (props: any) => {
  const { transaction, operation_type, isActiveSender } = props;
  return (
    <>
      {transaction?.logged_in_transactor === "receiver" &&
      operation_type === "direct_transfer" ? (
        <span
          className="flex items-center justify-center bg-orange-200"
          style={{ height: 30, width: 30, borderRadius: 20 }}
        >
          <i
            className={
              transaction?.transfer?.bulk_transfer_recipient_details?.length > 0
                ? "fa fa-flash  m-0 text-green-500"
                : `fa fa-arrow-down  m-0 text-orange-500`
            }
          ></i>
        </span>
      ) : transaction?.logged_in_transactor === "sender" &&
        operation_type === "direct_transfer" ? (
        <span
          className="flex items-center justify-center bg-green-200"
          style={{ height: 30, width: 30, borderRadius: 20 }}
        >
          <i
            className={
              transaction?.transfer?.bulk_transfer_recipient_details?.length > 0
                ? "fa fa-flash  m-0 text-green-500"
                : `fa fa-arrow-up  m-0 text-green-500`
            }
          ></i>
        </span>
      ) : operation_type === "instant_transfer" ? (
        <>
          {isActiveSender ? (
            <span
              className="flex items-center justify-center bg-green-200"
              style={{ height: 30, width: 30, borderRadius: 20 }}
            >
              <i className={`fa fa-arrow-up  m-0 text-green-500`}></i>
            </span>
          ) : (
            <span
              className="flex items-center justify-center bg-orange-200"
              style={{ height: 30, width: 30, borderRadius: 20 }}
            >
              <i className={`fa fa-arrow-down  m-0 text-orange-500`}></i>
            </span>
          )}
        </>
      ) : (
        <span
          className="flex items-center justify-center bg-green-200"
          style={{ height: 30, width: 30, borderRadius: 20 }}
        >
          <i className={`${ots[operation_type]?.icon} m-0 text-green-500`}></i>
        </span>
      )}
    </>
  );
};

export default OperationIcon;
