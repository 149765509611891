import { CircularProgress } from "@material-ui/core";
import { toast } from "material-react-toastify";
import PaymentMethodDescriptions from "./PaymentMethodDescriptions";
import CustomButton from "../CustomButton";

const PaymentMethods = ({ props, previous }: any) => {
  const {
    transfer,
    t,
    paymentMethod,
    errorGot,
    checkingForRestriction,
    isUserRestricted,
    handleCashMethod,
    fetchingFees,
    hideWithdrawModal,
    processingPayment,
    submitTransaction,
    feesCalculated,
    user,
    checkingForWithdrawProviders,
    googleSheetsLoading,
    reason,
    setpaymentActiveStep,
    noWithdrawMethodsFound,
    isUserActivationLevelYellow,
  } = props;

  // handle form to withdrawal page
  const proceedToWithdrawalMode = () => {
    if (reason !== "" && paymentMethod !== "") {
      setpaymentActiveStep(1);
    } else {
      if (reason == "") {
        toast.error(t("reasonError"));
      }
      if (paymentMethod == "") {
        toast.error(t("selectCashIn"));
      }
    }
  };

  const disableDirectPayment: any = () => {
    // true for active verified beneficiary company and individual user
    if (
      (user?.is_individual == true || user?.is_individual == 1) &&
      (transfer?.beneficiary?.is_active == true ||
        transfer?.beneficiary?.is_active == 1) &&
      (transfer?.beneficiary?.is_verified == true ||
        transfer?.beneficiary?.is_verified == 1) &&
      (transfer?.beneficiary?.is_individual == false ||
        transfer?.beneficiary?.is_individual == 0)
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="flex w-full mt-4 flex-col max-sm:!px-3">
      {/* cash in methods */}
      <div className="flex flex-row customeDesignwithdraw customeCacheDesign">
        <label className="withdrawTitle px-3">{t("selectCashinMethod")}</label>
        <div
          className={`${
            checkingForRestriction ||
            checkingForWithdrawProviders ||
            feesCalculated ||
            fetchingFees ||
            googleSheetsLoading
              ? "pointer-events-none "
              : "pointer-events-auto "
          }bacnkCheckBoxWrapper p-2 my-3 flex flex-col gap-2 relative`}
        >
          {checkingForRestriction && (
            <div className="flex justify-center items-center z-50 w-full h-48">
              <CircularProgress />
            </div>
          )}
          {!checkingForRestriction &&
            transfer.loggedInUserCountry?.cash_in_methods &&
            transfer.loggedInUserCountry?.cash_in_methods
              .filter((itm: any) => {
                if (isUserRestricted) {
                  return (
                    itm?.cash_in_method?.payment_type?.name == "bank_transfer"
                  );
                } else {
                  return true;
                }
              })
              ?.map((value: any, index: any) => {
                return (
                  <>
                    <div
                      className={`wrapperBankCheckBox m-0 innerBankBox${
                        value.cash_in_method?.payment_type.name.toLowerCase() ==
                          "bank_transfer" ||
                        value.cash_in_method?.payment_type.name.toLowerCase() ==
                          "manual_bank_transfer"
                          ? ""
                          : isUserActivationLevelYellow
                          ? " opacity-50"
                          : ""
                      }`}
                      key={"transferPaymentCheckbox" + index}
                    >
                      <div className="wrapperBankCheckBoxwrapper">
                        <input
                          type="checkbox"
                          className="default:ring-2"
                          onChange={(e: any) => {
                            handleCashMethod(
                              e.target.checked,
                              value.cash_in_method?.name.toLowerCase()
                            );
                          }}
                          name={value?.cash_in_method?.name}
                          checked={
                            value.cash_in_method?.name.toLowerCase() ===
                            paymentMethod
                          }
                        />
                        <span className="checkmark"></span>
                      </div>

                      <label>
                        {value?.cash_in_method?.name == "Instant SEPA"
                          ? t("direct_debit")
                          : t(
                              value?.cash_in_method?.name
                                ?.toLowerCase()
                                .split(" ")
                                .join("_")
                            )}
                      </label>
                    </div>

                    {isUserActivationLevelYellow &&
                      value.cash_in_method?.name.toLowerCase() ===
                        paymentMethod &&
                      paymentMethod !== "bank transfer" && (
                        <div className="text-sm text-red-500 innerBankBox">
                          {t("temp_restriction")}
                        </div>
                      )}

                    {/* max limit error message */}
                    {errorGot &&
                      !isUserActivationLevelYellow &&
                      !fetchingFees &&
                      value.cash_in_method?.name.toLowerCase() ===
                        paymentMethod && (
                        <small className="mb-2 innerBankBox text-red-500">
                          {t("max_amount_exceeded_cashin_message")}
                        </small>
                      )}

                    <PaymentMethodDescriptions
                      value={value}
                      paymentMethod={paymentMethod}
                      disabled={
                        isUserActivationLevelYellow &&
                        paymentMethod !== "bank transfer"
                      }
                    />
                  </>
                );
              })}
        </div>
      </div>

      <div className="flex gap-3 max-sm:flex-col">
        <CustomButton label="back" onClick={previous} />
        <CustomButton
          label={processingPayment ? t("processing") : t("continue")}
          onClick={() => {
            hideWithdrawModal || disableDirectPayment()
              ? submitTransaction()
              : proceedToWithdrawalMode();
          }}
          disabled={
            (isUserActivationLevelYellow &&
              paymentMethod !== "bank transfer") ||
            feesCalculated ||
            errorGot !== "" ||
            processingPayment ||
            checkingForWithdrawProviders ||
            googleSheetsLoading ||
            noWithdrawMethodsFound
          }
          classNames="w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
        />
      </div>
    </div>
  );
};

export default PaymentMethods;
