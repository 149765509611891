import React, { useEffect, useMemo, useState } from "react";
import TransactionsCard from "../components/TransactionsCard/TransactionsCard";
import { useAppSelector } from "../store/hooks";
import { useTranslation } from "react-i18next";
import "../helpers/i18n";
import BalanceAndOperations from "../components/Dashboard/BalanceAndOperations";
import Contacts from "../components/Dashboard/Contacts";
import IntroduceDanapay from "../components/Dashboard/IntroduceDanapay";
import NewIndividualBanners from "../components/Dashboard/NewIndividualBanners";
import { useDispatch } from "react-redux";
import { setActiveMenu } from "../store/features/Auth/AuthSlice";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { getPaymentById } from "../store/features/Transfer/Transfer";
import Skeleton from "react-loading-skeleton";

interface IHeap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

var heap: IHeap;

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { transfer, auth } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const canPerformActions = useMemo(() => {
    if (auth?.user?.is_individual) {
      if (auth?.user?.is_active) {
        return true;
      } else {
        return false;
      }
    } else {
      if (auth?.user?.is_active && auth?.user?.company?.is_active) {
        return true;
      } else {
        return false;
      }
    }
  }, [auth?.user]);

  // temporary transactions with statuses
  const [newTransactions, setnewTransactions]: any = useState([]);
  const [loadingTransactions, setloadingTransactions] = useState(true);

  const generateNewTransactionList = async (list: any) => {
    try {
      const newList = [];
      setloadingTransactions(true);
      for (let index = 0; index < transfer?.transfers.length; index++) {
        try {
          const element = transfer?.transfers[index];

          const response: any = await getPaymentById(element?.payment_id);
          newList.push({
            ...transfer?.transfers[index],
            contact:
              response?.operation_type?.toLowerCase() == "withdraw"
                ? response?.sender
                : response?.operation_direction?.toLowerCase() == "receiving"
                ? response?.sender
                : response?.receiver,
            status:
              auth.lang == "fr"
                ? response?.operation_status_fr
                : response?.operation_status_en,
          });
        } catch (error) {
          newList.push({
            ...transfer?.transfers[index],
          });
          Sentry.captureException(error);
        }
      }
      setnewTransactions(newList);
      setloadingTransactions(false);
    } catch (error) {
      Sentry.captureException(error);
      setloadingTransactions(false);
      setnewTransactions([]);
    }
  };

  useEffect(() => {
    try {
      if (transfer?.transfers?.length > 0) {
        generateNewTransactionList(transfer?.transfers);
      } else {
        setloadingTransactions(false);
      }
    } catch (error) {
      setloadingTransactions(false);
      Sentry.captureException(error);
    }
  }, [transfer?.transfers, auth.lang]);

  if (!canPerformActions) {
    return <NewIndividualBanners user={auth?.user} />;
  } else {
    return (
      <div className="px-3 max-sm:mt-4 max-sm:!px-1">
        {auth?.user?.activation_scoring == "YELLOW" && (
          <NewIndividualBanners
            user={auth?.user}
            containerClasses="p-3 rounded-xl max-sm:mt-4 !mb-5 max-sm:!px-2 bg-[#F7E4CD] shadow-c"
          />
        )}
        <BalanceAndOperations />

        <div className="flex max-sm:flex-col max-sm:gap-2 justify-between min-h-[246px] mt-10">
          <Contacts />
          <IntroduceDanapay />
        </div>

        <div className="!my-3 flex items-center mx-2">
          <div className="font-bold">{t("Transactions")}</div>
          {transfer?.totalTransactions > 0 && !loadingTransactions && (
            <div
              className="ml-auto text-[#037375] text-sm cursor-pointer"
              onClick={() => {
                dispatch(setActiveMenu("history"));
                history.push(`/dashboard/history`);
              }}
            >
              {t("all")} {transfer?.totalTransactions} transactions
            </div>
          )}
        </div>
        {loadingTransactions ? (
          <Skeleton height={76} style={{ borderRadius: "0.75rem" }} />
        ) : (
          <TransactionsCard transactions={newTransactions?.slice(0, 5)} />
        )}
      </div>
    );
  }
};

export default Home;
