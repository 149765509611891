import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import * as Sentry from "@sentry/browser";

const ConvertedAmount = (props: any) => {
  const { containerClasses, cash, cashCurrency = "EUR" } = props;
  const { currency, rate } = useAppSelector(
    (state) => state.persistedReducer.auth
  );

  const convertedAmount = useMemo(() => {
    try {
      const formattedCash = cash.replaceAll(" ", "").replaceAll(",", ".");
      if (currency == "EUR") {
        if (cashCurrency == "CFA") {
          return (parseFloat(formattedCash) / 655.957).toFixed(2) + "€";
        } else {
          return cash.replaceAll("EUR", "€").replaceAll(" ", "");
        }
      } else {
        if (cashCurrency == "CFA") {
          return cash.replaceAll("CFA", " CFA");
        } else {
          return (parseFloat(formattedCash) * 655.957).toFixed(2) + " CFA";
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      return cash;
    }
  }, [cash, cashCurrency, currency]);

  return <div className={containerClasses}>{convertedAmount}</div>;
};

export default ConvertedAmount;
