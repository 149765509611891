import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";

const BulkSelectBankModal = (props: any) => {
  const {
    selectedBankState,
    setSelectedBank,
    dana_pay_bank_accounts,
    setselectedBankState,
    setActiveStep,
    selectedBank,
  } = props;
  const { t } = useTranslation();
  return (
    <div className="h-full my-10">
      <div className="p-4 max-w-[800px] max-sm:!px-2 mx-auto flex flex-col items-center justify-center p-10 my-2 shadow-c">
        <div className="mb-4 text-center">
          <h3 className="text-2xl font-bold">{t("payment_instruction")}</h3>
          <p>{t("pi_text")}</p>
        </div>
        <>
          <div className="bacnkCheckBoxWrapper w-full p-0 m-0 p-2 mb-4 flex-col gap-2 flex">
            {selectedBankState.length > 0 &&
              selectedBankState.map((bank: any, index: number) => {
                return (
                  <div className="wrapperBankCheckBox innerBankBox m-0">
                    <div className="wrapperBankCheckBoxwrapper">
                      <input
                        type="checkbox"
                        className="default:ring-2"
                        onChange={(e: any) => {
                          setSelectedBank(dana_pay_bank_accounts[index]);
                          setselectedBankState((prev: any) =>
                            prev.map((itm: any, ind: any) => {
                              if (ind == index) {
                                return { ...itm, checked: true };
                              } else {
                                return { ...itm, checked: false };
                              }
                            })
                          );
                        }}
                        name={bank.bank_name}
                        checked={bank.checked}
                      />
                      <span className="checkmark"></span>
                    </div>
                    <label>{bank.bank_name}</label>
                  </div>
                );
              })}
          </div>

          <CustomButton
            label="continue"
            onClick={() => setActiveStep((prev: any) => prev + 1)}
            disabled={selectedBank === null}
            classNames="min-h-[48px] mt-auto w-full rounded-xl text-white capitalize text-sm font-bold bg-black"
          />
        </>
      </div>
    </div>
  );
};

export default BulkSelectBankModal;
