import moment from "moment";
import React from "react";
import CashLayout from "../CashLayout";
import StatusButtons from "../StatusButtons";
import { useAppSelector } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import Country_comp from "../Country_comp";
import { ots, statues } from "./data";
import { convertUTCDateToLocalDate } from "../../utilities/help";

const MobileList: React.FC<any> = ({
  transaction,
  OpenModal,
  t,
  user,
  last = false,
}) => {
  const { transfer } = useAppSelector((state) => state.persistedReducer);
  const {
    operation_type,
    amount_without_fee,
    status,
    source_user,
    destination_user,
    exchange_rate_applied,
    aborted,
    created_at,
  } = transaction;
  const isBulk: boolean = operation_type === "bulk_transfer";
  const isDemanded: boolean = operation_type === "payment_demand";
  const isActiveSender: boolean = user?.id === source_user?.id;
  const cash = isBulk
    ? transaction?.transfer?.bulk_transfer_recipient_details.reduce(
        (sum: number, user: any) => sum + parseFloat(user.amount_in_euros),
        0
      )
    : amount_without_fee;

  const returnUser = () => {
    try {
      if (isBulk) {
        return {
          full_name:
            transaction?.transfer?.bulk_transfer_recipient_details.length +
            " Beneficiaries",
          country:
            transaction?.transfer?.bulk_transfer_recipient_details[0].country,
        };
      } else {
        if (isDemanded) {
          if (isActiveSender) {
            return destination_user;
          } else {
            return source_user;
          }
        } else {
          if (operation_type === "pay_out" || operation_type === "deposit") {
            return destination_user;
          } else if (operation_type === "direct_transfer") {
            return destination_user;
          } else {
            if (isActiveSender) {
              return destination_user;
            } else {
              return source_user;
            }
          }
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <tr>
      <div className={`bg-white ${last ? "" : "border-bottom"}`}>
        <div className="flex flex-row py-2 px-2">
          <div
            className="flex flex-row items-center flex-1 cursor-pointer "
            onClick={() => OpenModal(transaction)}
          >
            {operation_type === "instant_transfer" ? (
              <>
                {isActiveSender ? (
                  <span
                    className="flex flex-shrink-0 items-center justify-center mr-2 bg-green-200"
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                  >
                    <i className={`fa fa-arrow-up  m-0 text-green-500`}></i>
                  </span>
                ) : (
                  <span
                    className="flex items-center justify-center mr-2 bg-orange-200 "
                    style={{ height: 40, width: 40, borderRadius: 20 }}
                  >
                    <i className={`fa fa-arrow-down  m-0 text-orange-500`}></i>
                  </span>
                )}
              </>
            ) : (
              <span
                className="flex flex-shrink-0 items-center justify-center mr-2 bg-green-200"
                style={{ height: 40, width: 40, borderRadius: 20 }}
              >
                <i
                  className={`${ots[operation_type]?.icon} m-0 text-green-500`}
                ></i>
              </span>
            )}

            <div className="flex flex-col min-w-[100px]">
              <div className="text-gray-800 text-xs uppercase">
                {t(ots[operation_type]?.text)}
              </div>
              <div className="font-medium capitalize">
                {transaction?.contact}
              </div>
              <Country_comp
                eng_country_name={returnUser()?.country}
                size={14}
              />
            </div>
          </div>

          <div className="flex flex-col items-end justify-end">
            <div className="text-right">
              {moment(
                convertUTCDateToLocalDate(new Date(transaction?.updated_at))
              ).format("DD/MM/YYYY   H:mm:ss")}
            </div>
            <CashLayout cash={cash} exchange_rate={exchange_rate_applied} />
            <div className="flex flex-row flex-wrap items-center justify-end">
              <div className="flex flex-row flex-wrap items-center justify-end">
                <div
                  className="px-2 mt-1 capitalize badge rounded-pill"
                  style={{
                    color: statues[status] && statues[status]?.text,
                    backgroundColor: statues[status] && statues[status]?.bg,
                  }}
                >
                  {t(status)}
                </div>
                <StatusButtons
                  transfer={transaction}
                  transcationsObject={transfer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </tr>
  );
};

export default MobileList;
