import React, { useMemo } from "react";
import * as Sentry from "@sentry/browser";
import InfoMessage from "../InfoMessage";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";

const DeliveryWithdrawBox = (props: any) => {
  const {
    setwidthdrawMethod,
    widthdrawMethod,
    directPaymentAmountExceed,
    allDirectPaymentMethods,
    setMethod,
    cashoutMethod,
    transfer,
    fetchingAllFees,
  } = props;

  const { t } = useTranslation();

  // working
  const methodID = useMemo(() => {
    try {
      if (cashoutMethod && cashoutMethod.length > 0) {
        const cashoutMethodObj = cashoutMethod.find((method: any) => {
          return method?.cashout_method?.payment_type?.name == "delivery";
        });
        if (cashoutMethodObj) {
          return cashoutMethodObj?.cashout_method?.id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }, [cashoutMethod]);

  const withdrawalFees = useMemo(() => {
    try {
      if (allDirectPaymentMethods && allDirectPaymentMethods.length > 0) {
        const feeObj = allDirectPaymentMethods.find((cashoutMethod: any) => {
          return cashoutMethod.cashout_method.payment_type.name == "delivery";
        });
        if (feeObj && feeObj.withdrawFees) {
          return feeObj.withdrawFees;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [allDirectPaymentMethods]);

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              cashoutMethod.cashout_method.payment_type.name == "delivery"
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + "€";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  return (
    <div
      className={`shadow-c rounded-lg cursor-pointer${
        widthdrawMethod == "delivery" ? " border-1 border-[#037375]" : ""
      }`}
      onClick={(e) => {
        setwidthdrawMethod("delivery");
        setMethod(methodID);
      }}
    >
      <div
        className={`!items-stretch flex m-0 flex-shrink-0 min-h-[56px] px-3 !border-none max-sm:flex-wrap max-sm:gap-4 max-sm:p-2`}
      >
        <div className="flex-center mr-4">
          <div
            className={`text-[rgba(0,0,0,0.75)] font-bold flex-center pr-4 opacity-[${
              directPaymentAmountExceed && widthdrawMethod == "delivery"
                ? 0.5
                : 1
            }]`}
          >
            {t("delivery")}
          </div>
        </div>
        <div className={"ml-auto my-auto hidden max-sm:block"}>
          {widthdrawMethod == "delivery" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>

        <div className="min-w-[200px] ml-auto max-sm:m-0 flex gap-12 max-sm:gap-3 text-center max-sm:justify-between max-sm:w-full items-center">
          {" "}
          <div>
            <div className="text-xs text-[#037375]">{t("costs")}</div>
            {fetchingAllFees ? (
              <CircularProgress size={12} />
            ) : (
              <b>{withdrawalFees && withdrawalFees.toFixed(2)}€</b>
            )}
          </div>
          <div>
            <div className="text-xs text-[#037375]">{t("dealine")}</div>
            <b>30mn</b>
          </div>
          <div>
            <div className="text-xs text-[#037375]">{t("ceiling")}</div>
            <b>{maxLimit}</b>
          </div>
        </div>
        <div className={"ml-8 my-auto max-sm:hidden"}>
          {widthdrawMethod == "delivery" ? (
            <KeyboardArrowDown
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          ) : (
            <KeyboardArrowRight
              style={{
                width: "30px",
                height: "38px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
          )}
        </div>
      </div>
      {widthdrawMethod == "delivery" && (
        <>
          <div className="flex flex-col p-3 max-sm:!py-3 opacity-75 text-sm">
            {t("delivery_cashout_banner")}
          </div>
          {directPaymentAmountExceed && widthdrawMethod == "delivery" && (
            <InfoMessage
              customTextContainerClasses="text-sm text-[#963B3B]"
              message={`withdraw_limit_exceed`}
              innerContainerClasses="flex items-center min-h-[46px] max-sm:p-2"
              mainContainerClasses="mx-3 mb-3 flex items-center rounded bg-[#F7C3C3]"
              iconFill="#963B3B"
            />
          )}
        </>
      )}
    </div>
  );
};

export default DeliveryWithdrawBox;
