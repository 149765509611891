import { useLayoutEffect, useState } from "react";
import LangSwitch from "../../components/LangSwitch";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import { requestPasswordResetLink } from "../../store/features/Auth/Auth";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import { extractError } from "../../utility";
import { useAppSelector } from "../../store/hooks";
import CustomButton from "../../components/CustomButton";
import CustomInputField from "../../components/SignUp/CustomInputField";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [size, setSize] = useState([0, 0]);
  const [error, setError] = useState(" ");
  const history = useHistory();

  const HandleBtnClick = () => {
    setProcessing(true);
    requestPasswordResetLink({ email })
      .then(() => {
        setProcessing(false);
        toast.success(t("passwordLinkSent"));
      })
      .catch((error: any) => {
        setProcessing(false);
        toast.error(t(extractError(error)));

        Sentry.captureException(error);
      });
  };

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="w-screen h-screen m-0 flex flex-col">
      <div className="flex items-center max-sm:relative fixed justify-between !mt-9 px-6 w-full max-sm:mb-10">
        <img
          src={`/images/logofull.png`}
          style={{ height: 34, width: 143 }}
          alt=""
          onClick={() => history.push("/login")}
          className="cursor-pointer"
        />
        <LangSwitch customMainContainerClasses="bg-[#FAFAFA] rounded-xl w-[120px] max-sm:!w-[80px] h-[52px] !text-sm font-semibold pl-3" />
      </div>

      <div className="flex-center flex-col max-sm:!justify-between h-full max-sm:!h-auto">
        <div className="logincard shadow-c px-4 py-9 min-h-[331px] rounded-xl max-w-[600px] w-full">
          <div className="font-bold text-2xl text-center h-[48px] flex-center">
            {t("forgotPass")}
          </div>
          <div className="opacity-75 text-center !mb-7">{t("fptext")}</div>

          <CustomInputField
            mainContainerClasses="w-full"
            inputClasses="!rounded-xl h-[50px] w-full border-1 !px-3 border-[rgba(0,0,0,0.2)]"
            placeholder={t("Email")}
            name="Email Address"
            handleChange={(value: any) => {
              setEmail(value.target.value);
              if (!/^\S+@\S+\.\S+$/.test(value.target.value)) {
                setError(t("email_not_valid"));
              } else {
                setError("");
              }
            }}
            onBlur={() => {
              if (!/^\S+@\S+\.\S+$/.test(email)) {
                setError(t("email_not_valid"));
              } else {
                setError("");
              }
            }}
            value={email}
            error={error}
          />

          <CustomButton
            type="submit"
            classNames="w-full my-7 min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
            disabled={error.length !== 0 || processing}
            onClick={() => {
              HandleBtnClick();
            }}
            label="reset"
          />

          <div
            className="font-bold text-center text-[#037375] cursor-pointer"
            onClick={() => history.push("/login")}
          >
            {t("log_in_v3")}
          </div>
        </div>
      </div>

      <div
        className="px-6 pb-3 opacity-75 cursor-pointer max-sm:!pb-20 max-sm:pt-10"
        onClick={() =>
          window.open("https://www.danapay.io/conditions", "_blank")
        }
      >
        {t("terms_conditions_privacy_policies")}
      </div>
    </div>
  );
};

export default ForgotPassword;
