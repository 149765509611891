import React, { useState, useEffect, useMemo } from "react";
import { getTransferDetails } from "../../store/features/Transfer/Transfer";
import * as Sentry from "@sentry/browser";
import "./style.css";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import "react-loading-skeleton/dist/skeleton.css";

import useResize from "../../hooks/useResize";
import { toast } from "material-react-toastify";
import AddDocumentModal from "./AddDocumentModal";
import TransactionLoadingShimmer from "./TransactionLoadingShimmer";
import TransactionInfo from "./TransactionInfo";
import TransactionOperations from "./TransactionOperations";
import TransactionRecipient from "../TransactionCellComponents/TransactionRecipient";
import TransactionIcon from "../TransactionCellComponents/TransactionIcon";
import CashLayout from "../CashLayout";
import { Tab, Tabs } from "@material-ui/core";
import TransactionUpdate from "./TransactionUpdate";
import { upload_transaction_docs } from "../../store/features/Auth/Auth";
import InfoMessage from "../InfoMessage";
import ConvertedAmount from "../ConvertedAmount";
import WarningMessage from "../warningMessage";

const no_ben_card_type = ["deposit", "pay_out", "withdraw"]; // for these operation types,  dont show bene card in the design

const statuses: any = {
  Completed: {
    bg: "rgba(52,224,0,0.10)",
    color: "#14AD00",
  },
  Reçu: {
    bg: "rgba(52,224,0,0.10)",
    color: "#14AD00",
  },
  "In Progress": {
    bg: "rgba(220,153,9,0.10)",
    color: "#DC9909",
  },
  "En cours": {
    bg: "rgba(220,153,9,0.10)",
    color: "#DC9909",
  },
  "Action Required": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  "Action requise": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  "Payment aborted": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  "Paiement annulé": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  Failed: {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  Échoué: {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
};

const CustomIcon = () => {
  return (
    <i className="fa fa-star fa solid !text-[24px] text-yellow-400 !text-lg mr-0"></i>
  );
};

const TransactionDetails: React.FC<any> = ({ selectedTransfer, close }) => {
  const [fetchData, setFetchedData] = useState<any>(null);
  const [fetching, setFetching] = useState<any>(true);
  const {
    transfer,
    auth: { user, lang },
  } = useAppSelector((state) => state.persistedReducer);
  const { t } = useTranslation();
  const [w, h] = useResize();
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isSender = user?.id === selectedTransfer?.source_user?.id;
  const [title, setTitle] = useState("");
  const directFeeSObject = selectedTransfer?.transfer?.data
    ? JSON.parse(selectedTransfer.transfer.data)
    : null;

  const getOperationMetaData = () => {
    if (selectedTransfer) {
      getTransferDetails(selectedTransfer?.payment_id)
        .then((response: any) => {
          setFetching(false);
          setFetchedData(response);
        })
        .catch((error: any) => {
          setFetching(false);
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
        });
    }
  };

  const upload_documents = () => {
    setIsOpen(true);
  };

  const upload = (event: any) => {
    try {
      const files: any = event;
      if (files.length === 0) {
        toast.error(t("Please select a file"));
        return;
      }
      if (title === "") {
        toast.error(t("Please enter the document title/name"));
        return;
      }

      const { name, type, size } = files[0];

      if (size < 5000000) {
        const formData = new FormData();
        const ext = name.split(".");
        formData.append("file", files[0]);
        formData.append("name", title);
        setUploading(true);

        upload_transaction_docs(formData, fetchData.payment_id)
          .then((res) => {
            setUploading(false);
            setIsOpen(false);
            getOperationMetaData();
            setTitle("");
          })
          .catch((error: any) => {
            toast.error(t(error?.data?.message));
            setUploading(false);
            setIsOpen(false);
            toast.warning(t(error?.data?.message));
            Sentry.captureException(error);
          });
      } else {
        toast.error(t("file_to_large"));
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    getOperationMetaData();
  }, []);

  const isBulk: boolean = fetchData?.operation_type === "bulk_transfer";
  const cash = isBulk
    ? fetchData.transfer?.bulk_transfer_recipient_details.reduce(
        (sum: number, user: any) => sum + parseFloat(user.amount_in_euros),
        0
      )
    : fetchData?.transfer?.amount_without_fee === undefined
    ? fetchData?.amount_without_fee
    : fetchData?.transfer?.amount_without_fee;

  // tab navigation
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabIndexChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTabIndex(newValue);
  };

  const allowUploadingDocs = useMemo(() => {
    return (
      ["instant_transfer", "bulk_transfer"].includes(
        fetchData?.operation_type
      ) &&
      !fetchData?.aborted &&
      isSender
    );
  }, [fetchData, isSender]);

  const isActiveSender: boolean = user?.id === fetchData?.source_user?.id;

  const { currency, rate } = useAppSelector(
    (state) => state.persistedReducer.auth
  );

  const showCustomIcon = useMemo(() => {
    try {
      if (
        fetchData?.operation_status_en?.toLowerCase() !== "payment aborted" &&
        fetchData?.operation_status_en?.toLowerCase() !== "payment failed" &&
        fetchData?.operation_status_en?.toLowerCase() !== "failed" &&
        fetchData?.operation_status_en?.toLowerCase() !== "action required" &&
        fetchData?.operation_status_en?.toLowerCase() !== "in progress"
      ) {
        return true;
      } else if (
        (fetchData?.operation_type == "Deposit" ||
          fetchData?.operation_type?.toLowerCase() == "withdraw") &&
        fetchData?.operation_status_en?.toLowerCase() !== "failed"
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }, [fetchData]);

  const operation = useMemo(() => {
    try {
      const isDeposit = fetchData?.operation_type == "Deposit";
      const isWithdraw = fetchData?.operation_type?.toLowerCase() == "withdraw";
      const isDepositFailed =
        fetchData?.operation_status_en == "Failed" && isDeposit;
      const isSending =
        fetchData?.operation_direction?.toLowerCase() == "sending";
      const operationStatus = fetchData?.operation_status_en?.toLowerCase();
      const isMM = fetchData.hasOwnProperty("operator_name");
      const isBankAcc = fetchData.hasOwnProperty("iban");
      const isDelivery = fetchData["withdraw_method_en"] === "Delivery";
      const isMfi = fetchData.hasOwnProperty("mfi_owner_id");

      // deposit
      if (isDeposit || isWithdraw) {
        if (isDepositFailed) {
          return {
            message: "payment_failed_sender_message",
            color: "#963B3B",
            bg: "#F7D1CD",
          };
        } else {
          return {
            message: "recommend_us_link",
            color: "rgba(0,0,0,0.75)",
            bg: "#F7E4CD",
          };
        }
      }

      if (operationStatus == "payment aborted") {
        return {
          message: isSending
            ? "aborted_sender_infomessage"
            : "aborted_receiver_infomessage",
          color: "#963B3B",
          bg: "#F7D1CD",
        };
      } else if (operationStatus == "failed") {
        return {
          message: isSending
            ? "aborted_sender_infomessage"
            : "aborted_receiver_infomessage",
          color: "#963B3B",
          bg: "#F7D1CD",
        };
      } else if (operationStatus == "in progress" && isSender) {
        let payload: any = { color: "#012938", bg: "#DEEEFA" };
        if (isMM) {
          payload.message = "mm_infomessage";
          return payload;
        } else if (isBankAcc) {
          payload.message = "bank_infomessage";
          return payload;
        } else if (isMfi) {
          payload.message = "funds_in_danapay_account";
          return payload;
        } else if (isDelivery) {
          payload.message = "delivery_infomessage";
          return payload;
        } else {
          payload.message = "danapay_account_infomessage";
          return payload;
        }
      } else if (operationStatus == "action required") {
        return {
          mainMessage: "action_required_main_message",
          message: "action_required_message",
          color: "#472F01",
          bg: "#F7E4CD",
        };
      } else {
        return {
          message: "recommend_us_link",
          color: "rgba(0,0,0,0.75)",
          bg: "#F7E4CD",
        };
      }
    } catch (error) {
      Sentry.captureException(error);
      return { message: "", fill: "", color: "" };
    }
  }, [fetchData]);

  const transferCompleted = useMemo(() => {
    try {
      if (fetchData?.statuses && fetchData?.statuses?.length > 0) {
        const isTransferCompleted = fetchData?.statuses?.find(
          (payment: any) => {
            return (
              payment.datetime !== null &&
              (payment.status == "transfer_completed" ||
                payment.status == "deposit_completed" ||
                payment.status == "withdraw_completed")
            );
          }
        );
        if (isTransferCompleted) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [fetchData]);

  const showReceiverInfo = useMemo(() => {
    try {
      if (fetchData?.operation_direction?.toLowerCase() == "sending") {
        return true;
      } else if (
        transferCompleted &&
        fetchData?.operation_direction?.toLowerCase() !== "sending"
      ) {
        return true;
      } else if (
        fetchData?.operation_direction?.toLowerCase() !== "sending" &&
        (fetchData?.operation_status_en == "Failed" ||
          fetchData?.operation_status_en == "Payment aborted")
      ) {
        return true;
      } else if (
        fetchData?.operation_type == "Deposit" ||
        fetchData?.operation_type?.toLowerCase() == "withdraw"
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }, [fetchData, transferCompleted]);

  const transactionUserName = useMemo(() => {
    try {
      const isUserIndividual = user?.is_individual || user?.is_individual == 1;
      if (
        fetchData?.operation_type?.toLowerCase() == "deposit" ||
        fetchData?.operation_type?.toLowerCase() == "withdraw"
      ) {
        return isUserIndividual ? user?.full_name : user?.company?.name;
      } else if (fetchData?.operation_direction?.toLowerCase() == "sending") {
        return fetchData?.receiver;
      } else {
        return fetchData?.sender;
      }
    } catch (error) {
      Sentry.captureException(error);
      return "";
    }
  }, [fetchData, user]);
  const isSenderAndNormalTransfer =
    fetchData?.operation_direction == "Sending" &&
    fetchData?.operation_status_en == "Completed" &&
    fetchData?.operation_type == "Transfer";

  return (
    <>
      {fetching && <TransactionLoadingShimmer t={t} />}
      {!fetching && (
        <div className="transactiondetailscontainer h-full flex flex-col">
          <div className="min-h-[59px] flex-shrink-0 flex flex-row items-center justify-between max-[400px]:justify-center max-[400px]:gap-4 flex-wrap gap-8 mx-4 my-1">
            <div className="flex items-center gap-3">
              <TransactionIcon
                transaction={fetchData}
                isInDetailsDrawer={true}
                operation_type={fetchData?.operation_type}
                isActiveSender={isActiveSender}
                iconSize={
                  fetchData?.operation_type == "Deposit" ||
                  fetchData?.operation_type?.toLowerCase() == "withdraw"
                    ? 20
                    : 30
                }
                height={59}
                width={59}
                borderRadius={59}
                customIcon={
                  isSender
                    ? "fa-long-arrow-up fa m-0 text-[#17BA08]"
                    : "fa-long-arrow-down fa m-0 text-[#E37602]"
                }
              />
              <TransactionRecipient
                fullName={transactionUserName}
                showAccountType={true}
                logged_in_transactor={
                  fetchData?.operation_direction?.toLowerCase() == "sending"
                    ? fetchData?.receiver
                    : fetchData?.sender
                }
                operation_type={fetchData?.operation_type}
                transaction={fetchData}
                returnUser={() =>
                  fetchData?.operation_direction?.toLowerCase() == "sending"
                    ? fetchData?.receiver
                    : fetchData?.sender
                }
                t={t}
                isSender={
                  fetchData?.operation_direction?.toLowerCase() == "sending"
                }
              />
            </div>

            <div className="h-[59px] items-end flex flex-col justify-between">
              <ConvertedAmount
                cashCurrency={
                  fetchData?.operation_direction?.toLowerCase() == "sending"
                    ? fetchData?.sender_currency
                    : fetchData?.receiver_currency
                }
                cash={
                  fetchData?.sent_amount_in_sender_currency
                    ?.replace(" ", "")
                    .replace(",", ".") ||
                  fetchData?.received_amount_in_receiver_currency
                    ?.replace(" ", "")
                    .replace(",", ".")
                }
                containerClasses="text-right font-bold max-[400px]:text-center block m-0 p-0 leading-none"
              />
              <div
                className="px-3 h-[32px] min-w-[85px] py-2 text-xs capitalize bg-[rgba(52,224,0,0.10)] font-semiBold badge rounded-pill"
                style={{
                  backgroundColor:
                    statuses[fetchData?.operation_status_en]?.bg ||
                    "rgba(52, 224, 0, 0.1)",
                  color:
                    statuses[fetchData?.operation_status_en]?.color ||
                    "#14AD00",
                }}
              >
                {lang == "en"
                  ? t(fetchData?.operation_status_en)
                  : fetchData?.operation_status_fr}
              </div>
            </div>
          </div>
          <hr className="mx-4 opacity-20 mt-7" />

          {/* action required */}
          {fetchData?.operation_status_en == "Action Required" &&
            fetchData?.operation_direction == "Sending" && (
              <div className="mt-8 mx-4 mb-4 flex flex-col justify-between h-full">
                <WarningMessage
                  svgClasses="mr-2"
                  svgWidth={24}
                  svgHeight={24}
                  mainMessage={operation.mainMessage}
                  message={operation.message}
                  cursorPointerUnderlineMessage={true}
                  iconFill={operation.color}
                  containerClasses="pl-3 pr-3 py-2 flex items-center"
                  extraClasses={`bg-[${
                    showCustomIcon ? "#F5A05B" : operation.bg
                  }] !border-none !p-0 !m-0 !rounded-xl`}
                  messageClasses={`text-sm text-[${
                    showCustomIcon ? "opacity-75" : operation.color
                  }]`}
                ></WarningMessage>
                <TransactionOperations
                  transferCompleted={transferCompleted}
                  fetchData={fetchData}
                  isSender={isSender}
                  upload_documents={upload_documents}
                  uploading={uploading}
                  setDownloading={setDownloading}
                  downloading={downloading}
                  transfer={transfer}
                  selectedTransfer={selectedTransfer}
                  t={t}
                />
              </div>
            )}

          {/* details */}
          {!(
            fetchData?.operation_status_en == "Action Required" &&
            fetchData?.operation_direction == "Sending"
          ) && (
            <div className="my-3 mx-4 mb-4">
              <Tabs
                value={tabIndex}
                classes={{
                  flexContainer: "flex-wrap sm:flex-nowrap",
                }}
                TabIndicatorProps={{
                  className: "block max-[370px]:hidden",
                  style: {
                    backgroundColor: "#037375",
                    minWidth: "10px",
                  },
                }}
                onChange={handleTabIndexChange}
                variant="fullWidth"
              >
                <Tab
                  style={{
                    textTransform: "none",
                    minWidth: "100px",
                    borderBottom: tabIndex == 0 ? "2px solid #037375" : "none",
                    color: tabIndex == 0 ? "#037375" : "rgba(0,0,0,0.75)",
                    fontWeight: tabIndex == 0 ? 700 : 400,
                  }}
                  label={t("status")}
                  className={`md:text-base text-sm`}
                />
                <Tab
                  style={{
                    textTransform: "none",
                    minWidth: "100px",
                    borderBottom: tabIndex == 1 ? "2px solid #037375" : "none",
                    color: tabIndex == 1 ? "#037375" : "rgba(0,0,0,0.75)",
                    fontWeight: tabIndex == 1 ? 700 : 400,
                  }}
                  label={t("Details")}
                  className={`md:text-base text-sm`}
                />
                {/* {allowUploadingDocs && (
                  <Tab
                    style={{
                      textTransform: "none",
                      minWidth: "100px",
                      borderBottom:
                        tabIndex == 2 ? "2px solid #037375" : "none",
                      color: tabIndex == 2 ? "#037375" : "rgba(0,0,0,0.75)",
                      fontWeight: tabIndex == 2 ? 700 : 400,
                    }}
                    label={t("Documents")}
                    className={`md:text-base text-sm`}
                  />
                )} */}
              </Tabs>
            </div>
          )}

          {!(
            fetchData?.operation_status_en == "Action Required" &&
            fetchData?.operation_direction == "Sending"
          ) && (
            <div className="h-full flex flex-col justify-between">
              {/* update and status */}
              {tabIndex == 0 && (
                <>
                  <TransactionUpdate
                    transferCompleted={transferCompleted}
                    isSender={isSender}
                    fetchData={fetchData}
                    t={t}
                  />
                  <div
                    className={`px-4 pb-10 flex gap-2 flex-col justify-between ${
                      isSenderAndNormalTransfer
                        ? "min-h-[234px] gap-2"
                        : "h-[234px]"
                    }`}
                  >
                    {isSenderAndNormalTransfer && (
                      <InfoMessage
                        svgClasses="mr-2"
                        svgWidth={24}
                        svgHeight={24}
                        onClick={() => {}}
                        message={"funds_in_danapay_account"}
                        cursorPointerUnderlineMessage={true}
                        iconFill={"#012938"}
                        mainContainerClasses="pr-3 py-3 flex items-center mb-2"
                        extraClasses={`bg-[#DEEEFA] !border-none !rounded-xl`}
                        textClasses={`text-base opacity-75 ${`text-[#012938]`}`}
                      ></InfoMessage>
                    )}

                    {showReceiverInfo && (
                      <InfoMessage
                        svgClasses="mr-2"
                        svgWidth={24}
                        svgHeight={24}
                        onClick={() => {
                          if (showCustomIcon) {
                            window.open(
                              "https://www.trustpilot.com/evaluate/danapay.io",
                              "_blank"
                            );
                          }
                        }}
                        CustomIcon={showCustomIcon ? <CustomIcon /> : null}
                        message={operation.message}
                        cursorPointerUnderlineMessage={true}
                        iconFill={operation.color}
                        mainContainerClasses="pr-3 py-3 flex items-center"
                        extraClasses={`bg-[${
                          showCustomIcon ? "#F7E4CD" : operation.bg
                        }] !border-none !rounded-xl ${
                          showCustomIcon ? "pl-3" : ""
                        }`}
                        textClasses={`text-base ${
                          showCustomIcon
                            ? "opacity-75 cursor-pointer"
                            : `text-[${operation.color}]`
                        }`}
                      ></InfoMessage>
                    )}
                    <TransactionOperations
                      mainContainerClasses={
                        isSenderAndNormalTransfer
                          ? "flex justify-end mt-3"
                          : "flex justify-end"
                      }
                      transferCompleted={transferCompleted}
                      fetchData={fetchData}
                      isSender={isSender}
                      upload_documents={upload_documents}
                      uploading={uploading}
                      setDownloading={setDownloading}
                      downloading={downloading}
                      transfer={transfer}
                      selectedTransfer={selectedTransfer}
                      t={t}
                    />
                  </div>
                </>
              )}

              {/* details tab */}
              {tabIndex == 1 && (
                <>
                  <TransactionInfo
                    fetchData={fetchData}
                    isSender={isSender}
                    t={t}
                    directFeeSObject={directFeeSObject}
                    getOperationMetaData={getOperationMetaData}
                  />
                  <div className="px-4 pb-10 flex flex-col justify-between h-[234px]">
                    <InfoMessage
                      svgClasses="mr-2"
                      svgWidth={24}
                      svgHeight={24}
                      onClick={() => {
                        window.open(
                          "https://www.trustpilot.com/evaluate/danapay.io",
                          "_blank"
                        );
                      }}
                      CustomIcon={<CustomIcon />}
                      message={"recommend_us_link"}
                      cursorPointerUnderlineMessage={true}
                      iconFill={"rgba(0,0,0,0.75)"}
                      mainContainerClasses="pr-3 py-3 flex items-center cursor-pointer"
                      extraClasses={`bg-[#F7E4CD] !border-none !rounded-xl pl-3`}
                      textClasses={`text-base opacity-75`}
                    ></InfoMessage>
                    <TransactionOperations
                      transferCompleted={transferCompleted}
                      fetchData={fetchData}
                      isSender={isSender}
                      upload_documents={upload_documents}
                      uploading={uploading}
                      setDownloading={setDownloading}
                      downloading={downloading}
                      transfer={transfer}
                      selectedTransfer={selectedTransfer}
                      t={t}
                      showRepeat={false}
                      showDownloadBtn={true}
                    />
                  </div>
                </>
              )}

              {/* documents */}
              {tabIndex == 2 && allowUploadingDocs && (
                <AddDocumentModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  uploading={uploading}
                  t={t}
                  setTitle={setTitle}
                  title={title}
                  fetchData={fetchData}
                  setUploading={setUploading}
                  isSender={isSender}
                  getOperationMetaData={getOperationMetaData}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TransactionDetails;
