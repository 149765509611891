import React from "react";
import { useAppSelector } from "../store/hooks";

type CashLayoutProps = {
  cash: any;
  size?: string;
  isCfa?: boolean;
  fontSize?: any;
  exchange_rate?: any;
  showSymbol?: any;
  showInBoldText?: any;
  classes?: any;
  showCurrencyIcon?: any;
};

const CashLayout: React.FC<CashLayoutProps> = ({
  cash,
  size = "text-base",
  isCfa = false,
  fontSize = 14,
  exchange_rate = null,
  showSymbol = false,
  showInBoldText = true,
  classes,
  showCurrencyIcon = false,
}) => {
  const { currency, rate } = useAppSelector(
    (state) => state.persistedReducer.auth
  );
  const conversion_rate = exchange_rate === null ? rate : exchange_rate;
  const curr = isCfa ? "CFA" : currency;

  return (
    <small
      style={{ fontSize: fontSize }}
      className={`${classes} ${showInBoldText ? "font-bold" : ""}`}
    >{`${
      currency === "CFA"
        ? (parseFloat(cash) * conversion_rate).toFixed(0)
        : parseFloat(cash)
            .toFixed(2)
            .toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, "  ")
    }${currency === "EUR" ? (showCurrencyIcon ? "€" : "EUR") : "CFA"}`}</small>
  );
};
export default CashLayout;
