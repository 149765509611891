import { Alert } from "@material-ui/lab";
import InfoMessage from "../InfoMessage";
import CustomButton from "../CustomButton";

const SelectBankModal = (props: any) => {
  const {
    t,
    selectedBankState,
    setBankIndex,
    setselectedBankState,
    bankIndex,
    setSelectedBank,
    setActiveStep,
    dana_pay_bank_accounts,
  } = props;

  const CustomMessageComponent = () => {
    return (
      <>
        {t("after_bank_transfer_message_1")}
        <b>{t("after_bank_transfer_message_2")}</b>
      </>
    );
  };

  return (
    <div className="mx-auto px-4 pb-4 max-sm:!px-3 shadow-c max-w-[800px]">
      <div className="pt-4 text-center">
        <h3 className="text-2xl font-bold">
          {t("bank_transfer_success_title")}
        </h3>
        <p>{t("bank_transfer_success_subtext")}</p>
      </div>
      <InfoMessage
        message={"after_bank_transfer_message"}
        extraClasses="mt-4"
        customMessageComponent={<CustomMessageComponent />}
      />
      <Alert severity="warning" className="my-2">
        {t("after_bank_transfer_message_3")}
      </Alert>
      <p className="mb-2 mt-4 text-center">{t("pi_text")}</p>
      <>
        <div className="bacnkCheckBoxWrapper flex flex-col gap-2 m-0 p-0 p-2 mb-4">
          {selectedBankState.length > 0 &&
            selectedBankState.map((bank: any, index: number) => {
              return (
                <div className="wrapperBankCheckBox m-0 innerBankBox">
                  <div className="wrapperBankCheckBoxwrapper">
                    <input
                      type="checkbox"
                      className="default:ring-2"
                      onChange={(e: any) => {
                        setBankIndex(index);
                        setselectedBankState((prev: any) =>
                          prev.map((itm: any, ind: any) => {
                            if (ind == index) {
                              return {
                                ...itm,
                                checked: true,
                              };
                            } else {
                              return {
                                ...itm,
                                checked: false,
                              };
                            }
                          })
                        );
                      }}
                      name={bank.bank_name}
                      checked={bank.checked}
                    />
                    <span className="checkmark"></span>
                  </div>
                  <label>{bank.bank_name}</label>
                </div>
              );
            })}
        </div>

        <CustomButton
          onClick={() => {
            if (bankIndex !== null) {
              setSelectedBank(dana_pay_bank_accounts[bankIndex]);

              setActiveStep(3);
            }
          }}
          label="continue"
          classNames="min-h-[48px] mt-auto w-full flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
        />
      </>
    </div>
  );
};

export default SelectBankModal;
