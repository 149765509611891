import { CircularProgress } from "@material-ui/core";
import BeneficiarySummary from "../BeneficiarySumary";
import CashLayout from "../CashLayout";
import { useState } from "react";
import CashInputComponent from "./CashInputComponent";
import PaymentMethodComponent from "./PaymentMethodComponent";
import { useAppSelector } from "../../store/hooks";
import { euroToCFA } from "../../utilities/help";

const DepositInitiationModal = (props: any) => {
  const {
    t,
    full_name,
    email,
    company,
    userBalance,
    RATE,
    paymentMethod,
    amountInEURos,
    amountInCFA,
    fees,
    fetchingFees,
    payment_reference_number,
  } = props;

  const {
    auth: { currency },
  } = useAppSelector((state) => state.persistedReducer);

  const [interalstep, setInternalStep] = useState(1);

  return (
    <div className="rounded-lg mx-auto maindepositdiv max-w-[800px] px-4 pb-4 pt-1 my-3 shadow-c">
      <div className="flex flex-row items-center justify-between border-b-2 col-md-12">
        <div className="flex-1">
          <BeneficiarySummary
            size={11}
            name={full_name}
            company={company?.name || email}
          />
        </div>
        <div className="flex flex-col items-end flex-1">
          <small>{t("Balance")}</small>
          <CashLayout size={"text-sm"} cash={userBalance} />
        </div>
      </div>
      <div className="flex max-sm:flex-col gap-4 justify-between">
        <div className="mt-4 w-full flex flex-col">
          {interalstep === 1 && (
            <CashInputComponent
              data={props}
              next={() => setInternalStep((prev) => prev + 1)}
            />
          )}
          {interalstep === 2 && (
            <PaymentMethodComponent
              data={props}
              prev={() => setInternalStep((prev) => prev - 1)}
            />
          )}
        </div>

        {/* info panel right side */}
        <div className="mt-4 w-full">
          {paymentMethod !== "balance" && (
            <div className="flex flex-row items-center justify-between p-2">
              <p>
                <small>{t("TransactionType")}</small>
              </p>
              <p>
                <small>
                  <b>{t("Deposit")}</b>
                </small>
              </p>
            </div>
          )}
          <div className="flex flex-row items-center justify-between p-2">
            <p>
              <small>{t("amount")}</small>
            </p>
            <p>
              <small className="text-right font-bold">
                {currency == "CFA"
                  ? amountInCFA
                  : parseInt(amountInEURos).toFixed(2)}
                {currency == "CFA" ? "CFA" : "€"}
              </small>
            </p>
          </div>
          <div className="flex flex-row items-center justify-between p-2">
            <p>
              <small>{t("fees")}</small>
            </p>
            {fetchingFees ? (
              <CircularProgress size={14} />
            ) : (
              <small className="text-right font-bold">
                {currency == "CFA"
                  ? (fees * 655.957).toFixed(2)
                  : fees.toFixed(2)}
                {currency == "CFA" ? "CFA" : "€"}
              </small>
            )}
          </div>
          <div className="flex flex-row items-center justify-between p-2">
            <p>
              <small style={{ color: "rgb(3, 115, 117)" }}>{t("total")}</small>
            </p>
            <p>
              <b
                style={{ color: "rgb(3, 115, 117)" }}
                className="text-right font-bold"
              >
                {currency == "CFA"
                  ? parseInt(amountInCFA) +
                    parseFloat((fees * 655.957).toFixed(2))
                  : (parseInt(amountInEURos) + fees).toFixed(2)}
                {currency == "CFA" ? "CFA" : "€"}
              </b>
            </p>
          </div>

          <div className="flex flex-row items-center justify-between p-2">
            <p>
              <small>{t("ExchangeRate")}</small>
            </p>
            <p>
              <small className="text-right flex">
                <b>1EUR = {RATE} CFA</b>
              </small>
            </p>
          </div>

          <div className="flex flex-row items-center justify-between p-2">
            <p>
              <small>{t("PaymentMode")}</small>
            </p>
            <p>
              <small className="text-right">
                <b>{t(paymentMethod?.toLowerCase()?.split(" ")?.join("_"))}</b>
              </small>
            </p>
          </div>

          {paymentMethod?.split(" ")?.join("_") === "bank_transfer" && (
            <div className="flex flex-row justify-between p-2 my-2">
              <small>{t("payment_reference_number")}</small>
              <small className="text-right">
                <b className="font-bold">{payment_reference_number}</b>
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositInitiationModal;
