import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import {
  resetAuth,
  saveUserFavorites,
  setAccounts,
  setSignUpData,
  updateDanaPayBankAccounts,
} from "../../store/features/Auth/AuthSlice";
import LangSwitch from "../../components/LangSwitch";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";

import {
  setTransferFromType,
  setExternalApiPaymentId,
  fetchTransferRecords,
  addLoggedInUserCountry,
} from "../../store/features/Transfer/TransferSlice";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import GoogleCaptchaVerfication from "./GoogleCaptchaVerfication";
import { customerIoIdentify } from "../../utilities/customeriohelpers";
import LoginWithEmail from "../../components/Login/LoginWithEmail";
import LoginWithPhone from "../../components/Login/LoginWithPhone";
import { isValidData } from "../../utilities/help";
import CustomModal from "../../components/CustomModal";

const Content = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <div className="bg-red-400 flex-center rounded-full w-[90px] h-[90px] mb-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="#fffff"
          stroke="#ffffff"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
      <div className="text-center font-bold text-xl mb-3">
        {t("sumsub_reject_title")}
      </div>
      <div className="text-center mb-12">{t("sumsub_reject_body")}</div>
    </div>
  );
};

const Login = () => {
  const { t } = useTranslation();
  const {
    auth: { user, access_token, isLoggedIn, lang, refreshCaptcha },
    transfer: { danaPayCountries },
  } = useAppSelector((state: any) => state.persistedReducer);
  const [errorMessage, showErrorMessage] = useState<any>([]);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const queryURL = new URLSearchParams(search).get("payment_id");
  const email = new URLSearchParams(search).get("email");
  const [captchaToken, setCaptchaToken] = useState<any>("");

  useEffect(() => {
    if (queryURL) {
      dispatch(setTransferFromType("external_api"));
      dispatch(setExternalApiPaymentId(queryURL));
      if (isLoggedIn && access_token && user) {
        history.push("/complete_form");
      } else {
        // clean local storage
      }
    } else {
      localStorage.removeItem("user:key");
      dispatch(resetAuth());
      dispatch(fetchTransferRecords([]));
      dispatch(saveUserFavorites([]));
      dispatch(addLoggedInUserCountry(null));
      dispatch(setAccounts([]));
      dispatch(updateDanaPayBankAccounts([]));
    }
  }, []);

  const setLoggedUserCountry = (user_info: any) => {
    try {
      const danapayCountries = danaPayCountries.find(
        (cc: any) => cc?.country_code === user_info?.country_code
      );
      if (danapayCountries !== undefined) {
        dispatch(addLoggedInUserCountry(danapayCountries));
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // set signupdata if any user data found
  const setRegistrationData = (user: any) => {
    try {
      if (user !== undefined && user !== null) {
        let userProfile: any = {};

        // new logic
        const fieldsToCheck = [
          "first_name",
          "last_name",
          "address_line",
          "city",
          "activity_id",
          "job",
          "monthly_revenue",
          "revenue_level_id",
          "company",
          // "meta",
          "country_code",
          "countryName",
          "residency",
          "phone_number",
          "post_code",
          "is_sole_proprietorship",
          "companyReceivingEnvisionedCountries",
          "companySendingEnvisionedCountries",
          "receiving_countries_ids",
          "usertype",
          "user_origin",
          "event",
          "referral_code",
        ];
        fieldsToCheck.forEach((field: any) => {
          if (isValidData(user?.[field])) {
            userProfile[field] = user[field];
          }
        });

        fieldsToCheck.forEach((field: any) => {
          if (isValidData(user?.progression[field])) {
            userProfile[field] = user?.progression[field];
          }
        });
        if (Object.keys(userProfile).length > 0) {
          dispatch(setSignUpData(userProfile));
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    // Your effect

    return () => {
      // Cleanup
    };
  }, []);

  const handleHeapAndCustomerioEvents = (result: any, data: any) => {
    window.heap.track("Logged In", {
      email: result?.user?.email,
    });
    customerIoIdentify({
      id: result?.user?.id,
      email: result?.user?.email,
      first_name: result?.user?.first_name,
      last_name: result?.user?.last_name,
      "User Type":
        result.user?.is_individual &&
        result.user?.client.type !== "temporary-customer"
          ? "Individual"
          : result.user?.is_company_owner
          ? "Company"
          : "Undefined",
    });
    if (
      result.user?.is_individual &&
      result.user?.client.type !== "temporary-customer"
    ) {
      window.heap.track("Has logged in as an individual", {
        email: result?.user?.email,
      });
    } else if (result.user?.is_company_owner) {
      window.heap.track("Has logged in as a company", {
        email: result?.user?.email,
      });
    }
    if (result?.access_token) {
      window?.heap?.identify(data?.email, "email");
    }
  };

  // login type
  const [loginType, setloginType]: any = useState("email");

  const handleLoginType = (event: React.ChangeEvent<{}>, newValue: number) => {
    setloginType(newValue);
  };
  const [isUserRejected, setisUserRejected] = useState(false);

  return (
    <div className="w-screen h-screen m-0 flex flex-col">
      <CustomModal
        showCloseButton={true}
        showHorizontalRuler={true}
        headerClasses="flex justify-between items-center mb-3.5"
        open={isUserRejected}
        containerClasses={"h-full flex-center"}
        modalClasses={
          "bg-white flex flex-col rounded-xl w-[486px] max-sm:!w-full min-h-[369px] p-4"
        }
        titleClasses={"font-bold text-xl max-sm:text-xl"}
        title={t("Information")}
        content={t("fake_name_warning")}
        contentComponent={<Content />}
        onNext={() => {
          window.location.href = "https://www.danapay.com";
        }}
        onClose={() => {
          setisUserRejected(false);
        }}
        onNextTitle={"close"}
        buttonContainerClasses={"flex gap-3 mt-auto"}
        nextBtnClasses={
          "w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
        }
      />

      <div className="flex items-center justify-between !mt-9 px-6 w-full max-sm:mb-10">
        <img
          src={`/images/logofull.png`}
          style={{ height: 34, width: 143 }}
          alt=""
          onClick={() => history.push("/login")}
          className="cursor-pointer"
        />
        <LangSwitch customMainContainerClasses="bg-[#FAFAFA] max-sm:!w-[80px] rounded-xl w-[120px] h-[52px] !text-sm font-semibold pl-3" />
      </div>

      <div className="flex-center flex-col max-sm:!justify-between h-full max-sm:!h-auto">
        <div className="logincard shadow-c px-4 py-9 rounded-xl max-w-[600px] w-full">
          <div className="font-bold text-2xl text-center h-[48px] flex-center">
            {t("please_log_in")}
          </div>
          <div className="opacity-75 text-center !mb-7">
            {t("choose_connection_method")}
          </div>

          <div className="flex-center max-sm:flex-col gap-2">
            <div
              className="rounded-xl flex items-center min-h-[56px] py-2 px-2 w-[189px] shadow-c cursor-pointer max-sm:w-full"
              onClick={() => setloginType("email")}
              style={{
                border: loginType == "email" ? "1px solid #037375" : "none",
              }}
            >
              <img
                src="/images/envelope.svg"
                style={{
                  height: 40,
                  width: 40,
                }}
              />
              <div className="opacity-75 font-bold ml-2">{t("by_email")}</div>
            </div>
            <div
              className="rounded-xl flex items-center min-h-[56px] py-2 px-2 w-[189px] shadow-c cursor-pointer max-sm:w-full"
              onClick={() => setloginType("phone")}
              style={{
                border: loginType == "phone" ? "1px solid #037375" : "none",
              }}
            >
              <img
                src="/images/phone.svg"
                style={{
                  height: 40,
                  width: 40,
                }}
              />
              <div className="opacity-75 font-bold ml-2">
                {t("by_telephone")}
              </div>
            </div>
          </div>

          {/* options */}
          {loginType == "email" && (
            <LoginWithEmail
              t={t}
              setisUserRejected={setisUserRejected}
              showErrorMessage={showErrorMessage}
              captchaToken={captchaToken}
              dispatch={dispatch}
              setLoggedUserCountry={setLoggedUserCountry}
              history={history}
              handleHeapAndCustomerioEvents={handleHeapAndCustomerioEvents}
              setCaptchaToken={setCaptchaToken}
              refreshCaptcha={refreshCaptcha}
              errorMessage={errorMessage}
              yup={yup}
              setRegistrationData={setRegistrationData}
            />
          )}
          {loginType == "phone" && (
            <LoginWithPhone
              t={t}
              setisUserRejected={setisUserRejected}
              showErrorMessage={showErrorMessage}
              captchaToken={captchaToken}
              dispatch={dispatch}
              setLoggedUserCountry={setLoggedUserCountry}
              history={history}
              handleHeapAndCustomerioEvents={handleHeapAndCustomerioEvents}
              setCaptchaToken={setCaptchaToken}
              refreshCaptcha={refreshCaptcha}
              errorMessage={errorMessage}
              yup={yup}
              setRegistrationData={setRegistrationData}
            />
          )}
        </div>
        <div
          className="flex items-center !mt-4 gap-1 max-sm:!mb-20"
          onClick={() => history.push("/join")}
        >
          <div className="opacity-75">{t("no_account")}</div>{" "}
          <div className="font-bold text-[#037375] cursor-pointer">
            {t("register")}
          </div>
        </div>
      </div>

      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
      >
        <GoogleCaptchaVerfication
          parentCallBack={(e: any) => setCaptchaToken(e)}
        />
      </GoogleReCaptchaProvider>

      <div
        className="px-6 pb-3 opacity-75 cursor-pointer max-sm:!pb-20"
        onClick={() =>
          window.open("https://www.danapay.io/conditions", "_blank")
        }
      >
        {t("terms_conditions_privacy_policies")}
      </div>
    </div>
  );

  return (
    <>
      {email && (
        <div className="p-2 mb-10 text-orange-700 bg-orange-100">
          <p>
            <small>{t("sent_credentials")}</small>
          </p>
          <p>
            <small className="font-bold">{email}</small>
          </p>
        </div>
      )}
    </>
  );
};

export default Login;
