import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import BankDetails from "./BankDetails";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import CashLayout from "./CashLayout";
import * as Sentry from "@sentry/browser";
import {
  bulkTransferPayment,
  calculateBulkTransferFees,
  currentBalance,
  verifyBulkCsv,
} from "../store/features/Transfer/Transfer";
import { extractError } from "../utility";
import { toast } from "material-react-toastify";
import { getFavorites } from "../store/features/Auth/Auth";
import {
  bulkTransferCsvPayment,
  openBulkTransferDialog,
  resetPayment,
  updateBulkTData,
  updateUsersForBulkyTransfer,
  verifiedBulkCsv,
} from "../store/features/Transfer/TransferSlice";
import {
  calculateSumFromArray,
  calculateTotalFromArray,
  getCashInMethod,
  getLoggedInUserReceivingCountries,
} from "../utilities/help";
import Papa from "papaparse";
import { translateError } from "../utilities/help";
import { getCashInMethodName } from "../utilities/help";
import BulkCsvUploadModal from "./BulkTransfer/BulkCsvUploadModal";
import BulkUploadSuccessModal from "./BulkTransfer/BulkUploadSuccessModal";
import SelectBeneficiaries from "./BulkTransfer/SelectBeneficiaries";
import BulkPaymentModal from "./BulkTransfer/BulkPaymentModal";
import BulkSelectBankModal from "./BulkTransfer/BulkSelectBankModal";
import BulkCsvUploadFailModal from "./BulkTransfer/BulkCsvUploadFailModal";
import BulkPaymenetCloseDialogue from "./BulkTransfer/BulkPaymenetCloseDialogue";
import CustomButton from "./CustomButton";

const BulkPayment: React.FC<any> = ({ setBulkPaymentModal, t }) => {
  const dispatch = useAppDispatch();

  const {
    transfer,
    auth: { user, currency, lang, dana_pay_bank_accounts },
  } = useAppSelector((state) => state.persistedReducer);
  const steps = [
    t("beneficiary"),
    t("Amount"),
    t("payment"),
    t("Confirmation"),
  ];
  const [size, setSize] = useState([0, 0]);
  const [activeStep, setActiveStep] = useState(0);
  const [fees, setFees] = useState(0);
  const [payMethod, setPayMethod] = useState(
    transfer?.bulkTransferData?.paymentMethod !== undefined &&
      transfer?.bulkTransferData?.paymentMethod !== ""
      ? transfer?.bulkTransferData?.paymentMethod
      : ""
  );
  const [openDialog, setopenDialog] = useState(false);
  const [openFailDialog, setopenFailDialog] = useState(false);
  const [openFailDialogLoss, setopenFailDialogLoss] = useState(false);
  const [openSucessDialog, setopenSucessDialog] = useState(false);
  const [amountEuro, setAmountEuro] = useState(0);
  const [errorGot, setErrorGot] = React.useState("");
  const [euroBalance, setEuroBalance] = React.useState(0);
  const [selectedBank, setSelectedBank] = React.useState("");
  const [favorites, setFavorites] = React.useState([]);
  const [processingPayment, setProcessingPayment] = React.useState(false);
  const [fetchingFees, setFetchingFees] = React.useState(false);
  const [wasBalance, setWasBalance] = React.useState<any>(false);
  const [fileCSV, setFileCSV] = React.useState<any>([]);
  const [dataCSV, setDataCSV] = React.useState<any>([]);
  const [uploadingCSV, setUploadingCSV] = React.useState<any>([]);
  const [dataSucessCSV, setDataSucessCSV] = React.useState<any>([]);
  const [showTableCSV, setshowTableCSV] = React.useState<boolean>(false);

  const [checkedCashMethod, setCheckedcashMethod] = useState<boolean>(false);
  const [selectedCashInReset, setSelectedCashInReset] =
    useState<boolean>(false);
  const [balaceTransferForBulk, setbalaceTransferForBulk] = useState(0);

  const [selectedBankState, setselectedBankState] = useState([]);

  useEffect(() => {
    if (
      dana_pay_bank_accounts !== undefined &&
      dana_pay_bank_accounts.length > 0
    ) {
      setselectedBankState(
        dana_pay_bank_accounts.map((i: any) => ({ ...i, checked: false }))
      );
    }
  }, [dana_pay_bank_accounts]);

  const [spread_config, setSpreadConfig] = React.useState({
    spread: 0,
    net_exchange_rate: 655.957,
    exchange_rate: 655.957,
    received_amount: 0,
  });

  const history = useHistory();

  useEffect(() => {
    try {
      if (transfer?.usersForBulkyTransfer?.length > 0) {
        showTransfer(transfer?.usersForBulkyTransfer);
        if (transfer?.bulkTransferData?.paymentMethod !== "") {
          // for repeat get fees
          handleCashMethod(
            true,
            t(payMethod?.toLowerCase().split(" ").join("_")).toLowerCase(),
            window.localStorage.getItem("i18nextLng") == "fr" ? true : false
          );
        }
      }
      if (Object.keys(transfer?.usersForBulkyTransfer).length < 1) {
        // set balance as default if balance greater than 0
        // if (!isNaN(user?.client?.euro_balance) && Number(user?.client?.euro_balance) > 0) {
        //   dispatch(resetPayment("balance"));
        // } else {
        //   dispatch(resetPayment(""));
        // }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  // doesnt work
  const calculateFees = () => {
    setErrorGot("");
    setFetchingFees(true);
    const selectedCashInMethodArray = getCashInMethod(transfer, payMethod);

    if (!selectedCashInMethodArray) {
      return;
    }

    const receiving = transfer.loggedInUserCountry?.receiving_countries?.find(
      (receiving_country_data: any) =>
        receiving_country_data?.receiving_country?.name?.toLowerCase() ===
        transfer?.bulkTransferData?.country?.toLowerCase()
    );

    if (!receiving) return;
    const totals: any = Object.values(transfer.usersForBulkyTransfer)?.map(
      (user: any) => user.enteredValue
    );

    interface IKeys {
      id: string;
      identification_number: number;
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
      amount_without_fees: string;
    }

    const beneficiaryArray =
      fileCSV?.data === undefined
        ? fileCSV.map(
            (val: any, index: any): IKeys => ({
              id: val.id,
              identification_number: index + 1,
              first_name: val.first_name,
              last_name: val.last_name,
              email: val.email,
              phone_number: val.phone_number,
              amount_without_fees: val.amount_without_fees,
            })
          )
        : fileCSV?.data.map(
            (val: any, index: any): IKeys => ({
              id: val.id,
              identification_number: index + 1,
              first_name: val.first_name,
              last_name: val.last_name,
              email: val.email,
              phone_number: val.phone_number,
              amount_without_fees: val.amount_without_fees,
            })
          );

    const filterOperator = transfer.danaPayCountries.filter(
      (e: any) =>
        e.name.toLowerCase() === transfer.bulkTransferData.country.toLowerCase()
    );
    calculateBulkTransferFees(
      {
        euro_amount:
          calculateSumFromArray(
            fileCSV?.data === undefined ? fileCSV : fileCSV.data
          )?.total.toString() ||
          calculateTotalFromArray(transfer.usersForBulkyTransfer)?.total,
        sending_country_id: transfer.loggedInUserCountry.id,
        receiving_country_id: receiving.receiving_country.id,
        cashin_method_id: selectedCashInMethodArray.cash_in_method.id,
        beneficiaries: beneficiaryArray,
        source_user_id: user?.id,
        country_code:
          filterOperator.length > 0 ? filterOperator[0].country_code : "",
        reason: transfer?.bulkTransferData?.reason,
      },
      user?.id
    )
      .then((response: any) => {
        setErrorGot("");
        setFees(response.fee);

        // find spread config settings

        const s_config = response.fee_calculation.find((value: any) =>
          value.hasOwnProperty("spread")
        );

        if (s_config) {
          setSpreadConfig(s_config);
        }

        setFetchingFees(false);
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t(error?.data?.message));
        setFetchingFees(false);
        if (error) {
          const err = extractError(error);
          setErrorGot(err);
        }
      });
  };

  const getUserContacts = () => {
    getFavorites()
      .then((res: any) => {
        setFavorites(res);
        const cc = new Set(
          res.map((response: any) => response.favorite.country)
        );
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t(error?.data?.message));
      });
  };

  const submitRequest = async (data: any) => {
    try {
      interface IKeys {
        id: string;
        identification_number: number;
        first_name: string;
        last_name: string;
        email: string;
        phone_number: string;
        amount_without_fees: string;
      }
      const beneficiaryArray =
        fileCSV?.data === undefined
          ? fileCSV.map(
              (val: any, index: any): IKeys => ({
                id: val.id,
                identification_number: index + 1,
                first_name: val.first_name,
                last_name: val.last_name,
                email: val.email,
                phone_number: val.phone_number,
                amount_without_fees: val.amount_without_fees,
              })
            )
          : fileCSV?.data.map(
              (val: any, index: any): IKeys => ({
                id: val.id,
                identification_number: index + 1,
                first_name: val.first_name,
                last_name: val.last_name,
                email: val.email,
                phone_number: val.phone_number,
                amount_without_fees: val.amount_without_fees,
              })
            );
      const params = {
        beneficiaries: beneficiaryArray,
      };

      // verify all receipents have amount defined
      let isbeneficiaryArrayOk = true;
      if (beneficiaryArray?.length > 0) {
        beneficiaryArray.forEach((element: any) => {
          if (
            element?.amount_without_fees == "" ||
            element?.amount_without_fees == 0 ||
            element?.amount_without_fees == "0" ||
            !element.hasOwnProperty("amount_without_fees")
          ) {
            toast.error(t("Please_enter_amount"));
            isbeneficiaryArrayOk = false;
            return;
          }
        });
      }
      if (!isbeneficiaryArrayOk) {
        return;
      }
      verifyBulkCsv(params)
        .then((res: any) => {
          dispatch(verifiedBulkCsv(res));
          if (res.status === "success") {
            if (data === false) {
              window.heap.track("Bulk recepient details", {
                email: user.email,
                country: user.country,
                phone: user.full_phone_number,
                name: user.full_name,
              });
            }
            setActiveStep(1);
          }
        })
        .catch((error: any) => {
          setActiveStep(0);
          toast.error(t(extractError(error)));
          Sentry.captureException(error);
        });

      const selectedCashInMethodArrayName = getCashInMethodName(
        transfer,
        payMethod
      );
      const selectedCashInMethodArray = getCashInMethod(transfer, payMethod);
      if (selectedCashInMethodArray !== undefined) return;
      const receiving = transfer.loggedInUserCountry?.receiving_countries?.find(
        (receiving_country_data: any) =>
          receiving_country_data?.receiving_country?.name?.toLowerCase() ===
          transfer.bulkTransferData.country.toLowerCase()
      );

      if (data === true && !transfer?.bulkTransferData?.reason) {
        toast.error(t("reasonError"));
        return;
      }

      if (data === true && !payMethod) {
        toast.error(t("selectCashIn"));
        return;
      }

      if (!receiving) {
        toast.error(t("country_not_configured"));
        return;
      }

      data === true && setProcessingPayment(true);

      const payload = {
        source_user_id: user.id,
        destination_user_ids: Object.keys(transfer.usersForBulkyTransfer),
        amount_without_fees_in_euro:
          Object.values(transfer.usersForBulkyTransfer)?.map(
            (user: any) => user.enteredValue
          ) || 0,
        cashin_method_id:
          checkedCashMethod === true
            ? selectedCashInMethodArrayName?.cash_in_method?.id
            : selectedCashInMethodArray?.cash_in_method?.id,
        local_currency: "cfa",
        is_escrowed: false,
        reason: transfer.bulkTransferData.reason,
      };

      if (data === true) {
        const filterOperator = transfer.danaPayCountries.filter(
          (e: any) =>
            e.name.toLowerCase() ===
            transfer.bulkTransferData.country.toLowerCase()
        );

        const params = {
          cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
          beneficiaries: beneficiaryArray,
          source_user_id: user?.id,
          country_code:
            filterOperator.length > 0 ? filterOperator[0].country_code : "",
          reason: transfer?.bulkTransferData?.reason,
        };
        await bulkTransferPayment(params)
          .then((res: any) => {
            window.heap.track("Bulk payment details", {
              email: user.email,
              country: user.country,
              phone: user.full_phone_number,
              name: user.full_name,
            });
            window.heap.track("Bulk payment initiation", {
              email: user.email,
              country: user.country,
              phone: user.full_phone_number,
              name: user.full_name,
            });
            dispatch(bulkTransferCsvPayment(res));
            setProcessingPayment(false);
            if (
              payMethod.toLowerCase() === "bank card" ||
              payMethod.toLowerCase() === "instant sepa"
            ) {
              window.location?.replace(res.connect_url);
            } else if (payMethod.toLowerCase() === "balance") {
              setbalaceTransferForBulk(
                res.details.transfer.bulk_transfer_recipient_details
                  .reduce(
                    (total: any, accumulator: any): Number =>
                      parseFloat(total) +
                      parseFloat(accumulator.amount_in_euros),
                    0
                  )
                  .toFixed(2)
              );
              setActiveStep(3);
              setWasBalance(true);
            } else {
              setActiveStep(2);
              setbalaceTransferForBulk(
                res.details.transfer.bulk_transfer_recipient_details
                  .reduce(
                    (total: any, accumulator: any): Number =>
                      parseFloat(total) +
                      parseFloat(accumulator.amount_in_euros),
                    0
                  )
                  .toFixed(2)
              );
            }
          })
          .catch((error: any) => {
            toast.error(t(extractError(error)));
            setProcessingPayment(false);
            Sentry.captureException(error);
          });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handelClickCsv = () => {
    setopenDialog(true);
    if (showTableCSV === true) {
      toast.error(t("Upload_Another_Csv_Toast"));
    }
  };
  const handleClose = () => {
    setopenDialog(false);
  };

  // csv upload
  const upload = (e: React.SetStateAction<any>) => {
    setProcessingPayment(true);
    setUploadingCSV(e);
    if (e?.length > 0) {
      if (
        e[0]?.name?.split(".")[e[0]?.name?.split(".")?.length - 1] === "csv"
      ) {
        setProcessingPayment(false);
        setDataCSV(e[0]);
      }
    } else {
      toast.error(t("Drag_Drop_Csv_Error"));
      setProcessingPayment(false);
    }
  };

  const showTransfer = (usersForBulkyTransfer: any) => {
    try {
      if (usersForBulkyTransfer) {
        if (usersForBulkyTransfer?.length > 999) {
          setopenFailDialog(true);
        } else {
          setProcessingPayment(false);
          interface IKeys {
            id: string;
            identification_number: number;
            first_name: string;
            last_name: string;
            email: string;
            phone_number: string;
            amount_without_fees: string;
          }
          const beneficiaryArray = usersForBulkyTransfer.map(
            (val: any, index: any): IKeys => ({
              id: index,
              identification_number: index + 1,
              first_name: val.first_name,
              last_name: val.last_name,
              email: val.email,
              phone_number: val.phone_number,
              amount_without_fees: val.amount_in_euros,
            })
          );
          setDataSucessCSV(beneficiaryArray);
          const params = {
            beneficiaries: beneficiaryArray,
          };
          verifyBulkCsv(params)
            .then((res: any) => {
              dispatch(verifiedBulkCsv(res));
            })
            .catch((error: any) => {
              toast.error(t(extractError(error)));
              Sentry.captureException(error);
            });
          setopenSucessDialog(true);
        }
      } else {
        Papa.parse(dataCSV, {
          header: true,
          complete: function (results: { data: any; errors: any }) {
            if (results?.data?.length > 999) {
              setopenFailDialog(true);
            } else if (results?.errors?.length > 0) {
              toast.error(t("Upload_Csv_Toast_Error"));
              setopenFailDialog(true);
            } else {
              setProcessingPayment(false);
              setDataSucessCSV(results);
              interface IKeys {
                id: string;
                identification_number: number;
                first_name: string;
                last_name: string;
                email: string;
                phone_number: string;
                amount_without_fees: string;
              }
              const beneficiaryArray = results.data.map(
                (val: any, index: any): IKeys => ({
                  id: val.id,
                  identification_number: index + 1,
                  first_name: val.first_name,
                  last_name: val.last_name,
                  email: val.email,
                  phone_number: val.phone_number,
                  amount_without_fees: val.amount_without_fees,
                })
              );

              const params = {
                beneficiaries: beneficiaryArray,
              };
              verifyBulkCsv(params)
                .then((res: any) => {
                  dispatch(verifiedBulkCsv(res));
                })
                .catch((error: any) => {
                  toast.error(t(extractError(error)));
                  Sentry.captureException(error);
                });
              setopenSucessDialog(true);
            }
          },
        });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const update_selected = (user: any) => {
    if (
      transfer.bulkTransferData.country !== "" &&
      user?.country?.toLowerCase() ===
        transfer.bulkTransferData.country?.toLowerCase()
    ) {
      dispatch(updateUsersForBulkyTransfer(user));
    }
    if (user?.length > 0) {
      user?.filter((val: any) => {
        if (
          transfer.bulkTransferData.country !== "" &&
          val?.favorite?.country?.toLowerCase() ===
            transfer.bulkTransferData.country?.toLowerCase()
        ) {
          dispatch(updateUsersForBulkyTransfer(val.favorite));
        }
      });
    }
  };

  const getBalance = async () => {
    try {
      const balance: any = await currentBalance();
      const bal = balance?.client.euro_balance;
      setEuroBalance(bal);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handleCashMethod = (checked: any, value: any, selectCashIn: any) => {
    setSelectedCashInReset(selectCashIn);
    setCheckedcashMethod(checked);
    setPayMethod(checked === false ? "" : value);
  };

  const getStepContent = () => {
    if (activeStep === 0) {
      return (
        <SelectBeneficiaries
          transfer={transfer}
          fileCSV={fileCSV}
          showTableCSV={showTableCSV}
          setFileCSV={setFileCSV}
          handelClickCsv={handelClickCsv}
          submitRequest={submitRequest}
        />
      );
    }

    if (activeStep === 1) {
      return (
        <BulkPaymentModal
          transfer={transfer}
          fileCSV={fileCSV}
          euroBalance={euroBalance}
          handleCashMethod={handleCashMethod}
          payMethod={payMethod}
          setActiveStep={setActiveStep}
          selectedCashInReset={selectedCashInReset}
          submitRequest={submitRequest}
          processingPayment={processingPayment}
          fetchingFees={fetchingFees}
          fees={fees}
          spread_config={spread_config}
        />
      );
    }

    if (activeStep === 2) {
      return (
        <BulkSelectBankModal
          selectedBankState={selectedBankState}
          setSelectedBank={setSelectedBank}
          dana_pay_bank_accounts={dana_pay_bank_accounts}
          setselectedBankState={setselectedBankState}
          setActiveStep={setActiveStep}
          selectedBank={selectedBank}
        />
      );
    }
    if (activeStep === 3) {
      return (
        <div className="pt-10 h-screen py-4 max-w-[800px] mx-auto">
          {wasBalance ? (
            <div className="flex flex-col items-center rounded-xl justify-center p-4 text-center bg-white shadow-c">
              <img
                src="../images/checked.png"
                style={{ height: 60, width: 60, margin: 20 }}
              />
              {lang === "en" ? (
                <p>
                  We are sending{" "}
                  <b>
                    <CashLayout cash={balaceTransferForBulk} />
                  </b>{" "}
                  {currency} <br />. Your beneficiaries will receive a message
                  with the necessary details for the withdrawal.
                </p>
              ) : (
                <p>
                  Nous envoyons{" "}
                  <b>
                    <CashLayout cash={balaceTransferForBulk} />
                  </b>
                  <br />
                  Vos bénéficiaires recevront un message avec les détails
                  nécessaires pour le retrait.
                </p>
              )}

              <CustomButton
                label="BackHome"
                onClick={() => {
                  dispatch(openBulkTransferDialog("close"));
                  dispatch(updateBulkTData({ users: {} }));
                  history.push("/");
                }}
                classNames="min-h-[48px] mt-4 w-full flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
              />
            </div>
          ) : (
            <div className="bg-white shadow-c">
              <BankDetails
                accountDetails={selectedBank}
                amount={balaceTransferForBulk}
                fees={fees}
                callback={() => {
                  window.heap.track("Bulk payment initiation", {
                    email: user.email,
                    country: user.country,
                    phone: user.full_phone_number,
                    name: user.full_name,
                  });
                }}
              />
            </div>
          )}
        </div>
      );
    }
  };
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    getUserContacts();
    getBalance();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // works for fee only if in repeat
  // fee calculatioin
  const getSelectedCashInMethod = async () => {
    try {
      setFetchingFees(true);
      const selectedCashInMethodArray = getCashInMethodName(
        transfer,
        t(
          transfer?.bulkTransferData?.paymentMethod
            ?.toLowerCase()
            .split(" ")
            .join("_")
        ).toLowerCase() !== payMethod
          ? payMethod.replace("_", " ")
          : transfer?.bulkTransferData?.paymentMethod === "card"
          ? "bank card"
          : transfer?.bulkTransferData?.paymentMethod?.replace("_", " ")
      );
      if (!selectedCashInMethodArray) {
        return;
      }
      if (selectedCashInMethodArray.cash_in_method.name !== "") {
        const receiving =
          transfer.loggedInUserCountry?.receiving_countries?.find(
            (receiving_country_data: any) =>
              receiving_country_data?.receiving_country?.name?.toLowerCase() ===
              transfer?.bulkTransferData?.country?.toLowerCase()
          );
        if (!receiving) {
          setErrorGot(t("no_receiving_country"));
          setFetchingFees(false);
          return;
        }
        if (activeStep === 1) {
          interface IKeys {
            id: string;
            identification_number: number;
            first_name: string;
            last_name: string;
            email: string;
            phone_number: string;
            amount_without_fees: string;
          }

          const beneficiaryArray =
            fileCSV?.data === undefined
              ? fileCSV.map(
                  (val: any, index: any): IKeys => ({
                    id: val.id,
                    identification_number: index + 1,
                    first_name: val.first_name,
                    last_name: val.last_name,
                    email: val.email,
                    phone_number: val.phone_number,
                    amount_without_fees: val.amount_without_fees,
                  })
                )
              : fileCSV?.data.map(
                  (val: any, index: any): IKeys => ({
                    id: val.id,
                    identification_number: index + 1,
                    first_name: val.first_name,
                    last_name: val.last_name,
                    email: val.email,
                    phone_number: val.phone_number,
                    amount_without_fees: val.amount_without_fees,
                  })
                );

          const filterOperator = transfer.danaPayCountries.filter(
            (e: any) =>
              e.name.toLowerCase() ===
              transfer.bulkTransferData.country.toLowerCase()
          );

          calculateBulkTransferFees(
            {
              euro_amount:
                calculateSumFromArray(
                  fileCSV?.data === undefined ? fileCSV : fileCSV?.data
                )?.total.toString() ||
                calculateTotalFromArray(transfer.usersForBulkyTransfer)?.total,
              sending_country_id: transfer.loggedInUserCountry.id,
              receiving_country_id: receiving.receiving_country.id,
              cashin_method_id: selectedCashInMethodArray?.cash_in_method?.id,
              beneficiaries: beneficiaryArray,
              source_user_id: user?.id,
              country_code:
                filterOperator.length > 0 ? filterOperator[0].country_code : "",
              reason: "fee calculation for bulk transfer",
            },
            user?.id
          )
            .then((response: any) => {
              setErrorGot("");
              setFees(0);
              setFees(response.fee);
              setFetchingFees(false);

              const s_config = response.fee_calculation.find((value: any) =>
                value.hasOwnProperty("spread")
              );

              if (s_config) {
                setSpreadConfig(s_config);
              }
            })
            .catch((error: any) => {
              setFetchingFees(false);
              const err = extractError(error);
              setErrorGot(translateError(err, t));
              toast.error(t(extractError(error)));
              Sentry.captureException(error);
            });
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // set balance as default method if balance greater than 0 and if its not repeat
  React.useEffect(() => {
    try {
      if (Object.keys(transfer?.usersForBulkyTransfer).length < 1) {
        if (
          !isNaN(user?.client?.euro_balance) &&
          Number(user?.client?.euro_balance) > 0
        ) {
          dispatch(resetPayment("balance"));
          setPayMethod("balance");
        } else {
          dispatch(resetPayment(""));
          setPayMethod("");
        }
      } else {
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [user, transfer?.usersForBulkyTransfer]);

  // fee calculation on change
  React.useEffect(() => {
    if (checkedCashMethod === true) {
      // for repeat
      // getSelectedCashInMethod();
    }
    getSelectedCashInMethod();
  }, [amountEuro, payMethod, activeStep]);

  React.useEffect(() => {
    const countries = getLoggedInUserReceivingCountries(transfer, user, true);
    if (countries && countries.length > 0) {
      if (!transfer?.bulkTransferData?.country) {
        dispatch(updateBulkTData({ country: countries[0] }));
      }
    }
  }, []);

  return (
    <div className="h-full">
      <div
        className="shadow-md w-full flex flex-row items-center justify-between px-6 py-2 text-white"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <b>{t("Bulk_Payments")}</b>

        <button
          id="bulk-payment-cancel"
          style={{ borderRadius: 20 }}
          className="flex items-center justify-center shadow-lg h-[40px] w-[40px]"
          onClick={() => setopenFailDialogLoss(true)}
        >
          <i className="m-0 fa fa-close"></i>
        </button>
      </div>

      <div className="px-2 h-full">
        {size[0] < 900 ? null : (
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        {activeStep === steps.length ? (
          <div className="flex flex-col justify-center w-full p-10 m-auto md:w-1/2 ">
            <p>All steps completed</p>
          </div>
        ) : (
          <div className="overflow-y-scroll h-full">{getStepContent()}</div>
        )}
      </div>

      {/* csv upload modal */}
      <BulkCsvUploadModal
        openDialog={openDialog}
        handleClose={handleClose}
        upload={upload}
        processingPayment={processingPayment}
        uploadingCSV={uploadingCSV}
        setopenDialog={setopenDialog}
        setUploadingCSV={setUploadingCSV}
        setProcessingPayment={setProcessingPayment}
        showTransfer={showTransfer}
      />
      <BulkPaymenetCloseDialogue
        openFailDialogLoss={openFailDialogLoss}
        handleClose={handleClose}
        setopenFailDialogLoss={setopenFailDialogLoss}
        user={user}
        setBulkPaymentModal={setBulkPaymentModal}
      />
      <BulkCsvUploadFailModal
        openFailDialog={openFailDialog}
        handleClose={handleClose}
        setopenFailDialog={setopenFailDialog}
        setopenDialog={setopenDialog}
      />
      <BulkUploadSuccessModal
        openSucessDialog={openSucessDialog}
        handleClose={handleClose}
        dataSucessCSV={dataSucessCSV}
        setopenSucessDialog={setopenSucessDialog}
        setshowTableCSV={setshowTableCSV}
        setFileCSV={setFileCSV}
      />
    </div>
  );
};

export default BulkPayment;
