import { CircularProgress, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CustomInputField from "../SignUp/CustomInputField";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import Avatar from "react-avatar";
import {
  getCountryByCode,
  getNumberOfPages,
  isEmpty,
} from "../../utilities/help";
import AddContactModal from "./AddContactModal";

import {
  addBeneficiary,
  addTransferCountry,
  getAllMobileOperatorWithCode,
  setBeneFound,
  setStep,
  setTransferDialogOpen,
} from "../../store/features/Transfer/TransferSlice";
import { getAllMobileOperatorWithCountryCode } from "../../store/features/Transfer/Transfer";
import { extractError } from "../../utility";
import { toast } from "material-react-toastify";
import { useDispatch } from "react-redux";
import DataTable from "react-data-table-component";

const FullContactsModal = (props: any) => {
  const { open, onClose = () => {} } = props;
  const { t } = useTranslation();
  const {
    transfer,
    auth: { favorites },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useDispatch();

  const [selectedCategory, setselectedCategory] = useState("individuals");
  const [searchValue, setsearchValue] = useState("");
  const [filteredFavourites, setfilteredFavourites] = useState([]);

  // pagination
  const [paginationButtons, setPaginationButtons] = useState([1, 2, 3]);
  const allFavouritesNumberArray =
    favorites?.map((itm: any, itmInd: any) => itmInd + 1) || [];
  const [currentPage, setcurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleBack = () => {
    const numberOfPages = getNumberOfPages(
      filteredWithoutPaginatedFavourites.length,
      itemsPerPage
    );

    // enable next button in case of more than one page and on going reverse
    if (currentPage <= numberOfPages && numberOfPages > 1) {
      setisNextDisabled(false);
    } else {
      setisNextDisabled(true);
    }

    if (currentPage <= 1) {
      return;
    } else {
      setcurrentPage((prev: any) => prev - 1);
    }
  };

  const [isNextDisabled, setisNextDisabled] = useState(false);
  const handleNext = () => {
    const numberOfPages = getNumberOfPages(
      filteredWithoutPaginatedFavourites.length,
      itemsPerPage
    );

    if (currentPage >= numberOfPages) {
      setisNextDisabled(true);
      return;
    } else {
      if (currentPage + 1 == numberOfPages) {
        setisNextDisabled(true);
      } else {
        setisNextDisabled(false);
      }
      setcurrentPage((prev: any) => prev + 1);
    }
  };

  const handlePaginationAndContantSlice = (filteredArr: any) => {
    try {
      if (filteredArr.length > 0) {
        let numberPair: any = [];
        const numberOfPages = getNumberOfPages(
          filteredArr.length,
          itemsPerPage
        );

        // is pages are 2 or less
        if (numberOfPages < 3) {
          numberPair = [] = allFavouritesNumberArray.slice(0, numberOfPages);
          setPaginationButtons(numberPair);
          setfilteredFavourites(() =>
            filteredArr.slice(
              itemsPerPage * (currentPage - 1),
              itemsPerPage * currentPage
            )
          );
          return;
        }

        // for generating last 2 pages
        const isLastSecond =
          currentPage == getNumberOfPages(filteredArr.length, itemsPerPage) - 1;
        const isLastPage =
          currentPage == getNumberOfPages(filteredArr.length, itemsPerPage);

        if (isLastSecond || isLastPage) {
          numberPair = [] = allFavouritesNumberArray.slice(
            numberOfPages - 3,
            numberOfPages
          );

          setfilteredFavourites(() =>
            filteredArr.slice(
              itemsPerPage * (currentPage - 1),
              itemsPerPage * currentPage
            )
          );
          setPaginationButtons(numberPair);
        } else {
          numberPair = [] = allFavouritesNumberArray.slice(
            currentPage - 1,
            currentPage + 2
          );

          setfilteredFavourites(() =>
            filteredArr.slice(
              itemsPerPage * (currentPage - 1),
              itemsPerPage * currentPage
            )
          );
          setPaginationButtons(numberPair);
        }
      } else {
        setfilteredFavourites([]);
        setPaginationButtons([]);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // filter by catergory and search
  const [
    filteredWithoutPaginatedFavourites,
    setfilteredWithoutPaginatedFavourites,
  ] = useState([]);
  useEffect(() => {
    try {
      if (favorites && favorites.length > 0) {
        const filteredArr = favorites.filter((fav: any) => {
          if (
            fav.favorite?.is_individual &&
            selectedCategory == "individuals"
          ) {
            if (isEmpty(searchValue)) {
              return true;
            } else {
              if (
                (fav.favorite?.email &&
                  fav.favorite?.email.includes(searchValue)) ||
                (fav.favorite?.full_name &&
                  fav.favorite?.full_name?.toLowerCase()?.includes(searchValue))
              ) {
                return true;
              } else {
                return false;
              }
            }
          } else if (
            fav.favorite.company !== null &&
            selectedCategory !== "individuals"
          ) {
            if (isEmpty(searchValue)) {
              return true;
            } else {
              if (
                (fav.favorite?.email &&
                  fav.favorite?.email.includes(searchValue)) ||
                (fav.favorite?.full_name &&
                  fav.favorite?.full_name?.toLowerCase()?.includes(searchValue))
              ) {
                return true;
              } else {
                return false;
              }
            }
          } else {
            return false;
          }
        });

        handlePaginationAndContantSlice(filteredArr);
        setfilteredWithoutPaginatedFavourites(filteredArr);
      }
    } catch (error) {
      Sentry.captureException(error);
      handlePaginationAndContantSlice(favorites);
    }
  }, [favorites, selectedCategory, searchValue, currentPage]);

  const [addContactModalOpen, setaddContactModalOpen] = useState(false);

  const moveToTransfer = (user: any) => {
    // set hub accounts according to country of user(recipient)

    try {
      const beneficiary_country = getCountryByCode(
        transfer.danaPayCountries,
        user?.country_code
      );
      dispatch(addBeneficiary(user));
      dispatch(setBeneFound(true));
      dispatch(addTransferCountry(beneficiary_country));
      dispatch(setStep(0));
      dispatch(setTransferDialogOpen(!transfer.isTransferDialogOpen));
      const filterOperator: any = transfer.danaPayCountries.filter(
        (e: any) => e.country_code?.toString() === user?.country_code.toString()
      );
      getAllMobileOperatorWithCountryCode(filterOperator[0]?.code)
        .then((res: any) => {
          dispatch(getAllMobileOperatorWithCode(res));
        })
        .catch((error: any) => {
          if (error) {
            toast.error(t(extractError(error)));
          }
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const columns: any = [
    {
      maxWidth: "150px",
      cell: (row: any) => {
        return (
          <div className="capitalize text-sm">
            <Avatar
              name={row?.favorite?.full_name}
              size="55"
              round
              textSizeRatio={3}
            />
          </div>
        );
      },
    },

    {
      name: t("full_name"),
      minWidth: "172px",
      cell: (row: any) => {
        return (
          <div className="capitalize text-sm">{row?.favorite?.full_name}</div>
        );
      },
    },

    {
      name: t("Country"),
      cell: (row: any) => {
        return <div className="text-sm">{row?.favorite?.country}</div>;
      },
    },
    {
      name: t("Phone_Number"),
      minWidth: "160px",
      cell: (row: any) => {
        return (
          <div className="capitalize text-sm">
            {row?.favorite?.full_phone_number}
          </div>
        );
      },
    },
    {
      name: t("email"),
      minWidth: "160px",
      cell: (row: any) => {
        return <div className="text-sm">{row?.favorite?.email}</div>;
      },
    },
    {
      right: true,
      cell: (row: any) => {
        return (
          <button
            className="bg-black h-full w-[97px] !h-[48px] rounded-xl text-white font-bold capitalize"
            onClick={() => moveToTransfer(row?.favorite)}
          >
            {t("transfer")}
          </button>
        );
      },
    },
  ];

  const customStyles: any = {
    table: {
      style: {
        boxShadow: "0px 2px 20px 0px rgba(0,0,0,0.06)",
        paddingInline: "20px",
        paddingBottom: "16px",
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        textAlign: "start",
      },
    },
    headRow: {
      style: {
        border: "none",
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },

    rows: {
      style: {
        height: "76px", // override the row height
        color: "#696969",
        fontSize: "14px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "(0,0,0,0.1)",
        },
      },
    },
  };

  const [totalRows, setTotalRows] = useState(0);
  const handlePerRowsChange = async (newPerPage: any, page: any) => {};

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <AddContactModal
          open={addContactModalOpen}
          onClose={() => setaddContactModalOpen(false)}
        />
        <div className="w-screen h-screen overflow-y-scroll bg-white !py-5">
          {/* header */}
          <div className="flex justify-between items-center !mb-5 !px-5">
            <div className="text-xl font-bold">{t("select_a_contact")}</div>
            <button
              onClick={onClose}
              style={{ height: 40, width: 40, borderRadius: 20 }}
              className="flex items-center justify-center shadow-c"
            >
              <Close />
            </button>
          </div>

          {/* search bar */}
          <div className="flex items-center gap-4 !px-5">
            <div className="!rounded-xl h-[50px] w-full border-1 !p-3 border-[rgba(0,0,0,0.2)] flex items-center">
              <img
                className="p-1"
                src={`/images/magnifyingglass.svg`}
                style={{ height: 26, width: 26 }}
              />
              <CustomInputField
                value={searchValue}
                handleChange={(e: any) => {
                  setsearchValue(e.target.value);
                  setcurrentPage(1);
                }}
                mainContainerClasses="w-full"
                inputClasses="!rounded-xl h-full w-full pl-5 pr-5 placeholder-opacity-50"
                placeholder={t("search_for_contact")}
              />
              {!isEmpty(searchValue) && (
                <img
                  className="p-1 cursor-pointer"
                  onClick={() => {
                    setsearchValue("");
                    setcurrentPage(1);
                  }}
                  src={`/images/x.svg`}
                  style={{ height: 26, width: 26 }}
                />
              )}
            </div>
            <div
              className="p-1 flex-center flex-shrink-0 cursor-pointer bg-black text-white"
              style={{ height: 50, width: 50, borderRadius: 50 }}
              onClick={() => setaddContactModalOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="#ffffff"
                viewBox="0 0 256 256"
              >
                <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
              </svg>
            </div>
          </div>

          <div className="flex items-center gap-2 !mb-9 !mt-5 !px-5">
            <div
              className="flex-center min-h-[36px] text-sm font-medium rounded-3xl bg-[#037375] !px-3 cursor-pointer"
              onClick={() => {
                setselectedCategory("individuals");
                setcurrentPage(1);
              }}
              style={{
                background:
                  selectedCategory == "individuals" ? "#037375" : "#F5F5F5",
                color: selectedCategory == "individuals" ? "white" : "black",
              }}
            >
              {t("individuals")}
            </div>
            <div
              className="flex-center min-h-[36px] text-sm font-medium bg-[#F5F5F5] rounded-3xl !px-3 cursor-pointer"
              onClick={() => {
                setselectedCategory("businesses");
                setcurrentPage(1);
              }}
              style={{
                background:
                  selectedCategory == "businesses" ? "#037375" : "#F5F5F5",
                color: selectedCategory == "businesses" ? "white" : "black",
              }}
            >
              {t("businesses")}
            </div>
          </div>

          {/* table */}
          <div className="tableWrapper">
            <DataTable
              columns={columns}
              data={filteredWithoutPaginatedFavourites}
              pagination
              progressComponent={
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ height: 300 }}
                >
                  <CircularProgress size={30} />
                </div>
              }
              pointerOnHover={true}
              customStyles={customStyles}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

export default FullContactsModal;
