import moment from "moment";
import React from "react";
import CashLayout from "../CashLayout";
import * as Sentry from "@sentry/browser";
import StatusButtons from "../StatusButtons";
import { useAppSelector } from "../../store/hooks";
import OperationIcon from "./OperationIcon";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { convertUTCDateToLocalDate } from "../../utilities/help";

const statuses: any = {
  Completed: {
    bg: "rgba(52,224,0,0.10)",
    color: "#14AD00",
  },
  transfer_completed: {
    bg: "rgba(52,224,0,0.10)",
    color: "#14AD00",
  },
  Reçu: {
    bg: "rgba(52,224,0,0.10)",
    color: "#14AD00",
  },
  "In Progress": {
    bg: "rgba(220,153,9,0.10)",
    color: "#DC9909",
  },
  transfer_in_progress: {
    bg: "rgba(220,153,9,0.10)",
    color: "#DC9909",
  },
  "En cours": {
    bg: "rgba(220,153,9,0.10)",
    color: "#DC9909",
  },
  "Action Required": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  "Action requise": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  "Payment aborted": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  "Paiement annulé": {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  Failed: {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  transfer_failed: {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
  Échoué: {
    bg: "rgba(219,47,9,0.10)",
    color: "#DB3A09",
  },
};

const CustomRepeatBtn = (props: any) => {
  const { t } = useTranslation();
  const { onClick, cancelPayOut, canceling, canCancel } = props;

  return (
    <div className="flex justify-between 1 w-full gap-4">
      <button
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        className={"btn flex-center h-[48px] w-full bg-black rounded-xl"}
      >
        <div className="font-bold flex gap-2 items-center text-white text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="#ffff"
            viewBox="0 0 256 256"
          >
            <path d="M88,104H40a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V76.69L62.63,62.06A95.43,95.43,0,0,1,130,33.94h.53a95.36,95.36,0,0,1,67.07,27.33,8,8,0,0,1-11.18,11.44,79.52,79.52,0,0,0-55.89-22.77h-.45A79.56,79.56,0,0,0,73.94,73.37L59.31,88H88a8,8,0,0,1,0,16Zm128,48H168a8,8,0,0,0,0,16h28.69l-14.63,14.63a79.56,79.56,0,0,1-56.13,23.43h-.45a79.52,79.52,0,0,1-55.89-22.77,8,8,0,1,0-11.18,11.44,95.36,95.36,0,0,0,67.07,27.33H126a95.43,95.43,0,0,0,67.36-28.12L208,179.31V208a8,8,0,0,0,16,0V160A8,8,0,0,0,216,152Z"></path>
          </svg>
          {t("Repeat")}
        </div>
      </button>

      {canCancel && (
        <button
          disabled={canceling}
          onClick={() => {
            if (cancelPayOut && !canceling) {
              cancelPayOut();
            }
          }}
          className={"btn flex-center h-[52px] w-full bg-[#DB1818] rounded-xl"}
        >
          {canceling ? (
            <CircularProgress className="text-white" size={20} />
          ) : (
            <div className="font-bold text-white text-sm">{t("cancel")}</div>
          )}
        </button>
      )}
    </div>
  );
};

const OperationType = (props: any) => {
  const { operation_type } = props;
  const { t } = useTranslation();
  if (
    operation_type == "direct_transfer" ||
    operation_type == "instant_transfer"
  ) {
    return (
      <div className="bg-[rgba(206,208,206,0.1)] !p-3 rounded-[15px] w-max text-[#333432] font-semibold text-xs">
        {t("transfer")}
      </div>
    );
  } else if (operation_type == "deposit") {
    return (
      <div className="bg-[rgba(206,208,206,0.1)] p-2 rounded-xl w-max text-[#333432] font-semibold text-xs">
        {t("deposit")}
      </div>
    );
  } else if (operation_type == "withdraw") {
    return (
      <div className="bg-[rgba(206,208,206,0.1)] p-2 rounded-xl w-max text-[#333432] font-semibold text-xs">
        {t("withdraw")}
      </div>
    );
  } else {
    return (
      <div className="bg-[rgba(206,208,206,0.1)] p-2 rounded-xl w-max text-[#333432] font-semibold text-xs">
        {t("transfer")}
      </div>
    );
  }
};

const WebList: React.FC<any> = ({
  transaction,
  OpenModal,
  t,
  user,
  last = false,
}) => {
  const { transfer } = useAppSelector((state) => state.persistedReducer);
  const {
    operation_type,
    amount_without_fee,
    status,
    source_user,
    destination_user,
    exchange_rate_applied,
    logged_in_transactor,
    aborted,
    created_at,
  } = transaction;
  const isBulk: boolean = operation_type === "bulk_transfer";
  const isDemanded: boolean = operation_type === "payment_demand";
  const isActiveSender: boolean = user?.id === source_user?.id;
  const cash = isBulk
    ? transaction.transfer?.bulk_transfer_recipient_details.reduce(
        (sum: number, user: any) => sum + parseFloat(user.amount_in_euros),
        0
      )
    : transaction?.transfer?.amount_without_fee === undefined
    ? amount_without_fee
    : transaction?.transfer?.amount_without_fee;

  const returnUser = () => {
    try {
      if (isBulk) {
        if (source_user.id === user.id) {
          return {
            full_name:
              transaction?.transfer?.bulk_transfer_recipient_details.length +
              " Beneficiaries",
            country:
              transaction?.transfer?.bulk_transfer_recipient_details[0].country,
          };
        } else {
          return source_user;
        }
      } else {
        if (isDemanded) {
          if (isActiveSender) {
            return destination_user;
          } else {
            return source_user;
          }
        } else {
          if (operation_type === "pay_out" || operation_type === "deposit") {
            return destination_user;
          } else if (operation_type === "direct_transfer") {
            return destination_user === null
              ? transaction?.transfer?.destination_users[0]
              : destination_user;
          } else {
            if (isActiveSender) {
              return destination_user;
            } else {
              return source_user;
            }
          }
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <tr
      className={`bg-white ${
        last ? "" : "border-b"
      } text-[#696969] text-sm h-[76px] !p-4`}
    >
      <td
        scope="row"
        className=" font-medium text-gray-900 whitespace-nowrap !pl-4"
        onClick={() => OpenModal(transaction)}
      >
        <OperationIcon
          transaction={transaction}
          operation_type={operation_type}
          isActiveSender={isActiveSender}
        />
      </td>

      <td onClick={() => OpenModal(transaction)}>
        <div className="capitalize text-sm text-center text-[#696969]">
          <OperationType operation_type={operation_type} />
        </div>
      </td>
      <td style={{ width: "12%" }} onClick={() => OpenModal(transaction)}>
        <div className="capitalize text-sm text-center text-[#696969]">
          {transaction?.logged_in_transactor === "receiver" &&
          transaction?.operation_type !== "withdraw"
            ? transaction?.done_at
              ? moment(
                  convertUTCDateToLocalDate(new Date(transaction?.updated_at))
                ).format("DD/MM/YYYY   H:mm:ss")
              : "-"
            : created_at
            ? moment(
                convertUTCDateToLocalDate(new Date(transaction?.created_at))
              ).format("DD/MM/YYYY   H:mm:ss")
            : "-"}
        </div>
      </td>

      <td style={{ width: "15%" }} onClick={() => OpenModal(transaction)}>
        <div className="capitalize text-sm text-center text-[#696969]">
          {returnUser()?.country}
        </div>
      </td>

      <td style={{ width: "10%" }} onClick={() => OpenModal(transaction)}>
        <div className="capitalize text-sm text-center text-[#696969]">
          {transaction?.contact}
          {/* {logged_in_transactor === "receiver" &&
          (operation_type === "direct_transfer" ||
            operation_type === "instant_transfer")
            ? transaction?.source_user?.full_name?.trim().toLowerCase()
            : returnUser()?.full_name?.trim().toLowerCase() ||
              returnUser()?.full_phone_number} */}
        </div>
      </td>

      <td
        className="text-center"
        style={{ width: "10%" }}
        onClick={() => OpenModal(transaction)}
      >
        <CashLayout cash={cash} exchange_rate={exchange_rate_applied} />
      </td>

      <td onClick={() => OpenModal(transaction)}>
        <div
          className="h-[30px] text-center mx-auto w-max !px-5 rounded-xl flex-center font-semibold text-xs"
          style={{
            backgroundColor:
              aborted == true
                ? statuses["Payment aborted"]?.bg
                : transaction?.transfer?.status == "suspicious"
                ? statuses["Action Required"]?.bg
                : statuses[status]?.bg,
            color:
              aborted == true
                ? statuses["Payment aborted"]?.color
                : transaction?.transfer?.status == "suspicious"
                ? statuses["Action Required"]?.color
                : statuses[status]?.color,
          }}
        >
          {aborted == true
            ? t("aborted")
            : status === "suspicious"
            ? t("suspicious")
            : transaction?.transfer?.status == "suspicious"
            ? t("action_required")
            : t(status)}
        </div>
      </td>

      <td>
        <StatusButtons
          transfer={transaction}
          transcationsObject={transfer}
          showCancelBtn={false}
          customMainClasses="btn-group flex items-center w-max mx-auto"
        >
          {(
            repeat: any,
            cancelPayOut: any,
            showCancel: any,
            canceling: any
          ) => (
            <CustomRepeatBtn
              onClick={repeat}
              cancelPayOut={cancelPayOut}
              canceling={canceling}
            />
          )}
        </StatusButtons>
      </td>
    </tr>
  );
};

export default WebList;
