import React from "react";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setSelected } from "../../store/features/Transfer/TransferSlice";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import MobileList from "./MobileList";
import useResize from "../../hooks/useResize";
import WebList from "./WebList";

// import moment
interface Props {
  transactions: any;
}

const TransactionListItem: React.FC<Props> = ({ transactions }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dimensions = useResize();
  const {
    auth: { user },
  } = useAppSelector((state) => state.persistedReducer);

  const OpenModal = (transaction: any) => {
    dispatch(setSelected(transaction));
  };

  return (
    <table className="w-full text-left rounded-xl overflow-hidden table-auto">
      <thead className="text-[#696969] block !h-0">
        <tr>
          {dimensions[0] < 1070 ? (
            <th scope="col" className={"w-[80%]"}></th>
          ) : (
            <>
              <th scope="col" className={"w-[5%]"}></th>
              <th scope="col" className="!pb-4 w-[6%]"></th>
              <th scope="col" className="!pb-4 w-[10%]"></th>
              <th scope="col" className="!pb-4 w-[5%]"></th>
              <th scope="col" className="!pb-4 w-[10%]"></th>
              <th scope="col" className="!pb-4 w-[7%]"></th>
              <th scope="col" className="!pb-4 w-[10%]"></th>
              <th scope="col" className="!pb-4 w-[5%]"></th>
            </>
          )}
        </tr>
      </thead>
      <tbody className="">
        {transactions?.map((transaction: any, index: number) => (
          <>
            {dimensions[0] < 1070 ? (
              <MobileList
                transaction={transaction}
                last={index == transactions?.length - 1}
                t={t}
                OpenModal={OpenModal}
                user={user}
                key={index}
              />
            ) : (
              <WebList
                transaction={transaction}
                last={index == transactions?.length - 1}
                t={t}
                OpenModal={OpenModal}
                user={user}
                key={index}
              />
            )}
          </>
        ))}
      </tbody>
    </table>
  );
};

export default TransactionListItem;
