import { useTranslation } from "react-i18next";
import useResize from "../hooks/useResize";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useHistory } from "react-router-dom";
import {
  setActiveMenu,
  setSideBarCollapsed,
} from "../store/features/Auth/AuthSlice";
import { useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { Tooltip } from "@material-ui/core";
import { useIntercom } from "react-use-intercom";

const menus = [
  {
    name: "menuHome",
    path: "home",
    svgPath: "/images/home.svg",
  },
  {
    name: "menuHistory",
    path: "history",
    svgPath: "/images/book-open.svg",
  },
  {
    name: "profile",
    path: "profile",
    svgPath: "/images/user.svg",
  },
  // {
  //   name: "sponsorship",
  //   path: "",
  //   svgPath: "/images/gift.svg",
  // },
  {
    name: "expand",
    svgPath: "/images/menu.svg",
  },
];

const bottomMenu = [
  {
    name: "help",
    path: "/help",
    svgPath: "/images/question.svg",
  },
];

const SideBarv3 = (props: any) => {
  const {} = props;
  const { t } = useTranslation();
  const dimensions = useResize();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { show } = useIntercom();

  useEffect(() => {
    try {
      window.Intercom &&
        window.Intercom("update", { hide_default_launcher: true });
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const {
    auth: { sideBarCollapsed },
  } = useAppSelector((state) => state.persistedReducer);

  const dynamicWidth = useMemo(() => {
    try {
      if (dimensions[0] < 1070 || sideBarCollapsed) {
        if (dimensions[0] < 900) {
          return "w-[50px]";
        } else {
          return "w-[5%]";
        }
      } else {
        return "w-[20%]";
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [dimensions, sideBarCollapsed]);

  // set sidebar state as user's preference
  useEffect(() => {
    try {
      if (
        window.localStorage.getItem("sideBarCollapsed") == "true" &&
        !sideBarCollapsed &&
        dimensions[0] > 1070
      ) {
        dispatch(setSideBarCollapsed(true));
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [dimensions, window.localStorage.getItem("sideBarCollapsed")]);

  return (
    <div
      className={`SideBarv3 z-20 fixed h-screen flex flex-col bg-white !px-3 !pt-8 pb-16 shadow-c z-40 ${dynamicWidth}`}
    >
      {dimensions[0] > 1070 && !sideBarCollapsed && (
        <div className="flex mb-10 items-center justify-between">
          <img
            src="/images/logofull.png"
            style={{ height: 34, width: 143 }}
            className=" w-min"
          />
          <svg
            className="cursor-pointer"
            onClick={() => {
              dispatch(setSideBarCollapsed(true));
              window.localStorage.setItem("sideBarCollapsed", "true");
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="25"
            fill="#012938"
            viewBox="0 0 256 256"
          >
            <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM40,56H80V200H40ZM216,200H96V56H216V200Z"></path>
          </svg>
        </div>
      )}
      {(dimensions[0] < 1070 || sideBarCollapsed) && (
        <img
          src="/images/logo.png"
          style={{ height: 34, width: 34 }}
          className="mx-auto mb-10 w-min"
        />
      )}
      <div>
        {menus.map((menu: any, index: number) => (
          <Tooltip
            title={dimensions[0] < 1070 || sideBarCollapsed ? t(menu.name) : ""}
            placement="right"
          >
            <div
              onClick={() => {
                if (index == 3) {
                  dispatch(setSideBarCollapsed(false));
                  window.localStorage.setItem("sideBarCollapsed", "false");
                  return;
                } else {
                  dispatch(setActiveMenu(menu.path));
                  history.push(`/dashboard/${menu.path}`);
                }
              }}
              key={index}
              className={`h-[52px] cursor-pointer flex flex-shrink-0 items-center rounded-xl ${
                dimensions[0] > 1070 && !sideBarCollapsed ? "!px-3" : ""
              } font-semibold ${
                window.location.pathname.includes(menu.path)
                  ? "bg-[rgba(250,250,250,1)] text-[#037375]"
                  : "text-[#000000] opacity-50"
              }`}
              style={{
                visibility:
                  (index == 3 && (sideBarCollapsed || dimensions[0] < 1070)) ||
                  index != 3
                    ? "visible"
                    : "hidden",
              }}
            >
              {dimensions[0] > 1070 && !sideBarCollapsed ? (
                <>
                  <img
                    src={menu.svgPath}
                    style={{
                      height: 14,
                      width: 14,
                    }}
                    className="w-min mr-2"
                  />
                  <div className="text-sm">{t(menu.name)}</div>
                </>
              ) : (
                <img
                  src={menu.svgPath}
                  style={{
                    height: 18,
                    width: 18,
                  }}
                  className="w-min mx-auto"
                />
              )}
            </div>
          </Tooltip>
        ))}
      </div>

      {/* bottom menu */}
      <div className="mt-auto">
        {bottomMenu.map((menu: any, index: number) => (
          <Tooltip
            title={dimensions[0] < 1070 || sideBarCollapsed ? t(menu.name) : ""}
            placement="right"
          >
            <div
              key={index}
              onClick={() => show()}
              className={`h-[52px] cursor-pointer flex flex-shrink-0 items-center rounded-xl ${
                dimensions[0] > 1070 && !sideBarCollapsed ? "!px-3" : ""
              } font-semibold ${
                window.location.pathname.includes(menu.path)
                  ? "bg-[rgba(250,250,250,1)] text-[#037375]"
                  : "text-[#000000] opacity-50"
              }`}
            >
              {dimensions[0] > 1070 && !sideBarCollapsed ? (
                <>
                  <img
                    src={menu.svgPath}
                    style={{
                      height: 14,
                      width: 14,
                    }}
                    className="w-min mr-2"
                  />
                  <div className="text-sm">{t(menu.name)}</div>
                </>
              ) : (
                <img
                  src={menu.svgPath}
                  style={{
                    height: 18,
                    width: 18,
                  }}
                  className="w-min mx-auto"
                />
              )}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default SideBarv3;
