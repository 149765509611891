import * as Sentry from "@sentry/browser";
import CustomButton from "../CustomButton";
const DepositBankSelector = (props: any) => {
  const {
    selectedBankState,
    setselectedBankState,
    setSelectedBank,
    dana_pay_bank_accounts,
    t,
    setNextStep,
    setActiveStep,
    full_name,
    full_phone_number,
    email,
    country,
    selectedBank,
  } = props;
  return (
    <div className="p-4 max-sm:mt-20 rounded-xl shadow-c my-3 mx-auto max-w-[800px]">
      <div className="mb-4 text-center">
        <h3 className="text-2xl font-bold">{t("payment_instruction")}</h3>
        <p>{t("pi_text")}</p>
      </div>
      <div className="bacnkCheckBoxWrapper p-2 flex flex-col gap-2">
        {selectedBankState &&
          selectedBankState.length > 0 &&
          selectedBankState.map((bank: any, index: number) => {
            return (
              <div
                className="wrapperBankCheckBox innerBankBox m-0"
                key={"depositbankselector" + index}
              >
                <div className="wrapperBankCheckBoxwrapper">
                  <input
                    type="checkbox"
                    className="default:ring-2"
                    onChange={(e: any) => {
                      try {
                        setSelectedBank(dana_pay_bank_accounts[index]);
                        setselectedBankState((prev: any) =>
                          prev.map((itm: any, ind: any) => {
                            if (ind == index) {
                              return { ...itm, checked: true };
                            } else {
                              return { ...itm, checked: false };
                            }
                          })
                        );
                      } catch (error: any) {
                        Sentry.captureException(error);
                      }
                    }}
                    name={bank.bank_name}
                    checked={bank.checked}
                  />
                  <span className="checkmark"></span>
                </div>
                <label>{bank.bank_name}</label>
              </div>
            );
          })}
      </div>

      <CustomButton
        classNames="min-h-[48px] w-full flex-1 mt-3 rounded-xl text-white capitalize text-sm font-bold bg-black"
        data-deposit-initiation="initiation"
        label="continue"
        onClick={() => {
          setNextStep("bank");
          setActiveStep(3);
          window.heap.track("Deposit payment successfully initiated", {
            name: full_name,
            email,
            phone: full_phone_number,
            country,
          });
        }}
        disabled={selectedBank === null}
      ></CustomButton>
    </div>
  );
};

export default DepositBankSelector;
