import { useDispatch } from "react-redux";
import CountryPicker from "../CountryPicker";
import {
  updateBulkTData,
  updateUsersForBulkyTransfer,
} from "../../store/features/Transfer/TransferSlice";
import { MyComponent } from "../TransactionsCard/CsvTable";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";

const SelectBeneficiaries = (props: any) => {
  const {
    transfer,
    fileCSV,
    showTableCSV,
    setFileCSV,
    handelClickCsv,
    submitRequest,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className="items-center justify-center w-full h-full">
      <div className="mt-4 row">
        <div className="py-2 col-md-12 col-sm-12 col-xs-12">
          <div className="flex flex-col items-center justify-center m-auto bg-white rounded-lg">
            <div className="w-full my-2 ">
              <div className="row">
                <div className="col-md-4 mx-auto">
                  <h2 className="mb-2 text-2xl font-bold text-center">
                    {t("Select_Beneficiaries")}
                  </h2>

                  <CountryPicker
                    setCountry={(text: any) => {
                      dispatch(updateBulkTData({ country: text.name }));

                      // clear bulk users when country change
                      dispatch(updateUsersForBulkyTransfer(null));
                    }}
                    country={
                      transfer?.usersForBulkyTransfer?.length > 0
                        ? transfer?.usersForBulkyTransfer[0]?.country
                        : transfer?.bulkTransferData?.country
                    }
                    onChange={null}
                    value={""}
                    cash_Out_Method_Name={""}
                    withPhoneInput={false}
                    setSelectedCountry={(e: any) => {}}
                  />
                  <p className="mb-3 text-center ">
                    {t("Beneficiary_selection")}
                  </p>
                  <br />
                  <br />
                </div>
              </div>
              <div className="col-md-10 mx-auto ">
                <div className="flex items-center justify-center">
                  <div className="flex flex-start"></div>
                  <button
                    className={`btn   bg-blue-400
           ${
             // size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2 "
             "rounded-lg"
           }`}
                    onClick={() => {
                      handelClickCsv();
                    }}
                    style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      border: "1px solid #d1d1d1",
                    }}
                  >
                    <i className="fa fa-upload text-black" />
                    <b className="font-bold text-black">
                      <small style={{ fontSize: 12 }}>{t("Upload_csv")}</small>
                    </b>
                  </button>
                </div>
              </div>
              <div className="my-10 row">
                <div className="col-md-11 mx-auto ">
                  {showTableCSV && (
                    <MyComponent data={fileCSV} setCsvData={setFileCSV} />
                  )}
                  <table className="table"></table>
                </div>
              </div>
            </div>

            {/* continue button */}

            <CustomButton
              classNames="w-[200px] max-sm:w-full min-h-[48px] rounded-xl text-white capitalize text-sm font-bold bg-black"
              label="continue"
              onClick={() => {
                const blank = Object.values(
                  transfer?.usersForBulkyTransfer
                ).filter(
                  (item: any) =>
                    !item?.hasOwnProperty("amount_in_euros") ||
                    item?.amount_in_euros == ""
                );
                if (blank?.length > 0) {
                  toast.error(t("Please_enter_amount"));
                } else {
                  submitRequest(false);
                }
              }}
              disabled={
                fileCSV?.data === undefined
                  ? (fileCSV?.length > 0 &&
                      transfer?.usersForBulkyTransfer[0]?.country !== "") ||
                    (fileCSV?.length > 0 &&
                      transfer.bulkTransferData.country !== "")
                    ? false
                    : true
                  : fileCSV?.data?.length > 0 &&
                    transfer.bulkTransferData.country !== ""
                  ? false
                  : true
              }
              id="bulk-payment-details"
            />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectBeneficiaries;
