import { Modal } from "@material-ui/core";
import SelectField from "../forms/SelectField";
import CustomButton from "../CustomButton";

const DownloadCSVModal = (props: any) => {
  const { open, t, setData, value, onClick, downloading, onCancel } = props;
  return (
    <Modal open={open}>
      <div className="row flex justify-center">
        <div className="col-md-6 col-lg-4 col-10 px-4 py-10 my-20 bg-white rounded-md shadow-lg">
          <h2 className="mb-2 text-xl font-bold text-gray-700">
            {t("downloading_transaction")}
          </h2>
          <p className="text-sm">{t("download_csv_subtitle")}</p>

          <div className="">
            <div className="my-2 p-2 mb-2 bg-green-50 text-sm font-semibold text-green-900 text-[11px]">
              {t("csv_format_text")}
            </div>

            <SelectField
              data={["default", "odoo", "saari"].map((val) => {
                return { type: val, name: val };
              })}
              setData={(text: any) => setData(text)}
              value={value}
              width
            />
          </div>

          <div className="flex gap-3 justify-between items-center max-sm:flex-col mt-3">
            <CustomButton label="cancel" onClick={onCancel} />
            <CustomButton
              classNames="min-h-[48px] flex-1 w-full rounded-xl text-white capitalize text-sm font-bold bg-black"
              label={downloading ? t("downloading") : t("download_CSV")}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadCSVModal;
