export const TRANSLATIONS_FR = {
  welcome: "Bienvenue sur Danapay!",
  version: "Version",
  thanks_for_application:
    "Merci! Nous traiterons votre inscription et vous recevrez un e-mail de confirmation dans les 24h.",
  linkExpired: "Le lien de vérification de votre compte a expiré.",
  serviceWarning:
    "Notre service est actuellement interrompu. Veuillez contacter le support.",
  frequired: "Veuillez remplir les champs requis.",
  emailExists: "Cet e-mail existe déjà. Veuillez vous connecter.",
  accountActivation:
    "Votre compte est en cours d'activation. Cela prendra 24 heures maximum. Veuillez vous reconnecter dès que vous recevez l'e-mail de confirmation.",
  sendRange: "Vous pouvez envoyer entre X et Y.",
  escrowText:
    "Le paiement avec séquestre apporte plus de confiance lors de vos opérations. Vos fonds seront bloqués et votre destinataire pourra voir qu'ils sont disponibles mais, il ne pourra pas les retirer tant qu'il n'aura envoyé le produit ou réalisé la prestation'. Vous ne pourrez pas non plus les retirer tant que votre destinataire n'aura pas accepté le remboursement.",
  instantText: "Réception des fonds en 24/48h.",
  payConfirmation:
    "Paiement accepté. Nous allons maintenant exécuter votre transfert.",
  payCancelled:
    "Paiement annulé ou refusé. Si vous ne l'avez pas annulé, veuillez essayer avec un autre compte bancaire.",
  adminErrorText: "Une erreur s'est produite, veuillez contacter le support.",
  forgotPass: "Mot de passe oublié",
  fptext: "Saisissez l'email que vous avez utilisé lors de votre inscription",
  termsText:
    "En cliquant sur Soumettre, vous acceptez notre politique de confidentialité applicable au traitement de vos données personnelles.",
  sutitile: "Inscrivez-vous à Danapay!",
  suSubtitle:
    "Inscrivez-vous et découvrez la nouvelle expérience de paiement local et international",
  email: "Adresse e-mail",
  password: "Mot de passe",
  changePass: "changez votre mot de passe",
  changePassSub:
    "choisissez un nouveau mot de passe pour sécuriser votre compte",
  confirmPass: "Confirmez le mot de passe",
  newPass: "Nouveau mot de passe",
  oldPass: "Ancien mot de passe",
  register: "M'inscrire",
  Change_Password: "Changer le mot de passe",
  ftHelp: "Aide",
  ftTerms: "Conditions générales",
  ftPolicy: "Politique de confidentialité",
  loginText: "Connectez-vous",
  login: "connecter",
  start_Transfer: "Envoyer de l'argent",
  beneficiary: "Bénéficiaire",
  amount: "Montant",
  payment: "Paiement",
  confirmation: "Confirmation",
  close: "Fermer",
  back: "Retour",
  Back: "Retour",
  next: "Suivant",
  fees: "Frais",
  TransferFees: "Frais de transfert",
  WithdrwalFees: "Frais de retrait",
  exchange_rate: "Taux de change",
  Payout_mode: "Mode de retrait",
  transaction_type: "Type de transaction",
  instant_payments: "Transfert",
  escrow_payment: "Paiement avec séquestre",
  escrowed: "Mise en séquestre",
  enterPerson: "Entrez vos informations personnelles",
  validPhone: "Un numéro de téléphone valide est requis.",
  finish: "Finaliser",
  coInfo: "Saisissez les informations de votre entreprise",
  docsTitle: "Documents de l'entreprise",
  docsReason:
    "Nous avons besoin de ces documents pour des raisons de conformité. Ils vous sont demandés une seule fois lors de votre inscription.",
  docsDrag:
    "Glissez et déposez vos fichiers ici, ou cliquez pour sélectionner des fichiers",
  docsDropping: "Déposez les fichiers ici...",
  skip: "Passer",
  continue: "Continuez",
  total: "Total",
  totalSent: "Total envoyé",
  action: "Actions",
  date: "Date",
  photo: "Photo",
  status: "Statut",
  instant: "Transfert",
  escrow: "Paiements avec séquestre",
  home: "Accueil",
  developer: "Développeur",
  eur_amount: "Montant en EUR",
  cfa_amount: "Montant en CFA",
  payOutMode: "Mode de paiement",
  transactionType: "Type de transaction",
  wellDone: "Bien joué!",
  successMessage:
    "Votre paiement a été pris en compte. Dès que nous aurons reçu les fonds, vous en serez informé et nous exécuterons le transfert",
  backHome: "Retour à l'accueil",
  successFooter: "Créé avec amour par Danapay pour une meilleure Afrique!",
  createApp: "Créer une nouvelle application",
  appId: "App Id",
  appIcon: "App Icon",
  apiKey: "API Key",
  sandBox: "Sand Box",
  production: "Production",
  AppGenInfo: "Informations générales sur l'application",
  appName: "Nom de l'application",
  application: "Description de l'application",
  general: "Général",
  name: "Nom",
  Email: "E-mail",
  companyNumber: "Numéro de l'entreprise",
  Verifying_your_Email: "Vérification de votre e-mail",
  Please_wait: "Veuillez patienter s'il vous plais",
  email_validated: "Votre e-mail a été vérifié",
  login_here: "Connectez-vous ici",
  EMail_Verification: "Vérification de l'e-mail",
  sent_code: "Nous vous avons envoyé un e-mail de confirmation",
  click_to_continue:
    "Cliquez sur le lien reçu pour continuer votre inscription",
  Send_New_Link: "Envoyer un nouveau lien",
  Email_Address: "Adresse e-mail",
  FirstName: "Prénom",
  LastName: "Nom de famille",
  OtherActivities: "Autres activités",
  CompanyName: "Nom de la compagnie",
  RegistrationID: "Numéro d'immatriculation",
  AddressInformation: "Adresse",
  Country: "Pays",
  City: "Ville",
  IBAN: "IBAN",
  Register: "S'inscrire",
  link_expired: "Ce lien de vérification a expiré",
  askNewLink:
    "Demandez un nouveau lien pour continuer votre inscription ou recommencez.",
  RequestLink: "Demander un lien",
  acceptTerms:
    "En cliquant sur Soumettre, vous acceptez notre Politique de confidentialité applicable au traitement de vos données personnelles.",
  AddApplication: "Ajouter une application",
  Date_Created: "Date de création",
  newApp: "Créer une nouvelle application.",
  SandBox: "Sand Box",
  App_General_Information: "Informations générales sur l'application",
  Application_Name: "Nom de l'application",
  Application_Description: "Description de l'application",
  Market_Place: "Marché",
  Market_Place_Desc: "Vos vendeurs seront payés par Danapay",
  Merchant: "Marchand (à venir)",
  Merchant_Desc: "Vos vendeurs seront payés par Danapay",
  API_Scope: "Périmètre de l'API ",
  Configuration: "Configuration",
  Redirect_URL: "URL de redirection",
  Webhook: "Webhook",
  Creating_App: "Création d'une application",
  AppInfoDesc:
    "Les app_secret et app_private_key doivent être stockés en toute sécurité dans votre environnement backend, c'est-à-dire ni dans un bundle côté client ni dans une application front-end.",
  AppInfo: "Informations sur l'application",
  App_Secret: "App Secret",
  Download_Private_Key: "Télécharger la clé privée",
  App_Icon: "Icône de l'application",
  App_Name: "Nom de l'application",
  Api_Key: "Api Key",
  App_ID: "App_ID",
  Amount_received_CFA: "Montant en CFA",
  Amount_received_EUR: "Montant en EUR",
  Processing: "Traitement en cours",
  fetchingTransfer: "Récupération de la transaction",
  Repeating_Transfer: "Répéter le transfert",
  Back_To_website: "Retourner vers le site marchand",
  Available_outstanding_balance: "Solde disponible ",
  Withdraw_All: "Tout retirer",
  expectAmount: "le montant saisi doit être entre 1 et ",
  Add_New_Account: "Ajouter un nouveau compte",
  Bank_Name: "Nom de banque",
  Owner: "Propriétaire",
  owner_tag: "titulaire",
  sendingOTP: "Nous enverrons un code secret à",
  Send_and_Continue: "Envoyer et continuer",
  sentOTP: "Nous vous avons envoyé un code secret à",
  enterOTP: "Entrez-le ci-dessous pour confirmer le retrait",
  EnterOTP: "Saisissez le code secret",
  ConfirmCashout: "Confirmer le retrait",
  onTheWay: "En cours...",
  onTheWayDesc:
    "Votre retrait a été pris en compte et est en cours de traitement. Vous serez informé dès que nous aurons finalisé la transaction.",
  Havegreatday: "Passez une bonne journée!",
  GOBACK: "Retour à l'accueil",
  Title: "Titre",
  OwnerName: "Nom titulaire",
  Add: "Ajouter",
  CompleteTransfer: "Transfert exécuté",
  WellDone: "Bien joué!",
  WellDoneDesc: "Effectuer un dépôt sur notre compte à la Banque d'Afrique",
  enterPhoneNumber: " Entrez le numéro de téléphone du bénéficiaire",
  beneInfo: "Veuillez saisir les informations sur le bénéficiaire.",
  AllTransactions: "Toutes vos transactions",
  Source: "Origine",
  Actions: "Actions",
  Status: "Statut",
  Amount: "Montant",
  Date: "Date",
  Name: "Nom",
  Photo: "Photo",
  Type: "Type",
  RefreshingList: "Mise à jour...",
  FetchingPayment: "Récupération des paiements",
  Release: "Débloquer et transférer",
  AskRefund: "Demander un remboursement",
  Repeat: "Répéter",
  RevokeRelease: "Annuler le déblocage",
  Pay: "Payer",
  Refund: "Rembourser",
  RevokeRefund: "Annuler le remboursement",
  Sender: "Expéditeur",
  CompanyRegistrationNo: "Numéro d'enregistrement.",
  Receiver: "Bénéficiaire",
  Details: "Détails",
  Payment_method: "Mode de paiement",
  Sent_amount: "Montant envoyé",
  Fees: "Frais",
  Exchange_rate: "Taux de change",
  Received_amount: "Montant reçu",
  Total_Received: "Total reçu",
  AskRelease: "Demander le déblocage",
  InstantPayment: "Transfert",
  Escrowed_Payments: "Paiements avec séquestre",
  Withdraws: "Effectuer un retrait",
  StartTransfer: "Envoyer de l'argent",
  Enter_Withdraw_details: "Effectuez un retrait",
  Information: "Information",
  schedule_meeting: "Prendre rendez-vous",
  // InformationDesc:
  //   "Votre entreprise est actuellement inactive. Veuillez contacter le support pour plus de détails.",
  InformationDesc: `Si vous avez besoin d’aide, nous sommes disponibles sur le chat ou bien sur `,
  Address: "Pays",
  Event: "Événement",
  event_placeholder: "Veuillez entrer le nom de votre événement",
  event_error: `Le nom de l'événement est obligatoire`,
  NotReceiver:
    "Veuillez contacter le support si ce n'est pas votre destinataire",
  BankDetails: "Coordonnées bancaires",
  Confirmation: "Confirmation",
  Complete: "Executer",
  TransactionDetails: "Détails de la transaction",
  sentOtp:
    "Nous avons envoyé un code secret sur votre numéro de téléphone mobile.",
  sentOtpHeader: "Vérifier le numéro de téléphone",
  // bankDetailsTitle: "Paiement pris en compte !",
  bankDetailsTitle: "Il est temps d'effectuer votre transfert",
  bankDetailsSubTitle:
    "Effectuer un dépôt sur notre compte à la Banque d'Afrique",
  bankDetailsSubDescription:
    "Maintenant, pour finaliser le dépôt sur votre compte Danapay, veuillez vous rendre dans une agence de la Bank Of Africa et effectuer un dépôt en espèces. Ou, si vous avez une banque en ligne, connectez-vous à votre compte bancaire en ligne, localisez le menu de transfert, et entrez les informations ci-dessous.",
  bd_reference: "Référence",
  complete_payment: "Paiement",
  validate_refund: "Valider le remboursement",
  Revoke_Refund: "Révoquer le remboursement",
  Validate_Release: "Valider le lancement",
  processing: "Traitement...",
  uploading: " Téléchargement...",
  file_upload: " Fichier téléchargé.",
  with_0_balance: "Il n'est pas possible de retirer avec un solde à 0.",
  cashout_confirm:
    "Cliquez pour sélectionner le compte sur lequel vous voulez envoyer l'argent.",
  delete: "Supprimer",
  title: "Titre",
  Instant: "Paiement instantané",
  Escrow: "Paiement avec séquestre",
  PaymentMode: "Mode de paiement",
  WithdrawMode: "Mode de retrait",
  BeneficiaryWith72Hours:
    "Votre bénéficiaire devrait recevoir les fonds dans les 72 heures",
  BeneficiaryWith1Hours:
    "Votre bénéficiaire recevra les fonds sur son compte Danapay dans les 2 prochaines minutes suivant la réception de votre paiement.",
  BeneficiaryWith1HoursTwo:
    "Il devra faire une demande de retrait depuis l’application ou en contactant le support.",
  // BeneficiaryWith1Hours: "Votre bénéficiaire devrait recevoir les fonds dans un délai d’une heure.",
  PleaseSpecifyYourRecipient:
    "Veuillez préciser les coordonnées bancaires de votre destinataire. Il doit être le propriétaire unique et la banque doit être située dans le pays de destination.",
  PleaseSpecifyYourRecipientMobile:
    "Veuillez spécifier les détails du compte mobile de votre destinataire. Il doit en être propriétaire.",
  nearestCollectionPoint:
    "Votre bénéficiaire sera contacté pour être dirigé vers le point de collecte le plus proche",
  PaymentReceivedAsSoon:
    "Votre bénéficiaire recevra instantanément les fonds sur son compte Danapay dès que nous aurons reçu le paiement",
  DependingWithDrawMethod:
    "Il pourra les retirer dans un délai de 5mn à 72h selon la méthode choisie",
  You_can_send_between: "Vous pouvez envoyer entre",
  Balance: "Solde",
  ExchangeRate: "Taux de change",
  TransactionType: "Type d'opération",
  Deposit: "Dépôt",
  deposit: "Dépôt",
  cant_use_balance:
    "Vous ne pouvez pas utiliser votre solde pour cette opération.",
  cant_deposit_zero: "Vous ne pouvez pas faire dépôt de 0",
  MakeDeposit: "Effectuer un dépôt",
  insufficient: "Votre solde est insuffisant.",
  enterEuros: "Veuillez saisir le montant en euros",
  selectCashIn: "Veuillez choisir un mode de paiement",
  enterCfa: "Veuillez entrer le montant en CFA",
  cantSendToSelf: "Vous ne pouvez pas vous envoyer des fonds",
  Your_current_balance: "Votre solde actuel",
  selectCashinMethod: "Choisir la méthode de paiement",
  selectPreviousUserList: "Sélectionnez une liste d'utilisateurs précédente",
  MobileOperator: "Opérateur mobile",
  selectWithdrawMode: "Sélectionnez un mode de retrait",
  BackHome: "Retour à l'accueil",
  Reference: "Référence",
  passwordLinkSent:
    "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse électronique.",
  payout: "Retrait",
  Please_select_cashin_Method: "Veuillez sélectionner le mode d'encaissement",
  menuHome: "Accueil",
  menuDeveloper: "Développeur",
  menuHelp: "Aide",
  menuPolicy: "Politique de confidentialité",
  menuTerms: "Conditions générales",
  menuHistory: "Historique",
  Payment_Mode: "Mode de paiement",
  Sent_amount_EUR: "Montant en EUR",
  Sent_Amount_Eur_Only: "Montant envoyé (EUR)",
  Sent_amount_Cfa: "Montant en CFA",
  Sent_amount_EUR_Only: "Montant en euros",
  Sent_amount_CFA_Only: "Montant en CFA",
  entries: "entrées",
  Sent_Amount_Cfa_Only: "Recevoir le montant (CFA)",
  Sent_Amount_GNF_Only: "Recevoir le montant (GNF)",
  Checked_Toogle:
    "Je précise la méthode de mise à disposition des fonds à mon bénéficiaire",
  type_withdraw_amount: "Saisissez le montant que vous souhaitez retirer",
  Enter_withdraw_Details: "Entrez les détails du retrait",
  Type_verification_code: "Tapez le code de vérification",
  TVCText: "Cliquez sur <b>Envoyer le code</b> pour recevoir un nouveau code.",
  warning: "Avertissement",
  started: "Démarré",
  funds_escrowed: "Fonds séquestrés",
  transfered: "Transféré",
  released: "Libéré",
  escrowing: "Dépôt en séquestre",
  transfering: "En cours...",
  releasing: "Libérer",
  pending: "En attente",
  paying_in: "En cours",
  paying_out: "En cours",
  payment_failed: "Échec",
  payment_started: "Démarré",
  revoking_refund_confirmation: "Révoquer",
  payment_pending: "En attente",
  payed_out: "Versé",
  completed: "Finalisé",
  payed_in: "Déposé",
  previous: "Précédent",
  reason: "Motif du paiement",
  Reason: "Motif",
  BankName: "Nom de banque",
  BIC: "BIC",
  password_required: "Le mot de passe est requis",
  email_required: "Identifiant de l'adresse e-mail requis.",
  email_not_valid: "L'email n'est pas valide.",
  LNR: "Le nom de famille est obligatoire",
  FNR: "Le prénom est obligatoire",
  P_REG:
    "Le mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial  (#$@!%&*?-_).",
  PSM: "Les mots de passe doivent correspondre",
  RNE: "Le numéro d'enregistrement est requis.",
  CNE: "Le nom de la société est obligatoire.",
  AIE: "Les informations relatives à l'adresse sont requises.",
  CE: "Le pays est requis.",
  CIE: "La ville est requise.",
  "": "",
  payment_instruction: "Instruction de paiement",
  pi_text:
    "Sélectionnez la banque vers laquelle vous souhaitez effectuer le virement",
  select_files:
    "sélectionnez les fichiers à télécharger  [pdf, png, jpg, jpeg] max 5MB.",
  upload: "Télécharger   ",
  uploaded_successfully: " a été téléchargé avec succès.",
  Drop_file_to_upload: "Déposer le fichier à télécharger.",
  or: "ou",
  Register_of_commerce: "Registre du commerce",
  Articles_of_association: "Statuts",
  Beneficiary_owner: "IDs bénéficiaires effectifs",
  Directors_IDs: "IDs des Directeurs",
  Proof_of_address: "Preuve d'adresse",
  bulkPayment: "Paiement en masse",
  Upload_csv: "Télécharger un détail csv des bénéficiaires",
  Csv_Format_Error: "Erreur. Veuillez vérifier le format csv",
  Upload_Csv_Toast_Error: "Le CSV chargé n’est pas au bon format.",
  // Beneficiary_selection: "Sélectionnez un bénéficiaire dans la liste ou cliquez sur le bouton ci-dessous pour télécharger une liste. Vous pouvez télécharger un maximum de 500 000 bénéficiaires",
  Beneficiary_selection:
    "Télécharger jusqu’à 1000 bénéficiaires depuis un fichier CSV en cliquant sur le bouton ci-dessous.",
  // Beneficiary_selection: "Sélectionnez un bénéficiaire dans la liste ou cliquez sur le bouton ci-dessous pour télécharger une liste. Vous pouvez télécharger un maximum de 1000 bénéficiaires",
  Upload_Csv_Toast:
    "Veuillez vous assurer que tous les numéros de téléphone sont corrects et qu’ils sont sans code pays ni espace. Notez que vous serez facturés en cas d'échec d’une opération dû à un numéro erroné ou mal formaté",
  Lose_Csv_Toast:
    "Vous allez quitter la page de transfert de masse. Toutes vos données seront supprimées.",
  Upload_Rows: "lignes téléchargées avec succès",
  Upload_Another_Csv_Toast:
    "Vous allez charger un nouveau fichier CSV. Vous perdrez les données précédemment chargées",
  Individual: "Particulier",
  Business: "Entreprise",
  Phone_Number: "Numéro de téléphone",
  Other: "Autre",
  Add_Contact: "Ajouter un contact",
  Latest_Transactions: "Vos dernières transactions",
  Contacts: "Vos contacts",
  People_selected: "Personnes sélectionnées",
  Select_Beneficiaries: "Sélectionner les bénéficiaires",
  select_cashOut: "Veuillez choisir le mode de paiement",
  select_account:
    "Veuillez sélectionner un compte sur lequel envoyer l'argent.",
  select_account_type: "Veuillez sélectionner votre type de compte",
  balance_zero: "Il n'est pas possible de retirer avec un solde nul.",
  Withdrawal_amount: "Le montant du retrait est trop élevé.",
  select_user:
    "Sélectionnez l'utilisateur auquel vous voulez envoyer de l'argent",
  select_country: "Sélectionnez un pays",
  country_not_configured: "Le pays destinataire n'est pas encore configuré..",
  country_not_selected: "Sélectionnez un pays",
  sumSubTitle: "Procédez à votre vérification ",
  sumSubDescription:
    "Nous avons besoin de ces documents pour des raisons de conformité. Ils sont obligatoires et ne seront pas demandés à nouveau.",
  skip_later: "Passer",
  add_user: "Ajouter",
  select_users:
    "Sélectionnez l'utilisateur auquel vous voulez envoyer de l'argent",
  TRQ: "Le type d'utilisateur est requis",
  ERQ: "L'adresse électronique est obligatoire.",
  amount_out_of_range: "Montant maximum dépassé",
  Amount_out_of_range: "Montant maximum dépassé",
  euroAmountRequired: "Le champ du montant en euros est obligatoire.",
  Make_deposit:
    "Votre opération sera finalisée une fois que vous recevrons les fonds. Veuillez à présent effectuer un virement sur notre compte ",
  the_key_code_must_be_between_5_and_6_digits:
    "Le code secret doit comporter entre 5 et 6 chiffres.",
  verification_failed: "La vérification a échoué",
  the_key_code_field_is_required: "Le champ code secret est obligatoire",
  Individual_Account: "Compte individuel",
  file_to_large:
    "Désolé, votre fichier est trop volumineux. Nous acceptons les fichiers d'une taille maximale de 5 mégaoctets.",
  pay_by_link: "Demander un paiement",
  Bulk_Payments: "Paiement en masse",
  Pay_By_Link: "Demander un paiement",
  not_config: "n'a pas de pays de réception associé.",
  accept_payment: "accepter le paiement",
  userNotActive:
    "Votre compte n'a pas encore été activé, veuillez contacter l'administrateur de Danapay.",
  userNotActiveTitle: "Activation du compte",
  termsTextDes: " En cliquant sur s'inscrire, vous acceptez nos conditions ",
  verification: "Vérification",
  bank_transfer: "Virement bancaire",
  "bank transfer": "Virement bancaire",
  balance: "Solde",
  Personal_Information: "Informations personnelles",
  Company_Information: "Informations de l'entreprise",
  Documents: "Documents",
  Verify_User: "Vérifier l'utilisateur",
  Account_Type: "Type de compte",
  Account_Type_Required: "Le type de compte est requis",
  select_bank: "Sélection banque",
  select_the_bank: "Sélectionnez la banque",
  select_the_micro_finance: "Sélectionnez la micro finance",
  validated: "Validé",
  bank_details: "Confirmation",
  bank_payout: "Retrait sur compte bancaire",
  the_payout_has_started: "Paiement démarré",
  the_payout_has_pending: "Paiement en cours",
  the_payout_has_completed: "Paiement terminé",
  the_payment_is_pending: "Transfert initié et paiement en attente",
  the_payment_is_compled: "Paiement effectué",
  the_payment_has_failed: "Paiement échoué",
  the_transfer_is_in_progress: "Transfert en cours d'exécution",
  the_transfer_is_completed: "Fonds transmis avec succès.",
  title_required: "Le titre est un champ obligatoire",
  bank_required: "La banque est un champ obligatoire",
  iban_required: "IBAN est un champ obligatoire",
  bic_required: "BIC est un champ obligatoire",
  Back_Home: "Accueil",
  sumsubText: "Téléchargez un document qui prouve votre identité",
  loggingOut: "Déconnexion",
  loggingOutSure: "Etes-vous sûr de vouloir vous déconnecter ?",
  yes: "Oui",
  no: "Non",
  your_account_has_been_activated: "Votre compte a été activé",
  we_confirm_the_reception_of_your_payment: "Nous avons reçu vos fonds",
  your_transfer_has_been_successfully_executed:
    "Votre transfert a été exécuté avec succès",
  your_payout_is_in_progress: "Votre retrait a bien été pris en compte",
  we_have_executed_your_withdraw: "Vos fonds ont été virés sur votre compte",
  your_account_has_been_inactivated: "Votre compte a été désactivé",
  your_account_has_been_deactivated: "Votre compte a été désactivé",
  your_deposit_is_completed: "Nous avons reçu vos fonds",
  the_payin_has_started:
    "Demande de dépôt pris en compte. En attente du paiement.",
  the_payin_is_completed: "Compte rechargé avec succès.",
  the_payin_is_in_progress: "Paiement reçu. Dépôt en cours d'exécution.",
  the_payout_is_in_progress: "Le retrait est en cours",
  the_payout_is_completed: "Le retrait est terminé",
  deleteAccount: "Êtes-vous sûr de vouloir supprimer ce compte ?",
  accountDeleted: "Le compte a été supprimé",
  accountError: "Une erreur est survenue, réessayez.",
  resource_item_for_transfercountry_not_found:
    "Erreur. Veuillez contacter le support.",
  deposit_fee_configuration_not_found: "Erreur. Veuillez contacter le support",
  you_will_receive_an_email_validation_link_if_the_email_is_found_and_is_not_validated_already:
    "Vous recevrez un lien de validation de l'email si l'email est trouvé et n'est pas déjà validé.",
  the_country_code_field_is_required: 'Le champ "code pays" est obligatoire.',
  the_phone_number_must_be_at_least_8_characters:
    "Le numéro de téléphone doit comporter au moins 8 caractères",
  withdraw_fee_configuration_not_found:
    "Configuration des frais de retrait non trouvée.",
  the_selected_country_code_is_invalid:
    "Le code pays sélectionné n'est pas valide",
  this_phone_number_is_already_registered:
    "Ce numéro de téléphone est déjà enregistré",
  your_username_or_password_is_invalid:
    "Votre nom d'utilisateur ou votre mot de passe n'est pas valide.",
  the_email_has_already_been_taken: "L'email a déjà été pris.",
  try_refresh:
    "Videz votre cache et rafraichissez votre navigateur, contactez le support si l'erreur persiste.",
  fields_required: "Tous les champs sont obligatoires",
  noCompany: "Vous n'avez pas d'entreprise",
  the_euro_amount_field_is_required:
    "Le champ du montant en euros est obligatoire.",
  cancelTitle: "Annuler",
  cancelMessage: "Confirmez-vous l’annulation de cette opération ?",
  cancel: "Annuler",
  ok: "d'accord",
  cancelling: "Annulation de",
  text_bene_details: "Détails du bénéficiaire du numéro de téléphone",
  text_tell_us_more:
    "est un nouveau bénéficiaire. Veuillez saisir ces informations.",
  reasonError: "Le motif est un champs obligatoire.",
  withdrawalError: "veuillez sélectionner n'importe quel mode de retrait.",
  BankNameError: "Le nom de la banque est requis.",
  IbanError: "Iban est requis.",
  BicError: "Le Bic est obligatoire.",
  CountryDialCodeError: "Le code de numérotation du pays est requis.",
  PhoneNumberError: "Le numéro de téléphone est requis.",
  this_phone_number_is_already_taken: "Le numéro de téléphone est déjà pris.",
  personal_information: "Vos informations personnelles",
  contact_information: "Vos contacts",
  company: "Entreprise",
  company_name: "Nom de l’entreprise",
  company_name_error: "Le nom de l'entreprise est requis",
  registration_id: "Numéro d’imatriculation",
  contact_first_name: "Prénom du contact",
  contact_first_name_error: "Le prénom du contact est obligatoire",
  contact_last_name: "Nom du contact",
  contact_last_name_error: "Le nom de famille du contact est requis",
  contact_email: "Email du contact",
  contact_phone_number: "Numéro de téléphone du contact",
  send_the_code_again: "Renvoyer le code OTP",
  not_verified: "Non vérifié",
  verify_my_account: "Vérifier le compte",
  optional: "En option",
  you_are_not_allowed_to_modify_company_informations_already_verified_by_us_please_contact_the_support:
    "Vous n'êtes pas autorisé à modifier les informations relatives à la société déjà vérifiées par nos soins. Veuillez contacter le service d'assistance.",
  you_are_not_allowed_to_modify_names_already_verified_by_us_please_contact_the_support:
    "Vous n’êtes pas autorisé à modifier ces données car nous avons déjà validé votre compte. Veuillez contacter le support.",
  this_action_requires_administrative_rights:
    "Cette action nécessite des droits d'administration.",
  contact_added: "Contact ajouté avec succès.",
  select: "Sélectionnez ...",
  the_phone_number_field_is_required:
    "Le champ du numéro de téléphone est obligatoire",
  code_sent: "Un nouveau code a été envoyé",
  Add_Mobile_account: "Ajouter un compte",
  resending: "Renvoi du site",
  no_receiving_country: "Vous ne pouvez pas faire de transfert vers ce pays.",
  too_many_attempts: "Limite de tentative atteinte",
  "Too Many Attempts": "Limite de tentative atteinte",
  "Too Many Attempts.": "Limite de tentative atteinte",
  is_number:
    "Veuillez vous assurer qu'il s'agit bien de votre numéro de téléphone ",
  manage_accounts: "Vos comptes",
  verify: "Vérifier",
  Changed_successfully: "Modifié avec succès",
  transfer_fee_configuration_not_found:
    "Configuration des frais de transfert non trouvée",
  userNotVerify:
    "Vous souhaitez faire un premier transfert ou dépôt ? Commencez par finaliser la vérification de votre compte en cliquant ici !",
  the_iban_has_already_been_taken: "L'IBAN a déjà été pris.",
  owner_name: "Nom du propriétaire",
  edit: "Modifier",
  profile: "Profile",
  Add_Account: "Ajouter un compte",
  Edit_Account: "Modifier le compte",
  operator: "Opérateur",
  Owner_Name: "Nom du propriétaire",
  Account_Title: "Titre du compte",
  Add_bank_account: "Ajouter un compte",
  operator_required: "Opérateur est un champ requis",
  phone_number_required: "Le numéro de téléphone est un champ obligatoire ",
  owner_required: "Le propriétaire est requis",
  bic_required: "Le BIC est requis",
  bank_name_required: "Le nom de la banque est requis ",
  title_required: "Le titre est requis",
  Added_successfully: "Ajouté avec succès",
  bank_accounts: "Comptes bancaires",
  mobile_money_accounts: "Comptes Mobile Money",
  "Invalid IBAN": "L'IBAN doit être valide",
  Are_you_want_to_delete: "Êtes-vous sûr de vouloir supprimer ce compte ?",
  Deleted_successfully: "Supprimé avec succès",
  confirm: "Confirmer",
  update: "Mettre à jour",
  calculating: "calcul de",
  Payout_delays_in_mali: "Retards de paiement au Mali",
  instant_transfer_text:
    "Les fonds sont transférés instantanément après réception des fonds. Cette option prend de 5 minutes à 1 heure.",
  direct_debit: "Prélèvement automatique",
  total_CFA: "Total CFA",
  pending_title: "Veuillez patienter...",
  pending_footer: "Plus de détails sur le processus de vérification.",
  pending_title_description:
    "Votre compte est en cours de vérification, cela peut prendre 10 minutes. Dès que cela sera fait, vous serez automatiquement redirigé vers la page de paiement.",
  Your_payment_has_been_title: "Bien joué ! 👍",
  Your_payment_has_been:
    "Votre paiement a été pris en compte. Dès que nous aurons reçu les fonds, vous en serez informé et nous exécuterons le transfert.",
  your_name_differs_from_the_account_holder:
    "Votre paiement a échoué car vous n'êtes pas le titulaire du compte bancaire. Veuillez réessayer",
  your_payment_failed_because_you_are_not_the_bank_account_owner_please_try_again:
    "Votre paiement a échoué car vous n'êtes pas le titulaire du compte bancaire. Veuillez réessayer",
  payment_failed_error: "Votre paiement a été rejeté. Veuillez réessayer",
  send_by_sms: "Recevoir le code par SMS",
  send_by_mail: "Recevoir le code par email",
  send_code: "Envoyer le code",
  sending: "En cours...",
  Please_enter_amount:
    // "Veuillez saisir le montant pour chacun des bénéficiaires",
    "Veuillez entrer le montant (EUR) pour chaque utilisateur.",
  mobile_money: "Argent mobile",
  "Mobile Money": "Argent mobile",
  iban_required: "L'IBAN est obligatoire",
  cashout_was_successfully_archived: "L'encaissement a été annulé avec succès.",
  pay_link: "Demande de paiement",
  benefactor: "Bénéficiaire",
  enter_benefactor_phone: "Entrez le numéro de téléphone du bénéficiaire",
  the_specified_sending_country_does_not_make_payments_to_this_receiving_country:
    "Le pays émetteur spécifié n'effectue pas de paiements vers ce pays récepteur.",
  the_selected_cashin_method_id_is_invalid:
    "La méthode d'encaissement sélectionnée n'est pas valide, veuillez contacter l'équipe de Danapay.",
  Copy: "Copie",
  copy_link: "Copier le lien",
  link_copied: "Le lien a été copié",
  receiver_requested_a_payment: "Demande de paiement",
  your_account_is_currently_inactive: "Votre compte est actuellement inactif",
  payment_demand: "Demande de paiement",
  completePayment: "Paiement complet",
  received_amount: "Montant reçu",
  make_a_transfer: "Envoyer de l'argent",
  ignore: "Ignorer",
  noTransactions:
    "Vous n'avez aucune transaction pour le moment. Veuillez effectuer votre première transaction.",
  notUser:
    "Ce lien ne vous est pas destiné. Veuillez contacter Danapay si vous avez reçu ce lien dans votre courrier électronique.",
  fetching_transactions_details: "Récupération des détails des transactions",
  Same_Day: "24h",
  Withdraw_mode_and_delays: "Mode de retrait et délais",
  Bank_Accounts: "Compte bancaire",
  "Bank Account": "Compte bancaire",
  Withdraw_mode_and_delays_description:
    "Les fonds sont transférés instantanément après leur réception. Votre bénéficiaire peut effectuer un retrait quelques minutes plus tard. Le délai de retrait dépend du mode choisi.",
  make_a_deposit: "Effectuer un dépôt",
  click_here_to: "Cliquez ici pour vous ",
  contact_information: "Informations de contact",
  personal_information: "Informations personnelles",
  Back_to_Login: "Se connecter",
  Type_amount: "Saisissez le montant",
  Please_Wait: "Veuillez patienter...",
  card: "Carte bancaire",
  trying_to_get_property_type_of_nonobject:
    "Essayer d'obtenir le type de propriété d'un non-objet",
  Add_Mobile_account: "Ajouter compte MM",
  instant_bank_transfer: "Prélèvement bancaire instantané",
  manual_bank_transfer: "Virement manuel",
  this_payout_method_is_not_configured_in_this_countrycontact_the_administrator:
    "Cette méthode de paiement n'est pas configurée dans ce pays. Contactez l'administrateur",
  the_phone_number_has_already_been_taken:
    "Le numéro de téléphone a déjà été pris",
  my_number:
    "Je confirme que je suis le propriétaire de ce compte mobile money.",
  my_account_required:
    "Confirmez que vous êtes propriétaire du compte mobile money.",
  bulk_transfer: "Transfert en masse",
  // bulk_transfer: "Bulk Transfer",
  recipients: "Bénéficiaires",
  UTR: "Le type d'utilisateur est requis",
  phone_required: "Le numéro de téléphone est un champ obligatoire",
  bank_payout: "Compte bancaire",
  mobile_money_by_hub2: "Portefeuille mobile (Hub2)",
  "mobile money": "Portefeuille mobile",
  mobile_money_by_hub2: "Portefeuille mobile",
  mobile_money: "Portefeuille mobile",
  select_user_type: "Sélectionnez un type",
  the_payment_is_completed: "Paiement effectué avec succès",
  the_payment_has_started: "Paiement initié",
  job: "Emploi",
  revenue: "Fourchette de revenus",
  job_err: "Le travail est requis.",
  job_select: "Sélectionnez un emploi",
  revenue_err: "Une fourchette de revenus est requise",
  revenue_information: "Revenus",
  payment_reference_number: "Numéro de référence du paiement",
  user_information: "Informations sur l'utilisateur",
  accounts_information: "Informations sur les comptes",
  dp_bank_accounts: "Détails des comptes bancaires de Danapay",
  manage_accounts_sub:
    "Ajouter, mettre à jour et supprimer le compte que vous utiliserez pour vos retraits.",
  danapay_accounts: "Comptes Danapay",
  danapay_bank_accounts: "Comptes bancaires Danapay",
  danapay_accounts_sub:
    "Trouvez la liste des comptes Danapay sur lesquels vous pouvez effectuer des dépôts dans votre pays.",
  You_requested_for_pay: "Vous avez demandé un paiement",
  update_email: "Mise à jour de l'adresse électronique",
  update_password: "Mettre à jour votre mot de passe",
  update_phone: "Mettez à jour votre numéro de téléphone",
  update_user_info: "Mettre à jour vos informations personnelles",
  Settings: "Paramètres",
  phone_verify_text:
    "Nous avons envoyé un OTP à votre numéro de téléphone. Entrez les six codes OTP ci-dessous.",
  phone_verify_header: "Vérifier le numéro de téléphone",
  phone_verify_btn_text: "Vérifier",
  verify_your_email: "Vérifier votre courriel",
  verify_your_email_text:
    "Nous vous avons envoyé un lien de vérification à votre adresse électronique. Allez à votre email et cliquez sur le lien. Vérifiez votre dossier spam si vous ne voyez pas le mail.",
  hone_update_success_message:
    "Le numéro de téléphone a été vérifié et mis à jour.",
  email_update_success_message:
    "L'adresse électronique a été vérifiée et mise à jour.",
  Select_Country: "Sélectionnez le pays",
  Select_Mobile_Operator: "Selectionner l’opérateur mobile ",
  Mobile_Operator: "Opérateur mobile",
  No_Operator_Found: "Aucun opérateur trouvé",
  No_Withdraw_Mode_Found:
    "Aucune méthode de retrait n'est disponible pour ce pays",
  No_Withdraw_Methods: "Aucune méthode de retrait n'est disponible pour votre pays.",
  Select_Operator_Error:
    "Veuillez sélectionner n'importe quel opérateur de téléphonie mobile.",
  Enter_revenue_details: "Entrez les détails de vos revenus",
  Enter_revenue_details_company: "Dites-en nous plus sur votre entreprise",
  payline_cancelled: "Paiement annulé",
  payline_cancelled_text:
    "Nous vous confirmons que votre paiement a été annulé.",
  payline_successful: "Bien joué ! 👍",
  payline_successful_text:
    "Votre paiement a été pris en compte. Dès que nous aurons reçu les fonds, vous en serez informé et nous exécuterons le transfert.",
  download: "Télécharger",
  select_user_text:
    "Sélectionnez le bénéficiaire dans la liste de contacts ou saisissez un numéro de téléphone.",
  your_password_has_been_saved: "Votre mot de passe a été enregistré",
  personal_information_text:
    "Mettez à jour vos informations personnelles. Possible uniquement avant la vérification de votre compte.",
  security: "Sécurité",
  the_payin_is_in_progress: "Le versement est en cours",
  payment_was_successfully_archived: "Paiement archivé avec succès",
  error_downloading:
    "Nous avons rencontré une erreur lors du téléchargementNous avons rencontré une erreur lors du téléchargement, réessayez plus tard.",
  comp_info: "Informations sur l'entreprise",
  comp_info_text:
    "Vous pouvez mettre à jour les informations relatives à votre entreprise avant l'activation",
  comp_doc: "Documents de l'entreprise",
  comp_doc_text:
    "Sélectionnez le type de document que vous souhaitez mettre à jour et téléchargez les documents correspondants.",
  download_done: "Votre téléchargement est prêt.",
  payment_started: "Paiement initié",
  payment_completed: "Paiement terminé",
  funds_received: "Fonds reçus",
  transfer_in_progress: "Transfert en cours",
  deposit_failed: "Dépôt échoué",
  deposit_completed: "Dépôt effectué",
  deposit_in_progress: "Dépôt en cours",
  transfer_failed: "Transfert échoué",
  transfer_completed: "Transfert terminé",
  withdraw_requested: "Retrait demandé",
  withdraw_in_progress: "Retrait en cours",
  withdraw_failed: "Retrait échoué",
  withdraw_completed: "Retrait terminé",
  the_deposit_is_in_progress: "Dépôt en cours",
  the_deposit_is_completed: "Dépôt terminé",
  select_provider: "Sélectionnez le fournisseur",
  select_provider_text:
    "Cliquez pour sélectionner un fournisseur d'argent mobile",
  payment_was_successfully_archived: "Paiement annulé avec succès",
  mm_title: "Traitement",
  mm_text:
    "Afin de continuer, veuillez vous connecter avec le mot de passe temporaire que nous vous avions envoyé par mail",
  payment_requested: "Payment Requested",
  All: "Tous",
  Transfer: "Transfert",
  "Bulk Transfer": "Transfert en masse",
  Deposits: "Dépôts",
  Withdraws: "Retraits",
  Contact_Name: "Nom du contact",
  to: "To",
  from: "De",
  download_receipts: "Télécharger les reçus",
  download_CSV: "Télécharger CSV",
  filter: "Filtre",
  de: "De",
  to: "To",
  your_withdraw_is_in_progress: "Envoi des fonds en cours",
  your_withdraw_is_complete: "Envoi des fonds terminé",
  sender_country_is_invalid: "Le pays de l'expéditeur n'est pas valide",
  only_designated_user_may_proceed_to_payment_please_get_in_touch_with_the_requestor_to_get_the_correct_link:
    "Seul l'utilisateur désigné peut procéder au paiement. Veuillez prendre contact avec le demandeur pour obtenir le lien correct.",
  password_reset_successfully: "Réinitialisation du mot de passe avec succès.",
  add_documents: "Ajouter des documents",
  file_title: "Nom du fichier",
  browse_file: "parcourir le fichier",
  drop_file: "Déposer des fichiers à joindre, ou",
  Uploading: "Uploading",
  drop_file_header: "Joindre un fichier à transférer",
  deleting_head_text: "Suppression du document",
  deleting_body_text: "Êtes-vous sûr de vouloir supprimer ce document ?",
  bridgeapi_cancelled: "Paiement annulé",
  bridgeapi_cancelled_text:
    "Vous avez annulé votre paiement, nous allons le supprimer",
  bridgeapi_failed: "Paiement échoué",
  bridgeapi_failed_text: "Désolé, votre paiement a échoué",
  bridgeapi: "Virement SEPA Instantané",
  sepa: "Virement bancaire",
  danapay: "Solde",
  truelayer: "Débit direct",
  payline: "Carte bancaire",
  hub2: "Mobile money",
  hub_2_mobile_money: "Mobile Money",
  instant_sepa: "Virement SEPA Instantané",
  bank_card: "Carte bancaire",
  your_withdraw_has_failed: "Votre retrait a échoué",
  upload_beneficiaries_details: "Télécharger les détails des bénéficiaires",
  click_here_to_upload:
    "Cliquez ici pour télécharger ou faites glisser et déposez votre fichier ici",
  // accepted_formats: "Les formats acceptés sont les fichiers CSV avec un maximum de 500 000 lignes",
  accepted_formats:
    "Les formats acceptés sont les fichiers CSV avec un maximum de 1000 lignes",
  download_template: "Télécharger le modèle",
  retry: "recommencez",
  wait_for_prompt_on_your_phone: "Attendez l'invite sur votre téléphone",
  wait_for_prompt_on_your_phone:
    "Veuillez vérifier si votre téléphone vous demande de saisir votre code PIN pour l'argent mobile. Saisissez votre code PIN pour terminer le paiement, puis appuyez sur Retour à la maison",
  payment_verification_pending: "Vérification de l'argent mobile",
  otp_required: "Veuillez saisir le code OTP à 4 chiffres",
  done: "Valider",
  Processing: "Traitement",
  processing_text:
    "Veuillez patienter pendant que nous traitons votre paiement, ne rafraîchissez pas la page",
  OTP_Verification: "Vérification OTP",
  payment_verification_pending_cancel: "Paiement annulé",
  wait_for_prompt_on_your_phone_cancel:
    "Votre paiement a été annulé, appuyez sur retour à la maison pour réessayer",
  mm_success_title: "Le paiement a été effectué avec succès",
  mm_success_description:
    "Votre paiement a été effectué avec succès, merci d'avoir choisi Danapay",
  payment_verification_failed: "Échec de la vérification du paiement",
  failed_message:
    "Nous n'avons pas pu traiter votre paiement, veuillez réessayer plus tard",
  link_already_used: "Le lien a déjà été utilisé",
  link_already_used_desc:
    "Si ce n'est pas vous, veuillez contacter Danapay pour obtenir de l'aide",
  sent_credentials:
    "Nous vous avons envoyé vos identifiants temporaires à l’adresse  ",
  link_expiry_text: "L’URL de paiement a déjà expiré",
  not_your_payment:
    "Si vous avez reçu ce lien de paiement dans votre e-mail, veuillez contacter l'administrateur pour obtenir de l'aide",
  cashin_method_is_not_yet_supported:
    "La méthode CashIn n'est pas encore prise en charge.",
  payment_request_has_already_been_executed_please_get_touch_with_the_administrator_for_further_details:
    "La demande de paiement a déjà été exécutée, veuillez prendre contact avec l'administrateur pour plus de détails",
  direct_transfer: "Transfert",
  the_cashout_is_in_progress: "Réduction du solde en cours",
  the_cashout_is_completed: "Réduction du solde terminé",
  downloading_transaction: "Téléchargement des transactions",
  downloading: "Téléchargement",
  download_csv_note:
    "Le téléchargement peut prendre un certain temps. Nous vous informerons par e-mail lorsqu'il sera prêt.",
  download_csv_subtitle:
    " Vous êtes sur le point de télécharger un fichier CSV des transactions filtrées, cliquez sur Download CSV pour continuer",
  csv_format_text:
    "Si vous prévoyez d'utiliser le fichier CSV dans un logiciel tiers, indiquez le format que vous préférez.",
  Search: "Research",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Drag_Drop_Csv_Error:
    "Veuillez faire glisser et déposer un fichier Csv valide.",
  you_could_not_be_verified_as_human: "You could not be verified as human.",
  reset: "Réinitialiser",
  withdrawal_Fees: "Frais de retrait",
  using_direct_transfer:
    "Votre bénéficiaire devrait recevoir les fonds sur son compte mobile money 5mn après la réception de votre paiement.",
  not_using_direct_transfer:
    "Votre bénéficiaire recevra les fonds sur son compte Danapay dans les 2 prochaines minutes suivant la réception de votre paiement.Il devra faire une demande de retrait depuis l’application ou en contactant le support.",
  suspicious: "En cours",
  Received_transfer: "Transfert reçu",
  discovery: "Comment vous nous avez découvert ?",
  discoverysub: "Veuillez sélectionner la bonne source",
  dismiss: "rejeter",
  Invite_Contact: "Inviter un contact",
  Add_New_Contact: "Ajouter un nouveau contact",
  Add_New_Contact_Info:
    "Ajoutez un contact et démarrez un paiement instantané en un seul clic.",
  registrationModalTitle: "Veuillez télécharger l'application mobile",
  registrationModalMessage:
    "L'application web est désormais entièrement dédiée aux entreprises. Comme vous êtes un particulier, merci de télécharger l'application mobile. Il offre une meilleure expérience utilisateur",
  new_contact:
    "Numéro de téléphone non reconnu. Veuillez l'ajouter en tant que nouveau contact",
  beneficiary_details: "Détails du bénéficiaire",
  Campaign_Reward: "Récompense de campagne",
  Reffered_by_someone: "Référé par quelqu'un",
  android_version: "version Android",
  ios_version: "version iOS",
  discovery_other_heard: `Où avez-vous entendu parlé de nous ?`,
  referral_code_error: `Le code de parrainage ne peut pas être vide`,
  other_field_error: `L'autre champ ne peut pas être vide`,
  referral_code: "Code de Parrainage",
  starting_date: "Date de démarrage",
  complete_date: "Date de fin",
  mfi_accounts: "Comptes IMF",
  Add_MFI_account: "Ajouter un compte IMF",
  no_direct_payment:
    "Votre bénéficiaire recevra instantanément les fonds sur son compte Danapay dès que nous aurons reçu le paiement.",
  no_direct_payment2:
    "Il recevra les fonds dans un délai de 5mn à 72h selon la méthode de retrait qu'il choisit",
  mobile_money_warning:
    "Veuillez spécifier les détails du compte d'argent mobile de votre destinataire. Il doit en être le propriétaire. Assurez-vous que c'est le bon numéro car nous ne faisons aucune vérification avant l'envoi. Nous ne serons pas responsables de toute erreur.",
  bank_account_warning:
    "Veuillez préciser les coordonnées bancaires de votre destinataire. Il doit être le propriétaire unique et la banque doit être située dans le pays de destination.",
  microfinance_warning:
    "Veuillez préciser les détails de votre microfinance bénéficiaire. Il peut vous fournir son identifiant unique en contactant son institution de microfinance affiliée",
  bank_account_directTransfer:
    "Votre bénéficiaire devrait recevoir les fonds sur son compte bancaire dans les 72 heures suivant la réception de votre paiement.",
  microfinance_directTransfer:
    "Votre bénéficiaire devrait recevoir les fonds sur son compte de microfinance dans les 30 minutes suivant la réception de votre paiement.",
  mobile_money_directTransfer:
    "Votre bénéficiaire devrait recevoir les fonds sur son compte d'argent mobile dans les 5 minutes suivant la réception de votre paiement.",
  "bank payout by aloohpay": "paiement bancaire par aloohpay",
  "mfi by aloohpay": "mfi par aloohpay",
  "mobile money by aloohpay": "argent mobile par aloohpay",
  receivers_fullname: `Nom complet du destinataire`,
  select_iban: "Sélectionnez IBAN",
  select_mobile_number: "Sélectionnez un numéro de mobile",
  MFIAccountNumberError: "Le numéro de compte est requis",
  not_specified: "Non spécifié",
  account_id_required: `L'identifiant du compte est requis`,
  owner_name_required: "Le nom du propriétaire est requis",
  select_mfi_name: "Sélectionnez le nom de l'IMF",
  mfi_name_required: `Le nom de l’IMF est requis`,
  "Account id": "Identifiant de compte",
  default_notification_update_message:
    "Une nouvelle version de l'application est désormais disponible. Nous vous invitons à recharger l'application afin de garantir le bon fonctionnement et l'accès à toutes les fonctionnalités récentes.",
  default_notification_update_title:
    "Une nouvelle version de l'application est désormais disponible.",
  verification_email_error: `Oups ! Quelque chose s'est mal passé.`,
  verification_email_error_description: `Nous rencontrons un petit problème technique de notre côté. Pas d'inquiétude, notre équipe est déjà sur le coup pour le résoudre au plus vite. Veuillez réessayer dans quelques instants. Nous vous remercions de votre patience et comprenons l'inconvénient que cela peut causer.`,
  revenue_select: "Sélectionner une tranche",
  transfer_countries:
    "Préciser les pays vers lesquels vous envisagez de faire des transferts une fois inscrit.",
  transfer_countries_placeholder: `Sélectionnez les pays d'accueil`,
  select_account_type: "Veuillez sélectionner votre type de compte",
  documents_and_selfie: "Vérification de votre identité",
  transfer_countries_required: "Veuillez sélectionner au moins 1 pays",
  residency: "Résidence",
  residency_required: "Veuillez sélectionner votre résidence",
  residency_selection: "Précisez votre statut résidentiel actuel.",
  residency_selection_placeholder: "Sélectionner",
  residency_details: "Résidence",
  residency_selection_placeholder: "Sélectionnez votre résidence",
  "Account id": "Identifiant de compte",
  bank_transfer_primary_info: `Votre transfert sera initié dès réception des fonds sur notre compte. Nous vous recommendons d'effectuer un virement instantané afin d'accélérer le traitement de votre transfert.`,
  bank_transfer_sub_info_1: `Veuillez à présent effectuer un virement d’un montant de`,
  bank_transfer_sub_info_2: `sur notre compte`,
  mobile_money_bank_transfer_info_1:
    "Les fonds seront crédités sur le compte mobile number",
  mobile_money_bank_transfer_info_2:
    " de votre bénéficiaire dans les 5 minutes qui suivent. Aucune action de sa part ne sera nécessaire.",
  bank_account_bank_transfer_info_1:
    "Les fonds seront crédités sur le compte bancaire number",
  bank_account_bank_transfer_info_2:
    " de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.",
  danapay_bank_transfer_main_info_1: `Votre bénéficiaire recevra les fonds sur son compte Danapay. Afin d'accéder à ces fonds, une authentification est impérative en utilisant le numéro`,
  danapay_bank_transfer_main_info_2: `ou l'adresse e-mail`,
  danapay_bank_transfer_instruction: `Veuillez lui transmettre les instructions suivantes :`,
  danapay_bank_transfer_instruction_1: `Comment accéderà son compte Danapay quand on a reçu des fonds ?`,
  danapay_bank_transfer_instruction_2: `Comment retirer ses fonds vers un compte mobile ?`,
  danapay_bank_transfer_instruction_3: `Comment retirer ses fonds vers un compte bancaire ?`,
  balance_main_info: "Nous exécutons votre transfert immédiatement",
  balance_danapay_info_1: `Les fonds seront crédités sur le compte bancaire n°`,
  balance_danapay_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire`,
  balance_microfinance_info_1: `Les fonds seront crédités sur le compte de microfinance n°`,
  balance_microfinance_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.`,
  balance_mobileMoney_info_1: `Les fonds seront crédités sur le compte mobile n°`,
  balance_bankAccount_info_1: `Les fonds seront crédités sur le compte bancaire n°`,
  balance_mobileMoney_info_2: `de votre bénéficiaire dans les 5 minutes qui suivent. Aucune action de sa part ne sera nécessaire.`,
  balance_bankAccount_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.`,
  instant_sepa_main_info: `Votre transfert sera initié dès réception des fonds sur notre compte. Selon votre banque le délai de traitement des virements instentanés peut varier entre 5mn et 72h.`,
  instant_sepa_mobileMoney_info_1: `Les fonds seront crédités sur le compte mobile n°`,
  instant_sepa_bankAccount_info_1: `Les fonds seront crédités sur le compte bancaire n°`,
  instant_sepa_microfinance_info_1: `Les fonds seront crédités sur le compte de microfinance n°`,
  instant_sepa_mobileMoney_info_2: `de votre bénéficiaire dans les 5 minutes qui suivent. Aucune action de sa part ne sera nécessaire.`,
  instant_sepa_bankAccount_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.`,
  instant_sepa_microfinance_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.`,
  payline_main_info: `Votre transfert sera initié dès réception de la confirmation de votre banque, un processus qui devrait prendre quelques minutes.`,
  payline_mobileMoney_info_1: `Les fonds seront crédités sur le compte mobile n°`,
  payline_microfinance_info_1: `Les fonds seront crédités sur le compte de microfinance n°`,
  payline_mobileMoney_info_2: `de votre bénéficiaire dans les 5 minutes qui suivent. Aucune action de sa part ne sera nécessaire.`,
  payline_microfinance_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.`,
  payline_bankAccount_info_1: `Les fonds seront crédités sur le compte bancaire n°`,
  payline_bankAccount_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.`,
  microfinance_bank_transfer_info_1: `Les fonds seront crédités sur le compte de microfinance n°`,
  microfinance_bank_transfer_info_2: `de votre bénéficiaire dans un délai de 24 à 72 heures. Aucune action de sa part ne sera nécessaire.`,
  residency_option_1: `Je vis en Union Européenne mais je ne suis pas citoyen (Étranger)`,
  residency_option_2: `Je réside et je suis citoyen de l'Union Européenne`,
  residency_option_3: `Résident et citoyen de l'UEMOA ou de la CEMAC`,
  terms_condition_1: `En cliquant sur S’inscrire, vous acceptez notre `,
  terms_condition_2: `applicable au traitement de vos données personnelles ainsi que nos conditions`,
  privacy_policy: `Politique de confidentialité`,
  terms_and_condition: `Conditions générales`,
  activity: 'Quelle est votre activité principale ?',
  revenue_level: 'Quel est votre chiffre d’affaires ?',
  sending_countries_1: `Pays desquels vous allez `,
  sending_countries_2: `recevoir des fonds`,
  company_receiving_countries_1: "Pays vers lesquels vous allez ",
  company_receiving_countries_2: "envoyer des fonds",
  company_receiving_countries_placeholder: "Sélectionner le.s pays",
  sending_countries: `Pays d'envoi`,
  activity: "Activité",
  revenue_level: "Niveau de revenus",
  sending_countries: `Pays d'envoi`,
  sending_countries_placeholder: "Sélectionnez un ou plusieurs pays",
  receiving_countries: `Destination de vos transferts`,
  envisioned_countries: "Flux financiers",
  company_envisioned_countries:
    "Spécifier les pays d'envoi et de réception envisagés",
  activity_err: "Une activité est requise.",
  company_verification: `Vérification de l'entreprise`,
  sumsub_server_error: "Erreur du serveur. Veuillez réessayer plus tard",
  login_phone: "Connection par numéro de téléphone",
  login_email: "Connection par email",
  pincode: "Code PIN",
  pincode_error: "Le code PIN est requis",
  pincode_invalid: `Le code PIN n'est pas valide. Veuillez saisir un code PIN valide`,
  submit: "Soumettre",
  otp_invalid: "OTP invalide. Veuillez entrer un OTP valide",
  phone_login_not_existing:
    "Veuillez créer un compte avec le numéro de téléphone correspondant",
  sumsub_skip: "Me faire vérifier plus tard",
  sumsub_verification_title: "Encore un peu de patience !",
  sumsub_verification_banner_text_1:
    "Nous avons bien reçu vos justificatifs et",
  sumsub_verification_banner_text_bold_1:
    " ils sont en cours de vérification. ",
  sumsub_verification_banner_text_2: "En attendant, vous avez",
  sumsub_verification_banner_text_bold_2: " un accès limité à nos services. ",
  sumsub_verification_banner_text_3: "Besoin d'aide ? Nous sommes disponibles sur le chat, par email ou sur WhatsApp.",
  whatsapp_redirection: "Nous contacter sur WhatsApp",
  sumsub_finish: "Accéder à mon compte",
  required_red: "(requis)*",
  sumsub_reject_title: "Votre compte n’a pas pu être validé par notre équipe Conformité",
  sumsub_reject_body: "Nous ne sommes pas en mesure de vous donner accès à la plateforme.",
  sumsub_return_home: "Retour à l’accueil",
  sumsub_verification_applicant_submitted_title: " Encore un peu de patience !",
  sumsub_verification_applicant_submitted_text_1: "Nous avons bien reçu vos justificatifs ",
  sumsub_verification_applicant_submitted_text_bold_1: "et ils sont en cours de vérification.",
  sumsub_verification_applicant_submitted_text_2: "En attendant, ",
  sumsub_verification_applicant_submitted_text_bold_2: "vous avez un accès limité à nos services. ",
  sumsub_verification_applicant_submitted_text_3: "Besoin d'aide ? Nous sommes disponibles sur le chat, par email ou sur WhatsApp.",
  sumsub_individual_application_reviewed: `Votre profil a été vérifié et validé par notre partenaire Sumsub. Il est en cours d’activation. En attendant, vous avez un accès limité à nos services. Besoin d'aide ? Nous sommes disponibles sur le chat, par email ou sur WhatsApp.`,
  sumsub_individual_application_reviewed_title: "Félicitations !",
  sumsub_individual_application_reviewed_1: "Votre profil a été vérifié et validé. Il est en cours d’activation. En attendant, ",
  sumsub_individual_application_reviewed_2: "vous avez un accès limité à nos services. ",
  sumsub_individual_application_reviewed_3: "Besoin d'aide ? Nous sommes disponibles sur le chat, par email ou sur WhatsApp.",
  sumsub_individual_application_created: "Finalisez d’abord, la vérification de votre compte en cliquant ici !",
  complete_verification: "Finaliser la vérification",
  sumsub_individual_application_created_title: 'Prêt à faire un premier transfert ?',
  sumsub_verification_banner_text_3:
    "Nous restons disponibles sur le chat, par email ou sur WhatsApp.",
  sumsub_individual_application_retry_title: "Oops. Des informations sont à compléter !",
  sumsub_individual_application_retry_text_1: "Votre profil a été vérifié. ",
  sumsub_individual_application_retry_text_2: "Nous avons besoin d’informations complémentaires.",
  sumsub_individual_application_retry_text_3: "Merci de les fournir en cliquant ici.",
  sumsub_individual_application_retry_cta: "Compléter mon dossier",

  delivery: "Livraison",
  delivery_description: "En choisissant cette méthode de retrait, nos équipes se chargeront de contacter votre bénéficiaire et de se rendre à l'heure et au lieu convenu pour lui remettre les fonds.",
  delivery_cashout_banner: "En choisissant cette méthode de retrait, nos équipes se chargeront de vous contacter afin de fixer une heure et un lieu de rendez-vous afin de vous remettre les fonds",
  company_revenue_required: "Des revenus commerciaux sont requis",
  bank_transfer_instructions:
    "Effectuez un virement vers danapay. Notre RIB vous sera fourni à la dernière étape.Pour accélérer votre transaction, effectuez un virement instantanné depuis votre banqueLe transfert sera exécuté dès que nous aurons reçu les fonds sur notre compte bancaire.",
  balance_instructions:
    "Utilisez l'argent disponible dans votre solde pour payer votre transfert instantanément. Votre transfert sera traité immédiatement.",
  bank_card_instructions:
    "Vous serez redirigé vers notre partenaire Monext - Payline pour effectuer votre paiementLe transfert sera exécuté immédiatement après votre paiement.",
  instant_sepa_instructions: "",
  balance_instructions_title: "Le plus rapide: ",
  bank_transfer_instructions_title: "Le moins cher: ",
  bank_card_instructions_title: "Le plus pratique: ",
  enter_reason: "Raison du paiement",
  enter_amount: "Montant à payer en EUR et CFA",
  "Amount out of range": "Montant maximum dépassé.",
  "Amount below minimum range": "Montant inférieur à la fourchette minimale.",
  "Amount above maximum range": "Montant supérieur à la fourchette maximale.",
  max_amount_exceeded: "Le montant maximum que vous pouvez envoyer est de ",
  max_amount_exceeded_cashin_message: "Montant maximum dépassé pour ce mode de paiement. Veuillez choisir une autre méthode de paiement",
  bank_transfer_instruction_title: "Le moins cher",
  bank_transfer_instruction_description:
    "Effectuez un virement vers danapay. Notre RIB vous sera fourni à la dernière étape. Pour accélérer votre transaction, effectuez un virement instantanné depuis votre banque Le transfert sera exécuté dès que nous aurons reçu les fonds sur notre compte bancaire.",
  bank_card_instruction_title: "Le plus pratique",
  bank_card_instruction_description:
    "Vous serez redirigé vers notre partenaire Monext - Payline pour effectuer votre paiement Le transfert sera exécuté immédiatement après votre paiement.",
  max_limit: "Limite maximale: ",
  remove_favourite_success: "Favorit supprimé avec succès",
  usertype_info_1: "Pour ouvrir un compte Entreprise, ",
  usertype_info_2: "il faut avoir une entreprise légalement créée ",
  usertype_info_3: "(avec des justificatifs légaux tels que les statuts...). ",
  usertype_info_4: "Si vous n'avez pas d'entreprise formelle, ",
  usertype_info_5: "veuillez créer un compte Particulier.",
  usertype_info_modal_title: "Êtes-vous sur de votre choix ?",
  usertype_info_modal_body_1: "Vous souhaitez créer un compte Entreprise. ",
  usertype_info_modal_body_2: " Pour pouvoir utiliser nos services, vous devez ",
  usertype_info_modal_body_3: "nous fournir des justificatifs légaux ",
  usertype_info_modal_body_4: "(statuts de l'entreprise etc.). ",
  usertype_info_modal_body_5: "Confirmez-vous ce choix ?",
  usertype_info_modal_newbody_1: "Vous vous apprêtez à créer un ",
  usertype_info_modal_newbody_2: "compte entreprise",
  usertype_info_modal_back: "Non, je modifie",
  usertype_info_modal_continue: "Je confirme",
  bonus: "Bonus",
  bonus_description: "Nous offrons à votre bénéficiaire un bonus gratuit de  ",
  invalid_phone_number: 'Numéro de téléphone invalide. Veuillez saisir un numéro de mobile valide au format correct.',
  remove: "Supprimer",
  otp_failed_default_error: "La validation OTP a échoué, veuillez réessayer plus tard",
  resend_in: "Renvoyer dans ",
  seconds: "secondes",
  otp_failed_default_error: "La validation OTP a échoué, veuillez réessayer plus tard.",
  otp_limit_error: "Trop d'essais. Veuillez réessayer après 2 minutes.",
  umo_description: "L’option de résidence de l’Union Monétaire Ouest Africaine (UMO) est disponible pour les utilisateurs résidant dans les pays de l’Union économique et monétaire ouest-africaine. La sélection de cette option signifie que vos activités financières seront réglementées conformément aux normes et réglementations de l'UMO.",
  non_umo_description: "L’option de résidence dans l’UE est disponible pour les utilisateurs résidant dans l’Union européenne. La sélection de cette option signifie que vos activités financières seront réglementées selon les normes et réglementations de l’Union européenne.",
  umo_description_error: "Cette option n'est pas être disponible pour les utilisateurs s'inscrivant avec un numéro de téléphone européen.",
  non_umo_description_error: "Cette option n'est pas disponible pour les utilisateurs s'inscrivant avec un numéro de téléphone africain.",
  inhuman_name_error: "Le nom saisi ne semble pas être un vrai nom. Veuillez saisir un nom valide.",
  danapay_account: "Compte Danapay",
  danapay_account_tooltip: `Le bénéficiaire doit faire un retrait depuis son compte pour récupérer les fonds.`,
  account_ending_with: "Compte finissant par ",
  see_details: "Voir le details",
  transaction_reference: "Référence de la transaction",
  send_funds_via: "Vous avez choisi d’envoyer les fonds via",
  sender_funds_via: "Expéditeur choisi pour envoyer les fonds via",
  you_sent: "Vous avez envoyé",
  you_received: "Vous avez reçu",
  sender_sent: "Expéditeur envoyé",
  danapay_fees: "Frais Danapay",
  received: "a reçu",
  on: "sur",
  beneficiary_details: "Coordonnées du bénéficiaire",
  full_name: "Nom complet",
  email_address: "Adresse email",
  not_received_funds_contact_whatsapp_sender: "Votre destinataire n’a toujours pas reçu les fonds ? Veuillez nous contacter via le chat ou ",
  not_received_funds_contact_whatsapp_receiver: "Vous n'avez toujours pas reçu les fonds ? Veuillez nous contacter par chat ",
  on_whatsapp: "sur WhatsApp",
  upload_file_placeholder: "Déposer le fichier à joindre ou cliquer ici pour les parcourir",
  document_upload_message: "Pensez également à conserver l’original de vos justificatifs. Ils peuvent toujours vous être utiles pour des raisons comptables ou légales.",
  inhuman_name_error: "Le nom saisi ne semble pas être un nom humain. Veuillez saisir un nom valide.",
  go_back_and_change: "Revenir en arrière et changer",
  proceed: "Continuer avec ce nom.",
  enterpreneur_label: "Je suis une entreprise unipersonnelle",
  enterpreneur_error: "Veuillez indiquer si votre entreprise est une entité entrepreneuriale ou non",
  eu_description: "En sélectionnant cette case, vous confirmez que vous disposez de la nationalité d'un des pays de l'Union Européenne et que vous y résidez de façon permanente.",
  non_eu_description: "En sélectionnant cette case, vous confirmez que vous êtes originaire d'un pays hors de l'Union Européenne mais que vous y résidiez de façon permanente.",
  post_code_placeholder: "Code Postal",
  post_code_error: "Vous êtes dans un pays où le code postal est obligatoire. Veuillez entrer votre code postal.",
  post_code_optional: "Le code postal est facultatif pour ce pays.",
  Flyers: "Flyers",
  max_amount_exceeded_cashout_message_1: "Montant maximum (",
  max_amount_exceeded_cashout_message_2: ") dépassé pour ce mode de retrait. Veuillez réduire votre montant ou choisir un autre mode de retrait",
  after_bank_transfer_message_1: "Veuillez vous assurer que vous envoyez les fonds depuis un ",
  after_bank_transfer_message_2: "compte bancaire enregistré à votre nom. ",
  after_bank_transfer_message_3: "L'utilisation d'un compte bancaire qui ne vous appartient pas entraînera la restitution des fonds, ce qui retardera la transaction.",
  bank_transfer_success_title: "Merci pour votre transaction.",
  bank_transfer_success_subtext: "Il est maintenant temps d'effectuer un virement bancaire vers DanaPay. Veuillez sélectionner un compte bancaire pour finaliser le transfert.",
  select_channel: "Sélectionnez votre chaîne",
  otp_verification_description: "Nous enverrons un OTP à votre canal préféré. Veuillez sélectionner votre chaîne et continuer",
  validationunique: "Cette adresse email est déjà associée à un compte. Veuillez vous connecter ou réinitialiser votre mot de passe si vous l'avez oublié.",
  aborted: "Annulé",
  send_otp: "Envoyer OTP",
  send_otp: "Envoyer OTP",
  sorry: "Désolé",
  no_withdraw_modes: "Configuration introuvable. Veuillez contacter l'équipe d'assistance de Danapay",
  download_the_receipt: "Télécharger le justificatif",
  repeat_the_transaction: "Répéter l'opération",
  professional_account: "Compte Professionnel",
  sent: "Envoi",
  transaction_status: "Statut de la transaction",
  recommend_us_link: "Vous avez aimé votre expérience ? Cliquez ici pour nous recommander",
  fee_paid: "Frais payé",
  mobile: "Mobile",
  bank: "Banque",
  delivery: "Livraison",
  mfi: "Microfinance",
  number: "Numéro",
  payment_initiated: "Initiation du transfert",
  fund_received_by_danapay: "Réception des fonds par Danapay",
  the_transfer_is_in_progress: "Transmission des fonds",
  fund_received_by_the_beneficiary: "Réception des fonds par ",
  on_hold: "En attente",
  reception: "Réception",
  download_receipt: "Télécharger le reçu",
  fund_received_on_your_danapay_wallet: "Fonds reçu sur votre compte Danapay",
  fund_deposited_on_the_user_danapay_wallet: "Réception des fonds par Danapay",
  funds_received_on_your: "Funds received on your",
  account: "account",
  transfer_completed_successfully: "Transfert exécuté avec succès",
  amount_to_withdraw: "Montant à retirer",
  withdaw_details: "Détails du retrait",
  specify_withdraw_details: "Précisez les détails de votre retrait",
  you_have_balance_1: "Vous avez un solde de ",
  you_have_balance_2: " sur votre compte",
  click_to_withdraw: "Cliquez ici pour tout retirer",
  specify_withdraw_method: "Précisez la method de retrait",
  costs: "Frais",
  dealine: "Delai",
  ceiling: "Plafond",
  withdraw_limit_exceed: "Vous ne pouvez pas utiliser cette méthode car le montant dépasse la limite autorisée",
  mm_desc: "Assurez-vous que votre numéro est le bon car nous ne faisons aucune vérification avant l'envoi.",
  choose_operator: "Choisissez l'opérateur",
  choose_new_number: "Choisir un nouveau numéro",
  bank_desc: "Vous devez être titulaire du compte bancaire précisé",
  account_holder: "Titulaire du compte",
  choose_bank: "Choisissez la banque",
  choose_iban: "Choisir un nouvel IBAN",
  bank: "Banque",
  mobile: "Mobile",
  verification_code: "Le code de vérification",
  verification_choose_method: "Choisissez le mode de réception du code de confirmation, puis validez",
  click_receive_code: "Cliquez ici pour recevoir le code",
  enter_code_received: "Entrez le code reçu",
  submit_code: "Soumettre le code",
  resend_withdraw_code_1: "Vous pourrez renvoyer le code dans ",
  resend_withdraw_code_2: " secondes",
  withdraw_confirmation_title: "Confirmation de votre retrait",
  withdraw_request_taken: "Votre demande de retrait a bien été prise en compte",
  receipt_account: "Compte de réceptiion",
  total_to_pay: "Total à payer",
  mobile_money_withdraw_success_message: "Vous devriez recevoir les fonds dans un délai de 5mn. Votre solde sera débiter du montant de l'opération.",
  bank_success_withdraw_message: "Vous devriez recevoir les fonds dans un délai de 72h. Votre solde sera débiter du montant de l'opération.",
  delivery_success_withdraw_message: "Vous devriez recevoir les fonds dans un délai de 6h. Votre solde sera débiter du montant de l'opération.Vous serez contactés par nos équipes pour convenir des modalités.",
  "No.": "N°",
  sms: "SMS",
  whatsapp: "Whatsapp",
  home_page: "Page d'accueil",
  verification: "Vérification",
  temp_restriction: "Votre compte est actuellement restreint. Vous avez uniquement accès au paiement par virement. Après votre première opération, vous aurez accès à l’ensemble des paiements",
  is_invalid: " n'est pas valide",
  fee_paid: "Frais payé",
  receiving_mode: "Mode de réception",
  withdraw_number: "Numéro",
  mfi_owner_id: "Identifiant du propriétaire de l'IMF",
  mfi_name: "Nom de l'IMF",
  temp_restriction: "Votre compte est actuellement restreint. Vous avez uniquement accès au paiement par virement. Après votre première opération, vous aurez accès à l’ensemble des paiements",
  is_invalid: " n'est pas valide",
  funds_in_danapay_account: "Votre bénéficiaire recevra les fonds sur son compte Danapay",
  action_required_message: "Votre transaction a été suspendue. Veuillez transmettre vos trois derniers relevés de compte ou vos trois dernières fiche de paie à l'adresse e-mail conformite@danapay.com",
  action_required_main_message: 'En attente de justificatif',
  payment_cancelled: "Votre paiement a été annulé. Veuillez réessayer avec un autre moyen de paiement",
  payment_failed_receiver_message: "Le paiement de cette opération a échoué. Votre envoyeurinitiera probablement une autre transaction sous peu.",
  payment_failed_sender_message: "Votre paiement a échoué. Veuillez réessayer avec un autre moyen de paiement",
  transaction_suspended: "Votre transaction a été suspendue. Veuillez transmettre vos trois derniers relevés de compte ou vos trois dernières fiche de paie à l'adresse e-mail conformite@danapay.com",
  business_signup_description_1: "Pour créer un compte entreprise, ",
  business_signup_description_2: "vous devez déjà  disposer d'une entreprise légalement établie. ",
  business_signup_description_3: "Les document légaux pourraient vous être demandés lors de votre inscription.",
  enter_info: "Saisissez vos informations",
  select_gender_placeholder: "Sélectionnez votre genre",
  income_details: "Détails sur le revenu",
  income_note_1: "Nous utilisons ces informations pour ",
  income_note_2: "ajuster votre plafond ",
  income_note_3: "d'envoi en fonction de vos revenus. Plus vos revenus sont élevés, plus votre plafond est élevé. Ces informations restent strictement confidentielles et sont traitées ",
  income_note_4: "conformément au RGPD.",
  individual_specify_section: "Précisez le secteur d'activité dans lequel vous travaillez",
  individual_income: "Tranche de revenu",
  country_destination: "Pays de destination des fonds",
  countries_to_send: "Précisez les pays vers lesquels vous allez faire des envois",
  current_residence: "Résidence actuelle",
  specify_residency: "Précisez votre situation résidentielle actuelle",
  select_otp_channel: "Veuillez sélectionner le canal sur le quel vous souhaitezrecevoir le code de vérification de votre numéro",
  enter_code: "Entrez le code reçu",
  click_to_receive_code: "Cliquez ici pour recevoir le code",
  resend_code_in: "Vous pourrez renvoyer le code dans ",
  gender_required: "Le sexe est un champ obligatoire",
  company_activity_title: "Informations de l'entreprise",
  company_envisioned_countries_subtitle: "Spécifiez les pays avec lesquels vous souhaitez faire des transactions",
  is_required: "est requis",
  transfer_success_message_sender: "Transfert exécuté avec succès.",
  transfer_success_message_receiver: "Transfert reçu avec succès.",
  danapay_account_infomessage: "Votre bénéficiaire recevra les fonds sur son compte Danapay.",
  mm_infomessage: "Votre bénéficiaire recevra les fonds sur son compte Mobile Money.",
  bank_infomessage: "Votre bénéficiaire recevra les fonds sur son compte bancaire.",
  delivery_infomessage: "Votre bénéficiaire recevra les fonds par livraison",
  cancel_sender_infomessage: "Votre paiement a été annulé, veuillez réessayer avec un autre moyen de paiement.",
  cancel_receiver_infomessage: "Le paiement de cette opération a échoué. Votre envoyeur initiera probablement une autre transaction sous peu.",
  aborted_sender_infomessage: "Votre paiement a échoué, veuillez réessayer avec un autre moyen de paiement.",
  aborted_receiver_infomessage: "Le paiement de cette opération a échoué. Votre envoyeur initiera probablement une autre transaction sous peu.",
  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
  Saturday: "Samedi",
  Sunday: "Dimanche",
  at: "à",
  Jan: "Janvier",
  Feb: "Février",
  Mar: "Mars",
  Apr: "Avril",
  May: "Mai",
  Jun: "Juin",
  Jul: "Juillet",
  Aug: "Août",
  Sep: "Septembre",
  Oct: "Octobre",
  Nov: "Novembre",
  Dec: "Décembre",
  enter_phone_number: "Entrez votre numéro de téléphone",
  add_contacts_and_initiate: "Ajoutez des contacts et initiez vos transactions en un seul click",
  individuals: "Particuliers",
  businesses: "Entreprises",
  select_a_contact: "Sélectionnez un contact",
  search_for_contact: "Recherchez un contact",
  transfer: "transférer",
  introduce_danapay: "Faites découvrir Danapay",
  do_you_like_danapay: "Vous aimez Danapay? Aidez-nous à nous faire connaître auprès de vos proches et partenaires",
  sponsor_loved_one: "Parrainez un proche",
  leave_review: "Laissez un avis",
  transfer_in_progress: "En cours",
  make_a_deposit_btn: "Effectuer un dépôt",
  make_a_transfer_btn: "Effectuer un transfert",
  make_a_withdraw_btn: "Effectuer un retrait",
  all: "Les",
  sponsorship: "Parrainage",
  help: "Aide",
  identification_required: "Identification requise",
  identification_required_details: "Afin d'utiliser nos services, il est nécessaire que nous vérifions votre identité.",
  finalize_verification: "Finaliser ma vérification",
  verification_in_progress: "Vérification en cours",
  verification_in_progress_details: "Nous avons bien reçu l'ensemble de vos documents et votre compte est actuellement en cours de vérification. Vous recevrez un email une fois votre compte activé",
  verification_verified: "Patientez encore un peu",
  action_required: "Action requises",
  action_required_details: "Votre compte est temporairement inaccessible. Nous avons besoin d'informations ou de documents supplémentaires de votre part",
  update_information: 'Mettre à jour mes informations',
  verification_verified_details: "Notre partenaire SumSub a bien vérifié votre identité, mais nous réalisons quelques contrôles additionnels en interne. Vous recevrez un mail une fois votre compte activé.",
  please_log_in: "Veuillez vous connecter",
  choose_connection_method: "Choisissez un mode de connexion pour démarrer",
  by_email: "Par mail",
  by_telephone: "Par téléphone",
  no_account: "Je n'ai pas de compte. ",
  terms_conditions_privacy_policies: "Conditions générales et politique de confidentialité",
  you_will_receive_code_sms: "Vous allez recevoir un code par SMS.",
  you_will_receive_code_whatsapp: "Vous allez recevoir un code par Whatsapp.",
  receive_code_via_whatsapp: "Recevoir plutôt par Whatsapp",
  receive_code_via_sms: "Recevoir plutôt par SMS",
  enter_otp_received: "Entrez le code OTP reçu",
  resend_code: "Renvoyer le code",
  log_in: "Se connecter",
  invalid_otp: "Le code OTP que vous avez entré est invalide.",
  alert: "Alerte",
  fake_name_warning: "Votre nom ou prénom semble faux. Voulez-vous continueravec ces informations ?",
  phone_already_exists: "Ce numéro de téléphone est déjà utilisé. Veuillez en utiliser un autre.",
  "Phone number provided is invalid": "Le numéro de téléphone fourni n'est pas valide",
  use_another_method: "Utiliser une autre méthode",
  withdrawal: "Retrait",
  completed_successfully: "exécuté avec succès",
  the_phone_number_may_not_be_greater_than_12_characters:
    "Le numéro de téléphone ne doit pas dépasser 12 caractères",
  phone_number_below_min_length: "Le numéro de téléphone est inférieur à la longueur minimale",
  phone_number_below_max_length: "Le numéro de téléphone dépasse la longueur maximale",
  'iban_validation.unique': "Cet IBAN est déjà utilisé. Veuillez utiliser un autre compte.",
  no_fee_config_withdraw_error: "Impossible de récupérer la configuration des frais. Veuillez contacter le support.",
  restricted_account: "Compte restreint",
  restricted_account_message: "Votre compte a été activé et vous pouvez effectuer des transferts. Mais, afin d'avoir accès à l'ensemble des moyens de paiement, veuillez effectuer un premier transfert  en payant par virement bancaire.",
  confirmation_message_company_presumsub_1: "Vous vous apprêtez à fournir les documents légaux de votre entreprise.",
  confirmation_message_company_presumsub_2: "Certifiez-vous conforme la liste des bénéficiaires effectifs que vous allez déclarer ?",
  I_ceritfy: "Je certifie",
  benin_phone_error: "Format de numéro de téléphone invalide pour le Bénin. Veuillez utiliser le +229 01 XX XX XX XX.",
  please_register: "Veuillez vous inscrire",
  danapay_experience: "Votre expérience Danapay commence ici",
  already_account: "J'ai déjà un compte.",
  log_in_v3: "Me connecter",
  confirmation_email_sent: "Mail de confirmation envoyé",
  resend_new_link: "Renvoyer un nouveau lien",
  neero_wallet_description: "Votre bénéficiaire recevra les fonds sur son portefeuille ",
  neero: "Neero",
  wallet_account_number: "Numéro de compte portefeuille",
  Add_Neero_account: "Ajouter un portefeuille Neero",
  Neero_accounts: "Comptes de portefeuille Neero",
  choose_phone_number: "Choisissez un numéro de téléphone"
};




