import { Modal } from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import InputField from "../forms/InputField";
import PhoneNumberPicker from "../SignUp/PhoneNumberPicker";
import FormErrorText from "../FormErrorText";
import CustomButton from "../CustomButton";
import { useAppSelector } from "../../store/hooks";
import { toast } from "material-react-toastify";
import {
  createNeeroWallet,
  editNeeroWallet,
} from "../../store/features/Auth/Auth";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js/max";

const NeeroWalletModal = (props: any) => {
  const { open, onClose = () => {} } = props;
  const { t } = useTranslation();
  const { auth } = useAppSelector((state) => state.persistedReducer);

  const validationSchema = yup.object().shape({
    title: yup.string().required("title_required"),
    country: yup.string().required(),
    owner_name: yup.string().required("owner_name_required"),
    country_code: yup.string().required(),
    wallet_account_number: yup
      .string()
      .required("PhoneNumberError")
      .test(
        "yourTestCondition",
        "invalid_phone_number",
        function (value: any, context: any) {
          const receipentNumber =
            "+" + context.parent.country_code + value?.toString();

          if (context.parent.country_code == 229) {
            return true;
          }

          if (
            !isValidPhoneNumber(receipentNumber) ||
            !isPossiblePhoneNumber(receipentNumber)
          ) {
            if (
              validatePhoneNumberLength(receipentNumber) == undefined ||
              validatePhoneNumberLength(receipentNumber) == "INVALID_COUNTRY"
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      )
      .test(
        "yourTestCondition",
        "phone_number_below_min_length",
        function (value: any, context: any) {
          const receipentNumber =
            "+" + context.parent.country_code + value?.toString();

          if (context.parent.country_code == 229) {
            if (value?.length < 10) {
              return false;
            } else {
              return true;
            }
          } else {
            if (validatePhoneNumberLength(receipentNumber) == "TOO_SHORT") {
              return false;
            } else {
              return true;
            }
          }
        }
      )
      .test(
        "yourTestCondition",
        "phone_number_below_max_length",
        function (value: any, context: any) {
          const receipentNumber =
            "+" + context.parent.country_code + value?.toString();

          if (context.parent.country_code == 229) {
            if (value?.length > 10) {
              return false;
            } else {
              return true;
            }
          } else {
            if (validatePhoneNumberLength(receipentNumber) == "TOO_LONG") {
              return false;
            } else {
              return true;
            }
          }
        }
      )
      .test(
        "yourTestCondition",
        "benin_phone_error",
        function (value: any, context: any) {
          if (
            context.parent.country_code == 229 &&
            value &&
            value.length == 10 &&
            value.slice(0, 2) == "01"
          ) {
            return true;
          } else if (context.parent.country_code != 229) {
            return true;
          } else {
            return false;
          }
        }
      ),
  });

  const handleSubmit = (data: any, setsubmitting: any) => {
    try {
      setsubmitting(true);

      // add
      if (auth?.selected_account == null) {
        createNeeroWallet(auth?.user?.id, {
          ...data,
        })
          .catch((error: any) => toast.warning(t(error?.data?.message)))
          .finally(() => {
            setsubmitting(false);
            onClose();
          });
      } else {
        editNeeroWallet(auth?.selected_account?.id, {
          ...data,
        })
          .catch((error: any) => toast.warning(t(error?.data?.message)))
          .finally(() => {
            setsubmitting(false);
            onClose();
          });
      }
    } catch (error) {
      setsubmitting(false);
      onClose();
      Sentry.captureException(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex h-screen w-full items-center justify-center overflow-scroll ScrollableModal">
        <div className=" m-auto max-h-full min-w-full">
          <div className="flex justify-center items-center h-full"></div>
          <div className="p-3 bg-white rounded col-11 col-md-8 col-lg-6 addaccountmodal mx-auto mb-4">
            <div className="flex flex-row items-center justify-between">
              <h3>
                {auth?.selected_account == null
                  ? t("Add_New_Account")
                  : t("Edit_Account")}
              </h3>
              <button
                onClick={onClose}
                style={{ height: 40, width: 40, borderRadius: 20 }}
                className="flex items-center justify-center shadow-lg"
              >
                <i className="m-0 fa fa-close"></i>
              </button>
            </div>
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                title: auth?.selected_account?.title || "",
                country: "Cameroon",
                owner_name: auth?.selected_account?.account?.owner_name || "",
                country_code: "237",
                wallet_account_number:
                  auth?.selected_account?.wallet_account_number || "",
              }}
              onSubmit={(data: any, { setSubmitting }) => {
                try {
                  handleSubmit(data, setSubmitting);
                } catch (error: any) {
                  Sentry.captureException(error);
                }
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
                setTouched,
                setValues,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <InputField
                    extraClasses="mb-3 customInputStyle"
                    name="title"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("Account_Title")}
                    value={values.title}
                    label={t("Account_Title")}
                    error={t(`${errors.title}`)}
                    touched={touched.title}
                  />
                  <InputField
                    extraClasses="mb-3 customInputStyle"
                    name="owner_name"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("OwnerName")}
                    value={values.owner_name}
                    label={t("OwnerName")}
                    error={t(`${errors.owner_name}`)}
                    touched={touched.owner_name}
                  />

                  <div className="mb-3">
                    <PhoneNumberPicker
                      labelClasses="!mb-3 mt-2 text-gray-600 text-[12.6px]"
                      label="enter_phone_number"
                      type="number"
                      phoneNumberClasses="ml-2 w-full"
                      placeholder={t("enter_phone_number")}
                      country={values?.country}
                      value={values?.wallet_account_number}
                      handleChange={(e: any) => {
                        setTouched({ ...touched, wallet_account_number: true });
                        handleChange("wallet_account_number")(e.target.value);
                      }}
                      mainContainerClasses="w-full relative"
                    />
                    {errors.wallet_account_number &&
                      touched.wallet_account_number && (
                        <FormErrorText
                          errorMessage={errors.wallet_account_number}
                          classNames="text-xs"
                        />
                      )}
                  </div>

                  <CustomButton
                    disabled={!isValid || isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                    label={auth?.selected_account == null ? "add" : "edit"}
                    classNames="w-full min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                  />
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NeeroWalletModal;
