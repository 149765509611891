import { Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";

const BulkUploadSuccessModal = (props: any) => {
  const {
    openSucessDialog,
    handleClose,
    dataSucessCSV,
    setopenSucessDialog,
    setshowTableCSV,
    setFileCSV,
  } = props;
  const { t } = useTranslation();
  return (
    <Dialog open={openSucessDialog} onClose={() => handleClose}>
      <div
        className=" flex-col flex p-4 col-md-12 justify-center success-box-model"
        style={{ alignItems: "center" }}
      >
        <div className="flex items-center justify-center mb-6">
          <p className="font-bold text-center text-2xl">
            {t("upload_beneficiaries_details")}
          </p>
        </div>
        <img
          src="/images/sucess-check-circle.png"
          alt="sucess-check-circle.png"
          width={90}
        />
        <p className="font-bold text-center my-2">
          {`${
            dataSucessCSV?.data === undefined
              ? dataSucessCSV.length
              : dataSucessCSV?.data?.length
          }`}{" "}
          {t("Upload_Rows")}
        </p>

        <CustomButton
          label="continue"
          onClick={() => {
            setopenSucessDialog(false);
            setFileCSV(dataSucessCSV);
            setshowTableCSV(true);
          }}
          classNames="w-full mt-4 flex-1 min-h-[48px] rounded-xl text-white capitalize text-sm font-bold bg-black"
        />
      </div>
    </Dialog>
  );
};

export default BulkUploadSuccessModal;
