import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { getCountryByCode, truncateLongString } from "../../utilities/help";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";

import {
  addBeneficiary,
  addTransferCountry,
  getAllMobileOperatorWithCode,
  setBeneFound,
  setStep,
  setTransferDialogOpen,
} from "../../store/features/Transfer/TransferSlice";
import { getAllMobileOperatorWithCountryCode } from "../../store/features/Transfer/Transfer";
import { extractError } from "../../utility";
import { toast } from "material-react-toastify";
import { useMemo, useState } from "react";
import AddContactModal from "./AddContactModal";

const ContactScroll = (props: any) => {
  const { selectedCategory } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    transfer,
    auth: { favorites },
  } = useAppSelector((state) => state.persistedReducer);

  const moveToTransfer = (user: any) => {
    // set hub accounts according to country of user(recipient)

    try {
      const beneficiary_country = getCountryByCode(
        transfer.danaPayCountries,
        user?.country_code
      );
      dispatch(addBeneficiary(user));
      dispatch(setBeneFound(true));
      dispatch(addTransferCountry(beneficiary_country));
      dispatch(setStep(0));
      dispatch(setTransferDialogOpen(!transfer.isTransferDialogOpen));
      const filterOperator: any = transfer.danaPayCountries.filter(
        (e: any) => e.country_code?.toString() === user?.country_code.toString()
      );
      getAllMobileOperatorWithCountryCode(filterOperator[0]?.code)
        .then((res: any) => {
          dispatch(getAllMobileOperatorWithCode(res));
        })
        .catch((error: any) => {
          if (error) {
            toast.error(t(extractError(error)));
          }
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const filteredFavourites = useMemo(() => {
    try {
      if (favorites && favorites.length > 0) {
        return favorites.filter((fav: any) => {
          if (
            fav.favorite?.is_individual &&
            selectedCategory == "individuals"
          ) {
            return true;
          } else if (
            fav.favorite.company !== null &&
            selectedCategory !== "individuals"
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  }, [favorites, selectedCategory]);

  const [addContactModalOpen, setaddContactModalOpen] = useState(false);
  return (
    <>
      <AddContactModal
        open={addContactModalOpen}
        onClose={() => setaddContactModalOpen(false)}
      />
      <div
        className="min-h-[79px] gap-4 flex items-center overflow-scroll !mt-3"
        style={{ width: "inherit" }}
      >
        <div
          className="flex flex-col items-center min-h-[90px] cursor-pointer"
          onClick={() => setaddContactModalOpen(true)}
        >
          <div
            style={{ height: 55, width: 55, borderRadius: 50 }}
            className="flex items-center justify-center bg-black"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#ffffff"
              viewBox="0 0 256 256"
            >
              <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
            </svg>
          </div>
          <small
            className="text-center text-gray-700 capitalize"
            style={{ fontSize: 11, marginTop: 5 }}
          >
            {t("add_user")}
          </small>
        </div>

        {filteredFavourites &&
          filteredFavourites.map((contact: any) => {
            return (
              <div
                style={{ minHeight: 90 }}
                className={`flex flex-col items-center cursor-pointer`}
                onClick={() => moveToTransfer(contact?.favorite)}
              >
                <Avatar
                  name={contact?.favorite?.full_name}
                  size="55"
                  round
                  textSizeRatio={3}
                />
                <small
                  className={`opacity-75 text-center capitalize word-break`}
                  style={{ fontSize: 11, marginTop: 5 }}
                >
                  {truncateLongString(contact?.favorite?.full_name, 12)}
                </small>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ContactScroll;
