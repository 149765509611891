import { useTranslation } from "react-i18next";
import UserSummary from "../MakeTransfer/UserSummary";
import { useAppSelector } from "../../store/hooks";
import MobileWithdrawBox from "./MobileWithdrawBox";
import { useEffect, useMemo, useState } from "react";
import BankWithdrawBox from "./BankWithdrawBox";
import {
  createMFIAccount,
  dynamicGetEndpoint,
  getAccounts,
  getAllMobileOperatorWithCountryCode,
  getAppliedFeesForWithdraws,
  getMFIAccounts,
  getUsersMFIAccounts,
} from "../../store/features/Transfer/Transfer";
import * as Sentry from "@sentry/browser";
import { toast } from "material-react-toastify";
import DeliveryWithdrawBox from "./DeliveryWithdrawBox";
import {
  addMMAccount,
  addUserBankAccount,
  createNeeroWallet,
  fetchMMAccounts,
  fetchUserBankAccounts,
  getAllUserNeeroWallets,
} from "../../store/features/Auth/Auth";
import {
  getCountryByCode,
  isEmpty,
  removeDuplicateObjectsFromArray,
  useDebounce,
} from "../../utilities/help";
import {
  setAccounts,
  setMFIAccounts,
  setMMAccounts,
  setNeeroWallets,
} from "../../store/features/Auth/AuthSlice";
import { useDispatch } from "react-redux";
import {
  getAllMobileOperatorWithCode,
  setWithdrawInfo,
} from "../../store/features/Transfer/TransferSlice";
import { extractError } from "../../utility";
import Skeleton from "react-loading-skeleton";
import CustomButton from "../CustomButton";
import NeeroWalletWithdrawBox from "./NeeroWalletWithdrawBox";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js/max";
const RATE = 655.957;

const WithdrawDetails = (props: any) => {
  const {
    toWithDraw,
    setToWithDraw,
    setMethod,
    cashoutMethod,
    directPaymentAmountExceed,
    newCashoutSubmission,
    method,
    isUserRestricted,
    checkingForRestriction,
    loading,
  } = props;
  const { t } = useTranslation();
  const {
    auth: {
      user,
      mobile_accounts,
      bank_accounts,
      mfi_accounts,
      neero_wallet_accounts,
      currency,
    },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useDispatch();
  const numberInputInvalidChars = ["-", "+", "e"];

  const [widthdrawMethod, setwidthdrawMethod] = useState("");

  // all direct payment dropdown listings
  // all direct payment methods
  const [allDirectPaymentMethods, setallDirectPaymentMethods] = useState([]);
  const [fetchingAllFees, setfetchingAllFees] = useState(false);

  // getting all providers for mobile, bank and mfi
  const [mobileMoneyAccounts, setmobileMoneyAccounts] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [mfiAccounts, setmfiAccounts] = useState([]);
  const [neeroAccounts, setneeroAccounts] = useState([]);

  const [fetchingAllProviderAccounts, setfetchingAllProviderAccounts] =
    useState(true);
  const other = [{ id: 999, name: "Other" }];

  // get all cashout methods
  useEffect(() => {
    try {
      const user_country = getCountryByCode(
        transfer.danaPayCountries,
        user?.country_code.toString()
      );
      getAllMobileOperatorWithCountryCode(user_country?.code)
        .then((res: any) => {
          dispatch(getAllMobileOperatorWithCode(res));
        })
        .catch((error: any) => {
          toast.error(t(extractError(error)));

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [user, transfer.danaPayCountries]);

  // all costs fetching
  const handleFeesListPopulation = async () => {
    try {
      if (parseInt(toWithDraw) < 1) {
        setfetchingAllFees(false);
        return;
      }
      setfetchingAllFees(true);
      let feesList: any = [];
      for (let index = 0; index < cashoutMethod.length; index++) {
        try {
          let response: any = await getAppliedFeesForWithdraws(
            {
              euro_amount: currency === "EUR" ? toWithDraw : toWithDraw / RATE,
              country_id: transfer?.loggedInUserCountry?.id,
              cashout_method_id: cashoutMethod[index]?.cashout_method?.id,
            },
            user?.id
          );
          feesList.push({
            ...cashoutMethod[index],
            withdrawFees: response.fee,
          });
        } catch (error) {
          feesList.push({
            ...cashoutMethod[index],
            withdrawFees: null,
          });
        }
      }
      setallDirectPaymentMethods(feesList);
      setfetchingAllFees(false);
    } catch (error) {
      Sentry.captureException(error);
      setfetchingAllFees(false);
    }
  };

  useDebounce(
    () => {
      try {
        handleFeesListPopulation();
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [toWithDraw, cashoutMethod],
    800
  );

  // mobile
  const [phone, setPhone] = useState("");
  const [selectedMobileOperator, setselectedMobileOperator] = useState("");
  const [customMobileOperator, setcustomMobileOperator] = useState("");
  const [customPhoneNumber, setcustomPhoneNumber] = useState("");

  // bank
  const [selectedBankName, setselectedBankName] = useState("");
  const [customBankName, setcustomBankName] = useState("");
  const [iBAN, setIBAN]: any = useState();
  const [customIban, setcustomIban] = useState("");

  const mobileMoneySubmission = () => {
    try {
      const isAlreadyPresent =
        mobile_accounts &&
        mobile_accounts.find((account: any) => {
          return (
            (account.operator == selectedMobileOperator ||
              account.operator == customMobileOperator) &&
            (account.phone_number == phone ||
              account.phone_number == customPhoneNumber)
          );
        });
      if (isAlreadyPresent) {
        newCashoutSubmission(isAlreadyPresent.id, "mobile");
      } else {
        const payload = {
          title: `My Mobile Money ${
            !isEmpty(phone)
              ? phone.toString().substring(-4)
              : customPhoneNumber.toString().substring(-4)
          }`,
          country: user.country,
          owner_name: user.full_name,
          country_code: user.country_code,
          operator:
            !isEmpty(selectedMobileOperator) &&
            selectedMobileOperator !== "Other"
              ? selectedMobileOperator
              : customMobileOperator,
          phone_number: !isEmpty(phone) ? phone : customPhoneNumber,
        };
        addMMAccount(payload)
          .then((res: any) => {
            fetchMMAccounts()
              .then((response) => {
                dispatch(setMMAccounts(response.data));
              })
              .catch((err: any) => {
                toast.error(t(err?.data?.message));
              })
              .finally(() => {
                newCashoutSubmission(res.mm_account.id, "mobile");
              });
          })
          .catch((err: any) => {
            toast.error(t(err?.data?.message));
          });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  const bankSubmission = () => {
    try {
      const isAlreadyPresent =
        bank_accounts &&
        bank_accounts.find((account: any) => {
          return (
            (account.bank_name == selectedBankName ||
              account.bank_name == customBankName) &&
            (account.iban == iBAN || account.iban == customIban)
          );
        });
      if (isAlreadyPresent) {
        newCashoutSubmission(isAlreadyPresent.id, "bank", isAlreadyPresent);
      } else {
        const payload = {
          title: `My Bank ${
            !isEmpty(iBAN)
              ? iBAN.toString().substring(-4)
              : customIban.toString().substring(-4)
          }`,
          country: user.country,
          owner_name: user.full_name,
          country_code: user.country_code,
          iban: !isEmpty(iBAN) && iBAN !== "Other" ? iBAN : customIban,
          // bic: null,
          bank_name:
            !isEmpty(selectedBankName) && selectedBankName !== "Other"
              ? selectedBankName
              : customBankName,
        };
        addUserBankAccount(payload)
          .then((res: any) => {
            fetchUserBankAccounts()
              .then((response) => {
                dispatch(setAccounts(response.data));
              })
              .catch((err: any) => {
                toast.error(t(err?.data?.message));
              })
              .finally(() => {
                newCashoutSubmission(res.user_bank_account.id, "bank");
              });
          })
          .catch((err: any) => {
            toast.error(t(err?.data?.message));
          });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // mfi
  const [selectedMfiName, setselectedMfiName] = useState("");
  const [customMfiName, setcustomMfiName] = useState("");
  const [mfiAccountUserName, setmfiAccountUserName] = useState("");
  const [mfiOwnerId, setmfiOwnerId] = useState("");
  const [customMfiOwnerId, setcustomMfiOwnerId] = useState("");

  const mfiSubmission = () => {
    try {
      const isAlreadyPresent =
        mfi_accounts &&
        mfi_accounts.find((account: any) => {
          return (
            (account.owner_id == mfiOwnerId ||
              account.owner_id == customMfiOwnerId) &&
            (account.mfi_name == selectedMfiName ||
              account.mfi_name == customMfiName)
          );
        });

      if (isAlreadyPresent) {
        newCashoutSubmission(isAlreadyPresent.id, "mfi");
      } else {
        const payload = {
          title: `My Mfi Account ${
            !isEmpty(mfiOwnerId)
              ? mfiOwnerId.toString().substring(-4)
              : customMfiOwnerId.toString().substring(-4)
          }`,
          country: user.country,
          owner_name: user.full_name,
          country_code: user.country_code,

          owner_id: !isEmpty(mfiOwnerId) ? mfiOwnerId : customMfiOwnerId,
          mfi_name: !isEmpty(selectedMfiName) ? selectedMfiName : customMfiName,
        };
        createMFIAccount(payload)
          .then((res: any) => {
            getUsersMFIAccounts()
              .then((response) => {
                dispatch(setMFIAccounts(response.data));
              })
              .catch((err: any) => {
                toast.error(t(err?.data?.message));
              })
              .finally(() => {
                newCashoutSubmission(res.user_mfi_account.id, "mfi");
              });
          })
          .catch((err: any) => {
            toast.error(t(err?.data?.message));
          });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  const deliverySubmission = () => {
    try {
      newCashoutSubmission("isAlreadyPresent.id", "delivery");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // neero wallet
  const [neeroWalletNumber, setneeroWalletNumber] = useState("");
  const [customNeeroWalletNumber, setcustomNeeroWalletNumber] = useState("");

  const neeroWalletSubmission = () => {
    try {
      const isAlreadyPresent =
        neero_wallet_accounts &&
        neero_wallet_accounts.find((account: any) => {
          return (
            account.wallet_account_number == neeroWalletNumber ||
            account.wallet_account_number == customNeeroWalletNumber
          );
        });
      if (isAlreadyPresent) {
        newCashoutSubmission(isAlreadyPresent.id, "wallet");
      } else {
        const payload = {
          title: `My Neero Wallet ${
            !isEmpty(neeroWalletNumber) && neeroWalletNumber !== "Other"
              ? neeroWalletNumber.toString().substring(-4)
              : customNeeroWalletNumber.toString().substring(-4)
          }`,
          country: user.country,
          owner_name: user.full_name,
          country_code: user.country_code,
          wallet_account_number:
            !isEmpty(neeroWalletNumber) && neeroWalletNumber !== "Other"
              ? neeroWalletNumber
              : customNeeroWalletNumber,
        };
        createNeeroWallet(user?.id, payload)
          .then((res: any) => {
            getAllUserNeeroWallets()
              .then((response) => {
                dispatch(setNeeroWallets(response.data));
              })
              .catch((err: any) => {
                toast.error(t(err?.data?.message));
              })
              .finally(() => {
                newCashoutSubmission(res.mm_account.id, "wallet");
              });
          })
          .catch((err: any) => {
            toast.error(t(err?.data?.message));
          });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // handle submission
  const handleSubmission = () => {
    try {
      if (widthdrawMethod == "Mobile Money") {
        mobileMoneySubmission();
        dispatch(
          setWithdrawInfo({
            operator:
              !isEmpty(selectedMobileOperator) &&
              selectedMobileOperator !== "Other"
                ? selectedMobileOperator
                : customMobileOperator,
            phone_number: !isEmpty(phone) ? phone : customPhoneNumber,
          })
        );
      } else if (widthdrawMethod == "Bank Account") {
        bankSubmission();
        dispatch(
          setWithdrawInfo({
            iban: !isEmpty(iBAN) && iBAN !== "Other" ? iBAN : customIban,
            bank_name:
              !isEmpty(selectedBankName) && selectedBankName !== "Other"
                ? selectedBankName
                : customBankName,
          })
        );
      } else if (widthdrawMethod == "Microfinance") {
        mfiSubmission();
        dispatch(
          setWithdrawInfo({
            owner_id: !isEmpty(mfiOwnerId) ? mfiOwnerId : customMfiOwnerId,
            mfi_name: !isEmpty(selectedMfiName)
              ? selectedMfiName
              : customMfiName,
          })
        );
      } else if (widthdrawMethod == "wallet") {
        neeroWalletSubmission();
        dispatch(
          setWithdrawInfo({
            neeroWalletNumber,
            customNeeroWalletNumber,
          })
        );
      } else {
        deliverySubmission();
        dispatch(setWithdrawInfo({}));
      }
    } catch (error: any) {
      toast.error(t(error?.data?.message));
      Sentry.captureException(error);
    }
  };

  // show hide withdraw methods
  const [withdrawMethods, setwithdrawMethods]: any = useState({
    mobile: false,
    bank: false,
    mfi: false,
    delivery: false,
    wallet: false,
  });

  const noWithdrawMethods = useMemo(() => {
    if (
      withdrawMethods.mobile == false &&
      withdrawMethods.bank == false &&
      withdrawMethods.wallet == false &&
      withdrawMethods.delivery == false
    ) {
      return true;
    } else {
      return false;
    }
  }, [withdrawMethods]);

  // remove other options in case of user is restriction
  useEffect(() => {
    if (!checkingForRestriction) {
      if (isUserRestricted) {
        setwithdrawMethods({
          bank: false,
        });
      } else {
        setwithdrawMethods({
          mobile: false,
          bank: false,
          mfi: false,
          delivery: false,
        });
      }
    }
  }, [checkingForRestriction, isUserRestricted]);

  // repeat show withdraw methods, set withdraw method
  useEffect(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator.length > 0 &&
        !checkingForRestriction
      ) {
        transfer?.getAllMobileOperator.forEach((cashoutMethod: any) => {
          // disabled for now
          // if (
          //   cashoutMethod.cashout_method.payment_type.name == "mfi" &&
          //   !isUserRestricted
          // ) {
          //   setwithdrawMethods((prev: any) => {
          //     return { ...prev, mfi: true };
          //   });
          //   if (cashoutMethod.cashout_method.id == method) {
          //     setwidthdrawMethod("Microfinance");
          //   }
          // } else

          if (
            cashoutMethod.cashout_method.payment_type.name == "mobile_money" &&
            !isUserRestricted
          ) {
            setwithdrawMethods((prev: any) => {
              return { ...prev, mobile: true };
            });
            if (
              cashoutMethod.cashout_method.id == method ||
              transfer?.withdraw?.withDrawMethod == "mobile_money"
            ) {
              setwidthdrawMethod("Mobile Money");
            }
          } else if (
            cashoutMethod.cashout_method.payment_type.name == "bank_transfer" ||
            cashoutMethod.cashout_method.payment_type.name ==
              "manual_bank_transfer"
          ) {
            setwithdrawMethods((prev: any) => {
              return { ...prev, bank: true };
            });
            if (
              cashoutMethod.cashout_method.id == method ||
              transfer?.withdraw?.withDrawMethod == "bank_transfer" ||
              transfer?.withdraw?.withDrawMethod == "manual_bank_transfer"
            ) {
              setwidthdrawMethod("Bank Account");
            }
          } else if (
            cashoutMethod.cashout_method.payment_type.name == "delivery" &&
            !isUserRestricted
          ) {
            setwithdrawMethods((prev: any) => {
              return { ...prev, delivery: true };
            });
            if (
              cashoutMethod.cashout_method.id == method ||
              transfer?.withdraw?.withDrawMethod == "delivery"
            ) {
              setwidthdrawMethod("delivery");
            }
          } else if (
            cashoutMethod.cashout_method.payment_type.name == "wallet" &&
            !isUserRestricted &&
            user?.country?.toLowerCase() == "cameroon"
          ) {
            setwithdrawMethods((prev: any) => {
              return { ...prev, wallet: true };
            });
            if (
              cashoutMethod.cashout_method.id == method ||
              transfer?.withdraw?.withDrawMethod == "wallet"
            ) {
              setwidthdrawMethod("wallet");
            }
          }
        });
      } else {
        setwithdrawMethods({
          mobile: false,
          bank: false,
          mfi: false,
          delivery: false,
          wallet: false,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [
    transfer?.getAllMobileOperator,
    method,
    isUserRestricted,
    checkingForRestriction,
  ]);

  // repeat provider selection
  useEffect(() => {
    try {
      if (transfer?.withdraw?.data && !fetchingAllProviderAccounts) {
        // bank
        if (transfer?.withdraw?.data?.bank_account_id) {
          const selectedBank = bank_accounts.find((account: any) => {
            return account.id == transfer?.withdraw?.data?.bank_account_id;
          });
          if (selectedBank) {
            setselectedBankName(selectedBank.bank_name);
            setIBAN(selectedBank.iban);
          }
        }

        // mobile
        if (transfer?.withdraw?.data?.mobile_money_account_id) {
          const selectedMMAccount = mobile_accounts.find((account: any) => {
            return (
              account.id == transfer?.withdraw?.data?.mobile_money_account_id
            );
          });
          if (selectedMMAccount) {
            setselectedMobileOperator(selectedMMAccount.operator);
            setPhone(selectedMMAccount.phone_number);
          }
        }

        // neero
        if (transfer?.withdraw?.data?.retry_payload?.wallet_account_id) {
          const selectedWalletAccount = neero_wallet_accounts.find(
            (account: any) => {
              return (
                account.id ==
                transfer?.withdraw?.data?.retry_payload?.wallet_account_id
              );
            }
          );
          if (selectedWalletAccount) {
            setneeroWalletNumber(selectedWalletAccount.wallet_account_number);
          }
        }

        // mfi
        // disabled for now
        // if (transfer?.withdraw?.data?.mfi_account_id) {
        //   const selectedMFIccount = mfi_accounts.find((account: any) => {
        //     return account.id == transfer?.withdraw?.data?.mfi_account_id;
        //   });
        //   if (selectedMFIccount) {
        //     setselectedMfiName(selectedMFIccount.mfi_name);
        //     setmfiOwnerId(selectedMFIccount.owner_id);
        //   }
        // }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [
    transfer?.withdraw,
    bank_accounts,
    mobile_accounts,
    fetchingAllProviderAccounts,
  ]);

  const [withdrawError, setwithdrawError] = useState("");
  const isValid = useMemo(() => {
    try {
      if (
        user.balance_in_euro == 0 ||
        user.balance_in_euro == "0" ||
        directPaymentAmountExceed ||
        fetchingAllFees ||
        fetchingAllProviderAccounts ||
        toWithDraw == 0 ||
        toWithDraw == "0" ||
        isEmpty(widthdrawMethod)
      ) {
        setwithdrawError("");
        return false;
      }

      if (widthdrawMethod == "Mobile Money") {
        if (
          (isEmpty(selectedMobileOperator) && isEmpty(customMobileOperator)) ||
          (selectedMobileOperator == "Other" &&
            isEmpty(customMobileOperator)) ||
          (isEmpty(customPhoneNumber) && isEmpty(phone))
        ) {
          setwithdrawError("invalid_phone_number");
          return false;
        } else {
          setwithdrawError("");
          return true;
        }
      } else if (widthdrawMethod == "Bank Account") {
        if (
          (isEmpty(selectedBankName) && isEmpty(customBankName)) ||
          (selectedBankName == "Other" && isEmpty(customBankName)) ||
          (isEmpty(iBAN) && isEmpty(customIban))
        ) {
          setwithdrawError("Invalid IBAN");
          return false;
        } else {
          setwithdrawError("");
          return true;
        }
      } else if (widthdrawMethod == "Microfinance") {
        if (
          (isEmpty(selectedMfiName) && isEmpty(customMfiName)) ||
          (selectedBankName == "Other" && isEmpty(customMfiName)) ||
          (isEmpty(mfiOwnerId) && isEmpty(customMfiOwnerId))
        ) {
          return false;
        } else {
          return true;
        }
      } else if (widthdrawMethod == "wallet") {
        if (
          (isEmpty(neeroWalletNumber) && isEmpty(customNeeroWalletNumber)) ||
          (neeroWalletNumber == "Other" && isEmpty(customNeeroWalletNumber))
        ) {
          setwithdrawError("PhoneNumberError");
          return false;
        } else {
          const phoneNumber = `+${user?.country_code}${
            !isEmpty(neeroWalletNumber) && neeroWalletNumber !== "Other"
              ? neeroWalletNumber
              : customNeeroWalletNumber
          }`;
          if (
            !(
              isValidPhoneNumber(phoneNumber) &&
              isPossiblePhoneNumber(phoneNumber) &&
              validatePhoneNumberLength(phoneNumber) == undefined
            )
          ) {
            setwithdrawError("invalid_phone_number");
            return false;
          } else {
            setwithdrawError("");
            return true;
          }
        }
      } else {
        setwithdrawError("");
        return true;
      }
    } catch (error) {
      setwithdrawError("");
      Sentry.captureException(error);
      return true;
    }
  }, [
    widthdrawMethod,
    selectedMobileOperator,
    customMobileOperator,
    phone,
    customPhoneNumber,
    selectedBankName,
    customBankName,
    iBAN,
    customIban,
    selectedMfiName,
    customMfiName,
    mfiOwnerId,
    customMfiOwnerId,
    directPaymentAmountExceed,
    fetchingAllFees,
    fetchingAllProviderAccounts,
    toWithDraw,
    neeroWalletNumber,
    customNeeroWalletNumber,
  ]);

  // all direct payment dropdown listings
  const [allProviderList, setallProviderList] = useState({
    mobileOperatorList: [],
    bankSelectionList: [],
    mfiSelectionList: [],
    checkingForWithdrawProviders: true,
  });
  // loader for getting withdraw providers
  const [checkingForWithdrawProviders, setcheckingForWithdrawProviders] =
    useState(true);

  // all user's accounts
  const fetchAllProviders = async (props: any) => {
    try {
      const result: any = await Promise.allSettled([
        fetchMMAccounts(),
        getAccounts(),
        getAllUserNeeroWallets(),
        // getMFIAccounts(),
      ]);

      let mmAccountsArr: any = [];
      let bankAccountsArr: any = [];
      let letMfiAccountsArr: any = [];
      let walletNeeroAccounts: any = [];

      // user mobile accounts
      if (result[0]?.value !== undefined) {
        const res = result[0]?.value;
        const mobileAccounts = res.data;

        if (mobileAccounts.length > 0) {
          mmAccountsArr = mobileAccounts
            .map((acc: any) => ({ name: acc.operator, id: acc.id }))
            .filter((itm: any) => itm?.name !== "Other")
            .concat(props.mobileOperatorList)
            .concat(other);

          const extProviders: any = removeDuplicateObjectsFromArray(
            mmAccountsArr,
            "name"
          );

          setmobileMoneyAccounts(extProviders);
        } else {
          setmobileMoneyAccounts(props.mobileOperatorList);
        }
      }
      // user bank accounts
      if (result[1]?.value !== undefined) {
        const res = result[1]?.value;
        const bankAccounts = res.data;

        if (bankAccounts.length > 0) {
          bankAccountsArr = bankAccounts
            .map((acc: any) => ({ name: acc.bank_name, id: acc.id }))
            .filter((itm: any) => itm?.name !== "Other")
            .concat(bankAccountsArr)
            .concat(other);

          const extProviders: any = removeDuplicateObjectsFromArray(
            bankAccountsArr,
            "name"
          );

          setBankAccounts(extProviders);
        } else {
          setBankAccounts(props.bankSelectionList);
        }
      }

      // neero wallet accounts
      if (result[2]?.value !== undefined) {
        const res = result[2]?.value;
        const neeroWallets = res.data;

        if (neeroWallets.length > 0) {
          walletNeeroAccounts = neeroWallets
            .map((acc: any) => ({
              name: acc.wallet_account_number,
              id: acc.id,
            }))
            .filter((itm: any) => itm?.name !== "Other")
            .concat(walletNeeroAccounts)
            .concat(other);

          const extProviders: any = removeDuplicateObjectsFromArray(
            walletNeeroAccounts,
            "name"
          );

          setneeroAccounts(extProviders);
        } else {
          setneeroAccounts([]);
        }
      }

      // user mfi accounts
      // if (result[2]?.value !== undefined) {
      //   const res = result[2]?.value;
      //   const mfiaccounts = res.data.filter(
      //     (account: any) => account.institution.type == "mfi"
      //   );

      //   if (mfiaccounts.length > 0) {
      //     letMfiAccountsArr = mfiaccounts
      //       .map((acc: any) => ({ name: acc.name, id: acc.institution_id }))
      //       .filter((itm: any) => itm?.name !== "Other")
      //       .concat(letMfiAccountsArr);

      //     const extProviders: any = removeDuplicateObjectsFromArray(
      //       letMfiAccountsArr,
      //       "name"
      //     );

      //     setmfiAccounts(extProviders);
      //   } else {
      //     setmfiAccounts(props.mfiSelectionList);
      //   }
      // }

      setfetchingAllProviderAccounts(false);
    } catch (error) {
      Sentry.captureException(error);
      setfetchingAllProviderAccounts(false);
    }
  };

  // calling dynamic endpoints to get all cashout methods
  const handleWithdrawData = async (endpointList: any) => {
    try {
      if (transfer?.isWithdrawOpen == false) {
        return;
      }

      let mobileOperatorList: any = [];
      let bankList: any = [];
      let mfiList: any = [];

      // dont call endpoints if no country code present
      if (isEmpty(user?.country_code)) {
        setallProviderList((prev: any) => ({
          ...prev,
          checkingForWithdrawProviders: false,
        }));
        setcheckingForWithdrawProviders(false);
        return;
      }

      if (endpointList.length > 0) {
        setcheckingForWithdrawProviders(true);
        setallProviderList((prev: any) => ({
          ...prev,
          checkingForWithdrawProviders: true,
        }));

        // call all endpoints in the array
        for (const endpoint of endpointList) {
          const result = await dynamicGetEndpoint(
            endpoint.provider,
            user?.country_code
          );

          if (result?.data && result?.data?.length > 0) {
            // bank accounts
            if (
              endpoint.type == "manual_bank_transfer" ||
              endpoint.type == "bank_transfer"
            ) {
              result?.data.forEach((data: any) => {
                if (
                  data?.institution?.type == "bank_transfer" ||
                  data?.institution?.type == "manual_bank_transfer"
                ) {
                  bankList.push(data);
                }
              });
            }

            // mfi accounts
            if (endpoint.type == "mfi") {
              result?.data.forEach((data: any) => {
                if (data?.institution?.type == "mfi") {
                  mfiList.push(data);
                }
              });
            }

            // mobile money accounts
            if (endpoint.type == "mobile_money") {
              result?.data.forEach((data: any) => {
                if (data?.institution?.type == "mobile_money") {
                  mobileOperatorList.push(data);
                }
              });
            }
          }
        }
        setcheckingForWithdrawProviders(false);

        setallProviderList({
          mobileOperatorList:
            mobileOperatorList.length > 0
              ? mobileOperatorList
                  .map((acc: any) => ({
                    name: acc.name,
                    id: acc.institution_id,
                  }))
                  .filter((itm: any) => itm?.name !== "Other")
              : [],
          mfiSelectionList: mfiList.length > 0 ? mfiList : [],
          bankSelectionList:
            bankList.length > 0
              ? bankList
                  .map((acc: any) => ({
                    name: acc.name,
                    id: acc.institution_id,
                  }))
                  .filter((itm: any) => itm?.name !== "Other")
              : [],
          checkingForWithdrawProviders: false,
        });

        // sending all providers to merge with users accounts
        fetchAllProviders({
          mobileOperatorList:
            mobileOperatorList.length > 0
              ? mobileOperatorList
                  .map((acc: any) => ({
                    name: acc.name,
                    id: acc.institution_id,
                  }))
                  .filter((itm: any) => itm?.name !== "Other")
              : [],
          mfiSelectionList: mfiList.length > 0 ? mfiList : [],
          bankSelectionList:
            bankList.length > 0
              ? bankList
                  .map((acc: any) => ({
                    name: acc.name,
                    id: acc.institution_id,
                  }))
                  .filter((itm: any) => itm?.name !== "Other")
              : [],
        });
      } else {
        setfetchingAllProviderAccounts(false);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // new withdraw logic
  // all cashout methods of user's logged in country
  useEffect(() => {
    try {
      if (transfer?.isWithdrawOpen == false) {
        return;
      }

      if (
        transfer?.getAllMobileOperator !== undefined &&
        transfer?.getAllMobileOperator !== null
      ) {
        let endpointList: any = [];

        // create dynamic endpoints
        transfer?.getAllMobileOperator.forEach((cashout: any) => {
          endpointList.push({
            provider: cashout?.cashout_method?.payment_provider?.name,
            type: cashout?.cashout_method?.payment_type?.name,
          });
        });

        if (
          transfer?.getAllMobileOperator !== undefined &&
          transfer?.getAllMobileOperator?.length > 0
        ) {
          setallDirectPaymentMethods(transfer?.getAllMobileOperator);
        } else {
          setallDirectPaymentMethods([]);
        }
        handleWithdrawData(endpointList);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [
    transfer?.getAllMobileOperator?.length,
    user?.country_code,
    mobile_accounts,
    bank_accounts,
  ]);

  return (
    <div
      className="max-w-[800px] my-3 mx-auto shadow-c rounded-lg p-4 max-sm:!px-2"
      id="transferPaymentWithdrawContainer"
    >
      <div className="font-bold text-2xl py-2 text-center">
        {t("withdaw_details")}
      </div>
      <div className="text-center text-md">{t("specify_withdraw_details")}</div>
      <UserSummary
        disabled={
          checkingForRestriction ||
          fetchingAllFees ||
          fetchingAllProviderAccounts ||
          noWithdrawMethods
        }
        userBalance={user?.balance_in_euro}
        amountInEuros={toWithDraw}
        setAmountInEuros={setToWithDraw}
      />
      <div className="mt-10 mb-3 px-0 text-black">
        {noWithdrawMethods && !fetchingAllProviderAccounts
          ? t("No_Withdraw_Methods")
          : t("specify_withdraw_method")}
      </div>

      {fetchingAllProviderAccounts && (
        <Skeleton height={200} containerClassName="p-0" />
      )}

      {!fetchingAllProviderAccounts && (
        <div className="bacnkCheckBoxWrapper flex flex-col m-0 p-0 max-sm:!px-0 gap-2 bg-white">
          {/* mobile */}
          {withdrawMethods.mobile && (
            <MobileWithdrawBox
              fetchingAllFees={
                fetchingAllFees ||
                (loading && widthdrawMethod == "Mobile Money")
              }
              setwidthdrawMethod={setwidthdrawMethod}
              widthdrawMethod={widthdrawMethod}
              transfer={transfer}
              numberInputInvalidChars={numberInputInvalidChars}
              cashoutMethod={cashoutMethod}
              setMethod={setMethod}
              countryCode={user.country_code}
              userMobileMoneyAccounts={mobile_accounts}
              mobileMoneyAccounts={mobileMoneyAccounts}
              allDirectPaymentMethods={allDirectPaymentMethods}
              directPaymentAmountExceed={directPaymentAmountExceed}
              phone={phone}
              setPhone={setPhone}
              selectedMobileOperator={selectedMobileOperator}
              setselectedMobileOperator={setselectedMobileOperator}
              customMobileOperator={customMobileOperator}
              setcustomMobileOperator={setcustomMobileOperator}
              customPhoneNumber={customPhoneNumber}
              setcustomPhoneNumber={setcustomPhoneNumber}
            />
          )}
          {withdrawMethods.wallet && (
            <NeeroWalletWithdrawBox
              fetchingAllFees={
                fetchingAllFees || (loading && widthdrawMethod == "wallet")
              }
              setwidthdrawMethod={setwidthdrawMethod}
              widthdrawMethod={widthdrawMethod}
              directPaymentAmountExceed={directPaymentAmountExceed}
              allDirectPaymentMethods={allDirectPaymentMethods}
              setMethod={setMethod}
              cashoutMethod={cashoutMethod}
              userNeeroAccounts={neeroAccounts}
              neeroWalletNumber={neeroWalletNumber}
              setneeroWalletNumber={setneeroWalletNumber}
              customNeeroWalletNumber={customNeeroWalletNumber}
              setcustomNeeroWalletNumber={setcustomNeeroWalletNumber}
              withdrawError={withdrawError}
            />
          )}

          {/* bank account */}
          {withdrawMethods.bank && (
            <BankWithdrawBox
              fetchingAllFees={
                fetchingAllFees ||
                (loading && widthdrawMethod == "Bank Account")
              }
              setwidthdrawMethod={setwidthdrawMethod}
              widthdrawMethod={widthdrawMethod}
              directPaymentAmountExceed={directPaymentAmountExceed}
              allDirectPaymentMethods={allDirectPaymentMethods}
              bankAccounts={bankAccounts}
              userBankAccounts={bank_accounts}
              setMethod={setMethod}
              cashoutMethod={cashoutMethod}
              selectedBankName={selectedBankName}
              setselectedBankName={setselectedBankName}
              customBankName={customBankName}
              setcustomBankName={setcustomBankName}
              iBAN={iBAN}
              setIBAN={setIBAN}
              customIban={customIban}
              setcustomIban={setcustomIban}
            />
          )}

          {/* disabled for now */}
          {/* micro transaction */}
          {/* {mfiAccounts && mfiAccounts.length > 0 && withdrawMethods.mfi && (
            <MfiWithdrawBox
              fetchingAllFees={
                fetchingAllFees ||
                (loading && widthdrawMethod == "Microfinance")
              }
              setwidthdrawMethod={setwidthdrawMethod}
              widthdrawMethod={widthdrawMethod}
              transfer={transfer}
              cashoutMethod={cashoutMethod}
              setMethod={setMethod}
              userMfiAccounts={mfi_accounts}
              mfiAccounts={mfiAccounts}
              directPaymentAmountExceed={directPaymentAmountExceed}
              setcustomMfiName={setcustomMfiName}
              setselectedMfiName={setselectedMfiName}
              selectedMfiName={selectedMfiName}
              customMfiName={customMfiName}
              setmfiAccountUserName={setmfiAccountUserName}
              mfiAccountUserName={mfiAccountUserName}
              setcustomMfiOwnerId={setcustomMfiOwnerId}
              setmfiOwnerId={setmfiOwnerId}
              mfiOwnerId={mfiOwnerId}
              customMfiOwnerId={customMfiOwnerId}
              allDirectPaymentMethods={allDirectPaymentMethods}
            />
          )} */}
          {withdrawMethods.delivery && (
            <DeliveryWithdrawBox
              fetchingAllFees={
                fetchingAllFees || (loading && widthdrawMethod == "delivery")
              }
              setwidthdrawMethod={setwidthdrawMethod}
              widthdrawMethod={widthdrawMethod}
              directPaymentAmountExceed={directPaymentAmountExceed}
              allDirectPaymentMethods={allDirectPaymentMethods}
              setMethod={setMethod}
              cashoutMethod={cashoutMethod}
              transfer={transfer}
            />
          )}
        </div>
      )}

      <CustomButton
        label="continue"
        classNames="w-full mt-10 min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
        disabled={
          !isValid || checkingForRestriction || checkingForWithdrawProviders
        }
        onClick={() => {
          handleSubmission();
        }}
      />
    </div>
  );
};

export default WithdrawDetails;
